import "./styles.css";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
// import cn from "classnames";
import Focus from "@tiptap/extension-focus";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { useCurrentEditor } from "@tiptap/react";
import italic from "../../Images/Icons/italic.svg";
import bold from "../../Images/Icons/bold.svg";
import code from "../../Images/Icons/code.svg";
import list from "../../Images/Icons/list.svg";
import strike from "../../Images/Icons/strike.svg";
import Gapcursor from "@tiptap/extension-gapcursor";
import Paragraph from "@tiptap/extension-paragraph";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import quote from "../../Images/Icons/quote.svg";
import Image from "@tiptap/extension-image";
import { useEffect, useRef, useState } from "react";
import { uploadFile } from "../../controllers/strapiController";
import Mention from "@tiptap/extension-mention";
import suggestionExtension from "./suggestion.js";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  AvatarGroup,
  Avatar,
  Tooltip,
} from "@nextui-org/react";
import Commands from "./command.js";
import commandsuggestion from "./commandsuggest.js";
import TextAlign from "@tiptap/extension-text-align";
import Document from "@tiptap/extension-document";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Underline from "@tiptap/extension-underline";
import Heading from "@tiptap/extension-heading";
import PermModal from "./PermModal.jsx";
import Settings from "./Settings.jsx";

const CustomDocument = Document.extend({
  content: "heading1 block*",
});

const Heading1 = Heading.extend({
  name: "heading1",
  addOptions() {
    return {
      levels: [1], // Only allow level 1 heading
    };
  },
});
export const extensions = (nameArray) => {
  return [
    CustomDocument,
    Heading1,
    StarterKit.configure({
      document: false,
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    }),
    Placeholder.configure({
      placeholder: ({ node }) => {
        if (node.type.name === "heading1") {
          return "Untitled";
        }

        return `Press '/' for commands`;
      },
    }),
    Underline,
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    Focus.configure({
      className: "has-focus",
      mode: "all",
    }),
    HorizontalRule,
    Paragraph,
    Mention.configure({
      HTMLAttributes: {
        class: "mention",
      },
      suggestion: suggestionExtension(nameArray),
    }),
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Commands.configure({
      suggestion: commandsuggestion,
    }),
    Image.configure({
      inline: true,
    }),
    Link.configure({
      HTMLAttributes: {
        class: "link-editor",
      },
    }),
    Highlight.configure({ multicolor: true }),
    TaskList,
    TaskItem.configure({
      className: " bg-pink-500",
      nested: false,
    }),
    Gapcursor,
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
  ];
};

export const MenuBar = ({
  descriptionContent,
  setDescriptionContent,
  imageArray,
  setImageArray,
  clear,
  initial,
  setInitial,
  members,
  res,
  permissions,
  setPermissions,
  owner,
  access,
  setAccess,
  id,
}) => {
  const { editor } = useCurrentEditor();
  const [fileUpload, setFileUpload] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const fileInputRef = useRef();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setFileUpload(file);
    } else {
      setFileUpload(null);
    }
  };
  const handlePaste = async (event, editor) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const formData = new FormData();
        formData.append("files", file);
        try {
          const response = await uploadFile(formData);
          const URLresponse = response && response[0];
          if (URLresponse) {
            const baseURL =
              process.env.REACT_APP_STAGE === "Dev"
                ? process.env.REACT_APP_STRAPI_IP_ADDRESS
                : "";
            const imageUrl = `${baseURL}${URLresponse.formats.small.url}`;
            setImageArray((prev) => [
              ...prev,
              {
                id: URLresponse.id,
                url: imageUrl,
              },
            ]);
            editor.chain().focus().setImage({ src: imageUrl }).run();
          }
        } catch (error) {
          console.error("Upload Error:", error);
        }
      }
    }
  };
  useEffect(() => {
    if (editor) {
      const handlePasteEvent = (event) => handlePaste(event, editor);
      document.addEventListener("paste", handlePasteEvent);
      return () => document.removeEventListener("paste", handlePasteEvent);
    }
  }, [editor]);

  const UploadFileHandler = async () => {
    let formData = new FormData();
    formData.append("files", fileUpload);
    try {
      const response = await uploadFile(formData);
      if (response) {
        const URLresponse = response && response[0];
        if (URLresponse) {
          setImageURL(
            `${
              process.env.REACT_APP_STAGE === "DEV"
                ? process.env.REACT_APP_STRAPI_IP_ADDRESS
                : ""
            }${URLresponse.formats.small.url}`
          );
          setImageArray((prev) => [
            ...prev,
            {
              id: URLresponse.id,
              url: `${
                process.env.REACT_APP_STAGE === "DEV"
                  ? process.env.REACT_APP_STRAPI_IP_ADDRESS
                  : ""
              }${URLresponse.formats.small.url}`,
            },
          ]);
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    if (clear > 0) {
      editor.commands.clearContent();
      setDescriptionContent("");
    }
  }, [clear]);
  useEffect(() => {
    if (fileUpload) {
      UploadFileHandler();
    }
  }, [fileUpload]);
  useEffect(() => {
    if (imageURL) {
      editor.chain().focus().setImage({ src: imageURL }).run();
    }
  }, [imageURL]);

  const html = editor.getHTML();
  useEffect(() => {
    if (editor && !initial) {
      editor.commands.setContent(descriptionContent);
      setInitial(true);
    }
  }, [editor, initial]);
  useEffect(() => {
    if (initial && descriptionContent !== html) {
      setDescriptionContent(html);
    }
  }, [html, initial]);
  const [rows, setRows] = useState(5);
  const [cols, setCols] = useState(5);
  const [hoverRow, setHoverRow] = useState(-1);
  const [hoverCol, setHoverCol] = useState(-1);
  const handleRenderTable = (i, j) => {
    editor
      .chain()
      .focus()
      .insertTable({ rows: i + 1, cols: j + 1, withHeaderRow: true })
      .run();
  };
  const handleSelectHover = (i, j) => {
    setHoverCol(j);
    setHoverRow(i);
    if (i < 9 && i > 3) {
      setRows(i + 2);
    }
    if (j < 9 && j > 3) {
      setCols(j + 2);
    }
    if (i < 4) {
      setRows(5);
    }
    if (j < 4) {
      setCols(5);
    }
  };

  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  if (!editor) {
    return null;
  }
  return (
    <div className="w-full flex items-center justify-between bg-[#F9F9FA] dark:bg-black dark:border dark:border-divider px-8">
      <PermModal
        id={id}
        isOpen={settingsOpen}
        close={() => setSettingsOpen(false)}
        owner={owner}
        access={access}
        setAccess={setAccess}
        permissions={permissions}
        setPermissions={setPermissions}
      />
      <div className="flex items-center menu-bar gap-2 ">
        <Tooltip content="Bold">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={`${
              editor.isActive("bold")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md  "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32]  rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[20px] h-[20px]"
            >
              <path
                strokeLinejoin="round"
                d="M6.75 3.744h-.753v8.25h7.125a4.125 4.125 0 0 0 0-8.25H6.75Zm0 0v.38m0 16.122h6.747a4.5 4.5 0 0 0 0-9.001h-7.5v9h.753Zm0 0v-.37m0-15.751h6a3.75 3.75 0 1 1 0 7.5h-6m0-7.5v7.5m0 0v8.25m0-8.25h6.375a4.125 4.125 0 0 1 0 8.25H6.75m.747-15.38h4.875a3.375 3.375 0 0 1 0 6.75H7.497v-6.75Zm0 7.5h5.25a3.75 3.75 0 0 1 0 7.5h-5.25v-7.5Z"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Italic">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={`${
              editor.isActive("italic")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-[20px] w-[20px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Underline">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={`${
              editor.isActive("underline")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.995 3.744v7.5a6 6 0 1 1-12 0v-7.5m-2.25 16.502h16.5"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Strike">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={`${
              editor.isActive("strike")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 12a8.912 8.912 0 0 1-.318-.079c-1.585-.424-2.904-1.247-3.76-2.236-.873-1.009-1.265-2.19-.968-3.301.59-2.2 3.663-3.29 6.863-2.432A8.186 8.186 0 0 1 16.5 5.21M6.42 17.81c.857.99 2.176 1.812 3.761 2.237 3.2.858 6.274-.23 6.863-2.431.233-.868.044-1.779-.465-2.617M3.75 12h16.5"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Insert image">
          <Button
            className="dark:hover:bg-[#2D2D32]"
            isIconOnly
            size="sm"
            variant="light"
            onClick={handleButtonClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
              className="  rounded-md  text-black dark:text-white  "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>

            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={(event) => handleFileChange(event)}
              className="hidden"
            />
          </Button>
        </Tooltip>
        <Tooltip content="Code">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleCode().run()}
            disabled={!editor.can().chain().focus().toggleCode().run()}
            className={`${
              editor.isActive("code")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md  "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Blockquote">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={`${
              editor.isActive("blockquote")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md  "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="size-5"
            >
              <path d="M16 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z" />
              <path d="M5 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z" />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Bullet list">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={`${
              editor.isActive("bulletlist")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Numbered list">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={`${
              editor.isActive("orderedlist")
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Task list">
          <Button
            size="sm"
            isIconOnly
            variant="light"
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            className={`${
              editor.isActive("taskList")
                ? "is-active"
                  ? "dark:bg-[#2D2D32]"
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
              className=" rounded-md  text-black dark:text-white  "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
              />
            </svg>
          </Button>
        </Tooltip>

        <div className=" flex items-center">
          <Popover
            onClose={() => {
              setCols(5);
              setRows(5);
              setHoverCol(-1);
              setHoverRow(-1);
            }}
            placement="bottom"
          >
            <PopoverTrigger>
              
                <Button
                  className="dark:hover:bg-[#2D2D32]"
                  size="sm"
                  isIconOnly
                  variant="light"
                >
                  <Tooltip content="Inset table">
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>
                  </Tooltip>
                  
                </Button>
              
            </PopoverTrigger>
            <PopoverContent className=" p-2 flex flex-col gap-4">
              <p>{`${hoverRow + 1 > 0 ? hoverRow + 1 : 0} X ${
                hoverCol + 1 > 0 ? hoverCol + 1 : 0
              }`}</p>
              <div className=" flex flex-col gap-1">
                {Array.from({ length: rows }).map((_, rowIndex) => (
                  <div key={rowIndex} className="flex items-center gap-1">
                    {Array.from({ length: cols }).map((_, colIndex) => {
                      const isHighlighted =
                        colIndex <= hoverCol && rowIndex <= hoverRow;
                      return (
                        <div
                          key={`${rowIndex}_${colIndex}`}
                          id={`${rowIndex}_${colIndex}`}
                          onClick={() => handleRenderTable(rowIndex, colIndex)}
                          onMouseEnter={() =>
                            handleSelectHover(rowIndex, colIndex)
                          }
                          className={`h-4 w-4 cursor-pointer border border-divider ${
                            isHighlighted ? "border-blue-400" : ""
                          }`}
                        ></div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <Tooltip content="Delete table">
          <Button
            onClick={() => editor.chain().focus().deleteTable().run()}
            size="sm"
            isIconOnly
            variant="light"
            className="dark:hover:bg-[#2D2D32]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Left align">
          <Button
            size="sm"
            isIconOnly
            variant="light"
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            className={`${
              editor.isActive({ textAlign: "left" })
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Center Align">
          <Button
            size="sm"
            isIconOnly
            variant="light"
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            className={`${
              editor.isActive({ textAlign: "center" })
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Right Align">
          <Button
            size="sm"
            isIconOnly
            variant="light"
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            className={`${
              editor.isActive({ textAlign: "right" })
                ? "is-active"
                  ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
                  : "bg-transparent"
                : ""
            } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Horizontal Rule">
          <Button
            size="sm"
            isIconOnly
            variant="light"
            className="dark:hover:bg-[#2D2D32]"
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Undo">
          <Button
            className="dark:hover:bg-[#2D2D32]"
            size="sm"
            disabled={!editor.can().undo()}
            isIconOnly
            variant="light"
            onClick={() => editor.chain().focus().undo().run()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
              />
            </svg>
          </Button>
        </Tooltip>
        <Tooltip content="Redo">
          <Button
            className="dark:hover:bg-[#2D2D32]"
            size="sm"
            isIconOnly
            disabled={!editor.can().redo()}
            variant="light"
            onClick={() => editor.chain().focus().redo().run()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499"
              />
            </svg>
          </Button>
        </Tooltip>
      </div>
      <div className="flex gap-4 items-center">
        <AvatarGroup max={6}>
          {members.map((item) => (
            <Avatar
              style={{ height: "25px", width: "25px" }}
              src={res && res[String(item)]?.ProfilePicture}
              showFallback
              name={
                res &&
                (res[String(item)]?.UserName?.toUpperCase()?.charAt(0) ?? "")
              }
              color={Colors[Number(item) % numColors]}
            />
          ))}
        </AvatarGroup>
        <Settings permissions={permissions} setIsOpen={setSettingsOpen} />
      </div>
    </div>
  );
};
export const props = (editable) => {
  return {
    attributes: {
      class: `prose max-w-none blueprint [&_ol]:list-decimal [&_ul]:list-disc blueprint`,
      contenteditable: editable ? true : false,
    },
  };
};
// export default () => {
//   return (
//     <EditorProvider editorProps={props} slotBefore={<MenuBar />} extensions={extensions} content={content}></EditorProvider>
//   )
// }
