import {
  Divider,
  Pagination,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteDataInbox, getAllInbox } from "../../controllers/vectorDB";
import { tableCell } from "./tableCell";
import InboxFilter from "./InboxFilter";
import InboxGroups from "./InboxGroups";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData } from "../../redux/actions";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import HelixLoader from "../../components/Loader/HelixLoader";
import {
  customizeTableCell,
  generateFilter,
  groupingCustomAttributes,
} from "../../utils/customAttributes";
import { paginateArray, removePrefix } from "../../utils/default";
import CustomGroups from "./Customgroups";

function InboxTable({ toggle, handleDrawer, customTags, template }) {
  const res = useSelector(selectResData);
  const orgData = useSelector(selectOrgData);
  const customizeList = ["Owner", "Tags", "Members"];
  const [threads, setThreads] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const [filterArr, setFilterArr] = useState([]);
  const emailId = JSON.parse(sessionStorage.getItem("userData")).user.email;
  const [filteredThreads, setFilteredThreads] = useState([]);
  const { org } = useParams();
  const [customizeItems, setCustomizeItems] = useState(new Set(["Tags"]));
  const [toggleOwner, setToggleOwner] = useState(false);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(new Set(["latest"]));
  const [selectedGroup, setSelectedGroup] = useState(new Set(["None"]));
  const [groupKeys, setGroupKeys] = useState(new Set([]));
  const [group, setGroup] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });
  const handleDelete = async (id) => {
    try {
      await deleteDataInbox(id, org);
      const arr = threads.filter(
        (item) => Number(item.payload.id) !== Number(id)
      );
      setThreads(arr);
      setFilteredThreads(arr);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const customizeHeaderList = [
    {
      id: "customer",
      label: "Customer",
    },
    {
      id: "source",
      label: "Source",
    },
    {
      id: "stage",
      label: "Stage",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customers = await getFilteredBySingleRelation(
          "customers",
          "organization",
          orgData.id
        );
        setCustomers(customers.data);
        setLoading(false);
      } catch (error) {}
    };
    if (orgData?.id) {
      fetchCustomers();
    }
  }, [orgData]);
  useEffect(() => {
    setFilterArr(generateFilter(template));
  }, [template]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resp = await getAllInbox(org);
        setThreads(resp);
        setFilteredThreads(resp);
        setLoader(false);
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (org && !loading) {
      fetchAll();
    }
  }, [org, loading]);
  useEffect(() => {
    if (
      threads.length > 0 &&
      Array.from(selectedGroup)[0] !== "None" &&
      !Array.from(selectedGroup)[0].startsWith("metadata_")
    ) {
      let groups = [];
      const key = Array.from(selectedGroup)[0];
      const group_by =
        key === "Customer"
          ? "customerId"
          : key === "Owner"
          ? "assignedId"
          : key === "Stage"
          ? "stage"
          : key === "Members"
          ? "members"
          : "tags";
      threads.map((thread) => {
        if (
          group_by === "customerId" ||
          group_by === "assignedId" ||
          group_by === "stage"
        ) {
          groups.push(thread.payload[group_by]);
        } else {
          thread.payload[group_by].map((item) => {
            groups.push(item);
          });
        }
      });
      setGroupKeys(new Set(groups));
    } else if (
      Array.from(selectedGroup).length > 0 &&
      Array.from(selectedGroup)[0].startsWith("metadata_")
    ) {
      const finalGroup = {};
      filteredThreads.map((item) => {
        groupingCustomAttributes(
          Array.from(selectedGroup)[0],
          template,
          item.payload.metadata,
          finalGroup,
          item,
          setGroupType
        );
      });
      setGroup(finalGroup);
    }
  }, [threads, selectedGroup]);

  useEffect(() => {
    if (threads.length > 0) {
      let filteredThreads = threads;
      if (toggle === "primary") {
        filteredThreads = filteredThreads.filter((thread) =>
          customers.some(
            (customer) =>
              customer.attributes.Email === thread.payload.customerMail
          )
        );
      }
      if (toggle === "spam") {
        filteredThreads = filteredThreads.filter(
          (thread) => thread.payload.customerMail === null
        );
      }
      if (toggle === "guest") {
        filteredThreads = filteredThreads.filter(
          (thread) =>
            thread.payload.customerMail && // Check if customerMail is not null or undefined
            !customers.some(
              (customer) =>
                customer.attributes.Email === thread.payload.customerMail
            )
        );
      }
      filteredThreads = filteredThreads.filter((thread) =>
        thread.payload.customerMail.toLowerCase().includes(search.toLowerCase())
      );

      filterArr.map((item) => {
        if (item?.val?.length > 0) {
          filteredThreads = filteredThreads.filter((t) => {
            if (t.payload.metadata && Array.isArray(t.payload.metadata)) {
              const find = t.payload.metadata.find(
                (field) => String(field.id) === String(item.id)
              );
              if (find) {
                return find?.val?.some((m) => item.val.includes(m));
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      });
      if (toggleOwner) {
        filteredThreads = filteredThreads.filter(
          (thread) => `${org}_${emailId}` === thread.payload.assignedId
        );
      }
      if (Array.from(sortOrder)[0] === "latest") {
        filteredThreads = filteredThreads.sort(
          (a, b) => b.payload.updatedAt - a.payload.updatedAt
        );
      }
      if (Array.from(sortOrder)[0] === "oldest") {
        filteredThreads = filteredThreads.sort(
          (a, b) => a.payload.updatedAt - b.payload.updatedAt
        );
      }
      if (Array.from(sortOrder)[0] === "high") {
        filteredThreads = filteredThreads.sort(
          (a, b) => b.payload.tickets.length - a.payload.tickets.length
        );
      }
      if (Array.from(sortOrder)[0] === "low") {
        filteredThreads = filteredThreads.sort(
          (a, b) => a.payload.tickets.length - b.payload.tickets.length
        );
      }
      setFilteredThreads(filteredThreads);
    }
  }, [search, toggleOwner, sortOrder, threads, customers, toggle, filterArr]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
      </div>
    );
  } else {
    return (
      <div className="w-full flex flex-col flex-1">
        <div className="w-full px-4 overflow-y-auto">
          <InboxFilter
            search={search}
            setSearch={setSearch}
            isCreated={toggleOwner}
            setIsCreated={setToggleOwner}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setCustomizeItems={setCustomizeItems}
            customizeItems={customizeItems}
            customizeCompleteList={customizeList}
            setFilterArr={setFilterArr}
            filterArr={filterArr}
            template={template}
          />
        </div>
        <div className="w-full">
          <Divider />
        </div>
        <div className="w-full flex-1 overflow-y-auto">
          {Array.from(selectedGroup)[0] === "None" ? (
            <>
              <Table removeWrapper className="mt-10 px-4">
                <TableHeader columns={customizeHeaderList}>
                  {(column) => (
                    <TableColumn key={column.id} className="text-xs">
                      {column.id.startsWith("metadata_")
                        ? template?.find(
                            (it) =>
                              it.id === removePrefix(column.id, "metadata_")
                          )?.name ?? ""
                        : column.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody emptyContent={"Inbox is empty."}>
                  {paginateArray(filteredThreads, 10, page - 1).map(
                    (thread, index) => (
                      <TableRow
                        onClick={() => handleDrawer(thread.payload.id)}
                        className=" cursor-pointer hover:bg-gray-100 dark:hover:bg-[#2D2D32] text-xs  border-b border-divider"
                        key={index}
                      >
                        {customizeHeaderList.map((item) => {
                          if (item.id.startsWith("metadata_")) {
                            return customizeTableCell(
                              item.id,
                              template,
                              thread.payload.metadata,
                              res
                            );
                          } else {
                            return tableCell(
                              res,
                              item.id,
                              thread,
                              customTags,
                              handleDelete,
                              org
                            );
                          }
                        })}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              {Math.ceil(filteredThreads?.length / 10) > 1 && (
                <div className="w-full flex justify-center items-center">
                  <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="danger"
                    page={page}
                    total={Math.ceil(filteredThreads?.length / 10)}
                    onChange={setPage}
                    className="mt-4"
                  />
                </div>
              )}
            </>
          ) : Array.from(selectedGroup)[0].startsWith("metadata_") ? (
            <CustomGroups
              group={group}
              type={groupType}
              handleDelete={handleDelete}
              customTags={customTags}
              template={template}
              customizeHeaderList={customizeHeaderList}
              handleDrawer={handleDrawer}
            />
          ) : (
            <InboxGroups
              template={template}
              customTags={customTags}
              customizeHeaderList={customizeHeaderList}
              handleDelete={handleDelete}
              handleDrawer={handleDrawer}
              res={res}
              keys={Array.from(groupKeys)}
              groupBy={Array.from(selectedGroup)[0]}
              filteredThreads={filteredThreads}
            />
          )}
        </div>
      </div>
    );
  }
}

export default InboxTable;
