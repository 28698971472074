import {
  Avatar,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  TableCell,
  DropdownTrigger,
  Button,
} from "@nextui-org/react";

// Function to get month names based on month number
export function getMonthName(monthNumber) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[monthNumber - 1];
}

export const Colors = ["secondary", "success", "warning", "danger", "primary"];
export const numColors = Colors.length;

export const renderCell = (
  row,
  columnId,
  res,
  parsedParts,
  handleDelete,
  customTags,
  status,
  deletePerm
) => {
  switch (columnId) {
    case "items":
      return (
        <TableCell className="text-xs ">
          <div className="flex gap-4 text-xs items-start">
            <Chip
              color={`${
                row?.payload?.type === "ticket"
                  ? "danger"
                  : row.payload.type === "issue"
                  ? "primary"
                  : "success"
              }`}
              radius="full"
              variant="flat"
              size="sm"
              className="text-xs flex items-center"
            >
              <div className="text-xs">
                {`${row?.payload?.type === "ticket" ? "TKT" : "ISS"}`}-
                {row?.payload?.latest}
              </div>
            </Chip>
            {status && (
              <Chip size="sm" radius="full" variant="flat" className="text-xs">
                Pending
              </Chip>
            )}
          </div>
        </TableCell>
      );
    case "title":
      return <TableCell className="text-xs">{row?.payload?.title}</TableCell>;

    case "stage":
      return (
        <TableCell className="capitalize text-xs">
          {row?.payload?.stage}
        </TableCell>
      );
    case "owner":
      return (
        <TableCell>
          <Avatar
            radius="sm"
            color={Colors[Number(row.payload.owner) % numColors]}
            size="sm"
            src={
              res?.[row.payload.owner]?.ProfilePicture
                ? process.env.REACT_APP_STAGE === "Dev"
                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                      res[row.payload.owner].ProfilePicture
                    }`
                  : `${res[row.payload.owner].ProfilePicture}`
                : null
            }
            showFallback
            name={
              res &&
              res[row.payload.owner] &&
              (res[row.payload.owner].FirstName
                ? `${res[row.payload.owner].FirstName?.charAt(0)}`
                : `${res[row.payload.owner].UserName?.charAt(0).toUpperCase()}`)
            }
          />
        </TableCell>
      );
    case "assigned to":
      return (
        <TableCell>
          {row.payload.assigned === "" ? (
            "-"
          ) : (
            <Avatar
              showFallback
              name={
                res &&
                res[row.payload.assigned] &&
                (res[row.payload.assigned].FirstName
                  ? `${res[row.payload.assigned].FirstName?.charAt(0)}`
                  : `${res[row.payload.assigned].UserName?.charAt(
                      0
                    ).toUpperCase()}`)
              }
              radius="sm"
              color={Colors[Number(row.payload.assigned) % numColors]}
              size="sm"
              src={
                res?.[row.payload.assigned]?.ProfilePicture
                  ? process.env.REACT_APP_STAGE === "Dev"
                    ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                        res[row.payload.assigned].ProfilePicture
                      }`
                    : `${res[row.payload.assigned].ProfilePicture}`
                  : null
              }
            />
          )}
        </TableCell>
      );
    case "priority":
      return (
        <TableCell>
          {row.payload.priority ? row.payload.priority : "-"}
        </TableCell>
      );
    case "estimated days":
      return (
        <TableCell>
          {row.payload.estimatedays ? row.payload.estimatedays : "-"}
        </TableCell>
      );
    case "target closing date":
      return (
        <TableCell>
          {row.payload.targetclosedate
            ? row.payload.targetclosedate.month
              ? `${getMonthName(row.payload.targetclosedate.month)} ${
                  row.payload.targetclosedate.day
                } ${row.payload.targetclosedate.year}`
              : "-"
            : "-"}
        </TableCell>
      );

    case "part":
      const partId = row.payload.part;
      const part = parsedParts[String(partId)];

      return (
        <TableCell className="text-xs">{part ? part.Name : "-"}</TableCell>
      );
    case "tags":
      return (
        <TableCell>
          {row.payload.tags && row.payload.tags.length > 0 ? (
            customTags.filter((item) => row.payload.tags.includes(item.id))
              .length < 3 ? (
              <div className="flex gap-2 items-center">
                {customTags
                  .filter((item) => row.payload.tags.includes(item.id))
                  .map((item) => (
                    <Chip
                      variant="bordered"
                      key={item.id}
                      size="sm"
                      className="border border-divider gap-2 text-xs"
                      startContent={
                        <div
                          style={{ backgroundColor: item.attributes.Color }}
                          className="w-2 h-2 rounded-full"
                        ></div>
                      }
                    >
                      {item.attributes.Name}
                    </Chip>
                  ))}{" "}
              </div>
            ) : (
              <div className="flex gap-2 items-center">
                <Chip
                  variant="bordered"
                  key={
                    customTags.filter((item) =>
                      row.payload.tags.includes(item.id)
                    )[0].id
                  }
                  size="sm"
                  className="border border-divider gap-2 text-xs"
                  startContent={
                    <div
                      style={{
                        backgroundColor: customTags.filter((item) =>
                          row.payload.tags.includes(item.id)
                        )[0].attributes.Color,
                      }}
                      className="w-2 h-2 rounded-full"
                    ></div>
                  }
                >
                  {
                    customTags.filter((item) =>
                      row.payload.tags.includes(item.id)
                    )[0].attributes.Name
                  }
                </Chip>
                <Chip
                  variant="bordered"
                  key={
                    customTags.filter((item) =>
                      row.payload.tags.includes(item.id)
                    )[1].id
                  }
                  size="sm"
                  className="border border-divider gap-2 text-xs"
                  startContent={
                    <div
                      style={{
                        backgroundColor: customTags.filter((item) =>
                          row.payload.tags.includes(item.id)
                        )[1].attributes.Color,
                      }}
                      className="w-2 h-2 rounded-full"
                    ></div>
                  }
                >
                  {
                    customTags.filter((item) =>
                      row.payload.tags.includes(item.id)
                    )[1].attributes.Name
                  }
                </Chip>{" "}
                +
                {customTags.filter((item) => row.payload.tags.includes(item.id))
                  .length - 2}
              </div>
            )
          ) : (
            "-"
          )}
        </TableCell> // Display a placeholder if there are no tags
      );
    case "delete":
      if (!deletePerm) {
        return <TableCell></TableCell>;
      } else
        return (
          <TableCell className="flex justify-end">
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Button size="sm" color="danger" variant="light" isIconOnly>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu size="sm">
                <DropdownItem
                  onClick={() =>
                    handleDelete({
                      images: row.payload.imageIds,
                      id: row.payload.id,
                    })
                  }
                  variant="flat"
                  color="danger"
                >
                  Delete Permanently
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TableCell>
        );
    default:
      return <TableCell>{columnId}</TableCell>; // Default case to handle unexpected column IDs
  }
};
export const renderGroup = (
  row,
  columnId,
  res,
  parsedParts,
  handleDelete,
  customTags,
  deletePerm
) => {
  switch (columnId) {
    case "items":
      return (
        <TableCell className="text-xs">
          <Chip
            color={
              row?.payload?.type === "ticket"
                ? "danger"
                : row?.payload?.type === "issue"
                ? "primary"
                : "success"
            }
            radius="full"
            variant="flat"
            size="sm"
            className="text-xs"
          >
            <div className="text-xs">
              {`${row?.payload?.type === "ticket" ? "TKT" : "ISS"}-${
                row?.payload?.latest
              }`}
            </div>
          </Chip>
        </TableCell>
      );

    case "title":
      return <TableCell className="text-xs">{row?.payload?.title}</TableCell>;

    case "part":
      const partId = row.payload.part;
      const part = parsedParts[String(partId)];

      return (
        <TableCell className="text-xs">{part ? part.Name : "boo"}</TableCell>
      );
    case "assigned to":
      return (
        <TableCell>
          {row?.payload?.assigned === "" ? (
            "-"
          ) : (
            <Avatar
              showFallback
              name={
                res &&
                res[row.payload.assigned] &&
                (res[row.payload.assigned].FirstName
                  ? `${res[row.payload.assigned].FirstName?.charAt(0)}`
                  : `${res[row.payload.assigned].UserName?.charAt(
                      0
                    ).toUpperCase()}`)
              }
              radius="sm"
              color={Colors[Number(row.payload.assigned) % numColors]}
              size="sm"
              src={
                res?.[row.payload.assigned]?.ProfilePicture
                  ? process.env.REACT_APP_STAGE === "Dev"
                    ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                        res[row.payload.assigned].ProfilePicture
                      }`
                    : `${res[row.payload.assigned].ProfilePicture}`
                  : null
              }
            />
          )}
        </TableCell>
      );

    case "stage":
      return (
        <TableCell className="capitalize text-xs">
          {row?.payload?.stage}
        </TableCell>
      );

    case "owner":
      return (
        <TableCell>
          <Avatar
            showFallback
            name={
              res &&
              res[row.payload.owner] &&
              (res[row.payload.owner].FirstName
                ? `${res[row.payload.owner].FirstName?.charAt(0)}`
                : `${res[row.payload.owner].UserName?.charAt(0).toUpperCase()}`)
            }
            radius="sm"
            color={Colors[Number(row.payload.owner) % numColors]}
            size="sm"
            src={
              res?.[row.payload.owner]?.ProfilePicture
                ? process.env.REACT_APP_STAGE === "Dev"
                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                      res[row.payload.owner].ProfilePicture
                    }`
                  : `${res[row.payload.owner].ProfilePicture}`
                : null
            }
          />
        </TableCell>
      );

    case "priority":
      return <TableCell>{row?.payload?.priority || "-"}</TableCell>;

    case "estimated days":
      return <TableCell>{row?.payload?.estimatedays || "-"}</TableCell>;

    case "target closing date":
      return (
        <TableCell>
          {row?.payload?.targetclosedate
            ? `${getMonthName(row?.payload?.targetclosedate?.month)} ${
                row?.payload?.targetclosedate?.day
              } ${row?.payload?.targetclosedate?.year}`
            : "-"}
        </TableCell>
      );

    case "tags":
      const filteredTags = customTags.filter((item) =>
        row.payload.tags.includes(item.id)
      );
      return (
        <TableCell>
          {filteredTags.length > 0 ? (
            <div className="flex gap-2 items-center">
              {filteredTags.length < 3 ? (
                filteredTags.map((item) => (
                  <Chip
                    key={item.id}
                    variant="bordered"
                    size="sm"
                    className="border border-divider gap-2 text-xs"
                    startContent={
                      <div
                        style={{ backgroundColor: item.attributes.Color }}
                        className="w-2 h-2 rounded-full"
                      />
                    }
                  >
                    {item.attributes.Name}
                  </Chip>
                ))
              ) : (
                <>
                  {filteredTags.slice(0, 2).map((item) => (
                    <Chip
                      key={item.id}
                      variant="bordered"
                      size="sm"
                      className="border border-divider gap-2 text-xs"
                      startContent={
                        <div
                          style={{ backgroundColor: item.attributes.Color }}
                          className="w-2 h-2 rounded-full"
                        />
                      }
                    >
                      {item.attributes.Name}
                    </Chip>
                  ))}
                  <span>+{filteredTags.length - 2}</span>
                </>
              )}
            </div>
          ) : (
            "-"
          )}
        </TableCell>
      );

    case "delete":
      if (!deletePerm) {
        return <TableCell />;
      } else {
        return (
          <TableCell>
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" color="danger" variant="light" isIconOnly>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu size="sm">
                <DropdownItem
                  onClick={() =>
                    handleDelete({
                      images: row.payload.imageIds,
                      id: row.payload.id,
                    })
                  }
                  variant="flat"
                  color="danger"
                >
                  Delete Permanently
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TableCell>
        );
      }

    default:
      return <TableCell>{columnId}</TableCell>; // Default case to handle unexpected column IDs
  }
};
