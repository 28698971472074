import React from "react";
import {
  customizeTableCell,
  DROPDOWN,
  PEOPLE,
} from "../../utils/customAttributes";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import {
  Accordion,
  AccordionItem,
  Avatar,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { Colors, numColors } from "../../utils/constant";
import { removePrefix } from "../../utils/default";

const ComponentGroup = ({
  group,
  type,
  columns,
  template,
  toggleProductManagement,
}) => {
  const res = useSelector(selectResData);
  if (group) {
    return (
      <div className="h-full flex overflow-y-auto">
        <div className="flex flex-col h-full overflow-y-auto w-96 border-r border-divider ">
          {type === DROPDOWN ? (
            <div className="flex flex-col w-full gap-2">
              {Object.keys(group).map((key) => (
                <div className="w-full hover:bg-gray-100 px-4 py-2  flex justify-between items-center cursor-pointer">
                  <p className="text-sm ">{key}</p>
                  <div className="text-xs text-gray-400">
                    {group[key]?.length}
                  </div>
                </div>
              ))}
            </div>
          ) : type === PEOPLE ? (
            <div className="flex flex-col w-full gap-2">
              {Object.keys(group).map((key) => (
                <div className="w-full hover:bg-gray-100 px-4 py-2  flex justify-between items-center cursor-pointer">
                  <div className="flex items-center gap-2">
                    <Avatar
                      color={Colors[Number(key) % numColors]}
                      showFallback
                      name={
                        res[String(key)]?.FirstName
                          ? res[String(key)]?.FirstName[0]
                          : res[String(key)]?.UserName
                          ? res[String(key)]?.UserName[0]
                          : ""
                      }
                      size="sm"
                      radius="sm"
                      src={
                        res[String(key)]?.ProfilePicture?.formats?.small?.url
                          ? process.env.REACT_APP_STAGE === "Dev"
                            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[String(key)].ProfilePicture.formats.small.url}`
                            : `${res[String(key)].ProfilePicture.formats.small.url}`
                          : ""
                      }
                    />

                    <p className="text-xs">
                      {res[String(key)]?.FirstName
                        ? res[String(key)]?.FirstName
                        : res[String(key)]?.UserName
                        ? res[String(key)]?.UserName
                        : "Unassigned"}
                    </p>
                  </div>
                  <div className="text-xs text-gray-400">
                    {group[key]?.length}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="h-full overflow-y-auto px-4 py-2 flex flex-col gap-4 w-full">
          {Object.keys(group || {}).map((key, ind) => (
            <div className=" px-4 mb-10" key={ind}>
              <Accordion defaultExpandedKeys={["1"]}>
                <AccordionItem
                  key="1"
                  title={
                    type === DROPDOWN ? (
                      <div>
                        <p className="text-sm ">{key}</p>
                      </div>
                    ) : type === PEOPLE ? (
                      <div className="flex items-center gap-2">
                        <Avatar
                          color={Colors[Number(key) % numColors]}
                          showFallback
                          name={
                            res[String(key)]?.FirstName
                              ? res[String(key)]?.FirstName[0]
                              : res[String(key)]?.UserName
                              ? res[String(key)]?.UserName[0]
                              : ""
                          }
                          size="sm"
                          radius="sm"
                          src={
                            res[String(key)]?.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[String(key)].ProfilePicture.formats.small.url}`
                                : `${res[String(key)].ProfilePicture.formats.small.url}`
                              : ""
                          }
                        />

                        <p className="text-xs">
                          {res[String(key)]?.FirstName
                            ? res[String(key)]?.FirstName
                            : res[String(key)]?.UserName
                            ? res[String(key)]?.UserName
                            : "Unassigned"}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                >
                  <Table removeWrapper className="mt-10  ">
                    <TableHeader columns={columns}>
                      {(column) => (
                        <TableColumn key={column.key} className="text-xs">
                          {column.key.startsWith("metadata_")
                            ? template?.find(
                                (it) =>
                                  it.id ===
                                  removePrefix(column.key, "metadata_")
                              )?.name ?? ""
                            : column.label}
                        </TableColumn>
                      )}
                    </TableHeader>
                    <TableBody items={group[key]}>
                      {(item) => (
                        <TableRow
                          className=" cursor-pointer hover:bg-gray-100 text-xs border-b border-divider"
                          key={item.key}
                          onClick={() => toggleProductManagement(item)}
                        >
                          {columns.map((column) => {
                            if (column.key.startsWith("metadata_")) {
                              return customizeTableCell(
                                column.key,
                                template,
                                item.metadata,
                                res
                              );
                            } else {
                              return (
                                <TableCell className="text-xs" key={column.key}>
                                  {column.key === "part" ? (
                                    <Chip
                                      className=""
                                      color={
                                        item["type"] === "Product"
                                          ? "primary"
                                          : item["type"] === "Capability"
                                          ? "secondary"
                                          : item["type"] === "Enhancement"
                                          ? "warning"
                                          : item["type"] === "Feature"
                                          ? "success"
                                          : "danger"
                                      }
                                      variant="flat"
                                      size="sm"
                                    >
                                      {item["type"] === "Product"
                                        ? "PRO"
                                        : item["type"] === "Capability"
                                        ? "CAP"
                                        : item["type"] === "Enhancement"
                                        ? "ENH"
                                        : item["type"] === "Feature"
                                        ? "FEA"
                                        : "RUN"}
                                      -{item[column.key]}
                                    </Chip>
                                  ) : column.key === "stage" ? (
                                    <p>{item.stage ? item.stage : "-"}</p>
                                  ) : column.key === "delete" ? (
                                    <></>
                                  ) : column.key === "owner" ? (
                                    <Avatar
                                      showFallback
                                      color={
                                        Colors[
                                          Number(item[column.key]) % numColors
                                        ]
                                      }
                                      name={
                                        res[String(item[column.key])]?.FirstName
                                          ? res[
                                            String(item[column.key])
                                            ]?.FirstName[0].toUpperCase()
                                          : res[
                                            String(item[column.key])
                                            ]?.UserName[0].toUpperCase()
                                      }
                                      size="sm"
                                      radius="sm"
                                      src={
                                        process.env.REACT_APP_STAGE === "Dev"
                                          ? `${
                                              process.env
                                                .REACT_APP_STRAPI_IP_ADDRESS
                                            }${
                                              res[String(item[column.key])]
                                                ?.ProfilePicture
                                            }`
                                          : `${
                                              res[String(item[column.key])]
                                                ?.ProfilePicture
                                            }`
                                      }
                                    />
                                  ) : column.key === "totaltickets" ||
                                    column.key === "totalissues" ? (
                                    item[column.key]?.length
                                  ) : (
                                    item[column.key]
                                  )}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </AccordionItem>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default ComponentGroup;
