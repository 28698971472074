import CryptoJS from "crypto-js";

export const encryptedText = (text) => {
  const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
  const inputString = text;
  const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
  const AESHash = encrypted.toString();
  const url = btoa(AESHash);
  return url;
};

export const decryptedText = (encryptedText) => {
  const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
  const inputString = atob(encryptedText).toString();

  const text = CryptoJS.AES.decrypt(inputString, secretKey).toString(
    CryptoJS.enc.Utf8
  );

  return text;
};

export const generateOtp = () => {
  const otp = Math.floor(100000 + Math.random() * 900000);

  return otp.toString();
};

export function areArraysEqual(arr1, arr2) {
  return arr1.length === arr2.length && new Set(arr1).size === new Set(arr2).size && arr1.every(item => arr2.includes(item));
}