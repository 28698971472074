import React, { useEffect, useState, useRef } from "react";
import Issue from "../Issues/issuedetails";
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  getFilteredBySingleRelation,
  getOneData,
} from "../../controllers/strapiController";
import Ticket from "../Tickets/ticketdetails";
import UpdateDrawer from "./UpdateDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tables from "./Table";
import CreateDrawer from "./CreateDrawer";
import ParentDropDown from "./ParentDropDown";
import OwnerDropdown from "./OwnerDropdown";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { useSelector } from "react-redux";
import {
  selectOrgData,
  selectPermissions,
  selectResData,
  selectUserData,
  selectUserNames,
} from "../../redux/actions";
import {
  DROPDOWN,
  generateFilter,
  LONG_TEXT,
  MARKDOWN_TEXT,
  PEOPLE,
} from "../../utils/customAttributes";
import FilterPeople from "../../components/Filters/FilterPeople";
import FilterDropdown from "../../components/Filters/Filter";

const ProductTrails = () => {
  const { org } = useParams();
  const searchRef = useRef();
  const drawerRef = useRef();
  const ticketRef = useRef();
  const tickRef = useRef();
  const inputRef = useRef();
  const divRef = useRef();
  const issueRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const newModalref = useRef();
  const navigate = useNavigate();
  const userNames = useSelector(selectUserNames);
  const currentOrg = useSelector(selectOrgData);
  const users = useSelector(selectUserData);
  const [grouping, setGrouping] = useState(new Set(["None"]));
  const [filterArr, setFilterArr] = useState([]);
  const [template, setTemplate] = useState([]);
  const [searchTicket, setSearchTicket] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Type"]));
  const [isProductManagementOpen, setIsProductManagementOpen] = useState(false);
  const [productData, setProductData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [isIssueOpen, setIsIssueOpen] = useState(false);
  const res = useSelector(selectResData);
  const customAttributeRef = useRef();
  const [customTags, setCustomTags] = useState([]);
  const [ticket, setTicket] = useState({});
  const [parentKeys, setParentKeys] = useState(new Set([]));
  const [dropdownOwner, setDropdownOwner] = useState(new Set([]));
  const [typeKeys, setTypeKeys] = React.useState(new Set([]));
  const [anyofKeys, setAnyofKeys] = React.useState(new Set(["Any of"]));
  const [sortOrder, setSortOrder] = useState("Latest");
  const [partData, setPartData] = useState([]);
  const [customize, setCustomize] = useState(new Set([]));
  const userPermTick = useSelector(selectPermissions)?.find(
    (it) => it.name === "Role-11"
  );
  const userPermIss = useSelector(selectPermissions)?.find(
    (it) => it.name === "Role-2"
  );
  const userPerm = useSelector(selectPermissions)?.find(
    (it) => it.name === "Role-6"
  );
  const coulmnDef = Array.from(customize).map((name) => {
    if (name === "totaltickets") {
      return {
        key: name,
        label: "Total Tickets",
      };
    } else if (name === "totalissues") {
      return {
        key: name,
        label: "Total Issues",
      };
    } else if (name === "stage") {
      return {
        key: name,
        label: "Stage",
      };
    } else {
      return {
        key: name,
        label: name,
      };
    }
  });
  const columns = [
    {
      key: "part",
      label: "Part",
    },
    {
      key: "name",
      label: "Name",
    },
    {
      key: "owner",
      label: "Owner",
    },
    ...coulmnDef,
    {
      key: "delete",
      label: "",
    },
  ];

  const [issue, setIssue] = useState({});
  const handleTicketChange = async (ticket) => {
    setIsIssueOpen(false);
    setIsTicketOpen(false);
    await setTicket(ticket);
    setIsTicketOpen(true);
  };

  const handleIssueChange = async (issue) => {
    setIsTicketOpen(false);
    setIsIssueOpen(false);
    await setIssue(issue);
    setIsIssueOpen(true);
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleTrails = () => {
    navigate(`/${org}/product-trails`);
  };
  const handleProduct = () => {
    setIsProductManagementOpen(false);
    setIsTicketOpen(false);
    setIsIssueOpen(false);
  };
  const handleClearFilter = () => {
    setParentKeys(new Set([]));
    setDropdownOwner(new Set([]));
    setTypeKeys(new Set([]));
    setAnyofKeys(new Set(["Any of"]));
    setFilterArr((prev) => prev.map((fil) => ({ ...fil, val: [] })));
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!newModalref.current || !newModalref.current.contains(event.target)) &&
        (!inputRef.current || !inputRef.current.contains(event.target)) &&
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!tickRef.current || !tickRef.current.contains(event.target)) &&
        (!issueRef.current || !issueRef.current.contains(event.target)) &&
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        setIsProductManagementOpen(false);
        setIsTicketOpen(false);
        setIsIssueOpen(false);
      }
    };
    if (isProductManagementOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProductManagementOpen]);

  const toggleProductManagement = (e) => {
    setProductData(e);
    setIsProductManagementOpen(!isProductManagementOpen);
  };
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        // Fetch tags
        const tagResults = await getFilteredBySingleRelation(
          "tags",
          "organization",
          currentOrg.id
        );
        setCustomTags(tagResults.data);

        // Fetch parts
        const partResults = await getFilteredBySingleRelation(
          "parts",
          "organization",
          currentOrg.id
        );
        setPartData(partResults.data);

        const template = await getOneData("organizations", currentOrg.id, [
          "component_metadata",
        ]);
        setTemplate(template.data.attributes.component_metadata ?? []);
        setFilterArr(
          generateFilter(template.data.attributes.component_metadata ?? [])
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (currentOrg?.id) {
      getAllDataByIds();
    }
  }, [currentOrg]);

  const [parsedParts, setParsedParts] = useState({});
  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[String(item?.id)] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  return (
    <div className="h-full flex flex-col overflow-y-auto w-full">
      <div className="h-[130px]">
        <div className=" flex items-center mx-8 justify-between pt-8">
          <div className=" flex text-center justify-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm" isDisabled>
              <BreadcrumbItem>Components</BreadcrumbItem>
            </Breadcrumbs>
          </div>
          <div className="gap-4 flex items-center">
            <Button size="sm" onClick={handleTrails}>
              <FontAwesomeIcon />
              Explore Trails
            </Button>
            {userPerm?.privileges?.[0]?.create && (
              <Button
                size="sm"
                onClick={toggleDrawer}
                className="capitalize text-white bg-[rgb(51,51,245)]"
              >
                + Part
              </Button>
            )}
          </div>
        </div>
        <div className="z-0 flex pb-4 px-8 border-b-1 border-divider mt-4 items-center justify-between">
          <div className="gap-4 flex">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all dark:bg-[#121212] duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8 `}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={searchTicket}
                  onChange={(e) => setSearchTicket(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
            <div
              onClick={(e) => setIsCreatedTickets(!isCreatedTickets)}
              className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${
                isCreatedTickets ? " bg-[#DBDADF] dark:bg-[#2D2D32]" : " hover:bg-[#DBDADF] dark:hover:bg-[#2D2D32]"
              } items-center justify-center`}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                />
              </svg>
            </div>
            {Array.from(filterDropdown).includes("Type") && (
              <div className="flex border border-divider rounded-sm  ">
                <div className=" flex items-center">
                  <h2 className="text-xs ml-2 mr-2">Type</h2>
                  <div className="border-l border-divider h-full " />
                </div>

                <div className=" flex rounded-r-md   text-xs">
                  <Dropdown className=" text-xs">
                    <DropdownTrigger className=" text-xs">
                      <Button size="sm" className="bg-white dark:bg-black text-xs">
                        {Array.from(typeKeys).length === 0
                          ? "Add"
                          : `${Array.from(typeKeys)[0]} ${
                              Array.from(typeKeys).length > 1
                                ? `+${Array.from(typeKeys).length - 1}`
                                : ""
                            }`}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Multiple selection example"
                      variant="flat"
                      className=" text-xs"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      selectedKeys={typeKeys}
                      onSelectionChange={setTypeKeys}
                    >
                      <DropdownItem key="Linkable" className=" text-xs">
                        Linkable
                      </DropdownItem>
                      <DropdownItem key="Runnable" className=" text-xs">
                        Runnable
                      </DropdownItem>
                      <DropdownItem key="Product" className=" text-xs">
                        Product
                      </DropdownItem>
                      <DropdownItem key="Capability" className=" text-xs">
                        Capability
                      </DropdownItem>
                      <DropdownItem key="Feature" className=" text-xs">
                        Feature
                      </DropdownItem>
                      <DropdownItem key="Enhancement" className=" text-xs">
                        Enhancement
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            )}
            {Array.from(filterDropdown).includes("Owner") && (
              <div className="flex border border-divider rounded-sm  ">
                <div className=" flex items-center">
                  <h2 className="text-xs ml-2 mr-2">Owner</h2>
                  <div className="border-l border-divider h-full " />
                </div>
                <div className="  flex text-xs">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" className="bg-white dark:bg-black">
                        {Array.from(anyofKeys)[0]}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Single selection example"
                      variant="flat"
                      closeOnSelect={false}
                      disallowEmptySelection
                      selectionMode="single"
                      selectedKeys={anyofKeys}
                      onSelectionChange={setAnyofKeys}
                    >
                      <DropdownItem key="Any of">Any of</DropdownItem>
                      <DropdownItem key="None of">None of</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="border-l border-divider h-full " />

                <div className="   border-divider flex rounded-r-md text-sm">
                  <OwnerDropdown
                    res={res}
                    users={users}
                    dropdownOwner={dropdownOwner}
                    setDropdownOwner={setDropdownOwner}
                  />
                </div>
              </div>
            )}
            {Array.from(filterDropdown).includes("Parent") && (
              <div className="flex border border-divider rounded-sm  ">
                <div className=" flex items-center">
                  <h2 className="text-xs ml-2 mr-2">Parent</h2>
                  <div className="border-l border-divider h-full " />
                </div>
                <div className="  flex rounded-r-md text-sm">
                  <ParentDropDown
                    partData={partData}
                    parentKeys={parentKeys}
                    setParentKeys={setParentKeys}
                  />
                </div>
              </div>
            )}
            {template
              .filter((a) => Array.from(filterDropdown).includes(String(a.id)))
              .map((item) => {
                if (item.type === PEOPLE) {
                  return (
                    <FilterPeople
                      filterArr={filterArr}
                      setFilterArr={setFilterArr}
                      boilerPlate={item}
                    />
                  );
                } else if (item.type === DROPDOWN) {
                  return (
                    <FilterDropdown
                      filterArr={filterArr}
                      setFilterArr={setFilterArr}
                      boilerPlate={item}
                    />
                  );
                }
              })}
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  variant="light"
                  className=" border border-divider"
                  isIconOnly
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={filterDropdown}
                onSelectionChange={setFilterDropdowm}
              >
                <DropdownItem key="Type">Type</DropdownItem>
                <DropdownItem key="Owner">Owner</DropdownItem>
                <DropdownItem key="Parent">Parent</DropdownItem>
                {template
                  .filter((a) => a.type === DROPDOWN || a.type === PEOPLE)
                  .map((it) => (
                    <DropdownItem key={String(it.id)}>{it.name}</DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              onClick={() => handleClearFilter()}
              size="sm"
              variant="light"
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  Group
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                disallowEmptySelection
                closeOnSelect={false}
                selectionMode="single"
                selectedKeys={grouping}
                onSelectionChange={setGrouping}
              >
                <DropdownItem key="None">None</DropdownItem>
                {template
                  .filter(
                    (item) => item.type === DROPDOWN || item.type === PEOPLE
                  )
                  .map((it) => (
                    <DropdownItem key={`metadata_${it.id}`}>
                      {it.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  Customize
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                selectedKeys={customize}
                onSelectionChange={setCustomize}
              >
                <DropdownItem key="totaltickets">Tickets</DropdownItem>
                <DropdownItem key="totalissues">Issues</DropdownItem>
                <DropdownItem key="stage">Stage</DropdownItem>
                {template
                  .filter(
                    (item) =>
                      item.type !== MARKDOWN_TEXT && item.type !== LONG_TEXT
                  )
                  .map((it) => (
                    <DropdownItem key={`metadata_${it.id}`}>
                      {it.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className=" flex-1 h-full">
        <Tables
          grouping={grouping}
          template={template}
          filterArr={filterArr}
          partData={partData}
          isOwner={isCreatedTickets}
          search={searchTicket}
          deletePerm={userPerm?.privileges?.[0]?.delete}
          org={org}
          sortOrder={sortOrder}
          columns={Array.from(columns)}
          filterTypeOwner={Array.from(anyofKeys)[0]}
          filterOwner={Array.from(dropdownOwner)}
          filterParent={Array.from(parentKeys)}
          sortType={Array.from(typeKeys)}
          res={res}
          toggleProductManagement={toggleProductManagement}
        />
      </div>

      {isDrawerOpen && (
        <div ref={drawerRef}>
          <CreateDrawer
            customAttributeRef={customAttributeRef}
            userNames={userNames}
            parsedParts={parsedParts}
            parentPath={""}
            customTags={customTags}
            parts={partData}
            users={users}
            org={org}
            orgid={currentOrg}
            type={""}
            divRef={divRef}
            parent={""}
            dropdownref={tagsref}
            handleclose={toggleDrawer}
            modalref={modalref}
            newModalref={newModalref}
            inputRef={inputRef}
          />
        </div>
      )}

      {isProductManagementOpen && (
        <div ref={ticketRef}>
          <UpdateDrawer
            customAttributeRef={customAttributeRef}
            userNames={userNames}
            tickPermission={userPermTick?.privileges?.[0]?.read}
            issPermission={userPermIss?.privileges?.[0]?.read}
            permission={userPerm?.privileges?.[0]?.update}
            partData={partData}
            parsedParts={parsedParts}
            param={org}
            orgid={currentOrg}
            customTags={customTags}
            users={users}
            dropdownref={tagsref}
            modalref={modalref}
            res={res}
            handleclose={() => handleProduct()}
            data={productData}
            handleClickTicket={handleTicketChange}
            handleClickIssue={handleIssueChange}
          />
        </div>
      )}

      {isProductManagementOpen && isTicketOpen && (
        <div>
          <div ref={tickRef}>
            <Ticket
              userNames={userNames}
              customAttributeRef={customAttributeRef}
              users={users}
              tenant={{ name: org, id: currentOrg.id }}
              pos="part"
              res={res}
              dropdownref={tagsref}
              modalref={modalref}
              ticket={ticket}
              handleCloseTicket={() => setIsTicketOpen(false)}
            />
          </div>
        </div>
      )}
      {isProductManagementOpen && isIssueOpen && (
        <div>
          <div ref={issueRef}>
            <Issue
              userNames={userNames}
              customAttributRef={customAttributeRef}
              users={users}
              tenant={{ name: org, id: currentOrg.id }}
              pos="part"
              res={res}
              dropdownref={tagsref}
              modalref={modalref}
              ticket={issue}
              handleCloseTicket={() => setIsIssueOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductTrails;
