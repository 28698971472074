import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs, Divider } from "@nextui-org/react";
import { lorem } from "../../utils/default";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Header = () => {
    const location = useLocation();
    const {org} = useParams();
    const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-8 pt-8">
      <div className="flex mx-8 justify-between">
        <div className="w-full flex items-center justify-between">
          <div className="flex text-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm">
              <BreadcrumbItem isDisabled>Settings</BreadcrumbItem>
              <BreadcrumbItem onClick={() => navigate(`/${org}/settings/knowledge-base`)}>Knowledge Base</BreadcrumbItem>
              {location.pathname.includes(`/${org}/settings/knowledge-base/ingest`) && (
            <BreadcrumbItem>Ingest</BreadcrumbItem>
          )}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      {location.pathname.includes(`/${org}/settings/knowledge-base/ingest`) ?  
      <div className="flex flex-col gap-4">
      <p className="mx-8 text-lg">Upload a document</p>
      <p className="mx-8 text-sm">{lorem}</p>
    </div>
      : <div className="flex flex-col gap-4">
        <p className="mx-8 text-lg">Knowledge Base</p>
        <p className="mx-8 text-sm">{lorem}</p>
      </div>}

      <Divider className=" border border-divider" />
    </div>
  );
};

export default Header;
