import React from "react";
import { useSelector } from "react-redux";
import { selectResData, selectUserData } from "../../redux/actions";
import { Avatar, AvatarGroup, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { Colors, numColors } from "../../utils/constant";

const FilterPeople = ({ filterArr, boilerPlate, setFilterArr }) => {
    
  const arrVal = filterArr.find(
    (item) => String(item.id) === String(boilerPlate.id)
  )?.val ?? [];
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const handleUpdate = (id, newValue) => {
    setFilterArr((prevMetaData) =>
      prevMetaData.map((item) =>
        String(item.id) === String(id) ? { ...item, val: newValue } : item
      )
    );
  };
  return (
    <div className="flex border border-divider rounded-sm">
      <div className="flex items-center">
        <h2 className="text-xs ml-2 mr-2">{boilerPlate.name}</h2>
        <div className="border-l border-divider h-full" />
      </div>
      <div className="rounded-r-md text-xs">
        <Dropdown>
          <DropdownTrigger>
            <Button size="sm" radius="none" variant="light">
              {arrVal.length === 0 ? (
                "Add"
              ) : (
                <AvatarGroup max={3}>
                  {arrVal.map((it) => (
                    <Avatar
                      color={Colors[Number(it) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="full"
                      name={res && res?.[it]?.UserName?.toUpperCase().charAt(0)}
                      className="flex-shrink-0"
                      size="sm"
                      src={res && res?.[it]?.ProfilePicture}
                    />
                  ))}
                </AvatarGroup>
              )}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            variant="flat"
            closeOnSelect={false}
            selectionMode="multiple"
            className="max-h-[400px] overflow-y-auto"
            selectedKeys={new Set(arrVal)}
            onSelectionChange={(e) =>
              handleUpdate(boilerPlate.id, Array.from(e))
            }
          >
            {users.map((user) => (
              <DropdownItem key={user.id}>
                <div className="flex gap-2 items-center">
                  <Avatar
                    showfallback
                    name={user.username?.toUpperCase().charAt(0)}
                    color={Colors[Number(user.id) % numColors]}
                    style={{ width: "22px", height: "22px" }}
                    radius="sm"
                    size="sm"
                    src={user.ProfilePicture}
                  />
                  <span className="text-xs">
                    {user.FirstName || user.username}
                  </span>
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default FilterPeople;
