import { Image, Modal, ModalContent } from "@nextui-org/react";
import React from "react";

const ImageModal = ({ isOpen, close, src }) => {
  return (
    <Modal backdrop="blur" className="h-[80vh] bg-transparent shadow-none" size="full" isOpen={isOpen} onClose={() => close()}>
      <ModalContent className="w-full h-[90%] flex items-center justify-center">
        <Image className="h-[70vh]" alt="image" src={src} />
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
