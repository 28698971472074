import { Card, CardBody, Switch } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { ConfigurationIcons, lorem } from "../../utils/default";

const PageCard = ({ item, handleUpdate }) => {
  const [hidden, setHidden] = useState(item?.hidden);
  useEffect(() => {
    handleUpdate({ title: item.title, hidden });
  }, [hidden]);
  return (
    <div className="h-[120px]  border border-divider w-full rounded-lg">
      <div className="flex justify-between w-full gap-4 p-4">
        <div className="flex flex-col gap-4 flex-1">
          <div className="flex items-center gap-2">
            {ConfigurationIcons(item.title)}
            <div className="">{item.title}</div>
          </div>

          <div className="text-xs text-gray-400 ">{lorem}</div>
        </div>
        <Switch isSelected={!hidden} onValueChange={(e) => setHidden(!e)} />
      </div>
    </div>
  );
};

export default PageCard;
