import React, { useEffect, useMemo, useState } from "react";
import useTags from "../../hooks/useTags";
import {
  Button,
  Card,
  CardBody,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
} from "@nextui-org/react";
import PartPicker from "../../components/Drawer/PartPicker";
import useParts from "../../hooks/useParts";
import { closed, progress } from "../../utils/ticketconstants";
import SingleAvatar, {
  renderUserName,
} from "../../components/AvatarRender/Avatar";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData } from "../../redux/actions";
import UserPick from "../../components/UserModal/UserPick";
import {
  checkSameArrays,
  convertToDateString,
  inputClassNames,
  priorityData,
} from "../../utils/default";
import Tags from "../../components/Tag/tags";
import { useParams } from "react-router-dom";
import { parseDate } from "@internationalized/date";

const Attributes = ({ ticket, handleUpdate }) => {
  const [selectedPart, setSelectedPart] = useState(new Set([ticket?.part]));
  const customTags = useTags();
  const partData = useParts();
  const [tags, setTags] = useState(ticket?.tags);
  const [selectedDate, setSelectedDate] = useState(
    ticket?.targetCloseDate
      ? parseDate(convertToDateString(ticket?.targetCloseDate))
      : null
  );
  const res = useSelector(selectResData);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(
    new Set([ticket?.priority])
  );
  const [selectedEstimatedDays, setSelectedEstimatedDays] = useState(
    ticket?.estimatedays
  );
  const { org } = useParams();
  const orgData = useSelector(selectOrgData);
  const parsedParts = useMemo(() => {
    const convertedParts = {};
    partData.forEach((item) => {
      convertedParts[String(item.id)] = {
        Name: item.attributes?.Name,
        Type: item.attributes?.Type,
        ParentPath: item.attributes?.ParentPath,
        Owner: item.attributes?.users_permissions_user?.data?.id,
      };
    });
    return convertedParts;
  }, [partData]);
  const [stage, setStage] = useState(new Set([ticket?.stage]));
  const [assigned, setAssigned] = useState(new Set([ticket?.assigned]));
  useEffect(() => {
    const filtered = customTags.filter((option) =>
      ticket?.tags.includes(option.id)
    );
    setTagsDetail(filtered);
  }, [ticket, customTags]);
  const handleUpdateWithDelay = async () => {
    let formData = {};
    if (
      Array.from(selectedPart).length > 0 &&
      String(Array.from(selectedPart)[0]) !== String(ticket?.part)
    ) {
      formData = {
        ...formData,
        part: String(Array.from(selectedPart)[0]),
        owner: String(parsedParts[String(Array.from(selectedPart)[0])]?.Owner),
      };
    }
    if (
      Array.from(selectedPriority).length > 0 &&
      String(Array.from(selectedPriority)[0]) !== String(ticket?.priority)
    ) {
      formData = {
        ...formData,
        priority: String(Array.from(selectedPriority)[0]),
      };
    }
    if (
      Array.from(assigned).length > 0 &&
      String(Array.from(assigned)[0]) !== String(ticket?.assigned)
    ) {
      formData = { ...formData, assigned: String(Array.from(assigned)[0]) };
    }
    if (!checkSameArrays(tags, ticket?.tags)) {
      formData = { ...formData, tags: tags };
    }
    if (
      convertToDateString(selectedDate) !==
      convertToDateString(ticket?.targetCloseDate)
    ) {
      formData = { ...formData, targetCloseDate: selectedDate };
    }
    await handleUpdate(formData);
  };
  useEffect(() => {
    const time = setTimeout(handleUpdateWithDelay,2000 );
    return () => clearTimeout(time);
  }, [selectedPart,selectedDate,selectedPriority,assigned,tags]);
  return (
    <Card shadow="none" className=" border border-divider">
      <CardBody className="p-8">
        <p className="pb-8 text-lg font-semibold">Details</p>
        <div className=" flex flex-col gap-6">
          <div className="flex gap-2 text-center items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg>

              <p className="  text-sm text-center flex items-center">
                Part<span className="text-red-500"> * </span>
              </p>
            </div>

            <div className=" min-w-40 max-w-80 text-xs  flex ">
              {partData?.length > 0 && (
                <PartPicker
                  parsedParts={parsedParts}
                  selected={selectedPart}
                  setSelected={setSelectedPart}
                  parts={partData}
                />
              )}
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.867 19.125h.008v.008h-.008v-.008Z"
                />
              </svg>

              <p className="text-sm text-center flex items-center">
                Stage<span className=" text-red-600 "> *</span>
              </p>
            </div>
            <div className="w-40">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="w-24 text-xs text-center justify-start flex items-center"
                  >
                    {Array.from(stage)[0]}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={stage}
                  onSelectionChange={setStage}
                >
                  <DropdownSection title="Open">
                    <DropdownItem key="Queued">Queued</DropdownItem>
                  </DropdownSection>
                  <DropdownSection title="In progress">
                    {progress.map((item) => (
                      <DropdownItem key={item}> {item}</DropdownItem>
                    ))}
                  </DropdownSection>
                  <DropdownSection title="Closed">
                    {closed.map((item) => (
                      <DropdownItem key={item}> {item}</DropdownItem>
                    ))}
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <p className="text-sm text-center flex items-center">
                Assigned to<span className=" text-red-600 "> *</span>
              </p>
            </div>
            <div className="w-40">
              <UserPick
                selected={assigned}
                setSelected={setAssigned}
                text={"Assign user"}
                buttonClass="w-full text-xs items-center justify-start text-center flex"
                selectionMode="single"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                />
              </svg>

              <p className=" text-sm text-center flex items-center">
                Priority<span className=" text-red-600"> *</span>
              </p>
            </div>
            <div className="w-40">
              <Dropdown variant="light" size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="w-24 text-xs items-center justify-start text-center flex"
                  >
                    {Array.from(selectedPriority).length > 0 ? (
                      <div className="flex flex-row items-center gap-2">
                        {priorityData[Array.from(selectedPriority)[0]]?.icon}
                        <p className="text-xs">
                          {priorityData[Array.from(selectedPriority)[0]]?.label}
                        </p>
                      </div>
                    ) : (
                      <p className="text-xs">Select Priority</p>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Priority selection"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedPriority}
                  onSelectionChange={setSelectedPriority}
                >
                  {Object.keys(priorityData).map((priority) => (
                    <DropdownItem
                      key={priority}
                      className="flex items-center w-full gap-2"
                    >
                      <div className="flex  gap-2">
                        {priorityData[priority].icon}
                        <p className="text-xs">
                          {priorityData[priority].label}
                        </p>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6h.008v.008H6V6Z"
                />
              </svg>
              <p className="text-sm text-center flex items-center">Tags</p>
            </div>
            <Tags
              param={org}
              customTags={customTags}
              org={orgData?.id}
              tags={tags}
              setTags={setTags}
              tagsDetail={tagsDetail}
              setTagsDetail={setTagsDetail}
            />
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <p className="text-sm gap-2 text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
                Target Close Date
              </p>
            </div>
            <div className="w-40">
              <DatePicker
                
                value={selectedDate}
                onChange={setSelectedDate}
                size="sm"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <p className=" text-sm text-center flex items-center">Owner</p>
            </div>
            <div className="w-40">
              <Button size="sm" variant="light" className="w-40 justify-start">
                <div className="flex gap-2 items-center">
                  <SingleAvatar
                    id={parsedParts[String(Array.from(selectedPart)[0])]?.Owner}
                  />
                  <div className="flex flex-col">
                    <span className="text-xs">
                      {renderUserName(
                        parsedParts[String(Array.from(selectedPart)[0])]?.Owner,
                        res,
                        30
                      )}
                    </span>
                  </div>
                </div>
              </Button>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-40 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                />
              </svg>

              <p className=" text-sm text-center flex items-center">
                Estimated Days
              </p>
            </div>
            <div className="w-40">
              <Input
                onBlur={() => {
                  if (
                    selectedEstimatedDays &&
                    selectedEstimatedDays !== ticket?.estimatedays
                  ) {
                    handleUpdate(
                      { estimatedays: selectedEstimatedDays }
                    );
                  }
                }}
                classNames={inputClassNames}
                value={selectedEstimatedDays}
                variant="bordered"
                onChange={(e) => setSelectedEstimatedDays(e.target.value)}
                type="number"
                size="sm"
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Attributes;
