import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Select,
  SelectItem,
  User,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectResData, selectUserData } from "../../redux/actions";
import { useLocation, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { accessOptions } from "./constants";
import { patchData } from "../../controllers/vectorDB";
const Colors = ["secondary", "success", "warning", "danger", "primary"];
const numColors = Colors.length;
const PermModal = ({
  isOpen,
  close,
  owner,
  permissions,
  setPermissions,
  access,
  setAccess,
  id,
}) => {
  const { org } = useParams();
  const res = useSelector(selectResData);
  const users = useSelector(selectUserData);
  const [open, setOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(
    useSelector(selectUserData)
  );
  const [perm, setPerm] = useState(permissions);
  const [acc, setAcc] = useState(access);
  const location = useLocation();
  const [search, setSearch] = useState("");
  const findRole = (permission) => {
    if (
      permission.read === true &&
      permission.write === false &&
      permission.perm === false
    ) {
      return "viewer";
    }
    if (
      permission.read === true &&
      permission.write === true &&
      permission.perm === false
    ) {
      return "editor";
    }
    if (
      permission.read === true &&
      permission.write === true &&
      permission.perm === true
    ) {
      return "owner";
    }
  };
  const updatePermissionList = (id) => {
    if(String(id) === String(owner)){
        return
    }
    const user = perm.find((item) => String(item.id) === String(id));
    if(user){
        setPerm((prev) => prev.filter((item) => String(item.id) !== String(id)))
    }
    else{
        setPerm((prev) => [...prev,{id:String(id), permissions:{read:true,write:false,perm:false}}])
    }
  }
  const updateAccess = (val) => {
    setAcc((prev) => ({ ...prev, public: val }));
  };
  const updatePermissions = (id, val) => {
    const newPerm =
      val === "viewer"
        ? { read: true, write: false, perm: false }
        : val === "editor"
        ? { read: true, write: true, perm: false }
        : val === "owner"
        ? { read: true, write: true, perm: true }
        : { read: true, write: false, perm: false };
    setPerm((prevPermissions) =>
      prevPermissions.map((item) =>
        item.id === id
          ? {
              ...item,
              permissions: newPerm,
            }
          : item
      )
    );
  };
  useEffect(() => {
    const filter = users.filter(
      (item) =>
        `${item?.FirstName ?? ""} ${item?.LastName ?? ""}`
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.username.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(filter);
  }, [search]);
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        window.location.origin + location.pathname
      );
      toast("Link copied", {
        icon: "🔗",
        duration: 2000,
        position: "bottom-right",
      });
    } catch (err) {
      toast("Cannot copy link!", {
        duration: 2000,
        position: "bottom-right",
      });
    }
  };
  const handleSave = async () => {
    setPermissions(perm);
    setAccess(acc);
    try {
      await patchData(
        {
          access: acc,
          permissions: perm,
        },
        id,
        org
      );
      close();
    } catch (error) {}
  };

  return (
    <>
      <Toaster />
      <Modal size="lg" isOpen={isOpen} onClose={() => close()}>
        <ModalContent>
          <ModalBody className="p-8 flex flex-col gap-8">
            <p className="text-xl">Share this document</p>
            <div className="flex relative flex-col gap-2">
              <Input
                value={search}
                variant="bordered"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                placeholder="Add people..."
              />
              <div
                className={`flex absolute w-full bg-white dark:bg-[#2D2D32] top-12 border border-divider p-2 rounded-lg shadow-lg z-20 flex-col  max-h-[300px] overflow-y-auto ${
                  open ? "" : "hidden"
                } `}
              >
                {filteredUsers.length ===0 ? <div className="flex items-center justify-center"><p>No users</p></div> : filteredUsers.map((item) => (
                  <div
                    className="hover:bg-gray-200 dark:hover:bg-[#3F3F46] p-2 rounded-lg cursor-pointer items-center flex justify-between"
                    onClick={() => updatePermissionList(item.id)}
                    key={item.id}
                  >
                    <User
                      name={item.FirstName ?? item.username}
                      description={item.email}
                      avatarProps={{
                        src: item?.ProfilePicture?.formats?.small?.url ?? null,
                        showFallback: true,
                        name: item.username.toUpperCase().charAt(0),
                        color: Colors[Number(item.id) % numColors],
                      }}
                    />
                    {perm.find((user) => String(user.id) === String(item.id)) && (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                      </svg>
                      
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=" flex flex-col gap-4">
              <p>People with access</p>
              <div className="flex flex-col gap-4 max-h-[300px] overflow-y-auto">
                {perm.map((item) => (
                  <div className="w-full flex items-center justify-between">
                    <User
                      name={
                        res &&
                        (res[String(item.id)]?.FirstName ??
                          res[String(item.id)]?.UserName)
                      }
                      description={res && res[String(item.id)]?.Email}
                      avatarProps={{
                        src:
                          res && (res[String(item.id)]?.ProfilePicture ?? null),
                        showFallback: true,
                        name:
                          res &&
                          res[String(item.id)]?.UserName.toUpperCase().charAt(
                            0
                          ),
                        color: Colors[Number(item.id) % numColors],
                      }}
                    />
                    {String(item.id) === String(owner) ? (
                      <p className="text-gray-300 pr-4 text-sm">Owner</p>
                    ) : (
                      <Select
                        aria-label="Role Select"
                        selectedKeys={new Set([findRole(item.permissions)])}
                        disallowEmptySelection
                        classNames={{
                          base: "bg-white dark:bg-[#2D2D32] dark:rounded-lg w-[92px]",
                          trigger:
                            "bg-white dark:bg-[#2D2D32] hover:bg-white data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadow-none",
                          popoverContent: "w-32",
                        }}
                        variant="light"
                        size="sm"
                      >
                        <SelectItem
                          onClick={() => updatePermissions(item.id, "viewer")}
                          key={"viewer"}
                        >
                          Viewer
                        </SelectItem>
                        <SelectItem
                          onClick={() => updatePermissions(item.id, "editor")}
                          key={"editor"}
                        >
                          Editor
                        </SelectItem>
                        <SelectItem
                          onClick={() => updatePermissions(item.id, "owner")}
                          key={"owner"}
                        >
                          Owner
                        </SelectItem>
                      </Select>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=" flex flex-col gap-4">
              <p>General access</p>
              <div className="flex items-center justify-between">
                <Select
                  aria-label="Public"
                  items={accessOptions}
                  classNames={{
                    base: "max-w-xs",
                    trigger:
                      "h-12 bg-white dark:bg-[#2D2D32] hover:bg-white data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadow-none",
                  }}
                  selectedKeys={new Set([acc.public ? "public" : "private"])}
                  disallowEmptySelection
                  renderValue={(items) => {
                    return items.map((item) => (
                      <div key={item.key} className="flex items-center gap-2">
                        {item.data.icon}
                        <div className="flex flex-col">
                          <span>{item.data.name}</span>
                          <span className="text-default-500 text-tiny">
                            {item.data.description}
                          </span>
                        </div>
                      </div>
                    ));
                  }}
                >
                  {(accessOptions) => (
                    <SelectItem
                      onClick={() => updateAccess(accessOptions.value)}
                      key={accessOptions.id}
                      textValue={accessOptions.name}
                    >
                      <div className="flex gap-2 items-center">
                        {accessOptions.icon}
                        <div className="flex flex-col">
                          <span className="text-small">
                            {accessOptions.name}
                          </span>
                          <span className="text-tiny text-default-400">
                            {accessOptions.description}
                          </span>
                        </div>
                      </div>
                    </SelectItem>
                  )}
                </Select>
                {acc.public && (
                  <Select
                    aria-label="Select Role"
                    selectedKeys={
                      new Set([acc.writeAccess ? "editor" : "viewer"])
                    }
                    disallowEmptySelection
                    classNames={{
                      base: "bg-white dark:bg-[#2D2D32] dark:rounded-lg w-[92px]",
                      trigger:
                        "bg-white dark:bg-[#2D2D32] hover:bg-white data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadow-none",
                      popoverContent: "w-32",
                    }}
                    variant="light"
                    size="sm"
                  >
                    <SelectItem
                      onClick={() =>
                        setAcc((prev) => ({ ...prev, writeAccess: false }))
                      }
                      key={"viewer"}
                    >
                      Viewer
                    </SelectItem>
                    <SelectItem
                      onClick={() =>
                        setAcc((prev) => ({ ...prev, writeAccess: true }))
                      }
                      key={"editor"}
                    >
                      Editor
                    </SelectItem>
                  </Select>
                )}
              </div>
            </div>
            <div className="w-full flex items-center justify-between">
              <Button
                onClick={() => handleCopyLink()}
                startContent={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                    />
                  </svg>
                }
                size="sm"
                variant="light"
                className="text-[rgb(51,51,245)]"
              >
                Copy Link
              </Button>
              <Button
                onClick={() => handleSave()}
                size="sm"
                className="bg-[rgb(51,51,245)] text-white"
              >
                Done
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PermModal;
