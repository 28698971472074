import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSocket from "../../hooks/useWebsocket";
import Inboxtabs from "./Inboxtabs";
import InboxNav from "./InboxNav";
import InboxTable from "./InboxTable";
import InboxDrawer from "./InboxDrawer";
import { getFilteredBySingleRelation, getOneData } from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserData} from "../../redux/actions";

export default function Inbox() {
  const pageRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const stageRef = useRef();
  const sprintRef = useRef();
  const { org } = useParams();
  const [template,setTemplate] = useState([]);
  const users = useSelector(selectUserData);
  const [emailRes,setEmailRes] = useState(null);
  const orgId = useSelector(selectOrgData)
  const [customTags, setCustomTags] = useState([]);
  const emailId = JSON.parse(sessionStorage.getItem("userData")).user.email;
  const socket = useSocket(emailId, org);
  const [toggle, setToggle] = useState("guest");
  const customAttributeRef = useRef();
  const [toggleDrawer, setToggleDrawer] = useState(true);
  const [threadId, setThreadId] = useState(null);
  const handleThreadOpen = async (e) => {
    setToggleDrawer(false);
    await setThreadId(e);
    setToggleDrawer(true);
  }

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        
        const tags = await getFilteredBySingleRelation("tags", "organization", orgId.id);
        setCustomTags(tags.data);
        const template = await getOneData("organizations",orgId.id,["inbox_metadata"]);
        setTemplate(template.data.attributes.inbox_metadata ?? []);
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgId?.id) {
      getAllDataByIds();
    }
  }, [orgId]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pageRef.current &&
        !pageRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target)) &&
        (!stageRef.current || !stageRef.current.contains(event.target)) &&
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        setToggleDrawer(false);
      }
    };
    if (toggleDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleDrawer]);
  useEffect(() => {
    const convertedUsers ={};
    users.forEach((user) => {
      convertedUsers[user.email] = {
        Id: user.id,
        Email: user.email,
        UserName: user.username,
        FirstName: user.FirstName,
        LastName: user.LastName,
        ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
      };
    });
    setEmailRes(convertedUsers);
  },[users])
  return (
    <div className="relative h-screen flex flex-col overflow-y-auto">
      {toggleDrawer && threadId &&
        <InboxDrawer customAttributeRef={customAttributeRef} template={template} emailRes={emailRes} customTags={customTags} tagRef={tagsref} modalRef={modalref} stageRef={stageRef} sprintRef={sprintRef} pageRef={pageRef} orgId={orgId?.id} socket={socket} email={emailId} org={org} threadId={threadId} handleCloseDrawer={() => setToggleDrawer(false)} />}
      <InboxNav />
      <Inboxtabs toggle={toggle} setToggle={setToggle} />
      <InboxTable template={template} toggle={toggle} customTags={customTags} handleDrawer={handleThreadOpen} />
    </div>
  );
}
