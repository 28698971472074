import { Button } from '@nextui-org/react'
import React from 'react'

const PrimaryButton = ({text,handler,disabled}) => {
  return (
    <Button disabled={disabled ? true : false} size="sm" className={`mx-2 ${disabled ? "" : "text-white bg-[rgb(51,51,245)]"} `} onClick={handler} >{text}</Button>
  )
}

export default PrimaryButton
