import React, { useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskCard from "./KanbanCards";
import { patchData } from "../../controllers/vectorDB";
import { useParams } from "react-router-dom";
import { useTheme } from "next-themes";


const KanbanBoard = ({issues,setIssues}) => {
  const {theme} = useTheme();
  const Container = styled.div`
  display: flex;
  justify-content: center;
  padding : 0px 60px
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: ${theme === "dark" ? "#2D2D32" : "#f3f3f3"};
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
  height: 75vh;
  overflow-y: auto;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  
`;

const Title = styled.span`
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`;
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const { org } = useParams();
  const [loading, setLoading] = useState(false);
  const [stages, setStages] = useState({
    1: {
      title: "Queued",
      items: issues?.filter((it) => it?.payload?.stage === "Queued"),
    },
    2: {
      title: "In Progress",
      items: issues?.filter((it) => it?.payload?.stage === "In Progress"),
    },
    3: {
      title: "Awaiting Customer Response",
      items: issues?.filter(
        (it) => it?.payload?.stage === "Awaiting Customer Response"
      ),
    },
    4: {
      title: "Resolved",
      items: issues?.filter((it) => it?.payload?.stage === "Resolved"),
    },
  });

  const handleUpdate = async (id, stage) => {
    setLoading(true);
    try {
      const logs = issues.find((it) => String(it?.payload?.id) === String(id))?.payload?.logs;
      logs.push({
        operation: "updated",
        field: "stage",
        to: stages[stage].title,
        by: userDataId,
        timestamp: Date.now(),
      });
      const formData = {
        stage: stages[stage].title,
        logs,
      };
      await patchData(formData, Number(id), org);
      setIssues((prev) =>
        prev.map((it) => (String(it?.payload?.id) === String(id) ? { ...it, payload : { ...it.payload, ...formData } } : it))
      );
      setLoading(false);
    } catch (e) {}
  };
  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      await handleUpdate(draggableId, destination.droppableId);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    
      <DragDropContext
      onDragEnd={(result) => onDragEnd(result, stages, setStages)}
    >
      <Container>
        <TaskColumnStyles>
          {Object?.entries(stages)?.map(([columnId, column], index) => {
            return (
              <Droppable
                isDropDisabled={loading}
                key={columnId}
                droppableId={columnId}
              >
                {(provided, snapshot) => (
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Title>{column.title}</Title>
                    {column.items.map((item, index) => (
                      <TaskCard item={item} index={index} />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            );
          })}
        </TaskColumnStyles>
      </Container>
    </DragDropContext>
    
    
  );
};

export default KanbanBoard;
