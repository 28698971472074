import React from "react";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import { Colors, numColors } from "../../utils/constant";
import { Avatar, AvatarGroup } from "@nextui-org/react";
import { trimString } from "../../utils/default";


export const renderUserName = (id,res,trimlen) => {
    
    if(trimlen){
        const stringName =  `${res?.[String(id)]?.FirstName ? `${res?.[String(id)]?.FirstName} ${res?.[String(id)]?.LastName ?? ""}` : res?.[String(id)]?.UserName}`;
        return trimString(stringName,trimlen)
    }
    return res?.[String(id)]?.FirstName ? `${res?.[String(id)]?.FirstName} ${res?.[String(id)]?.LastName ?? ""}` : res?.[String(id)]?.UserName ;
}

export const RenderAvatarGroup = ({ids}) => {
    const res = useSelector(selectResData);
    return (
        <AvatarGroup>
            {ids?.map((it) => (
                <Avatar
                  color={Colors[Number(it) % numColors]}
                  style={{ width: "22px", height: "22px" }}
                  showFallback
                  radius="full"
                  name={res && res?.[String(it)]?.UserName?.toUpperCase().charAt(0)}
                  className="flex-shrink-0"
                  size="sm"
                  src={res && res?.[String(it)]?.ProfilePicture}
                />
              ))}
        </AvatarGroup>
    )
}
const SingleAvatar = ({ id }) => {
  const res = useSelector(selectResData);
  return (
    <Avatar
      color={Colors[Number(id) % numColors]}
      style={{ width: "22px", height: "22px" }}
      showFallback
      radius="sm"
      name={res && res?.[String(id)]?.UserName?.toUpperCase()?.charAt(0) }
      size="sm"
      src={res && res?.[String(id)]?.ProfilePicture }
    />
  );
};

export default SingleAvatar;
