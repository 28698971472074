import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem,
  Switch,
  Textarea,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  DROPDOWN,
  generateBoilerPlate,
  PEOPLE,
  TypesArray,
  validationCheckField,
} from "../../utils/customAttributes";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { basicIcons, inputClassNames } from "../../utils/default";

export const SectionedDropDown = ({ sec, setBoilerPlate, boilerPlate }) => {
  const handleRemove = (id) => {
    if (boilerPlate.sectionList.length <= 1) {
      return;
    }
    setBoilerPlate((prev) => ({
      ...prev,
      sectionList: prev.sectionList.filter((it) => it.id !== id),
    }));
  };
  return (
    <div className=" flex flex-col gap-2">
      <Input
        size="sm"
        variant="bordered"
        placeholder="Enter section header"
        classNames={inputClassNames}
        label="Section header"
        labelPlacement="outside"
        value={sec.header}
        onChange={(e) =>
          setBoilerPlate((prevState) => ({
            ...prevState,
            sectionList: prevState.sectionList.map((section) =>
              section.id === sec.id
                ? { ...section, header: e.target.value }
                : section
            ),
          }))
        }
      />
      <Textarea
        classNames={inputClassNames}
        variant="bordered"
        placeholder="Seperate items using comma ( , )"
        label="Dropdown Fields"
        labelPlacement="outside"
        size="sm"
        value={sec.fields}
        onChange={(e) =>
          setBoilerPlate((prevState) => ({
            ...prevState,
            sectionList: prevState.sectionList.map((section) =>
              section.id === sec.id
                ? { ...section, fields: e.target.value }
                : section
            ),
          }))
        }
      />
      <div className="flex items-center justify-end">
        <Button
          disabled={boilerPlate.sectionList.length <= 1}
          onClick={() => handleRemove(sec.id)}
          variant="flat"
          size="sm"
          color="danger"
        >
          Remove Section
        </Button>
      </div>
    </div>
  );
};

const PopOverCreate = ({
  handleSubmit,
  setSelectedField,
  metadata,
  selectedField,
}) => {
  const [boilerPlate, setBoilerPlate] = useState(
    generateBoilerPlate(Array.from(selectedField)[0])?.boilerPlate
  );
  const [icon, setIcon] = useState(
    basicIcons.find(
      (it) =>
        String(it.name) ===
        String(
          generateBoilerPlate(Array.from(selectedField)[0])?.boilerPlate.icon
        )
    )
  );
  const [errorMsg, setErrorMsg] = useState("");
  const handleSelectIcon = (item) => {
    setBoilerPlate((prev) => ({ ...prev, icon: item.name }));
    setIcon(item);
  };
  const submitHandler = async () => {
    const valididty = validationCheckField(metadata, boilerPlate);
    if (valididty.status === false) {
      setErrorMsg(valididty.message);
      return;
    } else if (valididty.status === true) {
      const data = boilerPlate;
      data.id = String(Date.now());
      const newMeta = [...metadata, data];
      try {
        await handleSubmit(newMeta);
        setSelectedField(new Set([]));
      } catch (error) {
        console.log("error", error);
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    if (Array.from(selectedField).length > 0) {
      setBoilerPlate(
        generateBoilerPlate(Array.from(selectedField)[0]).boilerPlate
      );
    }
  }, [selectedField]);
  return (
    <div className="w-full h-full overflow-y-auto flex flex-col gap-4 p-2 ">
      <Input
        size="sm"
        variant="bordered"
        placeholder="Enter field name"
        classNames={inputClassNames}
        label="Field Name"
        labelPlacement="outside"
        value={boilerPlate?.name}
        onChange={(e) =>
          setBoilerPlate((prev) => ({ ...prev, name: e.target.value }))
        }
      />
      <Textarea
        classNames={inputClassNames}
        variant="bordered"
        placeholder="Enter field description"
        label="Description"
        labelPlacement="outside"
        size="sm"
        value={boilerPlate.description}
        onChange={(e) =>
          setBoilerPlate((prev) => ({ ...prev, description: e.target.value }))
        }
      />
      <div className=" flex items-center justify-between">
      <div className="flex items-center gap-4">
            <p className="text-sm">Required</p>
            <Switch
              isSelected={boilerPlate?.required}
              onChange={(e) =>
                setBoilerPlate((prev) => ({
                  ...prev,
                  required: e.target.checked,
                }))
              }
            />
          </div>
        <div className="flex items-center gap-4">
        <p className=" text-sm"> Select Icon</p>
        <Popover>
          <PopoverTrigger>
            <Button variant="light" size="sm" isIconOnly>
              {icon && (
                <svg
                  xmlns={icon.xmlns}
                  fill={icon.fill}
                  viewBox={icon.viewBox}
                  strokeWidth={icon.strokeWidth}
                  stroke={icon.stroke}
                  className="size-6"
                >
                  <path
                    strokeLinecap={icon.path.strokeLinecap}
                    strokeLinejoin={icon.path.strokeLinejoin}
                    d={icon.path.d}
                  ></path>
                </svg>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className=" flex justify-around flex-wrap w-60">
              {basicIcons.map((item) => (
                <div
                  onClick={() => handleSelectIcon(item)}
                  className=" hover:bg-[#F2F2F3] p-2 cursor-pointer"
                >
                  <svg
                    xmlns={item.xmlns}
                    fill={item.fill}
                    viewBox={item.viewBox}
                    strokeWidth={item.strokeWidth}
                    stroke={item.stroke}
                    className="size-4"
                  >
                    <path
                      strokeLinecap={item.path.strokeLinecap}
                      strokeLinejoin={item.path.strokeLinejoin}
                      d={item.path.d}
                    ></path>
                  </svg>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
        </div>
        
      </div>
      <div className="flex flex-col gap-2 w-full">
        <p className="text-sm">Type </p>
        <Dropdown>
          <DropdownTrigger>
            <Button
              className="flex items-center justify-start border border-divider"
              startContent={
                <svg
                  xmlns={
                    generateBoilerPlate(Array.from(selectedField)[0])?.icon
                      ?.xmlns
                  }
                  fill={
                    generateBoilerPlate(Array.from(selectedField)[0])?.icon
                      ?.fill
                  }
                  viewBox={
                    generateBoilerPlate(Array.from(selectedField)[0])?.icon
                      ?.viewBox
                  }
                  strokeWidth={
                    generateBoilerPlate(Array.from(selectedField)[0])?.icon
                      ?.strokeWidth
                  }
                  stroke={
                    generateBoilerPlate(Array.from(selectedField)[0])?.icon
                      ?.stroke
                  }
                  className={
                    generateBoilerPlate(Array.from(selectedField)[0])?.icon
                      .className
                  }
                >
                  <path
                    strokeLinecap={
                      generateBoilerPlate(Array.from(selectedField)[0])?.icon
                        ?.path?.strokeLinecap
                    }
                    strokeLinejoin={
                      generateBoilerPlate(Array.from(selectedField)[0])?.icon
                        ?.path?.strokeLinejoin
                    }
                    d={
                      generateBoilerPlate(Array.from(selectedField)[0])?.icon
                        ?.path?.d
                    }
                  ></path>
                </svg>
              }
              size="sm"
              variant="light"
            >
              {generateBoilerPlate(Array.from(selectedField)[0])?.label}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={selectedField}
            onSelectionChange={setSelectedField}
          >
            {TypesArray.map((item) => (
              <DropdownItem
                key={item}
                startContent={
                  <svg
                    xmlns={generateBoilerPlate(item)?.icon?.xmlns}
                    fill={generateBoilerPlate(item)?.icon?.fill}
                    viewBox={generateBoilerPlate(item)?.icon?.viewBox}
                    strokeWidth={generateBoilerPlate(item)?.icon?.strokeWidth}
                    stroke={generateBoilerPlate(item)?.icon?.stroke}
                    className={generateBoilerPlate(item)?.icon.className}
                  >
                    <path
                      strokeLinecap={
                        generateBoilerPlate(item)?.icon?.path?.strokeLinecap
                      }
                      strokeLinejoin={
                        generateBoilerPlate(item)?.icon?.path?.strokeLinejoin
                      }
                      d={generateBoilerPlate(item)?.icon?.path?.d}
                    ></path>
                  </svg>
                }
              >
                <p>{generateBoilerPlate(item)?.label}</p>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      {(Array.from(selectedField)[0] === DROPDOWN ||
        Array.from(selectedField)[0] === PEOPLE) && (
        <Select
          size="sm"
          label="Selection Mode"
          labelPlacement="outside"
          classNames={{
            base: "bg-white dark:bg-[#18181B]",
            trigger:
              "bg-white dark:bg-black shadow-none border border-divider data-[hover=true]:bg-default-100 data-[hover=true]:bg-[#2D2D32]",
          }}
          variant="light"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={new Set([boilerPlate.mode])}
        >
          <SelectItem
            onClick={() =>
              setBoilerPlate((prev) => ({ ...prev, mode: "single" }))
            }
            key="single"
          >
            Single select
          </SelectItem>
          <SelectItem
            onClick={() =>
              setBoilerPlate((prev) => ({ ...prev, mode: "multiple" }))
            }
            key="multiple"
          >
            Multiple select
          </SelectItem>
        </Select>
      )}
      {Array.from(selectedField)[0] === DROPDOWN && (
        <>
          <div className="flex items-center justify-between">
            <p className="text-sm">With Sections</p>
            <Switch
              isSelected={boilerPlate?.section}
              onChange={(e) =>
                setBoilerPlate((prev) => ({
                  ...prev,
                  section: e.target.checked,
                }))
              }
            />
          </div>
          {boilerPlate?.section ? (
            <>
              {boilerPlate?.sectionList.map((sec) => (
                <SectionedDropDown
                  sec={sec}
                  setBoilerPlate={setBoilerPlate}
                  boilerPlate={boilerPlate}
                />
              ))}
              <div>
                <Button
                  isIconOnly
                  size="sm"
                  variant="light"
                  onClick={() =>
                    setBoilerPlate((prev) => ({
                      ...prev,
                      sectionList: [
                        ...prev.sectionList,
                        {
                          id: Date.now(),
                          header: "",
                          fields: "",
                        },
                      ],
                    }))
                  }
                >
                  +
                </Button>
              </div>
            </>
          ) : (
            <Textarea
              classNames={inputClassNames}
              variant="bordered"
              placeholder="Seperate items using comma ( , )"
              label="Dropdown Fields"
              labelPlacement="outside"
              size="sm"
              value={boilerPlate?.fields}
              onChange={(e) =>
                setBoilerPlate((prev) => ({ ...prev, fields: e.target.value }))
              }
            />
          )}
        </>
      )}
      <div className="flex items-center justify-between">
        <Button
          onClick={() => setSelectedField(new Set([]))}
          size="sm"
          variant="light"
          className="border border-divider"
        >
          Cancel
        </Button>
        <PrimaryButton handler={submitHandler} text={"+ Add"} />
      </div>
      {errorMsg !== "" && <p className="text-red-400">{errorMsg}</p>}
    </div>
  );
};

const DropdownActions = ({ metadata, handleSubmit }) => {
  const [selectedField, setSelectedField] = useState(new Set([]));
  return (
    <PopoverContent className=" flex flex-col justify-start items-start gap-2 w-[400px] max-h-[500px] overflow-y-auto p-2">
      {Array.from(selectedField).length === 0 ? (
        TypesArray.map((item) => (
          <div
            className=" flex items-center gap-2 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-[#2D2D32] w-full cursor-pointer transition-colors duration-300"
            onClick={() => setSelectedField(new Set([item]))}
          >
            <svg
              xmlns={generateBoilerPlate(item)?.icon?.xmlns}
              fill={generateBoilerPlate(item)?.icon?.fill}
              viewBox={generateBoilerPlate(item)?.icon?.viewBox}
              strokeWidth={generateBoilerPlate(item)?.icon?.strokeWidth}
              stroke={generateBoilerPlate(item)?.icon?.stroke}
              className={generateBoilerPlate(item)?.icon.className}
            >
              <path
                strokeLinecap={
                  generateBoilerPlate(item)?.icon?.path?.strokeLinecap
                }
                strokeLinejoin={
                  generateBoilerPlate(item)?.icon?.path?.strokeLinejoin
                }
                d={generateBoilerPlate(item)?.icon?.path?.d}
              ></path>
            </svg>
            <p>{generateBoilerPlate(item)?.label}</p>
          </div>
        ))
      ) : (
        <PopOverCreate
          setSelectedField={setSelectedField}
          metadata={metadata}
          handleSubmit={handleSubmit}
          selectedField={selectedField}
        />
      )}
    </PopoverContent>
  );
};

export default DropdownActions;
