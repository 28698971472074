import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  Input,
  TableCell,
  TableRow,
  Breadcrumbs,
  BreadcrumbItem,
  Divider,
  Pagination,
} from "@nextui-org/react";
import {
  UpdateData,
  DeleteSingleAttribute,
  getFilteredBySingleRelation,
} from "../../controllers/strapiController.js";
import ColorPicker from "../../components/Tag/ColorPicker.jsx";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb.jsx";
import AddTagModal from "../../components/Tag/tagModal.jsx";
import Icon from "../../components/Tag/Icon.jsx";
import HelixLoader from "../../components/Loader/HelixLoader.jsx";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions.js";
import { inputClassNames, paginateArray } from "../../utils/default.js";

const Tag = () => {
  const [isOpen, setOpen] = useState(false);
  const searchRef = useRef();
  const inputRef = useRef();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedColor, setSelectedColor] = useState({
    name: "PASTEL BLUE",
    hex: "#A5BCFB",
  });
  const [page, setPage] = useState(1);
  const [editId, setEditId] = useState();
  const [tagName, setTagName] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [loading, setLoading] = useState(true);
  const orgData = useSelector(selectOrgData);

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData?.id
        );
        setTags(resp.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        setLoading(false);
      }
    };
    if (orgData?.id) {
      getAllDataByIds();
    } else {
      setLoading(false);
    }
  }, [orgData]);

  useEffect(() => {
    const handleSearch = () => {
      const searchTerm = searchTag.toLowerCase().trim();
      if (searchTerm === "") {
        setFilteredTags(tags);
      } else {
        const filtered = tags.filter((tag) =>
          tag.attributes.Name.toLowerCase().includes(searchTerm)
        );
        setFilteredTags(filtered);
      }
    };
    handleSearch();
  }, [tags, searchTag]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditTag = (tag) => {
    setTagName(tag.attributes.Name);
    setTagDescription(tag.attributes.Description);
    setSelectedColor({
      name: tag.attributes.ColorName,
      hex: tag.attributes.Color,
    });
    setEditId(tag?.id);
    setEditOpen(true);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleUpdateTag = async () => {
    const name = tagName.trim();
    const description = tagDescription.trim();
    if (name !== "" && description !== "" && selectedColor !== "") {
      const newTag = {
        Name: name,
        Description: description,
        Color: selectedColor.hex,
        ColorName: selectedColor.name,
      };
      try {
        await UpdateData("tags", editId, newTag);
        window.location.reload();
      } catch (error) {
        console.error("Form submission failed:", error);
      }
    }
  };

  const handleDeleteTag = async (id) => {
    try {
      await DeleteSingleAttribute("tags", id);
      window.location.reload();
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  return (
    <div>
      <div className="flex justify-between py-8 px-8">
        <div className="flex text-center justify-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Settings</BreadcrumbItem>
            <BreadcrumbItem>Tags</BreadcrumbItem>
          </Breadcrumbs>
        </div>

        <Button
          className="text-white bg-[rgb(51,51,245)]"
          size="sm"
          onClick={() => setOpen(true)}
        >
          + Tag
        </Button>
      </div>

      <div className="flex flex-wrap mb-4 ml-6 items-center gap-4">
        <div
          ref={searchRef}
          onClick={() => setIsSearchOpen(true)}
          className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
            isSearchOpen
              ? "pl-2 w-40 gap-2 justify-start"
              : "w-8 justify-center"
          } rounded-full h-8`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
          {isSearchOpen && (
            <input
              ref={inputRef}
              value={searchTag}
              onChange={(e) => setSearchTag(e.target.value)}
              style={{ height: "20px", outline: "none" }}
              className="w-full px-2 text-xs rounded-full"
              placeholder="Search"
            />
          )}
        </div>

        <div className="flex flex-row">
          <div className="p-2 border border-divider rounded-l-md text-xs">
            <h2>Work Type</h2>
          </div>
          <div className="border border-divider rounded-r-md text-xs">
            <Button size="sm" radius="none" color="warning" variant="light">
              Tags
            </Button>
          </div>
        </div>
      </div>

      <Divider />

      {loading ? (
        <div className="flex justify-center items-center h-48">
          <HelixLoader />
        </div>
      ) : filteredTags.length > 0 ? (
        <>
          <Table removeWrapper aria-label="Tag table" className="p-10">
            <TableHeader>
              <TableColumn>Name</TableColumn>
              <TableColumn>Description</TableColumn>
              <TableColumn></TableColumn>
            </TableHeader>
            <TableBody>
              {paginateArray(filteredTags, 10, page - 1).map((tag, index) => (
                <TableRow
                  className="hover:bg-gray-100 dark:hover:bg-[#2D2D32] text-xs border-b border-divider"
                  key={index}
                >
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <div
                        style={{ backgroundColor: tag.attributes.Color }}
                        className="w-3 h-3 rounded-full"
                      ></div>
                      {tag.attributes.Name}
                    </div>
                  </TableCell>
                  <TableCell>{tag.attributes.Description}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Button
                        size="sm"
                        variant="light"
                        isIconOnly
                        onClick={() => handleEditTag(tag)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                      </Button>
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            size="sm"
                            color="danger"
                            variant="light"
                            isIconOnly
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions">
                          <DropdownItem
                            key="delete"
                            onClick={() => handleDeleteTag(tag?.id)}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="w-full flex justify-center items-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="danger"
              page={page}
              total={Math.ceil(filteredTags?.length / 10)}
              onChange={setPage}
              className="mt-4"
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col mt-52 items-center justify-center h-48">
          <Icon className="mr-2" />
          <span className="text-xs">
            {searchTag ? "No matching tags present" : "No tag present"}
          </span>
        </div>
      )}

      <AddTagModal
        org={orgData?.id}
        name=""
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
      <Modal isOpen={isEditOpen} onOpenChange={setEditOpen}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-2">Update Tag</ModalHeader>
          <ModalBody>
            <p>Name</p>
            <Input
              classNames={inputClassNames}
              label=""
              className="text-xs rounded-lg"
              type="text"
              size="sm"
              variant="bordered"
              placeholder="Enter tag name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
            <p>Description</p>
            <Input
              classNames={inputClassNames}
              className="text-xs rounded-lg"
              type="text"
              size="sm"
              variant="bordered"
              placeholder="Enter description"
              value={tagDescription}
              onChange={(e) => setTagDescription(e.target.value)}
            />
            <div className="mt-2 flex items-center gap-4" >
          <div className=" text-xs">Choose Color</div>
          <Dropdown placement="right">
            <DropdownTrigger>
              <Button
                isIconOnly
                variant="light"
                className=" border border-divider"
              >
                <div
                  className="w-4 h-4 rounded-full"
                  style={{ backgroundColor: selectedColor?.hex }}
                ></div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" className="w-64" >
              <DropdownItem
                isReadOnly
                classNames={{
                  base: "gap-0 data-[selectable=true]:focus:bg-white dark:data-[selectable=true]:focus:bg-[#18181B]",
                  selectedIcon: "w-0",
                }}
              >
                <ColorPicker onColorSelect={handleColorSelect} />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          </div>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              onClick={() => handleUpdateTag()}
              auto
              className="ml-auto text-white bg-[rgb(51,51,245)]"
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Tag;
