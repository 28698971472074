import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  Input,
  Dropdown,
  Button,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Chip,
} from "@nextui-org/react";
import {
  CreateData,
  getFilteredBySingleRelationSingleData,
  getFilteredByTwoData,
  getFilteredData,
} from "../../controllers/strapiController";
import { sendInvite } from "../../controllers/middlewareController";

function InviteModal({ isOpen, onClose, orgid }) {
  const userLogged = JSON.parse(sessionStorage.getItem("userData"));
  const userId = userLogged?.user?.id || null;
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [groups, setGroups] = useState(new Set());
  const [userInvite, setUserInvite] = useState("");
  const [arrayUser, setArrayUser] = useState([]);
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleDelete = (item) => {
    setArrayUser((prevItems) => prevItems.filter((i) => i !== item));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEnterPress(event.target.value);
    }
  };
  const fetchSameEmails = async (email) => {
    try {
      const resp = await getFilteredBySingleRelationSingleData(
        "users",
        "organizations",
        orgid.id,
        "email",
        email
      );
      return resp;
    } catch (error) {}
  };
  const fetchInvite = async (email) => {
    try {
      const resp = await getFilteredBySingleRelationSingleData(
        "invitations",
        "organization",
        orgid.id,
        "Email",
        email
      );

      return resp.data;
    } catch (error) {}
  };
  const handleEnterPress = async (value) => {
    if (validateEmail(value.trim())) {
      const a = await fetchSameEmails(value.trim());
      if (a.length > 0) {
        setError("User already present in the current org");
      } else {
        const b = await fetchInvite(value.trim());
        if (b.length > 0) {
          setError("User already invited in the current org");
        } else {
          setArrayUser((prev) => [...prev, value.trim()]);
          setError("");
          setUserInvite("");
        }
      }
    } else {
      setError("Please enter a valid email");
    }
  };

  const handleSend = async () => {
    if (arrayUser.length === 0 || groups.size === 0) {
      setError("Please add users and groups before sending.");
      return;
    }

    try {
      const promises = arrayUser.map((mail) =>
        CreateData("invitations", {
          Status: "Pending",
          Email: mail,
          invitedBy: {
            disconnect: [],
            connect: [{ id: userId, position: { end: true } }],
          },
          groups: {
            disconnect: [],
            connect: Array.from(groups).map((item) => ({
              id: Number(item),
              position: { end: true },
            })),
          },
          organization: {
            disconnect: [],
            connect: [{ id: orgid.id, position: { end: true } }],
          },
        })
      );

      await Promise.all(promises);
      await sendInvite(orgid.id, { emails: arrayUser });
      window.location.reload();
    } catch (error) {}
  };
  return (
    <Modal size="4xl" isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        <ModalBody className="py-10">
          <div className="flex gap-2 flex-col">
            <p className="text-xs font-light">Send Invitation to:</p>
            <div className="border flex dark:bg-[#121212] rounded-lg flex-col gap-2 items-center min-h-[50px] border-divider  h-auto">
              <input
                value={userInvite}
                onChange={(e) => setUserInvite(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{ outline: "none" }}
                className="text-xs font-light rounded-lg w-full px-2 py-1"
                placeholder="Press Enter to add"
              />
              <p className="pl-2 w-full text-xs font-light text-red-500">
                {error}
              </p>
              <div className="rounded-lg px-2 pb-2 w-full flex gap-2 flex-wrap max-h-[100px] overflow-y-auto">
                {arrayUser.map((item, index) => (
                  <Chip
                    key={item}
                    variant="flat"
                    size="sm"
                    className="gap-2"
                    onClose={() => handleDelete(item)}
                    color={Colors[index % numColors]}
                  >
                    <div className="flex items-center gap-2">{item}</div>
                  </Chip>
                ))}
              </div>
            </div>
          </div>
          <div className="flex gap-2 flex-col">
            <p className="text-xs font-light">Add groups:</p>
            <Dropdown>
              <DropdownTrigger>
                <Button className="flex justify-start" variant="flat">
                  Add group
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                selectedKeys={Array.from(groups)}
                onSelectionChange={(keys) => setGroups(new Set(keys))}
                selectionMode="multiple"
                closeOnSelect={false}
              >
                {orgid?.attributes?.groups?.data?.map((group) => (
                  <DropdownItem key={group.id}>
                    {group.attributes.Name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <div className="w-full flex items-center pt-4 justify-end">
              <Button
                onClick={handleSend}
                size="sm"
                className="bg-[rgb(51,51,245)] text-white w-28 text-sm"
              >
                Invite
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default InviteModal;
