import React, { useEffect, useState } from "react";
import { useNavigate, useParams} from "react-router-dom";
import {
  UpdateOneUserData,
} from "../../controllers/strapiController";
import { Avatar } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/actions";

const Org = () => {
  const { org } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const userData = useSelector(selectUserData);

  useEffect(() => {
    setUserDetails(userData.find((item) => String(item.id)=== String(userId)))
  },[userData,userId])

  

  const handleOrgSelect = async (orgName) => {
    const update = { OrgRoute: `/${orgName}` };
    try {
       await UpdateOneUserData("users", userId, update);
      navigate(`/${orgName}`);
      window.location.reload();
    } catch (error) {
    }
  };

  return (
    <div className="w-full mb-2 max-h-[300px] overflow-y-auto">
      { userDetails && userDetails.organizations && (
        userDetails.organizations.map((organizationDetails, index) => {
          const colorIndex = index % numColors; 
          return (
            <div
              key={index}
              className="flex items-center justify-between px-2 py-2 cursor-pointer w-full gap-4 hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md"
              onClick={() => handleOrgSelect(organizationDetails.Name)}
            >
              <div>
                <p className="flex gap-2 flex-row items-center cursor-pointer rounded-sm text-xs">
                  <Avatar
                    color={Colors[colorIndex]}
                    name={organizationDetails.Tenant.charAt(0)}
                    radius="full"
                    className="rounded-md"
                    size="sm"
                    style={{ height: "24px", width: "24px" }}
                  />
                  {organizationDetails.Tenant}
                </p>
              </div>
              <div>
                { org === organizationDetails.Name && (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Org;
