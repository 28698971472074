import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";
import { pages } from "./Header";
import { useNavigate, useParams } from "react-router-dom";

const PageTable = () => {
    const navigate = useNavigate();
    const {org} = useParams();
  return (
    <div className="flex-1 flex flex-col pt-8 gap-8">
      <Divider className="border border-divider" />

      <Table className="px-8" removeWrapper>
        <TableHeader>
          <TableColumn>Name</TableColumn>
          <TableColumn>Description</TableColumn>
          
        </TableHeader>
        <TableBody>
          {pages.map((item, index) => (
            <TableRow  className="cursor-pointer hover:bg-gray-100 dark:hover:bg-[#2D2D32] text-xs  border-b border-divider" key={index} onClick={() => navigate(`/${org}/settings/custom-attributes/${item}`)}>
              <TableCell>
                <p className=" capitalize">{item}</p>
              </TableCell>
              <TableCell></TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PageTable;
