import {
  Avatar,
  Button,
  Card,
  CardBody,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { UpdateData, uploadFile } from "../../controllers/strapiController";
import { basicIcons } from "../../utils/default";
import ColorPicker from "../../components/ColorPicker/ColorPicker";

function Theme({ icon, setIcon, hex, setHex, image,id,fetch,setFetch}) {
  const fileInputRef = useRef();
  
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [fileInput, setFileInput] = useState(null);
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setFileInput(file);
    } else {
      setFileInput(null);
    }
  };
  const UploadFileHandler = async () => {
    let formData = new FormData();
    formData.append("files", fileInput);
    const response = await uploadFile(formData);
    if (response) {
      const URLresponse = response && response[0];
      await UpdateProfile(URLresponse);
    }
  };
  const UpdateProfile = async (resp) => {
    const payload = {
      Logo:resp,
    };

    const response = await UpdateData("widgets", id, payload);
    setFetch(!fetch)
  };

  useEffect(() => {
    if(fileInput){
      UploadFileHandler();
    }
  }, [fileInput]);
  return (
    <div className=" pt-8 flex flex-col gap-8">
      <Card shadow="none" className=" border border-divider">
        <CardBody className=" flex flex-col gap-4">
          <div className=" flex items-center justify-between">
            <p> Select Icon</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="light" size="sm" isIconOnly>
                  {icon && (
                    <svg
                      xmlns={icon.xmlns}
                      fill={icon.fill}
                      viewBox={icon.viewBox}
                      strokeWidth={icon.strokeWidth}
                      stroke={icon.stroke}
                      className="size-6"
                    >
                      <path
                        strokeLinecap={icon.path.strokeLinecap}
                        strokeLinejoin={icon.path.strokeLinejoin}
                        d={icon.path.d}
                      ></path>
                    </svg>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className=" flex justify-around flex-wrap w-60">
                  {basicIcons.map((item) => (
                    <div
                      onClick={() => setIcon(item)}
                      className="rounded-lg hover:bg-[#F2F2F3] dark:hover:bg-gray-700 p-2 cursor-pointer"
                    >
                      <svg
                        xmlns={item.xmlns}
                        fill={item.fill}
                        viewBox={item.viewBox}
                        strokeWidth={item.strokeWidth}
                        stroke={item.stroke}
                        className="size-4"
                      >
                        <path
                          strokeLinecap={item.path.strokeLinecap}
                          strokeLinejoin={item.path.strokeLinejoin}
                          d={item.path.d}
                        ></path>
                      </svg>
                    </div>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className=" text-xs">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo.
          </div>
        </CardBody>
      </Card>
      <Card shadow="none" className=" border border-divider">
        <CardBody className=" flex flex-col gap-4">
          <div className=" flex items-center justify-between">
            <p> Logo Upload</p>
            <div className=" flex items-center gap-4">
            <Avatar
              radius="sm"
              size="sm"
              src={image?.data?.attributes?.url ? image?.data?.attributes?.url :  "https://avatars.githubusercontent.com/u/170258109?s=200&v=4" }
            />
              <input
                ref={fileInputRef}
                accept="image/*"
                type="file"
                onChange={(event) => handleFileChange(event)}
                className="hidden"
              />
              <Button onClick={handleButtonClick} size="sm" variant="flat">
                Select Logo
              </Button>
            </div>
          </div>

          <div className=" text-xs">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo.
          </div>
        </CardBody>
      </Card>
      <Card shadow="none" className=" border border-divider">
        <CardBody className=" flex flex-col gap-4">
          <div className=" flex items-center justify-between">
            <p> Theme Color</p>
            <div className="relative">
              <Popover>
                <PopoverTrigger>
                  <Button
                    isIconOnly
                    size="sm"
                    style={{ backgroundColor: hex ? hex : "#000000" }}
                  ></Button>
                </PopoverTrigger>
                <PopoverContent className=" bg-transparent border-none shadow-none">
                  <ColorPicker color={hex} setColor={setHex} />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className=" text-xs">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo.
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default Theme;
