import React, { useEffect, useState } from "react";
import {
  getAllData
} from "../../controllers/strapiController";
import {
  Avatar,
  Card,
  Chip
} from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";

const Colors = ["secondary", "success", "warning", "danger", "primary"];
const numColors = Colors.length;
const TagRender = ({ tags,index }) => {
  return (
    <>
      {tags?.length <= 2 ? (
        <div className="flex flex-wrap gap-2 items-center">
          {tags?.map((item,i) => (
            <Chip
              variant="flat"
              key={i}
              size="sm"
              className=" gap-2 text-xs"
              color={Colors[Number(index) % numColors]}
            >
              {item}
            </Chip>
          ))}{" "}
        </div>
      ) : (
        <div className="flex flex-wrap gap-2 items-center">
          <Chip
            variant="flat"
            size="sm"
            className=" gap-2 text-xs"
            color={Colors[Number(index) % numColors]}
          >
            {tags[0]}
          </Chip>
          <Chip
            variant="flat"
            size="sm"
            className="gap-2 text-xs"
            color={Colors[Number(index) % numColors]}
          >
            {tags[1]}
          </Chip>
          
          +{tags.length - 2}
        </div>
      )}
    </>
  );
};

function BlueprintList() {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();
  
  const { org } = useParams();
  
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const resp = await getAllData("blueprint-templates");
        setTemplates(resp.data);
      } catch (error) {}
    };
    fetchTemplate();
  }, []);
  return (
    <div className=" pt-16 gap-12 pb-8 mx-8 flex flex-wrap">
      {templates.map((item,index) => (
        <Card
          key={index}
          isPressable
          onClick={() => navigate(`/${org}/blueprints/view/${item.id}`)}
          className=" flex flex-col p-4 border border-divider w-[350px] h-[250px]"
          shadow="none"
        >

          <div className="w-full flex-1 flex flex-col gap-6">
            <div className=" flex items-center justify-between">
              <div className=" flex items-center gap-4">
                <Avatar
                  src={item?.attributes?.Image?.formats?.small?.url ?? ""}
                  className="text-lg"
                  showfallback
                  name={item.attributes.Name.toUpperCase().charAt(0)}
                  color={Colors[Number(item.id) % numColors]}
                />
                <p className="text-lg font-semibold">
                  {item.attributes.Name.length > 20
                    ? `${item.attributes.Name.slice(0, 20)}...`
                    : item.attributes.Name}
                </p>
              </div>
              
              
            </div>

            <p className="text-sm text-left font-light">
              {item?.attributes?.desc?.replace(/<[^>]*>/g, "").length > 200
                ? `${item?.attributes?.desc
                    ?.replace(/<[^>]*>/g, "")
                    .slice(0, 200)}...`
                : item?.attributes?.desc?.replace(/<[^>]*>/g, "")}
            </p>
          </div>

          <div className=" flex flex-wrap gap-2">
            {item?.attributes?.Tags && <TagRender index={item.id}
              tags={item?.attributes?.Tags}
            />}
          </div>
        </Card>
      ))}
    </div>
  );
}

export default BlueprintList;
