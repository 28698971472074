import React, { useEffect, useState } from "react";
import "./styles.css";
import { EditorProvider } from "@tiptap/react";
import { extensions, MenuBar, props } from "../../components/markdown/markdown";
import { useSelector } from "react-redux";
import { selectUserNames } from "../../redux/actions";
import { Button } from "@nextui-org/react";
import { disableInputs } from "../../utils/default";

const DescriptionEditor = ({ ticket, handleUpdate, imageIds, setImageIds }) => {
  const [toggleEditor, setToggleEditor] = useState(false);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [start, setStart] = useState(true);
  const userNames = useSelector(selectUserNames);
  useEffect(() => {
    const settingDesc = async () => {
      await setDescriptionContent(ticket?.description);
      setStart(false);
    };
    if(ticket){
        settingDesc();
    }
  }, [ticket]);
  return (
    <>
      {toggleEditor ? (
        <div>
          <div className=" h-auto mb-4 border border-divider rounded-lg">
            <EditorProvider
              editorProps={props()}
              slotBefore={
                <MenuBar
                  descriptionContent={descriptionContent}
                  imageArray={imageIds}
                  setImageArray={setImageIds}
                  setDescriptionContent={setDescriptionContent}
                  initial={start}
                  setInitial={setStart}
                />
              }
              extensions={extensions(userNames)}
              content={descriptionContent}
            ></EditorProvider>
          </div>
          <div className=" flex pl-2 items-center gap-2">
            <Button
              onClick={async () => {
                await handleUpdate(
                  { description: descriptionContent }
                );
                setToggleEditor(false);
              }}
              className="bg-[rgb(51,51,245)] text-white"
              size="sm"
            >
              Save
            </Button>
            <Button
              onClick={async () => {
                await setDescriptionContent(ticket?.description);
                setStart(false);
                setToggleEditor(false);
              }}
              variant="light"
              size="sm"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className=" text-sm py-4 flex justify-between gap-2 px-2  rounded-lg">
          <div
            className="mydiv1"
            dangerouslySetInnerHTML={{
              __html: disableInputs(ticket?.description),
            }}
          ></div>

          <Button
            startContent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            }
            onClick={() => setToggleEditor(true)}
            size="sm"
            variant="light"
          >
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

export default DescriptionEditor;
