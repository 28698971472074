import { getFilteredBySingleRelation } from "../controllers/strapiController";
import { getDocument } from "../controllers/vectorDB";

export const fetchAllTickets = async (org) => {
  try {
    const tickData = await getDocument("type", "ticket", org);
    return tickData;
  } catch (error) {
    return [];
  }
};

export const fetchAllIssues = async (org) => {
  try {
    const tickData = await getDocument("type", "issue", org);
    return tickData;
  } catch (error) {
    return [];
  }
};

export const fetchAllBluePrints = async (org) => {
  try {
    const tickData = await getDocument("type", "blueprint", org);
    return tickData;
  } catch (error) {
    return [];
  }
};

export const fetchAllReleases = async (org) => {
  try {
    const tickData = await getDocument("type", "release", org);
    return tickData;
  } catch (error) {
    return [];
  }
};

export const fetchAllParts = async (orgId) => {
  try {
    const partResults = await getFilteredBySingleRelation(
      "parts",
      "organization",
      orgId
    );
    return partResults.data;
  } catch (error) {
    return [];
  }
};

export const searchVectorDb = (tickets,search,tagIds,type,userId) => {
    let filtered;
    if(type !== "blueprint"){
        if(tagIds.length>0){
            filtered = tickets?.filter((item) => item?.payload?.title?.toLowerCase()?.includes(search?.toLowerCase()) || item?.payload?.description?.toLowerCase()?.includes(search?.toLowerCase()) ||  item?.payload?.tags?.includes(Number(tagIds[0]?.id)) );
        }
        else{
            filtered = tickets?.filter((item) => item?.payload?.title?.toLowerCase()?.includes(search?.toLowerCase()) || item?.payload?.description?.toLowerCase()?.includes(search?.toLowerCase()));
        }
    }
    else{
        filtered = tickets?.filter((item) => (item.payload.access.public || item.payload.owner === String(userId) || item.payload.permissions.find((per) => String(per.id) === String(userId))?.permissions?.read ) && (item?.payload?.title?.toLowerCase()?.includes(search?.toLowerCase()) || item?.payload?.description?.toLowerCase()?.includes(search?.toLowerCase())));
    }
    return filtered;
}

export const searchParts = (parts,search,tagIds) => {
    let filtered = [];
    if(tagIds.length>0){
        filtered = parts?.filter((item) => item?.attributes?.Name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.attributes?.Description?.toLowerCase()?.includes(search?.toLowerCase()) ||  item?.attributes?.Tags?.includes( Number(tagIds[0]?.id) || String(tagIds[0]?.id) ) );
    }
    else{
        filtered = parts?.filter((item) => item?.attributes?.Name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.attributes?.Description?.toLowerCase()?.includes(search?.toLowerCase()));
    }
    return filtered;
}
