import {
  SET_USER_DATA,
  SET_RES,
  SET_UPDATES,
  SET_USERNAMES,
  SET_ORG,
  SET_TICKETS,
  SET_ISSUES,
  SET_PARTS,
  SET_BLUEPRINTS,
  SET_RELEASES,
  SET_PERMISSIONS,
  SET_TAGS,
  SET_ADMIN,
  SET_COLLAPSE,
  SET_THEME,
} from "./actions";

const initialState = {
  data: [],
  org: null,
  usernames: [],
  res: null,
  updates: [],
  tickets: null,
  issues: null,
  parts: null,
  blueprints: null,
  releases: null,
  permissions: null,
  tags: null,
  admin: false,
  collapse: false,
  theme : "light"
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ORG:
      return {
        ...state,
        org: action.payload,
      };
    case SET_RES:
      return {
        ...state,
        res: action.payload,
      };
    case SET_UPDATES:
      return {
        ...state,
        updates: action.payload,
      };
    case SET_USERNAMES:
      return {
        ...state,
        usernames: action.payload,
      };
    case SET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };
    case SET_ISSUES:
      return {
        ...state,
        issues: action.payload,
      };
    case SET_PARTS:
      return {
        ...state,
        parts: action.payload,
      };
    case SET_BLUEPRINTS:
      return {
        ...state,
        blueprints: action.payload,
      };
    case SET_RELEASES:
      return {
        ...state,
        releases: action.payload,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case SET_ADMIN:
      return {
        ...state,
        admin: action.payload,
      };
    case SET_COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
