export const animationData = {
  nm: "Comp 1",
  ddd: 0,
  h: 400,
  w: 400,
  meta: { g: "@lottiefiles/toolkit-js 0.33.2" },
  layers: [
    {
      ty: 4,
      nm: "Capa de formas 5",
      sr: 1,
      st: 0,
      op: 60,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [-1, -182.375, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50, 50, 100],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50, 94, 100],
              t: 12,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [70, 43.333, 100],
              t: 13,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [104.25800000000001, 32, 100],
              t: 14,
            },
            { s: [212, 18, 100], t: 15 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [199, -14.000000000000002, 0],
              t: 0,
              ti: [0, -28.9375, 0],
              to: [0, 28.3333339691162, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [199, 156, 0],
              t: 12,
              ti: [0, -2.45892143249512, 0],
              to: [0, 4.54861259460449, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [199, 164.066, 0],
              t: 13,
              ti: [0, -1.72074222564697, 0],
              to: [0, 13.1843204498291, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [199, 166.125, 0],
              t: 14,
              ti: [0, -0.04166666790843, 0],
              to: [0, 2.04166674613953, 0],
            },
            { s: [199, 168.375, 0], t: 15 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 15,
            },
            { s: [0], t: 16 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Forma 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Trazado 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.938, 0],
                    [0, -5.25],
                    [-4.563, 0.125],
                    [0.108, 4.624],
                  ],
                  o: [
                    [-0.813, 0.125],
                    [0, 4.813],
                    [4.563, -0.125],
                    [-0.125, -5.375],
                  ],
                  v: [
                    [-1.344, -193.078],
                    [-8.75, -180.5],
                    [-1.063, -172.313],
                    [6.938, -180.188],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Relleno 1",
              c: { a: 0, k: [0.2667, 0.2667, 0.9804], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "Capa de formas 3",
      sr: 1,
      st: 0,
      op: 60,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [-32, -31, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-4, 1, 100],
              t: 47,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1.5, 4, 100],
              t: 48,
            },
            { s: [2, 3, 100], t: 53 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [199.98, 168.25, 0],
              t: 47,
              ti: [0, 17.58864402771, 0],
              to: [0, -0.20375619828701, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [199.98, 158.037, 0],
              t: 48,
              ti: [0, 1.17485654354095, 0],
              to: [-2.8421709430404e-14, -50.4047393798828, 0],
            },
            { s: [199.98, -10, 0], t: 53 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 46,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 47,
            },
            { s: [100], t: 48 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Elipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "el",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Ellipse",
              nm: "Trazado elÃ­ptico 1",
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [308, 308], ix: 2 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Relleno 1",
              c: { a: 0, k: [0.2667, 0.2667, 0.9804], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-31, -31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: "Capa de formas 4",
      sr: 1,
      st: 0,
      op: 60,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [-32, -31, 0], ix: 1 },
        s: { a: 0, k: [55.00000000000001, 55.00000000000001, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [199, 252.99999999999997, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Elipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "el",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Ellipse",
              nm: "Trazado elÃ­ptico 1",
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [308, 308], ix: 2 },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Trazo 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              c: { a: 0, k: [0.2667, 0.2667, 0.9804], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-31, -31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "tm",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Filter - Trim",
          nm: "Recortar trazados 1",
          ix: 2,
          e: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [50],
                t: 12,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 24,
              },
              { s: [50], t: 48 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 180, ix: 3 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [50],
                t: 12,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100],
                t: 24,
              },
              { s: [50], t: 48 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: "Capa de formas 1",
      sr: 1,
      st: 0,
      op: 60,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      td: 1,
      ao: 0,
      ks: {
        a: { a: 0, k: [-32, -31, 0], ix: 1 },
        s: { a: 0, k: [50, 50, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [199, 252.99999999999997, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Elipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "el",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Ellipse",
              nm: "Trazado elÃ­ptico 1",
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [308, 308], ix: 2 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Relleno 1",
              c: { a: 0, k: [0.2667, 0.2667, 0.9804], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-31, -31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
    },
    {
      ty: 0,
      nm: "Precomp. 1",
      sr: 1,
      st: 0,
      op: 144,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      tt: 1,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [200, 200, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [200, 200, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      w: 400,
      h: 400,
      refId: "comp_0",
      ind: 5,
    },
  ],
  v: "4.10.1",
  fr: 24,
  op: 72,
  ip: 0,
  assets: [
    {
      nm: "",
      id: "comp_0",
      layers: [
        {
          ty: 4,
          nm: "Capa de formas 12",
          sr: 1,
          st: 0,
          op: 144,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [43, 43, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [81.5, 370.25, 0],
                  t: 24,
                  ti: [-60.6666679382324, 28.5, 0],
                  to: [60.6666679382324, -28.5, 0],
                },
                { s: [445.5, 199.25, 0], t: 48 },
              ],
              ix: 2,
            },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Forma 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Trazado 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [28, 0],
                        [34.935, -19.483],
                        [31.619, 18.821],
                        [33, -14],
                        [57, 29],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-28, 0],
                        [-52, 29],
                        [-42, -25],
                        [-28.892, 12.257],
                        [-57, -29],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [367.75, -97],
                        [277, -75],
                        [155, -82],
                        [35, -82],
                        [-94, -82.326],
                        [-200, -74],
                        [-352.07, 320.209],
                        [499.162, 354.093],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Relleno 1",
                  c: { a: 0, k: [0.2667, 0.2667, 0.9804], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 1,
        },
        {
          ty: 4,
          nm: "Capa de formas 2",
          sr: 1,
          st: 0,
          op: 144,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [43, 43, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [-133, 374, 0],
                  t: 24,
                  ti: [-60.6666679382324, 28.5, 0],
                  to: [60.6666679382324, -28.5, 0],
                },
                { s: [231, 203, 0], t: 48 },
              ],
              ix: 2,
            },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Forma 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Trazado 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [28, 0],
                        [34.935, -19.483],
                        [31.619, 18.821],
                        [33, -14],
                        [57, 29],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-28, 0],
                        [-52, 29],
                        [-42, -25],
                        [-28.892, 12.257],
                        [-57, -29],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [367.75, -97],
                        [277, -75],
                        [155, -82],
                        [35, -82],
                        [-94, -82.326],
                        [-200, -74],
                        [-352.07, 320.209],
                        [499.162, 354.093],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Relleno 1",
                  c: { a: 0, k: [0.2, 0.2, 0.9608], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 2,
        },
      ],
    },
  ],
};
