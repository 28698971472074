// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropdown menu */
.dropdown-menu-blueprints {
    background: white;
    border: 1px solid #DCDCDC;
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    overflow-y: auto;
    width: 300px;
    padding: 0.4rem;
    position: relative;
    /* box-shadow: #DCDCDC; */
  }
  

  

  
  
 
  `, "",{"version":3,"sources":["webpack://./src/components/BlurprintEditor/commandstyles.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,yBAAyB;EAC3B","sourcesContent":["/* Dropdown menu */\n.dropdown-menu-blueprints {\n    background: white;\n    border: 1px solid #DCDCDC;\n    border-radius: 0.7rem;\n    display: flex;\n    flex-direction: column;\n    gap: 0.1rem;\n    overflow-y: auto;\n    width: 300px;\n    padding: 0.4rem;\n    position: relative;\n    /* box-shadow: #DCDCDC; */\n  }\n  \n\n  \n\n  \n  \n \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
