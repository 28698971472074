import React from "react";
import { customizeTableCell, DROPDOWN, PEOPLE } from "../../utils/customAttributes";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import { Accordion, AccordionItem, Avatar, Table, TableBody, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { Colors, numColors, renderGroup } from "../../utils/constant";
import { removePrefix } from "../../utils/default";


const CustomIssueGroup = ({ group, type,deletePerm,customTags,handleDelete,parsedParts,template,customizeHeaderList,toggleTicket }) => {
  const res = useSelector(selectResData);
  if (group) {
    return (
      <div className="h-full flex overflow-y-auto">
        <div className="h-full flex flex-col  gap-4 w-[400px] overflow-y-auto border-r border-divider ">
          {type === DROPDOWN ? (
            <div className="flex flex-col w-full gap-2">
              {Object.keys(group).map((key) => (
                <div className="w-full hover:bg-gray-100 px-4 py-2  flex justify-between items-center cursor-pointer">
                  <p className="text-sm ">{key}</p>
                  <div className="text-xs text-gray-400">
                    {group[key]?.length}
                  </div>
                </div>
              ))}
            </div>
          ) : type === PEOPLE ? (
            <div className="flex flex-col w-full gap-2">
              {Object.keys(group).map((key) => (
                <div className="w-full hover:bg-gray-100 px-4 py-2  flex justify-between items-center cursor-pointer">
                  <div className="flex items-center gap-2">
                    <Avatar
                      color={Colors[Number(key) % numColors]}
                      showFallback
                      name={
                        res[String(key)]?.FirstName
                          ? res[String(key)]?.FirstName[0]
                          : res[String(key)]?.UserName
                          ? res[String(key)]?.UserName[0]
                          : ""
                      }
                      size="sm"
                      radius="sm"
                      src={
                        res[String(key)]?.ProfilePicture?.formats?.small?.url
                          ? process.env.REACT_APP_STAGE === "Dev"
                            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[String(key)].ProfilePicture.formats.small.url}`
                            : `${res[String(key)].ProfilePicture.formats.small.url}`
                          : ""
                      }
                    />

                    <p className="text-xs">
                      {res[String(key)]?.FirstName
                        ? res[String(key)]?.FirstName
                        : res[String(key)]?.UserName
                        ? res[String(key)]?.UserName
                        : "Unassigned"}
                    </p>
                  </div>
                  <div className="text-xs text-gray-400">
                    {group[key]?.length}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="h-full overflow-y-auto px-4 py-2 flex flex-col gap-4 w-full">
          {Object.keys(group || {}).map((key, ind) => (
            <div className=" px-4 mb-10" key={ind}>
              <Accordion defaultExpandedKeys={["1"]}>
                <AccordionItem
                  key="1"
                  title={
                    type === DROPDOWN ? (
                      <div>
                        <p className="text-sm ">{key}</p>
                      </div>
                    ) : type === PEOPLE ? (
                      <div className="flex items-center gap-2">
                        <Avatar
                          color={Colors[Number(key) % numColors]}
                          showFallback
                          name={
                            res[String(key)]?.FirstName
                              ? res[String(key)]?.FirstName[0]
                              : res[String(key)]?.UserName
                              ? res[String(key)]?.UserName[0]
                              : ""
                          }
                          size="sm"
                          radius="sm"
                          src={
                            res[String(key)]?.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[String(key)].ProfilePicture.formats.small.url}`
                                : `${res[String(key)].ProfilePicture.formats.small.url}`
                              : ""
                          }
                        />

                        <p className="text-xs">
                          {res[String(key)]?.FirstName
                            ? res[String(key)]?.FirstName
                            : res[String(key)]?.UserName
                            ? res[String(key)]?.UserName
                            : "Unassigned"}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                >
                {/* Table */}
                <Table
                  removeWrapper
                  radius="none"
                  className="mt-4 text-xs"
                  color="default"
                  aria-label="Example static collection table"
                >
                  <TableHeader columns={customizeHeaderList}>
                    {(column) => (
                      <TableColumn key={column.id} className="text-xs">
                        {column.id.startsWith("metadata_")
                          ? template?.find(
                              (it) =>
                                it.id === removePrefix(column.id, "metadata_")
                            )?.name ?? ""
                          : column.label}
                      </TableColumn>
                    )}
                  </TableHeader>
                  <TableBody emptyContent={"No tickets. Time for coffee."}>
                    {group[key]?.map((row, index) => (
                      <TableRow
                        className="cursor-pointer  border-b border-divider  hover:bg-gray-100 text-xs"
                        onClick={(e) => toggleTicket(row)}
                        key={`${index + 1}`}
                      >
                        {customizeHeaderList.map((item) => {
                          if (item.id.startsWith("metadata_")) {
                            return customizeTableCell(
                              item.id,
                              template,
                              row.payload.metadata,
                              res
                            );
                          } else {
                            return renderGroup(
                              row,
                              item.id,
                              res,
                              parsedParts,
                              handleDelete,
                              customTags,
                              deletePerm
                            );
                          }
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </AccordionItem>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default CustomIssueGroup;
