import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrgData, selectParts, selectPermissions, setParts } from "../redux/actions";
import { fetchAllParts } from "../utils/searchHelper";

const useParts = () => {
  const partData = useSelector(selectParts);
  const dispatch = useDispatch();
  const orgData = useSelector(selectOrgData);
  const permission = useSelector(selectPermissions)?.find((item) => item.name === "Role-6")?.privileges?.[0]?.read;
  useEffect(() => {
    const getParts = async () => {
      try {
        const partsArr = await fetchAllParts(orgData.id);
        dispatch(setParts(partsArr));
      } catch (error) {
        console.log("error", error);
      }
    };
    if (partData === null && orgData?.id && permission) {
      getParts();
    }
    else if(permission === false){
        dispatch(setParts(null));
    }
  }, [partData, orgData,permission]);

  const memoizedParts = useMemo(() => {
    if (Array.isArray(partData)) {
      return partData;
    } else {
      return [];
    }
  }, [partData]);
  
  return memoizedParts;
};

export default useParts;
