import {
  Button,
  Pagination,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreateRelease from "./CreateRelease";
import { deleteData, getMultipleFilters } from "../../controllers/vectorDB";
import { renderReleaseCell } from "./RenderReleaseCell";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import Icon from "../../components/Tag/Icon";
import HelixLoader from "../../components/Loader/HelixLoader";
import { paginateArray } from "../../utils/default";

const ReleasesTable = () => {
  const navigate = useNavigate();
  const { org } = useParams();
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();
  const [page, setPage] = useState(1);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [releases, setReleases] = useState([]);
  const [filteredReleases, setFilteredReleases] = useState([]);
  const res = useSelector(selectResData);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      id: "title",
      label: "Title",
    },
    {
      id: "owner",
      label: "Owner",
    },
    // {
    //   id: "startDate",
    //   label: "Start Date",
    // },
    {
      id: "releaseDate",
      label: "Release Date",
    },
    {
      id: "delete",
      label: "",
    },
  ];
  const handleDelete = async (id) => {
    try {
      await deleteData(id, org);
      setReleases((prev) =>
        prev.filter((item) => String(item.payload.id) !== String(id))
      );
    } catch (error) {}
  };
  useEffect(() => {
    const filtered = releases.filter((item) =>
      item.payload.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredReleases(filtered);
  }, [search, releases]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && search === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, search]);
  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const resp = await getMultipleFilters(
          [{ key: "type", value: "release" }],
          org
        );
        setReleases(resp);
        setFilteredReleases(resp);
        setLoading(false);
      } catch (error) {}
    };
    if (org) {
      fetchReleases();
    }
  }, [org]);
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
      </div>
    );
  }
  return (
    <div>
      <div className="flex flex-col mx-8 pt-8 gap-8">
        <p className="text-xl font-semibold ">Releases</p>
        <div className=" flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all dark:bg-[#121212] duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
          </div>
          <Popover placement="left-start">
            <PopoverTrigger>
              <Button size="sm" className="mx-2 text-white bg-[rgb(51,51,245)]">
                Create a release
              </Button>
            </PopoverTrigger>
            <PopoverContent className=" flex flex-col justify-start items-start gap-2 w-[400px] max-h-[500px] overflow-y-auto p-2">
              <CreateRelease />
            </PopoverContent>
          </Popover>
        </div>
        {filteredReleases.length > 0 ? (
          <>
            <Table removeWrapper>
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn key={column.id} className="text-xs">
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody>
                {paginateArray(filteredReleases,10,page-1).map((item) => (
                  <TableRow
                    className="cursor-pointer text-xs rounded-lg hover:bg-gray-100 dark:hover:bg-[#2D2D32] border-b border-divider "
                    onClick={() =>
                      navigate(`/${org}/releases/${item.payload.id}`)
                    }
                    key={item.id}
                  >
                    {columns.map((column) => {
                      return renderReleaseCell(
                        column.id,
                        item,
                        handleDelete,
                        res
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="w-full flex justify-center items-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="danger"
                page={page}
                total={Math.ceil(filteredReleases?.length / 10)}
                onChange={setPage}
                className="mt-4"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col mt-60 text-xs gap-2 justify-center items-center text-slate-400">
            <Icon />
            No Releases
          </div>
        )}
      </div>
    </div>
  );
};

export default ReleasesTable;
