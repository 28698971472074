import React, { useEffect, useMemo, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import { Avatar, AvatarGroup, Button, Chip } from "@nextui-org/react";
import { Colors, numColors } from "../../utils/constant";
import {
  convertToDate,
  convertToDateString,
  disableInputs,
  priorityData,
  renderIcon,
  trimString,
} from "../../utils/default";
import useParts from "../../hooks/useParts";
import SingleAvatar, {
  RenderAvatarGroup,
  renderUserName,
} from "../AvatarRender/Avatar";
import TagsRenderer from "../Tag/TagsRenderer";
import { useNavigate, useParams } from "react-router-dom";

const linkicon = {
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  ),
};

const RenderCell = ({ selectedGroup, item }) => {
  const res = useSelector(selectResData);
  switch (selectedGroup) {
    case "ticket":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-2 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Chip size="sm" variant="flat" color="danger">
                TKT-{item.payload.latest}
              </Chip>
              <p className="text-sm">{trimString(item.payload.title, 20)}</p>
            </div>

            <Avatar
              showFallback
              name={
                res &&
                res[item.payload.assigned] &&
                (res[item.payload.assigned].FirstName
                  ? `${res[item.payload.assigned].FirstName?.charAt(0)}`
                  : `${res[item.payload.assigned].UserName?.charAt(
                      0
                    ).toUpperCase()}`)
              }
              radius="sm"
              color={Colors[Number(item.payload.assigned) % numColors]}
              size="sm"
              src={
                res?.[item.payload.assigned]?.ProfilePicture
                  ? process.env.REACT_APP_STAGE === "Dev"
                    ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                        res[item.payload.assigned].ProfilePicture
                      }`
                    : `${res[item.payload.assigned].ProfilePicture}`
                  : null
              }
            />
          </div>
          <div className=" text-xs text-gray-400">
            {trimString(item.payload.description.replace(/<[^>]*>/g, ""), 100)}
          </div>
        </div>
      );
    case "issue":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-2 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Chip size="sm" variant="flat" color="primary">
                ISS-{item.payload.latest}
              </Chip>
              <p className="text-sm">{trimString(item.payload.title, 20)}</p>
            </div>

            <Avatar
              showFallback
              name={
                res &&
                res[item.payload.assigned] &&
                (res[item.payload.assigned].FirstName
                  ? `${res[item.payload.assigned].FirstName?.charAt(0)}`
                  : `${res[item.payload.assigned].UserName?.charAt(
                      0
                    ).toUpperCase()}`)
              }
              radius="sm"
              color={Colors[Number(item.payload.assigned) % numColors]}
              size="sm"
              src={
                res?.[item.payload.assigned]?.ProfilePicture
                  ? `${res[item.payload.assigned].ProfilePicture}`
                  : null
              }
            />
          </div>
          <div className=" text-xs text-gray-400">
            {trimString(item.payload.description.replace(/<[^>]*>/g, ""), 100)}
          </div>
        </div>
      );
    case "release":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-2 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <p className="text-sm">{trimString(item?.payload?.title, 20)}</p>
            </div>
            <div>
              <SingleAvatar id={item?.payload?.owner} />
            </div>
          </div>
          <div className=" text-xs text-gray-400">
            {trimString(
              item?.payload?.description?.replace(/<[^>]*>/g, ""),
              100
            )}
          </div>
        </div>
      );
    case "blueprint":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-2 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <p className="text-sm">{trimString(item.payload.title, 20)}</p>
            </div>
            <AvatarGroup>
              {item.payload.authors.map((id) => (
                <Avatar
                  showFallback
                  name={
                    res &&
                    res[String(id)] &&
                    (res[String(id)].FirstName
                      ? `${res[String(id)].FirstName?.charAt(0)}`
                      : `${res[String(id)].UserName?.charAt(0).toUpperCase()}`)
                  }
                  radius="sm"
                  color={Colors[Number(id) % numColors]}
                  size="sm"
                  src={
                    res?.[String(id)]?.ProfilePicture
                      ? `${res[String(id)].ProfilePicture}`
                      : null
                  }
                />
              ))}
            </AvatarGroup>
          </div>
          <div className=" text-xs text-gray-400">
            {trimString(item.payload.description.replace(/<[^>]*>/g, ""), 100)}
          </div>
        </div>
      );
    case "part":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-2 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Chip
                className=""
                color={
                  item.attributes.Type === "Product"
                    ? "primary"
                    : item.attributes.Type === "Capability"
                    ? "secondary"
                    : item.attributes.Type === "Enhancement"
                    ? "warning"
                    : item.attributes.Type === "Feature"
                    ? "success"
                    : "danger"
                }
                variant="flat"
                size="sm"
              >
                {item.attributes.Type === "Product"
                  ? "PRO"
                  : item.attributes.Type === "Capability"
                  ? "CAP"
                  : item.attributes.Type === "Enhancement"
                  ? "ENH"
                  : item.attributes.Type === "Feature"
                  ? "FEA"
                  : "RUN"}
                -{item.attributes.latest_part}
              </Chip>
              <p className="text-sm">{trimString(item.attributes.Name, 20)}</p>
            </div>

            <Avatar
              showFallback
              name={
                res &&
                res[String(item.attributes.users_permissions_user.data.id)] &&
                (res[String(item.attributes.users_permissions_user.data.id)]
                  .FirstName
                  ? `${res[
                      String(item.attributes.users_permissions_user.data.id)
                    ].FirstName?.charAt(0)}`
                  : `${res[
                      String(item.attributes.users_permissions_user.data.id)
                    ].UserName?.charAt(0).toUpperCase()}`)
              }
              radius="sm"
              color={
                Colors[
                  Number(item.attributes.users_permissions_user.data.id) %
                    numColors
                ]
              }
              size="sm"
              src={
                res?.[String(item.attributes.users_permissions_user.data.id)]
                  ?.ProfilePicture
                  ? process.env.REACT_APP_STAGE === "Dev"
                    ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                        res[
                          String(item.attributes.users_permissions_user.data.id)
                        ].ProfilePicture
                      }`
                    : `${
                        res[
                          String(item.attributes.users_permissions_user.data.id)
                        ].ProfilePicture
                      }`
                  : null
              }
            />
          </div>
          <div className=" text-xs text-gray-400">
            {trimString(
              item.attributes.Description.replace(/<[^>]*>/g, ""),
              100
            )}
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

const DetailView = ({ item, selectedGroup }) => {
  const res = useSelector(selectResData);
  const parts = useParts();
  const parsedParts = useMemo(() => {
    const convertedParts = {};
    parts.forEach((item) => {
      convertedParts[String(item.id)] = {
        Name: item.attributes?.Name,
        Type: item.attributes?.Type,
        ParentPath: item.attributes?.ParentPath,
        Owner: item.attributes?.users_permissions_user?.data?.id,
      };
    });
    return convertedParts;
  }, [parts]);
  const navigate = useNavigate();
  const { org } = useParams();
  switch (selectedGroup) {
    case "ticket":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-8 w-full">
          <div className="flex items-center gap-2">
            <Chip size="sm" variant="flat" color="danger">
              TKT-{item?.payload?.latest}
            </Chip>
            <p className="text-lg">{item?.payload?.title}</p>
          </div>
          <TagsRenderer ids={item?.payload?.tags ?? []} />
          <div
            className="mydiv  text-base text-gray-400"
            dangerouslySetInnerHTML={{ __html: disableInputs(item?.payload?.description) }}
          ></div>
          <div className="flex flex-col gap-6 w-full">
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Part</div>
              <div className="flex items-center gap-2">
                <div>
                  {renderIcon(parsedParts?.[String(item?.payload?.part)]?.Type)}
                </div>
                <div>{parsedParts?.[String(item?.payload?.part)]?.Name}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Owner</div>
              <div className="flex items-center gap-2">
                <SingleAvatar id={item?.payload?.owner} />
                <div>{renderUserName(item?.payload?.owner, res)}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Priority</div>
              <div className="flex items-center gap-2">
                <div>{priorityData?.[item?.payload?.priority]?.icon}</div>
                <div>{priorityData?.[item?.payload?.priority]?.label}</div>
              </div>
            </div>

            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Assigned to</div>
              <div className="flex items-center gap-2">
                <SingleAvatar id={item?.payload?.assigned} />
                <div>{renderUserName(item?.payload?.assigned, res)}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Stage</div>
              <div className="">{item?.payload?.stage}</div>

              <div className="flex items-center gap-4 w-[50%]"></div>
            </div>
          </div>
        </div>
      );
    case "issue":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-8 w-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <Chip size="sm" variant="flat" color="danger">
                ISS-{item?.payload?.latest}
              </Chip>
              <p className="text-lg">{item?.payload?.title}</p>
            </div>
            <Button
              isIconOnly
              variant="light"
              size="sm"
              onClick={() =>
                navigate(`/${org}/issues/${item?.payload?.latest}`)
              }
            >
              {linkicon.icon}
            </Button>
          </div>

          <TagsRenderer ids={item?.payload?.tags ?? []} />
          <div
            className="mydiv  text-base text-gray-400"
            dangerouslySetInnerHTML={{ __html: disableInputs(item?.payload?.description) }}
          ></div>
          <div className="flex flex-col gap-6 w-full">
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Part</div>
              <div className="flex items-center gap-2">
                <div>
                  {renderIcon(parsedParts?.[String(item?.payload?.part)]?.Type)}
                </div>
                <div>{parsedParts?.[String(item?.payload?.part)]?.Name}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Owner</div>
              <div className="flex items-center gap-2">
                <SingleAvatar id={item?.payload?.owner} />
                <div>{renderUserName(item?.payload?.owner, res)}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Priority</div>
              <div className="flex items-center gap-2">
                <div>{priorityData?.[item?.payload?.priority]?.icon}</div>
                <div>{priorityData?.[item?.payload?.priority]?.label}</div>
              </div>
            </div>

            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Assigned to</div>
              <div className="flex items-center gap-2">
                <SingleAvatar id={item?.payload?.assigned} />
                <div>{renderUserName(item?.payload?.assigned, res)}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-32">Stage</div>
              <div className="">{item?.payload?.stage}</div>

              <div className="flex items-center gap-4 w-[50%]"></div>
            </div>
          </div>

          <div></div>
        </div>
      );
    case "release":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-8 w-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <p className="text-lg">{item?.payload?.title}</p>
            </div>
            <Button
              isIconOnly
              variant="light"
              size="sm"
              onClick={() => navigate(`/${org}/releases/${item?.payload?.id}`)}
            >
              {linkicon.icon}
            </Button>
          </div>

          <TagsRenderer ids={item?.payload?.tags ?? []} />
          <p className="text-base">{item?.payload?.description}</p>
          <div className="flex flex-col gap-4 w-full">
            <div className="flex text-sm items-center w-full ">
              <div className="w-20">Owner</div>
              <div className="flex-1 flex items-center gap-2">
                <SingleAvatar id={item?.payload?.owner} />
                <div>{renderUserName(item?.payload?.owner, res)}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-20">Stage</div>
              <div className="flex-1 flex items-center">
                {item?.payload?.status}
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-20">Start Date</div>
              <div className="flex-1 flex items-center">
                {convertToDate(item?.payload?.startDate)}
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-20">End Date</div>
              <div className="flex-1 flex items-center">
                {convertToDate(item?.payload?.releaseDate)}
              </div>
            </div>
          </div>

          <div></div>
        </div>
      );
    case "blueprint":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-8 w-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <p className="text-lg">{item?.payload?.title}</p>
            </div>
            <Button
            isIconOnly
              variant="light"
              size="sm"
              onClick={() =>
                navigate(`/${org}/blueprints/docs/${item?.payload?.id}`)
              }
            >
              {linkicon.icon}
            </Button>
          </div>

          {item?.payload?.tags?.length > 0 && (
            <TagsRenderer ids={item?.payload?.tags ?? []} />
          )}
          <p className="w-full flex">
            {trimString(
              item?.payload?.description?.replace(/<[^>]*>/g, ""),
              300
            )}
          </p>
          <div className="flex flex-col gap-4 w-full">
            <div className="flex text-sm items-center w-full ">
              <div className="w-20">Owner</div>
              <div className="flex-1 flex items-center gap-2">
                <SingleAvatar id={item?.payload?.owner} />
                <div>{renderUserName(item?.payload?.owner, res)}</div>
              </div>
            </div>
            <div className="flex text-sm items-center w-full ">
              <div className="w-20">Authors</div>
              <div className="flex-1 flex items-center">
                <RenderAvatarGroup ids={item?.payload?.authors} />
              </div>
            </div>
          </div>

          <div>
            
          </div>
        </div>
      );
    case "part":
      return (
        <div className="flex flex-col h-full p-2 px-4 gap-8 w-full">
          <div className="flex items-center gap-2">
            <Chip
              className=""
              color={
                item?.attributes?.Type === "Product"
                  ? "primary"
                  : item?.attributes?.Type === "Capability"
                  ? "secondary"
                  : item?.attributes?.Type === "Enhancement"
                  ? "warning"
                  : item?.attributes?.Type === "Feature"
                  ? "success"
                  : "danger"
              }
              variant="flat"
              size="sm"
            >
              {item?.attributes?.Type === "Product"
                ? "PRO"
                : item?.attributes?.Type === "Capability"
                ? "CAP"
                : item?.attributes?.Type === "Enhancement"
                ? "ENH"
                : item?.attributes?.Type === "Feature"
                ? "FEA"
                : "RUN"}
              -{item?.attributes?.latest_part}
            </Chip>
            <p className="text-lg">{item?.attributes?.Name}</p>
          </div>
          <TagsRenderer ids={item?.attributes?.Tags ?? []} />
          <div
            className="mydiv  text-base text-gray-400"
            dangerouslySetInnerHTML={{ __html: disableInputs(item?.attributes?.Description) }}
          ></div>

          <div className="flex flex-col gap-4 text-sm w-full">
            <div className="flex items-center w-full ">
              <div className="w-20">Owner</div>
              <div className="flex-1 flex items-center gap-2">
                <SingleAvatar
                  id={item?.attributes?.users_permissions_user?.data?.id}
                />
                <div>
                  {renderUserName(
                    item?.attributes?.users_permissions_user?.data?.id,
                    res
                  )}
                </div>
              </div>
            </div>
            {item?.attributes?.Type !== "Product" && (
              <div className="flex items-center w-full">
                <div className="w-20">Part</div>
                <div className="flex-1 flex items-center gap-2">
                  <div>
                    {item?.attributes?.ParentPath?.split("/")?.[
                      item?.attributes?.ParentPath?.split("/")?.length - 1
                    ] &&
                      renderIcon(
                        parsedParts[
                          String(
                            item?.attributes?.ParentPath?.split("/")?.[
                              item?.attributes?.ParentPath?.split("/")?.length -
                                1
                            ]
                          )
                        ]?.Type
                      )}
                  </div>
                  <div>
                    {item?.attributes?.ParentPath?.split("/")?.[
                      item?.attributes?.ParentPath?.split("/")?.length - 1
                    ] &&
                      parsedParts[
                        String(
                          item?.attributes?.ParentPath?.split("/")?.[
                            item?.attributes?.ParentPath?.split("/")?.length - 1
                          ]
                        )
                      ]?.Name}
                  </div>
                </div>
              </div>
            )}
            {item?.attributes?.Type === "Enhancement" && (
              <div className="flex items-center w-full">
                <div className="w-20">Stage</div>
                <div className="flex-1 flex items-center gap-2">
                  {item?.attributes?.Stage}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

const SearchResults = ({
  tickets,
  issues,
  parts,
  blueprints,
  releases,
  selectedGroup,
}) => {
  const [selected, setSelected] = useState(null);
  const array =
    selectedGroup === "ticket"
      ? tickets
      : selectedGroup === "issue"
      ? issues
      : selectedGroup === "part"
      ? parts
      : selectedGroup === "release"
      ? releases
      : blueprints;
  useEffect(() => {
    setSelected(
      selectedGroup === "ticket"
        ? tickets[0]
        : selectedGroup === "issue"
        ? issues[0]
        : selectedGroup === "part"
        ? parts[0]
        : selectedGroup === "release"
        ? releases[0]
        : blueprints[0]
    );
  }, [selectedGroup]);

  return (
    <div className="w-full h-full overflow-y-auto flex">
      <div className="w-[50%] border-r h-full overflow-y-auto flex flex-col gap-2 border-divider p-4">
        {array.map((item) => (
          <div
            onMouseEnter={() => setSelected(item)}
            className={`w-full h-[100px] rounded-lg shadow-md cursor-pointer opacity-75 hover:opacity-100 flex transition-opacity duration-300 items-center justify-between bg-white dark:bg-gray-700 hover:shadow-lg ${
              selectedGroup === "part"
                ? selected?.id === item?.id
                  ? "bg-white shadow-lg"
                  : ""
                : selected?.payload?.id === item?.payload?.id
                ? "bg-white shadow-lg"
                : ""
            }  `}
            key={selectedGroup === "part" ? item.id : item.payload.id}
          >
            <RenderCell selectedGroup={selectedGroup} item={item} />
          </div>
        ))}
      </div>
      <div className="w-[50%] h-full overflow-y-auto p-4 bg-white dark:bg-gray-800 ">
        {selected && (
          <DetailView item={selected} selectedGroup={selectedGroup} />
        )}
      </div>
    </div>
  );
};

export default SearchResults;
