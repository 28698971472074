import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Button
} from "@nextui-org/react";
import React from "react";

const FilterDropdown = ({ filterArr, boilerPlate, setFilterArr }) => {
    console.log("filter",filterArr)
  const arrVal = filterArr.find(
    (item) => String(item.id) === String(boilerPlate.id)
  )?.val ?? [];
  const handleUpdate = (id, newValue) => {
    setFilterArr((prevMetaData) =>
      prevMetaData.map((item) =>
        String(item.id) === String(id) ? { ...item, val: newValue } : item
      )
    );
  };
  return (
    <div className="flex border border-divider rounded-sm">
      <div className="flex items-center">
        <h2 className="text-xs ml-2 mr-2">{boilerPlate.name}</h2>
        <div className="border-l border-divider h-full" />
      </div>
      <div className="rounded-r-md text-xs">
        <Dropdown>
          <DropdownTrigger>
            <Button size="sm" radius="none" variant="light">
              {arrVal.length === 0 ? "Add" : arrVal[0]}{" "}
              {arrVal.length > 1 && `+${arrVal.length - 1}`}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            variant="flat"
            closeOnSelect={false}
            selectionMode="multiple"
            selectedKeys={new Set(arrVal)}
            onSelectionChange={(e) =>
              handleUpdate(boilerPlate.id, Array.from(e))
            }
          >
            {boilerPlate.section
              ? boilerPlate.sectionList.map((section) => (
                  <DropdownSection title={section.header} key={section.header}>
                    {section.fields.split(",").map((op) => (
                      <DropdownItem key={op}>{op}</DropdownItem>
                    ))}
                  </DropdownSection>
                ))
              : boilerPlate.fields
                  .split(",")
                  .map((field) => (
                    <DropdownItem key={field}>{field}</DropdownItem>
                  ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default FilterDropdown;
