export const sendNotification = async (orgId, newData) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/notifications?orgId=${orgId}`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error updating collection data"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const sendInvite = async (orgId, emails) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/invite?orgId=${orgId}`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(emails),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error updating collection data"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};
