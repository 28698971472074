import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";

function AnalyticsHeader() {
  return (
    <div className="flex mx-8 justify-between py-8">
      <div className="w-full flex items-center justify-between">
        <div className="flex text-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm">
            <BreadcrumbItem>Analytics</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        
      </div>
    </div>
  );
}

export default AnalyticsHeader;
