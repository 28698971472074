import React, { useEffect, useState } from "react";
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Tooltip,
} from "@nextui-org/react";
import { renderIcon, trimString } from "../../utils/default";
import { useTheme } from "next-themes";

const PartItem = (item, Name, url) => {

  return (
    <div className="flex items-center gap-2">
      {renderIcon(item.attributes.Type)}
      <div className="flex flex-col">
        <p>{trimString(Name || "--", 30)}</p>
        <p className="text-xs">{trimString(url || "--", 40)}</p>
      </div>
    </div>
  );
};

function PartPicker({
  selected,
  setSelected,
  parts,
  parsedParts,
  divRef,
  inputRef,
}) {
  const {theme} = useTheme();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState("");

  const handleDelete = (item) => {
    const arr = Array.from(selected).filter(
      (id) => Number(item) !== Number(id)
    );
    setSelected(new Set(arr));
  };

  const generatePartName = (part) => {
    return part.attributes.Type !== "Product"
      ? `${part.attributes.ParentPath?.split("/")
        ?.map((id) => parsedParts[String(id)]?.Name)
        .join("/ ")} / ${part.attributes.Name}`
      : part.attributes.Name;
  };

  const newGenerateUrl = (part) => {
    return part.attributes.Type !== "Product"
      ? `${part.attributes.ParentPath?.split("/")
        ?.map((id) => parsedParts[String(id)]?.Name)
        .join("/ ")}`
      : "Product";
  };

  useEffect(() => {
    const filteredParts = parts.filter((item) =>
      generatePartName(item).toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOptions(filteredParts);
  }, [search, parts]);

  return (
    
      <Dropdown className="w-full">
        <DropdownTrigger>
          <div className="rounded-lg w-full cursor-pointer min-h-4 h-auto flex items-center">
            <div className="gap-2 flex items-center h-auto w-full flex-wrap pt-[2px] px-2">
              {Array.from(selected)?.length > 0 ? (
                Array.from(selected).length < 4 ? (
                  Array.from(selected).map((item) => {
                    const part = filteredOptions.find(
                      (option) => String(option.id) === String(item)
                    );
                    return part ? (
                      <p
                        size="sm"
                        key={item}
                        className="cursor-pointer flex items-center"
                      >
                        {renderIcon(part?.attributes?.Type)}
                        <span className="ml-2 text-sm">{part?.attributes?.Name}</span>
                      </p>
                    ) : null;
                  })
                ) : (
                  <>
                    {Array.from(selected)
                      .slice(0, 3)
                      .map((item) => {
                        const part = filteredOptions.find(
                          (option) => option.id === item
                        );
                        return part ? (
                          <Chip
                            size="sm"
                            key={item}
                            onClose={() => handleDelete(item)}
                            className="cursor-pointer flex items-center"
                          >
                            {renderIcon(part.attributes.Type)}
                            <span className="ml-2">
                              {generatePartName(part)}
                            </span>
                          </Chip>
                        ) : null;
                      })}
                    <Tooltip
                      content={
                        <div className="flex flex-col">
                          {Array.from(selected)
                            .slice(3)
                            .map((item) => {
                              const part = filteredOptions.find(
                                (option) => option.id === item
                              );
                              return part ? (
                                <div key={item} className="flex items-center">
                                  {renderIcon(part.attributes.Type)}
                                  <span className="ml-2">
                                    {generatePartName(part)}
                                  </span>
                                </div>
                              ) : null;
                            })}
                        </div>
                      }
                    >
                      <p className="text-xs">
                        +{Array.from(selected).length - 3}
                      </p>
                    </Tooltip>
                  </>
                )
              ) : (
                <p className="text-xs w-full text-gray-400">Add Parts</p>
              )}
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          
          ref={divRef}
          selectedKeys={selected}
          onSelectionChange={setSelected}
          selectionMode="single"
          className="w-fit h-auto shadow-lg"
          aria-label="Static Actions"
          closeOnSelect={true}
          variant="faded"
        >
          <DropdownSection>
            <DropdownItem
              classNames={{
                base: "gap-0 data-[selectable=true]:focus:bg-white border-none dark:data-[selectable=true]:focus:bg-[#18181B]",
                selectedIcon: "w-0",
              }}
              isReadOnly
              key="new"
            >
              <Input
                // ref={inputRef}
                size="sm"
                startContent={
                  <lord-icon
                    src="https://cdn.lordicon.com/kkvxgpti.json"
                    trigger="hover"
                    colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                    style={{ height: "16px" }}
                  ></lord-icon>
                }
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search parts.."
                style={{ fontSize: "12px" }}
                className="w-full"
              ></Input>
            </DropdownItem>
          </DropdownSection>

          {filteredOptions?.length > 0 ? (
            <DropdownSection className="max-h-[200px] w-[350px] overflow-y-auto">
              {filteredOptions?.map((item) => (
                <DropdownItem
                  key={item.id}
                  // startContent={renderIcon(item.attributes.Type)}
                  onClick={() => setSelected(new Set([item.id]))}
                >
                  {PartItem(item, item?.attributes?.Name, newGenerateUrl(item))}
                </DropdownItem>
              ))}
            </DropdownSection>
          ) : (
            <DropdownItem className="cursor-default w-[350px]" isReadOnly>
              No matching records.
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    
  );
}


export default PartPicker;
