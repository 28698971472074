import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs, Button } from "@nextui-org/react";

function AccountsHeader({ setIsOpen }) {
  return (
    <div className="flex mx-8 justify-between pt-8">
      <div className="w-full flex items-center justify-between">
        <div className="flex text-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm">
            <BreadcrumbItem>Accounts</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <Button size="sm" className="mx-2 text-white bg-[rgb(51,51,245)]" onClick={() => setIsOpen(true)} >Create</Button>
      </div>
    </div>
  );
}

export default AccountsHeader;
