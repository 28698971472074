import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectResData, selectUserData } from "../../redux/actions";
import {
  Avatar,
  AvatarGroup,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
} from "@nextui-org/react";
import { Colors, numColors } from "../../utils/constant";
import SingleAvatar, { renderUserName } from "../AvatarRender/Avatar";
import { useTheme } from "next-themes";

const UserPick = ({
  selected,
  setSelected,
  text,
  buttonClass,
  menuClass,
  selectionMode,
  inputRef,
  dropDownRef,
}) => {
  const [search, setSearch] = useState("");
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const {theme} = useTheme();
  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    const filtered = users.filter((option) =>
      option.FirstName
        ? `${option.FirstName} ${option?.LastName}`
            .toLowerCase()
            .includes(search.toLowerCase())
        : option.username.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [search, users]);
  return (
    <Dropdown ref={dropDownRef}>
      <DropdownTrigger>
        <Button className={buttonClass} variant="light" size="sm">
          {Array.from(selected).length === 0 ? (
            text
          ) : Array.from(selected).length === 1 ? (
            <div className="flex items-center gap-2">
              <SingleAvatar id={Array.from(selected)[0]} />
              {renderUserName(Array.from(selected)[0],res,30)}
            </div>
          ) : (
            <AvatarGroup>
              {Array.from(selected)?.map((it) => (
                <Avatar
                  color={Colors[Number(it) % numColors]}
                  style={{ width: "22px", height: "22px" }}
                  showFallback
                  radius="full"
                  name={
                    res && res?.[String(it)]?.UserName?.toUpperCase().charAt(0)
                  }
                  className="flex-shrink-0"
                  size="sm"
                  src={res && res?.[String(it)]?.ProfilePicture}
                />
              ))}
            </AvatarGroup>
          )}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        className={menuClass}
        selectionMode={selectionMode}
        selectedKeys={selected}
        onSelectionChange={setSelected}
      >
        <DropdownSection>
          <DropdownItem
            classNames={{
              base: "gap-0 data-[selectable=true]:focus:bg-white border-none",
              selectedIcon: "w-0",
            }}
            isReadOnly
            key="new"
          >
            <Input
              ref={inputRef}
              size="sm"
              startContent={
                <lord-icon
                  src="https://cdn.lordicon.com/kkvxgpti.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  trigger="hover"
                  style={{ height: "16px" }}
                ></lord-icon>
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search user.."
              style={{ fontSize: "12px" }}
              className="w-full"
            ></Input>
          </DropdownItem>
        </DropdownSection>
        {filteredUsers?.length > 0 ? (
          <DropdownSection className=" max-h-[200px] overflow-y-auto">
            {filteredUsers?.map((item) => (
              <DropdownItem
                key={item?.id}
                startContent={
                  <Avatar
                    showFallback
                    name={item?.username?.slice(0, 1).toUpperCase()}
                    radius="full"
                    color={Colors[Number(item.id) % numColors]}
                    style={{ height: "20px", width: "20px" }}
                    size="sm"
                    src={item?.ProfilePicture?.formats?.small?.url}
                  />
                }
              >
                {item.FirstName
                  ? `${item.FirstName} ${item?.LastName ?? ""}`
                  : item.username ?? ""}
              </DropdownItem>
            ))}
          </DropdownSection>
        ) : (
          <DropdownItem className=" cursor-default" isReadOnly>
            {" "}
            No matching records.
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserPick;
