import { Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { CiDark, CiLight } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import { useTheme } from "next-themes";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { setTheme as setReduxTheme } from "../../redux/actions";
import { UpdateOneUserData } from "../../controllers/strapiController";

const ThemeSwitch = () => {
  const { setTheme } = useTheme();
  const currTheme = useSelector(selectTheme);
  const [selected, setSelected] = useState(currTheme);
  const dispatch = useDispatch();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  useEffect(() => {
    const setUserTheme = async () => {
      try{
        await UpdateOneUserData("users",UserId,{theme : selected});
        dispatch(setReduxTheme(selected));
      }
      catch(e){
        console.log(e);
      }
      
    };

    if (selected) {
      setUserTheme();
    }
  }, [selected]);
  useEffect(() => {
    const handleSystemThemeChange = (e) => {
      if (selected === "auto") {
        setTheme(e.matches ? "dark" : "light");
      }
    };

    // Check the initial system theme preference
    if (selected === "auto") {
      const isSystemDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setTheme(isSystemDark ? "dark" : "light");
    } else {
      setTheme(selected);
    }

    // Listen for system theme changes
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleSystemThemeChange);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleSystemThemeChange);
    };
  }, [selected, setTheme]);
  return (
    <Tabs
      selectedKey={selected}
      onSelectionChange={setSelected}
      size="sm"
      aria-label="Tabs sizes"
      className="mb-2"
    >
      <Tab
        key={"dark"}
        title={
          <div className="flex items-center space-x-2">
            <CiDark />
            <span className=" text-xs">Dark</span>
          </div>
        }
      />
      <Tab
        key={"light"}
        title={
          <div className="flex items-center space-x-2">
            <CiLight />
            <span className=" text-xs">Light</span>
          </div>
        }
      />
      <Tab
        key={"auto"}
        title={
          <div className="flex items-center space-x-2">
            <MdAutorenew />
            <span className=" text-xs">Auto</span>
          </div>
        }
      />
    </Tabs>
  );
};

export default ThemeSwitch;
