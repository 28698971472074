import {
  Button,
  Dropdown,
  DropdownTrigger,
  Input,
  Modal,
  ModalContent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem,
  Switch,
  Textarea,
} from "@nextui-org/react";
import React, { useState } from "react";
import {
  DROPDOWN,
  generateBoilerPlate,
  PEOPLE,
  validationCheckField,
} from "../../utils/customAttributes";
import { SectionedDropDown } from "./DropdownActions";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { basicIcons, inputClassNames } from "../../utils/default";

const EditModal = ({ metadata, handleSubmit, boilerP, isOpen, onClose }) => {
  const [boilerPlate, setBoilerPlate] = useState(boilerP);
  const [selectedField, setSelectedField] = useState(new Set([boilerP?.type]));
  const [errorMsg, setErrorMsg] = useState("");
  const [icon, setIcon] = useState(
    basicIcons.find((item) => String(item.name) === String(boilerP?.icon))
  );
  const handleSelectIcon = (item) => {
    setBoilerPlate((prev) => ({ ...prev, icon: item.name }));
    setIcon(item);
  };
  const handleEdit = async () => {
    const meta = metadata.filter((it) => String(it.id) !== String(boilerP.id));
    const valididty = validationCheckField(meta, boilerPlate);
    if (valididty.status === false) {
      setErrorMsg(valididty.message);
      return;
    } else if (valididty.status === true) {
      const data = boilerPlate;
      data.id = String(boilerP.id);
      const newMeta = [...meta, data];
      try {
        await handleSubmit(newMeta);
        onClose();
      } catch (error) {
        console.log("error", error);
      }
    } else {
      return;
    }
  };
  return (
    <Modal size="xl" isOpen={isOpen} onClose={() => onClose()}>
      <ModalContent className=" px-8 py-4  max-h-[80%] overflow-y-auto">
        <div className="w-full h-full overflow-y-auto flex flex-col gap-4 p-2 ">
          <Input
            size="sm"
            variant="bordered"
            placeholder="Enter field name"
            classNames={inputClassNames}
            label="Field Name"
            labelPlacement="outside"
            value={boilerPlate?.name}
            onChange={(e) =>
              setBoilerPlate((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <Textarea
            classNames={inputClassNames}
            variant="bordered"
            placeholder="Enter field description"
            label="Description"
            labelPlacement="outside"
            size="sm"
            value={boilerPlate?.description}
            onChange={(e) =>
              setBoilerPlate((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
          <div className=" flex items-center justify-between">
          <div className="flex items-center gap-4">
            <p className="text-sm">Required</p>
            <Switch
              isSelected={boilerPlate?.required}
              onChange={(e) =>
                setBoilerPlate((prev) => ({
                  ...prev,
                  required: e.target.checked,
                }))
              }
            />
          </div>
            <div className="flex items-center gap-4">
              <p className=" text-sm"> Select Icon</p>
              <Popover>
                <PopoverTrigger>
                  <Button variant="light" size="sm" isIconOnly>
                    {icon && (
                      <svg
                        xmlns={icon.xmlns}
                        fill={icon.fill}
                        viewBox={icon.viewBox}
                        strokeWidth={icon.strokeWidth}
                        stroke={icon.stroke}
                        className="size-6"
                      >
                        <path
                          strokeLinecap={icon.path.strokeLinecap}
                          strokeLinejoin={icon.path.strokeLinejoin}
                          d={icon.path.d}
                        ></path>
                      </svg>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className=" flex justify-around flex-wrap w-60">
                    {basicIcons.map((item) => (
                      <div
                        onClick={() => handleSelectIcon(item)}
                        className=" hover:bg-[#F2F2F3] p-2 cursor-pointer"
                      >
                        <svg
                          xmlns={item.xmlns}
                          fill={item.fill}
                          viewBox={item.viewBox}
                          strokeWidth={item.strokeWidth}
                          stroke={item.stroke}
                          className="size-4"
                        >
                          <path
                            strokeLinecap={item.path.strokeLinecap}
                            strokeLinejoin={item.path.strokeLinejoin}
                            d={item.path.d}
                          ></path>
                        </svg>
                      </div>
                    ))}
                  </div>
                </PopoverContent>
              </Popover>
            </div>

          </div>
          <div className="flex flex-col gap-2 w-full">
            <p className="text-sm">Type </p>
            <Dropdown isDisabled>
              <DropdownTrigger>
                <Button
                  className="flex items-center justify-start border border-divider"
                  startContent={
                    <svg
                      xmlns={
                        generateBoilerPlate(Array.from(selectedField)[0])?.icon
                          ?.xmlns
                      }
                      fill={
                        generateBoilerPlate(Array.from(selectedField)[0])?.icon
                          ?.fill
                      }
                      viewBox={
                        generateBoilerPlate(Array.from(selectedField)[0])?.icon
                          ?.viewBox
                      }
                      strokeWidth={
                        generateBoilerPlate(Array.from(selectedField)[0])?.icon
                          ?.strokeWidth
                      }
                      stroke={
                        generateBoilerPlate(Array.from(selectedField)[0])?.icon
                          ?.stroke
                      }
                      className={
                        generateBoilerPlate(Array.from(selectedField)[0])?.icon
                          .className
                      }
                    >
                      <path
                        strokeLinecap={
                          generateBoilerPlate(Array.from(selectedField)[0])
                            ?.icon?.path?.strokeLinecap
                        }
                        strokeLinejoin={
                          generateBoilerPlate(Array.from(selectedField)[0])
                            ?.icon?.path?.strokeLinejoin
                        }
                        d={
                          generateBoilerPlate(Array.from(selectedField)[0])
                            ?.icon?.path?.d
                        }
                      ></path>
                    </svg>
                  }
                  size="sm"
                  variant="light"
                >
                  {generateBoilerPlate(Array.from(selectedField)[0])?.label}
                </Button>
              </DropdownTrigger>
            </Dropdown>
          </div>
          {(Array.from(selectedField)[0] === DROPDOWN ||
            Array.from(selectedField)[0] === PEOPLE) && (
            <Select
              size="sm"
              label="Selection Mode"
              labelPlacement="outside"
              classNames={{
                base: "bg-white dark:bg-[#18181B]",
                trigger:
                  "bg-white dark:bg-black shadow-none border border-divider data-[hover=true]:bg-default-100 data-[hover=true]:bg-[#2D2D32]",
              }}
              variant="light"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={new Set([boilerPlate?.mode])}
            >
              <SelectItem
                onClick={() =>
                  setBoilerPlate((prev) => ({ ...prev, mode: "single" }))
                }
                key="single"
              >
                Single select
              </SelectItem>
              <SelectItem
                onClick={() =>
                  setBoilerPlate((prev) => ({ ...prev, mode: "multiple" }))
                }
                key="multiple"
              >
                Multiple select
              </SelectItem>
            </Select>
          )}
          {Array.from(selectedField)[0] === DROPDOWN && (
            <>
              <div className="flex items-center justify-between">
                <p className="text-sm">With Sections</p>
                <Switch
                  isSelected={boilerPlate?.section}
                  onChange={(e) =>
                    setBoilerPlate((prev) => ({
                      ...prev,
                      section: e.target.checked,
                    }))
                  }
                />
              </div>
              {boilerPlate?.section ? (
                <>
                  {boilerPlate?.sectionList.map((sec) => (
                    <SectionedDropDown
                      sec={sec}
                      setBoilerPlate={setBoilerPlate}
                      boilerPlate={boilerPlate}
                    />
                  ))}
                  <div>
                    <Button
                      isIconOnly
                      size="sm"
                      variant="light"
                      onClick={() =>
                        setBoilerPlate((prev) => ({
                          ...prev,
                          sectionList: [
                            ...prev.sectionList,
                            {
                              id: Date.now(),
                              header: "",
                              fields: "",
                            },
                          ],
                        }))
                      }
                    >
                      +
                    </Button>
                  </div>
                </>
              ) : (
                <Textarea
                  classNames={inputClassNames}
                  variant="bordered"
                  placeholder="Seperate items using comma ( , )"
                  label="Dropdown Fields"
                  labelPlacement="outside"
                  size="sm"
                  value={boilerPlate?.fields}
                  onChange={(e) =>
                    setBoilerPlate((prev) => ({
                      ...prev,
                      fields: e.target.value,
                    }))
                  }
                />
              )}
            </>
          )}
          <div className="flex items-center justify-between">
            <Button
              onClick={() => onClose()}
              size="sm"
              variant="light"
              className="border border-divider"
            >
              Cancel
            </Button>
            <PrimaryButton handler={handleEdit} text={"Update"} />
          </div>
          {errorMsg !== "" && <p className="text-red-400">{errorMsg}</p>}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
