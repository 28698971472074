import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleDoc, patchData } from "../../controllers/vectorDB";
import {
  Button,
  DatePicker,
  Input,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import { parseDate } from "@internationalized/date";
import { checkSameArrays, convertToDateString, inputClassNames } from "../../utils/default";
import UserPick from "../../components/UserModal/UserPick";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions";
import Tags from "../../components/Tag/tags";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import ReleaseMarkdown from "./ReleaseMarkdown";
import HelixLoader from "../../components/Loader/HelixLoader";

const ReleaseDoc = () => {
  const { org, docId } = useParams();
  const orgData = useSelector(selectOrgData);
  const navigate = useNavigate();
  const [doc, setDoc] = useState(null);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(new Set(["Unreleased"]));
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [releaseDate, setReleaseDate] = useState(null);
  const [owner, setOwner] = useState(new Set([]));
  const [tags, setTags] = useState([]);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [titleEdit, setTitleEdit] = useState(false);
  const [documentation, setDocumentation] = useState("");
  const [start, setStart] = useState(false);
  const [loader, setLoader] = useState(true);
  const checkIfSame = (date1, date2) => {
    if (
      date1.day === date2.day &&
      date1.month === date2.month &&
      date1.year === date2.year
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkForUpdate = () => {
    if (
      description === "" ||
      Array.from(owner).length === 0 ||
      startDate === null ||
      releaseDate === null ||
      Array.from(status).length === 0 ||
      (description === doc.description &&
        String(Array.from(owner)[0]) === String(doc?.owner) &&
        checkIfSame(startDate, doc.startDate) &&
        checkIfSame(releaseDate, doc.releaseDate) &&
        checkSameArrays(tags, doc.tags) &&
        doc.status === Array.from(status)[0])
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleTitleEdit = async () => {
    if (title === "" || title === doc?.title) {
      setTitle(doc?.title);
      setTitleEdit(false);
      return;
    }
    try {
      await patchData({ title }, doc.id, org);
      setDoc((prev) => ({ ...prev, title: title }));
      setTitleEdit(false);
    } catch (error) {}
  };
  const handleUpdate = async () => {
    const inValid = checkForUpdate();
    if (inValid) {
      return;
    }
    const formData = {
      description,
      startDate,
      releaseDate,
      owner: String(Array.from(owner)[0]),
      tags,
      type: "release",
      status: Array.from(status)[0],
    };
    try {
      await patchData(formData, doc.id, org);
      setDoc((prev) => ({ ...prev, ...formData }));
    } catch (error) {}
  };
  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const resp = await getSingleDoc(docId, org);
        console.log("resp", resp[0]?.payload);
        if (!resp || resp?.length === 0) {
          navigate(`/${org}/releases`);
        }
        setDoc(resp[0].payload);
        setStatus(new Set([resp[0]?.payload?.status ?? "Unreleased"]));
        setStartDate(
          resp[0]?.payload?.startDate
            ? parseDate(convertToDateString(resp[0]?.payload?.startDate))
            : null
        );
        setReleaseDate(
          resp[0]?.payload?.releaseDate
            ? parseDate(convertToDateString(resp[0]?.payload?.releaseDate))
            : null
        );
        setOwner(new Set([resp[0]?.payload?.owner]));
        setDescription(resp[0]?.payload?.description);
        setTags(resp[0]?.payload?.tags);
        setTitle(resp[0]?.payload?.title);
        setDocumentation(resp[0]?.payload?.release_doc ?? "");
        setLoader(false);
      } catch (error) {}
    };
    if (docId && org) {
      fetchDoc();
    }
  }, [docId]);
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagsResp = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        setCustomTags(tagsResp.data);
        const filtered = tagsResp.data.filter((option) =>
          doc?.tags.includes(option.id)
        );
        setTagsDetail(filtered);
      } catch (error) {}
    };
    if (orgData.id && doc) {
      fetchTags();
    }
  }, [orgData, doc]);
  useEffect(() => {
    const updateDoc = async () => {
      if (
        doc === null ||
        documentation === "" ||
        documentation === doc?.release_doc
      ) {
        return;
      }
      try {
        await patchData({ release_doc: documentation }, doc.id, org);
        setDoc((prev) => ({ ...prev, release_doc: documentation }));
      } catch (error) {}
    };
    if (doc) {
      let time = setTimeout(() => updateDoc(), 2000);
      return () => clearTimeout(time);
    }
  }, [documentation]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
      </div>
    );
  }
  return (
    <div className="flex flex-1 w-full border-t border-divider mt-8">
      <div className="h-full w-[75%] overflow-y-auto">
        <div className="py-4 px-8 flex flex-col gap-4">
          {titleEdit ? (
            <div className="flex items-center gap-4">
              <Input
                className="w-96"
                classNames={inputClassNames}
                value={title}
                variant="bordered"
                onChange={(e) => setTitle(e.target.value)}
              />{" "}
              <Button
                onClick={handleTitleEdit}
                color="success"
                isIconOnly
                size="sm"
                variant="light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 12.75 6 6 9-13.5"
                  />
                </svg>
              </Button>
              <Button
                onClick={() => {
                  setTitle(doc?.title);
                  setTitleEdit(false);
                }}
                color="danger"
                isIconOnly
                size="sm"
                variant="light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </Button>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <div className=" text-lg">{doc?.title} </div>
              <Button
                variant="light"
                size="sm"
                isIconOnly
                onClick={() => setTitleEdit(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              </Button>{" "}
            </div>
          )}
          {doc && (
            <ReleaseMarkdown
              start={start}
              setStart={setStart}
              desc={documentation}
              setDesc={setDocumentation}
            />
          )}
        </div>
      </div>
      <div className="h-full w-[25%] border-l border-divider overflow-y-auto">
        <div className="flex flex-col p-4 gap-4">
          <Select selectedKeys={status} onSelectionChange={setStatus}>
            <SelectItem key="Released">Released</SelectItem>
            <SelectItem key="Unreleased">Unreleased</SelectItem>
            <SelectItem key="Archived">Archived</SelectItem>
            <SelectItem key="Delayed">Delayed</SelectItem>
          </Select>
          <div className="flex items-center justify-between w-full">
            <DatePicker
              labelPlacement="outside"
              label="Start date"
              className="w-[40%]"
              size="sm"
              value={startDate}
              onChange={setStartDate}
            />
            <DatePicker
              labelPlacement="outside"
              label="Release date"
              className="w-[40%]"
              size="sm"
              value={releaseDate}
              onChange={setReleaseDate}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-xs">Owner</p>
            <UserPick
              menuClass="w-[400px]"
              selected={owner}
              setSelected={setOwner}
              text="Select Owner"
              buttonClass="w-full flex justify-start border border-divider "
              selectionMode="single"
            />
          </div>
          <Textarea
            classNames={inputClassNames}
            variant="bordered"
            placeholder="Enter description"
            label="Description"
            labelPlacement="outside"
            size="sm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="flex flex-col gap-2">
            <p className="text-xs">Tags</p>
            <Tags
              param={org}
              customTags={customTags}
              org={orgData.id}
              tags={tags}
              classnames="w-full border border-divider rounded-lg min-h-8 overflow-y-auto flex items-center pt-2"
              setTags={setTags}
              tagsDetail={tagsDetail}
              setTagsDetail={setTagsDetail}
            />
          </div>
          <div className="flex items-center justify-end">
            <PrimaryButton
              text="Save Details"
              handler={handleUpdate}
              disabled={checkForUpdate()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleaseDoc;
