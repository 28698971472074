import "./messagestyles.css";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import Focus from "@tiptap/extension-focus";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { useCurrentEditor } from "@tiptap/react";
import italic from "../../Images/Icons/italic.svg";
import bold from "../../Images/Icons/bold.svg";
import code from "../../Images/Icons/code.svg";
import list from "../../Images/Icons/list.svg";
import strike from "../../Images/Icons/strike.svg";
import quote from "../../Images/Icons/quote.svg";
import numberlist from "../../Images/Icons/numberlist.svg";
import Image from "@tiptap/extension-image";
import { useEffect, useRef, useState } from "react";
import { uploadFile } from "../../controllers/strapiController";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import { NoNewLine } from "./EnterPreventExt.js";

export const extensions = (ref) => {
  return [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    Focus.configure({
      className: "has-focus",
      mode: "all",
    }),
    NoNewLine.configure({
      handleEnter: () => {
        ref.current.click();
        return true;
      },
    }),
    // Mention.configure({
    //   HTMLAttributes: {
    //     class: "mention",
    //   },
    //   suggestion: suggestionExtension(nameArray),
    // }),
    Placeholder.configure({
      emptyEditorClass: "Add Description",
      placeholder: "Add Description",
    }),
    Image.configure({
      inline: true,
    }),
    Link.configure({
      HTMLAttributes: {
        class: "link-editor",
      },
    }),
    TaskList,
    TaskItem.configure({
      nested: false,
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
  ];
};

export const MenuBar = ({
  descriptionContent,
  setDescriptionContent,
  imageArray,
  setImageArray,
  clear,
  initial,
  setInitial,
}) => {
  const { editor } = useCurrentEditor();
  const [fileUpload, setFileUpload] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const fileInputRef = useRef();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setFileUpload(file);
    } else {
      setFileUpload(null);
    }
  };
  const handlePaste = async (event, editor) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const formData = new FormData();
        formData.append("files", file);
        try {
          const response = await uploadFile(formData);
          const URLresponse = response && response[0];
          if (URLresponse) {
            const baseURL =
              process.env.REACT_APP_STAGE === "Dev"
                ? process.env.REACT_APP_STRAPI_IP_ADDRESS
                : "";
            const imageUrl = `${baseURL}${URLresponse.formats.small.url}`;
            setImageArray((prev) => [
              ...prev,
              {
                id: URLresponse.id,
                url: imageUrl,
              },
            ]);
            editor.chain().focus().setImage({ src: imageUrl }).run();
          }
        } catch (error) {
          console.error("Upload Error:", error);
        }
      }
    }
  };
  useEffect(() => {
    if (editor) {
      const handlePasteEvent = (event) => handlePaste(event, editor);
      document.addEventListener("paste", handlePasteEvent);
      return () => document.removeEventListener("paste", handlePasteEvent);
    }
  }, [editor]);

  const UploadFileHandler = async () => {
    let formData = new FormData();
    formData.append("files", fileUpload);
    try {
      const response = await uploadFile(formData);
      if (response) {
        const URLresponse = response && response[0];
        if (URLresponse) {
          setImageURL(
            `${
              process.env.REACT_APP_STAGE === "DEV"
                ? process.env.REACT_APP_STRAPI_IP_ADDRESS
                : ""
            }${URLresponse.formats.small.url}`
          );
          setImageArray((prev) => [
            ...prev,
            {
              id: URLresponse.id,
              url: `${
                process.env.REACT_APP_STAGE === "DEV"
                  ? process.env.REACT_APP_STRAPI_IP_ADDRESS
                  : ""
              }${URLresponse.formats.small.url}`,
            },
          ]);
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    if (clear > 0) {
      editor.commands.clearContent();
      setDescriptionContent("");
    }
  }, [clear]);
  useEffect(() => {
    if (fileUpload) {
      UploadFileHandler();
    }
  }, [fileUpload]);
  useEffect(() => {
    if (imageURL) {
      editor.chain().focus().setImage({ src: imageURL }).run();
    }
  }, [imageURL]);

  const html = editor.getHTML();

  useEffect(() => {
    if (editor && !initial) {
      editor.commands.setContent(descriptionContent);
      setInitial(true);
    }
  }, [editor, initial]);
  useEffect(() => {
    if (initial) {
      setDescriptionContent(html);
    }
  }, [html, initial]);
  if (!editor) {
    return null;
  }
  return (
    <div className="flex gap-1 p-1 border-b border-divider">
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={`${
          editor.isActive("bold")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md  "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinejoin="round"
            d="M6.75 3.744h-.753v8.25h7.125a4.125 4.125 0 0 0 0-8.25H6.75Zm0 0v.38m0 16.122h6.747a4.5 4.5 0 0 0 0-9.001h-7.5v9h.753Zm0 0v-.37m0-15.751h6a3.75 3.75 0 1 1 0 7.5h-6m0-7.5v7.5m0 0v8.25m0-8.25h6.375a4.125 4.125 0 0 1 0 8.25H6.75m.747-15.38h4.875a3.375 3.375 0 0 1 0 6.75H7.497v-6.75Zm0 7.5h5.25a3.75 3.75 0 0 1 0 7.5h-5.25v-7.5Z"
          />
        </svg>
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={`${
          editor.isActive("italic")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803"
          />
        </svg>
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={`${
          editor.isActive("strike")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 12a8.912 8.912 0 0 1-.318-.079c-1.585-.424-2.904-1.247-3.76-2.236-.873-1.009-1.265-2.19-.968-3.301.59-2.2 3.663-3.29 6.863-2.432A8.186 8.186 0 0 1 16.5 5.21M6.42 17.81c.857.99 2.176 1.812 3.761 2.237 3.2.858 6.274-.23 6.863-2.431.233-.868.044-1.779-.465-2.617M3.75 12h16.5"
          />
        </svg>
      </button>
      <button
        className="hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md"
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleButtonClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={(event) => handleFileChange(event)}
          className="hidden"
        />
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={`${
          editor.isActive("code")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md  "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
          />
        </svg>
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`${
          editor.isActive("blockquote")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md  "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="size-4"
        >
          <path d="M16 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z" />
          <path d="M5 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z" />
        </svg>
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${
          editor.isActive("bulletlist")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${
          editor.isActive("orderedlist")
            ? "is-active"
              ? " bg-gray-200 dark:bg-[#2D2D32] rounded-md   "
              : "bg-transparent"
            : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99"
          />
        </svg>
      </button>
      <button
        style={{
          height: "25px",
          width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={`${
          editor.isActive("taskList") ? "is-active" : ""
        } hover:bg-gray-200 dark:hover:bg-[#2D2D32] rounded-md`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
          />
        </svg>
      </button>
    </div>
  );
};
export const props = () => {
  return {
    attributes: {
      class: `prose max-w-none message [&_ol]:list-decimal [&_ul]:list-disc text-xs`,
    },
  };
};
// export default () => {
//   return (
//     <EditorProvider editorProps={props} slotBefore={<MenuBar />} extensions={extensions} content={content}></EditorProvider>
//   )
// }
