import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData } from "../../redux/actions";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import { Avatar, Chip } from "@nextui-org/react";
import { renderIcon } from "../../utils/default";

const MessageRenderer = ({ update }) => {
  const res = useSelector(selectResData);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [parsedParts, setParsedParts] = useState(null);
  const orgData = useSelector(selectOrgData);
  useEffect(() => {
    const fetchParts = async () => {
      try {
        const fields = ["Name", "Type"];
        const resp = await getFilteredBySingleRelation(
          "parts",
          "organization",
          orgData.id,
          fields
        );
        const convertedParts = {};
        resp?.data?.forEach((item) => {
          convertedParts[String(item?.id)] = {
            Name: item?.attributes?.Name,
            Type: item?.attributes?.Type,
          };
        });
        setParsedParts(convertedParts);
      } catch (error) {}
    };
    if (orgData.id) {
      fetchParts();
    }
  }, [orgData]);

  return (
    <div>
      <div className="flex items-center gap-3 leading-loose">
        <Avatar
          size="sm"
          radius="sm"
          color={Colors[Number(update?.message?.by) % numColors]}
          src={
            res?.[update?.message?.by]?.ProfilePicture
              ? process.env.REACT_APP_STAGE === "Dev"
                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                    res[String(update?.message?.by)].ProfilePicture
                  }`
                : `${res[String(update?.message?.by)].ProfilePicture}`
              : null
          }
          showFallback
          name={
            res &&
            res[String(update?.message?.by)] &&
            (res[String(update?.message?.by)].FirstName
              ? `${res[String(update?.message?.by)].FirstName?.charAt(0)}`
              : `${res[String(update?.message?.by)].UserName?.charAt(0).toUpperCase()}`)
          }
        />
       {update?.type ==="part" ? 
       <>
       <p>{res && res[String(update?.message?.by)]?.FirstName ? `${res[String(update?.message?.by)]?.FirstName} ${res[String(update?.message?.by)]?.LastName ?? ""}` : res[String(update?.message?.by)].UserName} {" "}
            {update?.message?.role === "owner" ? (update?.operation === "created" ? "created" : "updated" ) :
            update?.message?.role === "mention" ? (update?.operation === "create" ? "mentioned you in" : "updated" ) : "" } 
        </p>
       </>
       : <>
        <p>{res && res[String(update?.message?.by)]?.FirstName ? `${res[String(update?.message?.by)]?.FirstName} ${res[String(update?.message?.by)]?.LastName ?? ""}` : res[String(update?.message?.by)].UserName} {" "}
            {update?.message?.role === "assigned" ? (update?.operation === "create" ? "assigned you" : "updated" ) : update?.message?.role === "owner" ? (update?.operation === "created" ? "created" : "updated" ) :
            update?.message?.role === "mention" ? (update?.operation === "create" ? "mentioned you in" : "updated" ) : "" } 
        </p>
        <Chip size="sm" variant="flat" color={update.type === "ticket" ? "danger" : "primary"} >{update.type === "ticket" ? `TKT-${update.message.latest}` : `ISS-${update.message.latest}` }</Chip>
        <p>for the component</p></>}
        
        
        {renderIcon(parsedParts?.[String(update?.message?.partId)]?.Type)}
        {parsedParts?.[String(update?.message?.partId)]?.Name}
      </div>
    </div>
  );
};

export default MessageRenderer;
