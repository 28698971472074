import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Popover,
  PopoverTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { lorem } from "../../utils/default";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions";
import { getOneData, UpdateData } from "../../controllers/strapiController";
import {
  attributeFields,
  generateBoilerPlate,
} from "../../utils/customAttributes";
import DropdownActions from "./DropdownActions";
import EditModal from "./EditModal";
import {
  accountAttributes,
  componentAttributes,
  customerAttributes,
  inboxAttributes,
  issueAttributes,
  ticketAttributes,
} from "../../utils/permanentAttributes";

const AttributesTable = () => {
  const { page } = useParams();
  const [metadata, setMetadata] = useState([]);
  const [editBoilerP, setEditBoilerP] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [perm, setPerm] = useState([]);
  const orgData = useSelector(selectOrgData);
  const handleDelete = async (id) => {
    try {
      const data = metadata.filter((item) => item.id !== id);
      const formData = { [attributeFields[page]]: data };
      await UpdateData("organizations", orgData.id, formData);
      setMetadata(data);
    } catch (error) {}
  };
  const handleSubmit = async (data) => {
    const formData = { [attributeFields[page]]: data };

    try {
      await UpdateData("organizations", orgData.id, formData);
      setMetadata(data);
    } catch (error) {}
  };
  useEffect(() => {
    const fetchMeta = async () => {
      const resp = await getOneData("organizations", orgData.id, [
        attributeFields[page],
      ]);
      setMetadata(resp.data.attributes[attributeFields[page]] ?? []);
    };
    setPerm(
      page === "tickets"
        ? ticketAttributes
        : page === "issues"
        ? issueAttributes
        : page === "inbox"
        ? inboxAttributes
        : page === "customers"
        ? customerAttributes
        : page === "accounts"
        ? accountAttributes
        : componentAttributes
    );
    if (page && orgData?.id) {
      fetchMeta();
    }
  }, [page, orgData]);
  return (
    <div className="flex-1 flex flex-col pt-8 gap-8">
      {editBoilerP && (
        <EditModal
          metadata={metadata}
          handleSubmit={handleSubmit}
          boilerP={editBoilerP}
          isOpen={isOpen}
          onClose={() => {
            setEditBoilerP(null);
            setIsOpen(false);
          }}
        />
      )}
      <Divider className="border border-divider" />
      <div className=" flex flex-col capitalize gap-4 px-8">
        <p className="text-xl font-semibold">{page}</p>
        <p className=" text-sm text-gray-400">{lorem}</p>
      </div>
      <div className=" flex px-8 justify-end">
        <Popover placement="left-start">
          <PopoverTrigger>
            <Button size="sm" className="mx-2 text-white bg-[rgb(51,51,245)]">
              Add attributes
            </Button>
          </PopoverTrigger>
          <DropdownActions metadata={metadata} handleSubmit={handleSubmit} />
        </Popover>
      </div>
      <Table removeWrapper className="px-8 pb-8">
        <TableHeader>
          <TableColumn>Field Name</TableColumn>
          <TableColumn>Type</TableColumn>
          <TableColumn></TableColumn>
        </TableHeader>
        <TableBody>
          {perm?.map((item, index) => (
            <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-[#2D2D32] cursor-not-allowed text-xs text-gray-400  border-b border-divider">
              <TableCell className="">
                <div className=" flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                  <p>{item.name}</p>
                </div>
              </TableCell>
              <TableCell>
                <div className=" flex items-center gap-2">
                  <svg
                    xmlns={generateBoilerPlate(item.type)?.icon?.xmlns}
                    fill={generateBoilerPlate(item.type)?.icon?.fill}
                    viewBox={generateBoilerPlate(item.type)?.icon?.viewBox}
                    strokeWidth={
                      generateBoilerPlate(item.type)?.icon?.strokeWidth
                    }
                    stroke={generateBoilerPlate(item.type)?.icon?.stroke}
                    className={generateBoilerPlate(item.type)?.icon.className}
                  >
                    <path
                      strokeLinecap={
                        generateBoilerPlate(item.type)?.icon?.path
                          ?.strokeLinecap
                      }
                      strokeLinejoin={
                        generateBoilerPlate(item.type)?.icon?.path
                          ?.strokeLinejoin
                      }
                      d={generateBoilerPlate(item.type)?.icon?.path?.d}
                    ></path>
                  </svg>
                  <p>{generateBoilerPlate(item.type)?.label}</p>
                </div>
              </TableCell>
              <TableCell>
                <Button size="sm" variant="light" isDisabled isIconOnly disabled>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-red-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.166m-1.022.165L5.84 19.673A2.25 2.25 0 0 0 8.084 21.75h7.832a2.25 2.25 0 0 0 2.244-2.077L19.228 5.79m-14.456 0a48.108 48.108 0 0 1 3.478-.397M9.75 5.25v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5M9.75 5.25h4.5"
                    />
                  </svg> */}
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {metadata?.map((item, index) => (
            <TableRow
              key={index}
              onClick={() => {
                setEditBoilerP(item);
                setIsOpen(true);
              }}
              className="cursor-pointer hover:bg-gray-100 dark:hover:bg-[#2D2D32] text-xs  border-b border-divider"
            >
              <TableCell>{item.name}</TableCell>
              <TableCell>
                <div className=" flex items-center gap-2">
                  <svg
                    xmlns={generateBoilerPlate(item.type)?.icon?.xmlns}
                    fill={generateBoilerPlate(item.type)?.icon?.fill}
                    viewBox={generateBoilerPlate(item.type)?.icon?.viewBox}
                    strokeWidth={
                      generateBoilerPlate(item.type)?.icon?.strokeWidth
                    }
                    stroke={generateBoilerPlate(item.type)?.icon?.stroke}
                    className={generateBoilerPlate(item.type)?.icon.className}
                  >
                    <path
                      strokeLinecap={
                        generateBoilerPlate(item.type)?.icon?.path
                          ?.strokeLinecap
                      }
                      strokeLinejoin={
                        generateBoilerPlate(item.type)?.icon?.path
                          ?.strokeLinejoin
                      }
                      d={generateBoilerPlate(item.type)?.icon?.path?.d}
                    ></path>
                  </svg>
                  <p>{generateBoilerPlate(item.type)?.label}</p>
                </div>
              </TableCell>
              <TableCell>
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      size="sm"
                      variant="light"
                      className="text-red-500"
                      isIconOnly
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.166m-1.022.165L5.84 19.673A2.25 2.25 0 0 0 8.084 21.75h7.832a2.25 2.25 0 0 0 2.244-2.077L19.228 5.79m-14.456 0a48.108 48.108 0 0 1 3.478-.397M9.75 5.25v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5M9.75 5.25h4.5"
                        />
                      </svg>
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Actions" variant="flat">
                    <DropdownItem
                      startContent={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.166m-1.022.165L5.84 19.673A2.25 2.25 0 0 0 8.084 21.75h7.832a2.25 2.25 0 0 0 2.244-2.077L19.228 5.79m-14.456 0a48.108 48.108 0 0 1 3.478-.397M9.75 5.25v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5M9.75 5.25h4.5"
                          />
                        </svg>
                      }
                      onClick={() => {
                        handleDelete(item.id);
                      }}
                      key="delete"
                      color="danger"
                      variant="flat"
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AttributesTable;
