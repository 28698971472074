import React, { useEffect, useState, useRef } from "react";
import {
  NavLink,
  Outlet,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiUser, CiDark, CiLight, CiSettings } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import Sprint from "./Sprint";
import {
  Button,
  Divider,
  Avatar,
  Tabs,
  Tab,
  Kbd,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
} from "@nextui-org/react";
import Drawer from "../Drawer/drawer";
import {
  UpdateOneUserData,
  getFilteredBySingleRelation,
  getFilteredBySingleRelationSingleData,
  getFilteredByTwoRelation,
} from "../../controllers/strapiController";
import Org from "./Org";
import Modals from "./Modal";
import InviteIcon from "../../Images/Icons/InviteIcon";
import InviteModal from "../../pages/Invitations/modal";
import { IoLogOutOutline } from "react-icons/io5";
import { PermissionGroup } from "../../utils/permission";
import { UserPermissions } from "../../utils/default";
import { useDispatch, useSelector } from "react-redux";
import {
  orgInfo,
  selectCollapse,
  selectOrgData,
  selectPermissions,
  selectResData,
  selectUserData,
  selectUserNames,
  setAdmin,
  setPermissions,
  setRes,
  setTheme as setReduxTheme,
  setUpdates,
  setUserData,
  setUsernames,
} from "../../redux/actions";
import HelixLoader from "../Loader/HelixLoader";
import SearchModal from "./SearchModal";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import { useTheme } from "next-themes";
import CollapseButton from "./CollapseButton";

const Sidebar = () => {
  const { org } = useParams();
  const navigate = useNavigate();
  const drawerRef = useRef();
  const customAttributeRef = useRef();
  const [isOpenn, setIsOpenn] = useState(false);
  const tagsref = useRef();
  const modalref = useRef();
  const handleSearchButtonClick = () => {
    setIsOpenn(!isOpenn);
  };
  const [loader, setLoader] = useState({ fir: true, sec: true, thi: true });
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const dispatch = useDispatch();
  const [isInviteOpen, setInviteOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sideItems1, setSideItems1] = useState(new Set(["Resolv AI"]));
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const users = useSelector(selectUserData);
  const orgData = useSelector(selectOrgData);
  const resData = useSelector(selectResData);
  const userNames = useSelector(selectUserNames);
  const [userDetails, setUserDetails] = useState(null);
  const location = useLocation();
  const [isOpenPop, setIsOpenPop] = useState(false);
  const collapse = useSelector(selectCollapse);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const userPerm = useSelector(selectPermissions);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const { theme , setTheme } = useTheme();
  const [userPermSprint, setUserPermSprint] = useState({
    name: "Role-4",
    class: "Sprint Boards",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const numColors = Colors.length;
  const colorIndex = UserId && UserId % numColors;

  const handleNavigate = () => {
    navigate(`/${org}/settings`);
  };
  const handleLogout = () => {
    console.log("logout");
    sessionStorage.removeItem("userData");
    navigate(`/`);
  };
  const handleNotification = async () => {
    const formData = { Notification: !userDetails.Notification };
    try {
      await UpdateOneUserData("users", UserId, formData);
      setUserDetails((prev) => ({ ...prev, Notification: !prev.Notification }));
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "organizations",
          "users",
          UserId,
          "Name",
          org
        );
        if (resp.data.length > 0) {
          dispatch(orgInfo(resp.data[0]));
          setLoader((prev) => ({ ...prev, fir: false }));
        } else {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
    };
    if (org && UserId) {
      fetchOrg();
    }
  }, [org, UserId]);
  useEffect(() => {
    const UpdateData = async () => {
      const data = { OrgRoute: `/${orgData?.attributes?.Name}` };
      try {
        await UpdateOneUserData("users", UserId, data);
      } catch (error) {}
    };
    if (orgData?.attributes?.Name) {
      UpdateData();
    }
  }, [orgData]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation(
          "users",
          "organizations",
          orgData.id,
          ["username", "email", "FirstName", "LastName", "Notification","theme"],
          ["ProfilePicture", "organizations"]
        );
        dispatch(setUserData(results));
        const detailsUser = results.find((item) => String(item.id) === String(UserId));
        setUserDetails(
          detailsUser
        );
        const isSystemDark = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        dispatch(setReduxTheme( detailsUser?.theme ?? "auto" ));
        setTheme( detailsUser?.theme === "dark" ? "dark" : detailsUser?.theme === "light" ? "light" : 
          isSystemDark ? "dark" : "light"
         )
        if (results.length > 0) {
          const convertedUsers = {};
          const userN = [];
          results.forEach((user) => {
            userN.push({
              username: user.username,
              id: user.id,
              name: user.FirstName
                ? `${user.FirstName} ${user.LastName ? user.LastName : ""}`
                : user.username,
              url: user.ProfilePicture?.formats?.small?.url || "",
            });
            convertedUsers[String(user.id)] = {
              Email: user.email,
              UserName: user.username,
              FirstName: user.FirstName,
              LastName: user.LastName,
              ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
            };
          });
          dispatch(setUsernames(userN));
          dispatch(setRes(convertedUsers));
        }
        const updates = await getFilteredByTwoRelation(
          "updates",
          "organization",
          orgData.id,
          "user",
          UserId
        );
        if (updates?.data?.length > 0) {
          dispatch(
            setUpdates({
              id: updates.data[0].id,
              updates: updates.data[0].attributes.updates ?? [],
            })
          );
        }
        setLoader((prev) => ({ ...prev, sec: false }));
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgData?.id) {
      getAllDataByIds();
    }
  }, [orgData]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const perm = await PermissionGroup(UserId, orgData?.id);
        if (perm === false) {
          setLoader((prev) => ({ ...prev, thi: false }));
          return;
        }
        dispatch(setPermissions(perm.roles));
        dispatch(setAdmin(perm.admin));
        setLoader((prev) => ({ ...prev, thi: false }));
      } catch (error) {}
    };
    if (UserId && orgData?.id) {
      fetchPerm();
    }
  }, [orgData, UserId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!customAttributeRef.current ||
          !customAttributeRef.current.contains(event.target))
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  const searchButtonRef = useRef();
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "k") {
      event.preventDefault();
      setIsOpenn(!isOpenn);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenn]);

  useEffect(() => {
    if (!UserLogged) {
      const pathname = location.pathname;
      let search = location.search;
      if (search !== "") {
        search = search.replace(/\?/g, "&");
      }
      window.location.href = `/sign-in?redirected-to=${pathname}${search}`;
    } else {
    }
  }, []);

  const UserProfile =
    userDetails && userDetails.ProfilePicture !== null
      ? process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userDetails.ProfilePicture.url}`
        : `${userDetails.ProfilePicture.url}`
      : "";

  const NavBase = [
    {
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/vspbqszr.json"
          trigger="hover"
          state="morph-book"
          target="#updates"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Updates",
      path: `/${org}/updates`,
      id: "updates",
    },
    {
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/vdjwmfqs.json"
          trigger="hover"
          state="hover"
          target="#MyTasks"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "My Tasks",
      path: `/${org}/tasks`,
      id: "MyTasks",
    },
  ];
  const Nav1 = [
    // {
    //   ids: 1,
    //   icon: (
    //     <lord-icon
    //       src="https://cdn.lordicon.com/xtnsvhie.json"
    //       trigger="hover"
    //       state="morph-book"
    //       target="#inbox"
    //       colors={`primary: "#000000"`}
    //       style={{ width: "16px", height: "16px" }}
    //     ></lord-icon>
    //   ),
    //   title: "Inbox",
    //   path: `/${org}`,
    //   id: "inbox",
    // },
    {
      ids: 3,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/ciawvzjk.json"
          trigger="hover"
          state="morph-book"
          target="#Accounts"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Accounts",
      path: `/${org}/accounts`,
      id: "Accounts",
    },
    {
      ids: 2,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/gyblqrqz.json"
          trigger="hover"
          state="morph-book"
          target="#tickets"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Tickets",
      path: `/${org}/tickets`,
      id: "tickets",
    },
    {
      ids: 4,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/kddybgok.json"
          trigger="hover"
          state="morph-book"
          target="#customers"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Customers",
      path: `/${org}/customers`,
      id: "customers",
    },
    {
      ids: 5,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/fdxqrdfe.json"
          trigger="hover"
          state="morph-book"
          target="#team-activity"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Team Activity",
      path: `/${org}/team-activity`,
      id: "team-activity",
    },
    {
      ids: 6,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/whrxobsb.json"
          trigger="hover"
          state="morph-book"
          target="#analytics"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Analytics",
      path: `/${org}/analytics`,
      id: "analytics",
    },
  ];
  const Nav2 = [
    {
      ids: 1,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/ygvjgdmk.json"
          trigger="hover"
          state="morph-book"
          target="#issues"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Issues",
      path: `/${org}/issues`,
      id: "issues",
    },
    {
      ids: 2,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/eouimtlu.json"
          trigger="hover"
          state="morph-book"
          target="#queues"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Queues",
      path: `/${org}/queues`,
      id: "queues",
    },
    {
      ids: 3,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/hqymfzvj.json"
          trigger="hover"
          state="morph-book"
          target="#sprint-boards"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Sprint Board",
      path: `/${org}/sprint-boards`,
      id: "sprint-boards",
    },
    {
      ids: 4,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/ktsahwvc.json"
          trigger="hover"
          state="morph-book"
          target="#resolv-ai"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Resolv AI",
      path: `/${org}/resolv-ai`,
      id: "resolv-ai",
    },
  ];

  const Nav3 = [
    {
      ids: 1,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/nizfqlnk.json"
          trigger="hover"
          target="#productTrails"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Product Trails",
      path: `/${org}/product-trails`,
      id: "productTrails",
    },
    {
      ids: 2,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/eouimtlu.json"
          trigger="hover"
          state="morph-book"
          target="#components"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Components",
      path: `/${org}/components`,
      id: "components",
    },
    {
      ids: 3,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/zyzoecaw.json"
          trigger="hover"
          state="morph-book"
          target="#roadmap"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Roadmap",
      path: `/${org}/roadmap`,
      id: "roadmap",
    },
    {
      ids: 4,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/kikjlzqr.json"
          trigger="hover"
          state="morph-book"
          stroke="bold"
          target="#releases"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Releases",
      path: `/${org}/releases`,
      id: "releases",
    },
    {
      ids: 5,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/lyrrgrsl.json"
          trigger="hover"
          state="morph-book"
          target="#blueprints"
          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Blueprints",
      path: `/${org}/blueprints`,
      id: "blueprints",
    },
  ];
  useEffect(() => {
    if (userPerm) {
      let arr = Array.from(sideItems1);
      userPerm.map((item) => {
        if (item.privileges[0].read) {
          if (item.name === "Role-11") {
            arr.push("Tickets");
          } else if (item.name === "Role-13") {
            arr.push("Team Activity");
          } else if (item.name === "Role-12") {
            arr.push("Analytics");
          } else if (item.name === "Role-2") {
            arr.push("Issues");
          } else if (item.name === "Role-6") {
            arr.push("Components");
            arr.push("Product Trails");
          } else if (item.name === "Role-1") {
            arr.push("Inbox");
          } else if (item.name === "Role-3") {
            arr.push("Queues");
          } else if (item.name === "Role-4") {
            setUserPermSprint(item);
            arr.push("Sprint Board");
          } else if (item.name === "Role-5") {
            arr.push("Roadmap");
          } else if (item.name === "Role-15") {
            if (item.privileges[0].create === true) {
              arr.push("Invitaion");
            }
          }
        }
      });
      orgData.attributes?.configuration?.map((it) => {
        if (it.hidden === false) {
          arr.push(it.title);
        }
      });
      setSideItems1(new Set(arr));
    }
  }, [userPerm]);
  if (loader.fir || loader.sec || loader.thi) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <HelixLoader />
      </div>
    );
  }
  return (
    <div className="flex w-full h-screen">
      <CollapseButton />
      <Modals
        setIsPop={setIsOpenPop}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      />
      <InviteModal
        orgid={orgData}
        isOpen={isInviteOpen}
        onClose={() => setInviteOpen(false)}
      />
      <SearchModal isOpenn={isOpenn} setIsOpenn={setIsOpenn} />
      <div
        className={`scroll-container ${
          collapse ? "w-20" : "w-64"
        } h-screen left-0 bg-[#F9F9FA] dark:bg-black dark:text-white border-r-1 border-divider overflow-y-auto`}
      >
        <div className="p-4">
          <div className="flex items-center mx-2 my-4 justify-between">
            <div
              className={`flex ${
                collapse ? "flex-col " : " items-center "
              }  gap-4 cursor-pointer text-xs`}
            >
              <div className="flex items-center gap-2">
                <Popover
                  isOpen={isOpenPop}
                  onOpenChange={(open) => setIsOpenPop(open)}
                >
                  <PopoverTrigger>
                    <Avatar
                      radius="full"
                      className="rounded-md"
                      size="sm"
                      color={Colors[colorIndex]}
                      src={UserProfile}
                      name={
                        userDetails?.FirstName
                          ? userDetails.FirstName.slice(0, 1).toUpperCase()
                          : userDetails?.email?.slice(0, 1).toUpperCase() || ""
                      }
                    />
                  </PopoverTrigger>

                  <PopoverContent
                    isReadOnly
                    key="profile"
                    className="left-0 flex flex-col justify-start opacity-100 text-xs"
                    startContent={
                      <Avatar
                        radius="full"
                        className="rounded-md"
                        size="sm"
                        color={Colors[colorIndex]}
                        src={UserProfile}
                        name={
                          userDetails?.FirstName
                            ? userDetails.FirstName.slice(0, 1).toUpperCase()
                            : userDetails?.email?.slice(0, 1).toUpperCase() ||
                              ""
                        }
                      />
                    }
                  >
                    <div className="flex flex-col p-2 w-full justify-start left-0 items-start text-xs">
                      <div className=" flex gap-6 mb-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md w-full text-center">
                        <Avatar
                          radius="full"
                          className="rounded-md"
                          size="sm"
                          color={Colors[colorIndex]}
                          src={UserProfile}
                          name={
                            userDetails?.FirstName
                              ? userDetails.FirstName.slice(0, 1).toUpperCase()
                              : userDetails?.email?.slice(0, 1).toUpperCase() ||
                                ""
                          }
                        />

                        <div>
                          <p className="text-center mt-2 text-xs">
                            {userDetails?.email}
                          </p>
                          <p></p>
                        </div>
                      </div>
                      <Divider />
                      <div
                        onClick={() => handleNotification()}
                        className=" flex px-1 py-2 mt-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row"
                      >
                        {userDetails?.Notification ? (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="h-[16px] w-[16px]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.143 17.082a24.248 24.248 0 0 0 3.844.148m-3.844-.148a23.856 23.856 0 0 1-5.455-1.31 8.964 8.964 0 0 0 2.3-5.542m3.155 6.852a3 3 0 0 0 5.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 0 0 3.536-1.003A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53"
                              />
                            </svg>

                            <p className=" text-xs">Pause Notifications</p>
                          </>
                        ) : (
                          <>
                            <IoIosNotificationsOutline
                              style={{ height: "16px", width: "16px" }}
                            />
                            <p className=" text-xs">Resume Notifications</p>
                          </>
                        )}
                      </div>
                      <div className=" flex px-1 py-2 mb-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row">
                        <CiUser style={{ height: "16px", width: "16px" }} />
                        <p className=" text-xs"> Set youself as away</p>
                      </div>
                      <Divider />
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center justify-between w-full mt-2">
                          <div>Organizations</div>
                          <Button
                            onPress={onOpen}
                            className="flex justify-center items-center"
                            size="sm"
                            isIconOnly
                            variant="light"
                          >
                            +
                          </Button>
                        </div>
                      </div>

                      <div className=" flex flex-row cursor-pointer  hover:rounded-md  w-full">
                        <Org />
                      </div>
                      <Divider />
                    </div>
                    <div>
                      <ThemeSwitch />
                    </div>
                    <Divider />
                    <div className="w-full my-2">
                      {Array.from(sideItems1).includes("Invitaion") && (
                        <div
                          onClick={() => setInviteOpen(true)}
                          className="flex px-1 py-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row"
                        >
                          <InviteIcon
                            style={{ height: "16px", width: "16px" }}
                          />
                          <p className="text-xs">Invite members</p>
                        </div>
                      )}
                      <div
                        className=" flex gap-4 w-full  px-1 py-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md"
                        onClick={handleNavigate}
                      >
                        <CiSettings
                          style={{ height: "16px", width: "16px" }}
                          onClick={handleNavigate}
                        />
                        <p className=" text-xs">Settings</p>
                      </div>
                    </div>
                    <Divider />
                    <div
                      onClick={handleLogout}
                      className="mt-2 mb-2 flex px-1 py-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row"
                    >
                      <IoLogOutOutline
                        style={{ height: "16px", width: "16px" }}
                      />
                      <p className="text-xs"> Log Out</p>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
              <div className={`${collapse ? "pl-2" : ""}`}>
                <Tooltip content="Settings" placement="right">
                  <lord-icon
                    src="https://cdn.lordicon.com/lecprnjb.json"
                    trigger="hover"
                    colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                    style={{ width: "18px", height: "18px" }}
                    onClick={handleNavigate}
                  ></lord-icon>
                </Tooltip>
              </div>
            </div>
            {!collapse && (
              <div>
                <Button
                  onClick={toggleDrawer}
                  // onClick={() => dispatch(setCollapse(!collapse))}
                  size="sm"
                  variant="light"
                  className={`border border-divider ${theme === "dark" ? "bg-[#2D2D32]" : "bg-white"} `}
                  isIconOnly
                >
                  <div className="text-md">+</div>
                </Button>
              </div>
            )}
          </div>
          <div className="w-full flex items-center my-4">
            {collapse ? (
              <Tooltip content="Search" placement="right">
                <Button
                  size="sm"
                  isIconOnly
                  variant="light"
                  onClick={handleSearchButtonClick}
                  className="border ml-2 border-divider dark:bg-[#2D2D32]"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/kkvxgpti.json"
                    trigger="hover"
                    colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                    style={{ height: "16px", width: "16px" }}
                  ></lord-icon>
                </Button>
              </Tooltip>
            ) : (
              <Button
                size="md"
                onClick={handleSearchButtonClick}
                className="border border-divider w-full dark:bg-[#2D2D32]"
                variant="light"
                radius="md"
                ref={searchButtonRef}
              >
                <div className="flex items-center justify-between w-full text-black dark:text-white light:bg-white ">
                  <div className="flex gap-2 items-center justify-start">
                    <lord-icon
                      src="https://cdn.lordicon.com/kkvxgpti.json"
                      trigger="hover"
                      colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                      style={{ height: "16px", width: "16px" }}
                    ></lord-icon>
                    Search
                  </div>
                  <div>
                    {window.navigator.userAgent.toLowerCase().indexOf("mac") !==
                    -1 ? (
                      <Kbd keys={["command"]} size="sm">
                        K
                      </Kbd>
                    ) : (
                      <div className="text-xs text-gray-400">Ctrl + K</div>
                    )}
                  </div>
                </div>
              </Button>
            )}
          </div>

          <ul className={`${collapse ? "pl-2" : ""}`}>
            {NavBase.map((item, index) => (
              <li key={index}>
                <NavLink className="rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 ${
                      collapse ? "w-8 h-8 flex items-center justify-center" : ""
                    }
          items-center gap-2 rounded-md px-2 text-xs ${
            theme === "light" ? "text-black" : "text-white"
          } hover:text-foreground ${
                      location.pathname.includes(item.path)
                        ? theme === "light"
                          ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                          : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                        : theme === "light"
                        ? "hover:bg-[#F3F4F6]"
                        : "hover:bg-[#3A3A40]"
                    }`}
                  >
                    <div className="flex justify-center">
                      {collapse ? (
                        <Tooltip placement="right" content={item.title}>
                          {item.icon}
                        </Tooltip>
                      ) : (
                        item.icon
                      )}
                    </div>
                    {collapse ? "" : item.title}
                  </span>
                </NavLink>
              </li>
            ))}

            <Divider className="my-4" />
            <li>
              <NavLink className="rounded-md" to={`/${org}`} id="inbox">
                <span
                  className={`flex py-2 ${
                    collapse ? "w-8 h-8 flex items-center justify-center" : ""
                  }
          items-center gap-2 rounded-md px-2 text-xs ${
            theme === "light" ? "text-black" : "text-white"
          } hover:text-foreground ${
                    location.pathname === `/${org}`
                      ? theme === "light"
                        ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                        : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                      : theme === "light"
                      ? "hover:bg-[#F3F4F6]"
                      : "hover:bg-[#3A3A40]"
                  }`}
                >
                  <div className="flex justify-center">
                    {collapse ? (
                      <Tooltip placement="right" content="Inbox">
                        <lord-icon
                          src="https://cdn.lordicon.com/xtnsvhie.json"
                          trigger="hover"
                          state="morph-book"
                          target="#inbox"
                          colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                          style={{ width: "16px", height: "16px" }}
                        ></lord-icon>
                      </Tooltip>
                    ) : (
                      <lord-icon
                        src="https://cdn.lordicon.com/xtnsvhie.json"
                        trigger="hover"
                        state="morph-book"
                        target="#inbox"
                        colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                        style={{ width: "16px", height: "16px" }}
                      ></lord-icon>
                    )}
                  </div>
                  {collapse ? "" : "Inbox"}
                </span>
              </NavLink>
            </li>
            {Nav1.filter((item) =>
              Array.from(sideItems1).includes(item.title)
            ).map((item, index) => (
              <li key={item?.ids}>
                <NavLink className="rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 ${
                      collapse ? "w-8 h-8 flex items-center justify-center" : ""
                    }
          items-center gap-2 rounded-md px-2 text-xs ${
            theme === "light" ? "text-black" : "text-white"
          } hover:text-foreground ${
                      location.pathname.includes(item.path)
                        ? theme === "light"
                          ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                          : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                        : theme === "light"
                        ? "hover:bg-[#F3F4F6]"
                        : "hover:bg-[#3A3A40]"
                    }`}
                  >
                    <div className="flex justify-center">
                      {collapse ? (
                        <Tooltip placement="right" content={item.title}>
                          {item.icon}
                        </Tooltip>
                      ) : (
                        item.icon
                      )}
                    </div>
                    {collapse ? "" : item.title}
                  </span>
                </NavLink>
              </li>
            ))}

            <Divider className="my-4" />
            {Nav2.filter((item) =>
              Array.from(sideItems1).includes(item.title)
            ).map((item, index) => (
              <li key={item?.ids}>
                <NavLink className="rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 ${
                      collapse ? "w-8 h-8 flex items-center justify-center" : ""
                    }
          items-center gap-2 rounded-md px-2 text-xs ${
            theme === "light" ? "text-black" : "text-white"
          } hover:text-foreground ${
                      location.pathname.includes(item.path)
                        ? theme === "light"
                          ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                          : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                        : theme === "light"
                        ? "hover:bg-[#F3F4F6]"
                        : "hover:bg-[#3A3A40]"
                    }`}
                  >
                    <div className="flex justify-center">
                      {collapse ? (
                        <Tooltip placement="right" content={item.title}>
                          {item.icon}
                        </Tooltip>
                      ) : (
                        item.icon
                      )}
                    </div>
                    {collapse ? "" : item.title}
                  </span>
                </NavLink>
              </li>
            ))}
            <Divider className="my-4" />
            {Nav3.filter((item) =>
              Array.from(sideItems1).includes(item.title)
            ).map((item) => (
              <li key={item?.ids}>
                <NavLink className="rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 ${
                      collapse ? "w-8 h-8 flex items-center justify-center" : ""
                    }
          items-center gap-2 rounded-md px-2 text-xs ${
            theme === "light" ? "text-black" : "text-white"
          } hover:text-foreground ${
                      location.pathname.includes(item.path)
                        ? theme === "light"
                          ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                          : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                        : theme === "light"
                        ? "hover:bg-[#F3F4F6]"
                        : "hover:bg-[#3A3A40]"
                    }`}
                  >
                    <div className="flex justify-center">
                      {collapse ? (
                        <Tooltip placement="right" content={item.title}>
                          {item.icon}
                        </Tooltip>
                      ) : (
                        item.icon
                      )}
                    </div>
                    {collapse ? "" : item.title}
                  </span>
                </NavLink>
              </li>
            ))}
            {Array.from(sideItems1).includes("Sprint Board") && (
              <>
                <Divider className="my-4" />
                <div className="">
                  <div className="text-xs">
                    <Sprint
                      Update={userPermSprint.privileges[0].update}
                      Delete={userPermSprint.privileges[0].delete}
                      org={org}
                      orgId={orgData?.id}
                    />
                  </div>
                </div>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className={`flex flex-col gap-2 w-full`}>
        {UserLogged && <Outlet />}
      </div>
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            customAttributeRef={customAttributeRef}
            userNames={userNames}
            inputRef={customAttributeRef}
            divRef={customAttributeRef}
            query={location.pathname.includes("issue") ? "issue" : "ticket"}
            users={users}
            res={resData}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={toggleDrawer}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
