import React, { useEffect, useState } from "react";
import { Button, Divider, Image, Input, Link } from "@nextui-org/react";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon.jsx";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon.jsx";
import Google from "../../Images/Google.jsx";
import { getAllData, getAllRoles } from "../../controllers/strapiController.js";
import { NavigateSign } from "../../controllers/loginController.js";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { GoogleAuth } from "../../controllers/social.js";
import CryptoJS from "crypto-js";
import HelixLoader from "../Loader/HelixLoader.jsx";

const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handleGoogleAuth = async (e) => {
    e.stopPropagation();
    const data = await GoogleAuth();
    window.location.href = data.urlWithQueryParams;
  };

  const handlePasswordlessAuth = async (e) => {
    e.stopPropagation();
    navigate("/passwordless-sign");
  };

  const [isVisible, setIsVisible] = React.useState(false);
  const Navigate = useNavigate();
  const [ValidationConditions, setValidationConditions] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [initial, setInitial] = useState(true);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [LoginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [LoginError, setLoginError] = useState({
    emailError: "",
    passwordError: "",
    FinalError: "",
  });

  const navigate_forgot = () => {
    Navigate("/auth/reset-password");
  };

  const Validation = () => {
    let IsValid = true;

    if (LoginDetails.email === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Email is required.",
      }));
    } else if (!EmailRegex.test(LoginDetails.email)) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Enter valid email format.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, emailError: "" }));
    }

    const passwordValidations = [
      /[a-z]/.test(LoginDetails.password),
      /[A-Z]/.test(LoginDetails.password),
      /[0-9]/.test(LoginDetails.password),
      LoginDetails.password.length >= 8,
    ];

    if (LoginDetails.password === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Password is required.",
      }));
    } else if (!passwordValidations.every(Boolean)) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Enter valid password format.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, passwordError: "" }));
    }

    return IsValid;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SubmitHandler();
    }
  };
  const SubmitHandler = async () => {
    setInitial(false);
    setLoader(true);
    const Validate = Validation();
    if (Validate) {
      const navigate_route = await NavigateSign(
        LoginDetails.email,
        LoginDetails.password
      );
      if (navigate_route === "Incorrect") {
        setLoginError((previous) => ({
          ...previous,
          passwordError: "Password didn't match.",
        }));
        setLoginError((previous) => ({ ...previous, FinalError: null }));
        setValidationConditions(true);
        setLoader(false);
        return;
      }
      setValidationConditions(false);
      if (navigate_route && navigate_route) {
        if (navigate_route === "/onboarding") {
          setLoader(false);
          Navigate(navigate_route);
        } else {
          if (!location.search.includes("redirected-to")) {
            setLoader(false);
            Navigate(navigate_route);
          } else if (location.search.includes("redirected-to")) {
            setLoader(false);
            const path = searchParams.get("redirected-to");
            searchParams.delete("redirected-to");
            const newSearch = searchParams.toString();
            Navigate(`${path}${newSearch ? `?${newSearch}` : ""}`);
          }
        }
      } else {
        setLoader(false);
      }
    } else {
      setValidationConditions(true);
      setLoader(false);
    }
  };

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      {Loader && (
        <div className="fixed z-50 flex items-center justify-center inset-0 h-screen w-screen bg-white">
          <HelixLoader />
        </div>
      )}

      <div className={`flex h-screen items-center justify-center bg-slate-50`}>
        <main className="mx-auto min-h-[590px] w-full max-w-[450px]">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="https://avatars.githubusercontent.com/u/170258109?s=200&v=4"
          />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Sign in to Resolv
          </h1>
          <p className="pb-8 sm:text-left text-base text-slate-11 font-normal"></p>
          <form data-gtm-form-interact-id="0" onKeyDown={handleKeyPress}>
            {LoginError.FinalError ? (
              <p className="sm:text-left text-base text-slate-11 font-normal">
                {LoginError.FinalError}
              </p>
            ) : (
              ""
            )}
            <div className="mb-6 space-y-2">
              <Input
                radius="sm"
                className="border-slate-6 mb-10 "
                isInvalid={
                  ValidationConditions &&
                  (LoginDetails.email === "" ||
                    !EmailRegex.test(LoginDetails.email)) &&
                  LoginDetails.email !== null
                    ? ValidationConditions &&
                      (LoginDetails.email === "" ||
                        !EmailRegex.test(LoginDetails.email))
                    : ""
                }
                errorMessage={
                  ValidationConditions &&
                  (LoginDetails.email === "" ||
                    !EmailRegex.test(LoginDetails.email)) &&
                  LoginDetails.email !== null
                    ? LoginError.emailError
                    : ""
                }
                key={"outside"}
                type="email"
                label="Email"
                variant={"faded"}
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, email: e.target.value })
                }
                labelPlacement={"outside"}
                placeholder="Enter your email"
              />
            </div>
            <div className=" mb-6 ">
              <Input
                radius="sm"
                className="mt-6"
                variant={"faded"}
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, password: e.target.value })
                }
                isInvalid={
                  !initial &&
                  (ValidationConditions ||
                    LoginDetails.password === "" ||
                    !/[a-z]/.test(LoginDetails.password) ||
                    !/[A-Z]/.test(LoginDetails.password) ||
                    !/[0-9]/.test(LoginDetails.password) ||
                    LoginDetails.password.length < 8)
                }
                errorMessage={
                  ValidationConditions ||
                  LoginDetails.password === "" ||
                  !/[a-z]/.test(LoginDetails.password) ||
                  !/[A-Z]/.test(LoginDetails.password) ||
                  !/[0-9]/.test(LoginDetails.password) ||
                  LoginDetails.password.length < 8
                    ? LoginError?.passwordError
                    : ""
                }
                label={
                  <div
                    className="flex justify-between  "
                    style={{ width: "442px" }}
                  >
                    <span>Password</span>
                    <span className="cursor-pointer" onClick={navigate_forgot}>
                      Forgot your password?
                    </span>
                  </div>
                }
                labelPlacement={"outside"}
                placeholder="Enter your password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
              />
              {ValidationConditions &&
              (LoginDetails.password === "" ||
                !/[a-z]/.test(LoginDetails.password) ||
                !/[A-Z]/.test(LoginDetails.password) ||
                !/[0-9]/.test(LoginDetails.password) ||
                LoginDetails.password.length < 8) ? (
                <ul className="text-red-500 text-xs mb-8">
                  {!/[a-z]/.test(LoginDetails.password) && (
                    <li>Must include at least one lowercase letter.</li>
                  )}
                  {!/[A-Z]/.test(LoginDetails.password) && (
                    <li>Must include at least one uppercase letter.</li>
                  )}
                  {!/[0-9]/.test(LoginDetails.password) && (
                    <li>Must include at least one number.</li>
                  )}
                  {LoginDetails.password.length < 8 && (
                    <li>Must be at least 8 characters long.</li>
                  )}
                </ul>
              ) : null}
            </div>
            <Button
              id="CreateAccount"
              radius="sm"
              onClick={SubmitHandler}
              className={`w-full gap-2 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
            >
              Sign In
              <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#CreateAccount"
                colors="primary:#ffffff"
                style={{
                  width: "18px",
                  height: "20px",
                }}
              />
            </Button>
            <div className="text-small text-default-400 flex items-center justify-end mt-4">
              <Link
                onClick={() => navigate("/sign-up")}
                className="cursor-pointer"
                size="sm"
              >
                Dont have an account? Create a new one
              </Link>
            </div>
            <div className="mb-6 mt-6 flex items-center justify-center">
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
              <span className="text-small text-default-400 px-4">OR</span>
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
            </div>
          </form>

          <div className="flex w-full flex-row flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Button
              radius="sm"
              variant={"faded"}
              className="flex gap-2"
              style={{ width: "48%" }}
              onClick={handlePasswordlessAuth}
            >
              <div className="flex items-center gap-2">
                <i className="fas fa-key"></i>
                Sign in Passwordless
              </div>
            </Button>
            <Button
              radius="sm"
              variant={"faded"}
              className="flex w-1/2 gap-2 bg"
              style={{ width: "48%" }}
              onClick={handleGoogleAuth}
            >
              <Google />
              Sign in with Google
            </Button>
          </div>
          <p className="text-small text-default-400 mt-8">
            By signing in, you agree to our{" "}
            <Link href="#" size="sm">
              Terms of Service{" "}
            </Link>{" "}
            and{" "}
            <Link href="#" size="sm">
              Privacy Policy{" "}
            </Link>
            .
          </p>
        </main>
      </div>
    </>
  );
};

export default Signin;
