import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrgData, selectTags,setTags } from "../redux/actions";
import { getFilteredBySingleRelation } from "../controllers/strapiController";

const useTags = () => {
  const tagData = useSelector(selectTags);
  const dispatch = useDispatch();
  const orgData = useSelector(selectOrgData);
  useEffect(() => {
    const getTags = async () => {
      try {
        const tags = await getFilteredBySingleRelation(
            "tags",
            "organization",
            orgData.id
          );
        dispatch(setTags(tags.data));
      } catch (error) {
        console.log("error", error);
      }
    };
    if (tagData === null && orgData.id) {
      getTags();
    }
  }, [tagData, orgData]);

  const memoizedTags = useMemo(() => {
    if (Array.isArray(tagData)) {
      return tagData;
    } else {
      return [];
    }
  }, [tagData]);
  
  return memoizedTags;
};

export default useTags;
