import React, { useEffect, useState } from "react";
import {
  MARKDOWN_TEXT,
  renderCustomAttributes,
} from "../../utils/customAttributes";
import { useSelector } from "react-redux";
import {
  selectResData,
  selectUserData,
  selectUserNames,
} from "../../redux/actions";
import { EditorProvider } from "@tiptap/react";
import { extensions, MenuBar, props } from "../markdown/markdown";
import { basicIcons } from "../../utils/default";

const MarkdownRender = ({ metadata, setMetadata, boiler}) => {
  const [description, setDescription] = useState(
    metadata.find((item) => String(item.id) === String(boiler.id)).val
  );
  const [start, setStart] = useState(false);
  const [imageIds, setImageIds] = useState([]);
  const icon = basicIcons.find((it) => it.name === boiler.icon);
  const userNames = useSelector(selectUserNames);
  useEffect(() => {
    setMetadata((prevMetaData) =>
      prevMetaData.map((item) =>
        String(item.id) === String(boiler.id)
          ? { ...item, val: description }
          : item
      )
    );
  }, [description]);
  return (
    <div className="flex flex-col gap-2">
      <p className="w-full gap-2 text-xs text-center flex items-center">
        {icon && (
          <svg
            xmlns={icon.xmlns}
            fill={icon.fill}
            viewBox={icon.viewBox}
            strokeWidth={icon.strokeWidth}
            stroke={icon.stroke}
            className="size-6"
          >
            <path
              strokeLinecap={icon.path.strokeLinecap}
              strokeLinejoin={icon.path.strokeLinejoin}
              d={icon.path.d}
            ></path>
          </svg>
        )}
        <span className="text-sm">
          {boiler?.name}{" "}
          {boiler.required && <span className="text-red-400">*</span>}{" "}
        </span>
      </p>
      <div className=" h-auto border-[2px] rounded-lg">
        <EditorProvider
          placeholder="Enter your description"
          editorProps={props()}
          slotBefore={
            <MenuBar
              imageArray={imageIds}
              setImageArray={setImageIds}
              descriptionContent={description}
              setDescriptionContent={setDescription}
              initial={start}
              setInitial={setStart}
            />
          }
          extensions={extensions(userNames)}
          content={description}
        ></EditorProvider>
      </div>
    </div>
  );
};

const AttributesRenderer = ({ metadata, setMetadata, template , attributes,attributesRef }) => {
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const finalTemp = template.filter((a) => attributes.includes(a.name));
  return (
    <div className={`flex flex-col gap-4 ${finalTemp.length === 0 ? "" : "my-4"}`}>
      {finalTemp.map((item) => {
        if (item.type === MARKDOWN_TEXT) {
          return (
            <MarkdownRender
              boiler={item}
              metadata={metadata}
              setMetadata={setMetadata}
            />
          );
        } else {
          return renderCustomAttributes(
            item,
            metadata,
            setMetadata,
            users,
            res,attributesRef
          );
        }
      })}
    </div>
  );
};

export default AttributesRenderer;
