import React from 'react'
import { useSelector } from 'react-redux'
import { orgInfo, selectOrgData } from '../../redux/actions'
import { useDispatch } from 'react-redux';
import PageCard from './PageCard';
import { UpdateData } from '../../controllers/strapiController';

const ConfigPages = () => {
    const orgData = useSelector(selectOrgData);
    const dispatch = useDispatch();
    const handleUpdate = async(formData) => {
        try{
            const newConfig = orgData?.attributes?.configuration.map((it) => {
                return it.title === formData.title ? formData : it;
            });
            await UpdateData("organizations",orgData.id,{configuration : newConfig});
            const newOrgData = orgData;
            newOrgData.attributes.configuration = newConfig;
            dispatch(orgInfo(newOrgData));
        }
        catch(error){

        }
    }
    
  return (
    <div className='w-full px-8 flex flex-col py-4' >
        <div className='flex flex-col h-full gap-4 ' >
            {orgData?.attributes?.configuration?.map((it) => (
                <PageCard item={it} handleUpdate={handleUpdate} />
            ))}
        </div>
        <div className="min-h-8" />
    </div>
  )
}

export default ConfigPages
