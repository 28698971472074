import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  NextUIProvider,
  Avatar,
  Breadcrumbs,
  BreadcrumbItem,
} from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  getAllData,
  getFilteredData,
  getOneData,
} from "../../controllers/strapiController";
import { PermissionGroupSingleRole } from "../../utils/permission";
import HelixLoader from "../../components/Loader/HelixLoader";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { getDocument } from "../../controllers/vectorDB";

const General = () => {
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [organizationName, setOrganizationName] = useState("");
  const [region, setRegion] = useState("");
  const [username, setUserName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [orgId, setOrgId] = useState(null);
  const [groups, setGroups] = useState([]);
  const { org } = useParams();
  const navigate = useNavigate();
  const [userPerm, setUserPerm] = useState({
    name: "Role-9",
    class: "User Management",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState([]);
  const [deleteId, setDeleteId] = useState(new Set([]));
  const [deletePart, setDeletePart] = useState(null);
  const [partsData, setPartsData] = useState([]);
  const [partData, setPartData] = useState([]);
  const [partIds, setPartIds] = useState([]);
  const [tags, setTags] = useState();
  const [sprints, setSprints] = useState();
  const [groupId, setGroupId] = useState();
  const [partsComplete, setPartsComplete] = useState([]);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;

  useEffect(() => {
    async function fetchDocument() {
      try {
        const issData = await getDocument("type", "issue", org);
        const tickData = await getDocument("type", "ticket", org);
        if (issData && tickData) {
          setPartsData(partData);
          setPartsComplete(
            partData?.map((part, index) => ({
              key: index.toString(),
              id: part?.id,
              type: part?.attributes?.Type,
              name: part?.attributes?.Name,
              owner: part?.attributes?.users_permissions_user?.data?.id,
              stage: part?.attributes?.Stage,
              part: part?.attributes?.latest_part,
              totaltickets: tickData.filter(
                (tick) => String(tick?.payload?.part) === String(part?.id)
              ),
              description: part?.attributes?.Description,
              tags: part?.attributes?.Tags,
              totalissues: issData.filter(
                (tick) => String(tick?.payload?.part) === String(part?.id)
              ),
              delete: part,
              parent: part?.attributes?.ParentPath,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
    if (partData.length > 0) {
      fetchDocument();
    }
  }, []);

  useEffect(() => {
    if (organizations.length > 0) {
      const arr = organizations.filter(
        (tenant) => tenant.attributes.Name === org
      );
      if (arr.length === 1) {
        const check = arr[0]?.attributes?.users?.data?.filter(
          (user) => String(userDataId) === String(user.id)
        );
        if (check.length > 0) {
          let partArray = [];
          for (let i = 0; i < arr[0].attributes.parts.data.length; i++) {
            partArray.push(arr[0].attributes.parts.data[i].id);
          }
          setPartIds(partArray);
          setOrgId(arr[0].id);
        } else {
          navigate("*");
        }
      } else {
        navigate("*");
      }
    }
  }, []);

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = partIds.map((id) => getOneData("parts", id));
        const results = await Promise.all(promises);
        let partArray = [];
        for (let i = 0; i < results.length; i++) {
          partArray.push(results[i].data);
        }
        setPartData(partArray);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
      }
    };
    if (partIds.length > 0) {
      getAllDataByIds();
    }
  }, []);

  useEffect(() => {
    if (deletePart === null) {
      return;
    }
    const handleOpenDeleteTag = () => {
      let arrDel = [];
      arrDel.push(deletePart);
      partsData.forEach((item) => {
        const arr = item?.attributes?.ParentPath.split("/");
        if (arr.includes(deletePart)) {
          arrDel.push(String(item?.id));
        }
      });
      setDeleteId(new Set(arrDel));
    };
    handleOpenDeleteTag();
  }, []);

  const handleTicketDelete = async () => {
    try {
      const tickData = await getDocument("type", "ticket", org);
      const deleteTicketPromises = tickData.map((ticket) =>
        DeleteSingleAttribute("ticket", ticket.id)
      );
      await Promise.all(deleteTicketPromises);
    } catch (error) {
      console.error("Error deleting tickets:", error);
    }
  };

  const deleteGroups = async () => {
    try {
      const deleteGroupPromises = groups.map((group) =>
        DeleteSingleAttribute("groups", group.id)
      );
      await Promise.all(deleteGroupPromises);
    } catch (error) {
      console.error("Error deleting groups:", error);
    }
  };
  const deleteSprints = async () => {
    try {
      const deleteSprintPromises = sprints.map((sprint) =>
        DeleteSingleAttribute("sprints", sprint.id)
      );
      await Promise.all(deleteSprintPromises);
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const deleteTags = async () => {
    try {
      const deleteTagsPromises = tags.map((tags) =>
        DeleteSingleAttribute("tags", tags.id)
      );
      await Promise.all(deleteTagsPromises);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const deleteParts = async () => {
    try {
      const deleteGroupPromises = partData.map((part) =>
        DeleteSingleAttribute("parts", part.id)
      );
      await Promise.all(deleteGroupPromises);
    } catch (error) {
      console.error("Error deleting groups:", error);
    }
  };

  const deleteInvitations = async () => {
    try {
      const deleteInvitationPromises = invitations.map((invitation) =>
        DeleteSingleAttribute("invitations", invitation.id)
      );
      await Promise.all(deleteInvitationPromises);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    if (organizations.length > 0 && id) {
      const orgData = organizations.find(
        (tenant) => tenant.attributes.Name === org
      );
      if (orgData) {
        const isUserInOrg = orgData.attributes.users.data.some(
          (user) => String(userDataId) === String(user.id)
        );
        if (isUserInOrg) {
          const userArray = orgData.attributes.users.data.map(
            (user) => user.id
          );
          const groupData = orgData.attributes.groups.data.find(
            (group) => group.attributes.path === id
          );
          if (groupData) {
            setGroupId(groupData.id);
            setUserIds(userArray);
          } else {
            navigate("*");
          }
        } else {
          navigate("*");
        }
      } else {
        navigate("*");
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgResp = await getFilteredData("organizations", "Name", org);
        setOrgId(orgResp?.data[0]?.id);
        const orgsResp = await getAllData("organizations");
        setOrganizations(orgsResp.data);

        if (orgsResp.data.length === 0) {
          navigate("/404");
          return;
        }
        const orgDetails = orgsResp.data.find(
          (tenant) => tenant.attributes.Name === org
        );
        if (orgDetails) {
          const usersData = orgDetails.attributes.users?.data || [];
          const filteredUsers = usersData.filter(
            (user) => user.id === userDataId
          );
          if (filteredUsers.length > 0) {
            const userIdsArray = usersData.map((user) => user.id);
            setUserIds(userIdsArray);
            setOrganizationName(orgDetails.attributes.Name);
            setRegion(orgDetails.attributes.Region);
            setUserName(filteredUsers[0].attributes.username);
            setProfilePicture(
              filteredUsers[0].attributes.profilePicture?.data?.attributes
                .url || ""
            );
            setGroups(orgDetails.attributes.groups?.data || []);
            setInvitations(orgDetails.attributes.invitations?.data || []);
            setSprints(orgDetails.attributes.sprints?.data || []);
            setTags(orgDetails.attributes.tags?.data || []);
          }
        }

        if (userIds.length > 0) {
          const userPromises = userIds.map((id) => getOneData("users", id));
          const userResults = await Promise.all(userPromises);
          setUsers(userResults);
        }

        if (orgId) {
          const permResp = await PermissionGroupSingleRole(
            userDataId,
            orgId,
            "Role-9",
            "org"
          );
          if (permResp !== false) {
            setUserPerm(permResp);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/404");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <HelixLoader />
      </div>
    );
  }

  const orgInitial = organizationName.charAt(0).toUpperCase();

  const handleConfirmDelete = async () => {
    if (orgId) {
      try {
        await handleTicketDelete();
        await deleteParts();
        await deleteGroups();
        await deleteSprints();
        await deleteTags();
        await deleteInvitations();
        await DeleteSingleAttribute("organizations", orgId);
        setOrganizations((prevOrgs) =>
          prevOrgs.filter((org) => org.id !== orgId)
        );
        navigate("/");
      } catch (error) {
        console.error("Failed to delete organization:", error);
      }
    } else {
      console.error("Organization ID is not defined.");
    }
  };

  return (
    <div className="w-full p-8">
      <div className="flex">
        <Breadcrumb />
        <Breadcrumbs size="sm" isDisabled>
          <BreadcrumbItem>Settings</BreadcrumbItem>
          <BreadcrumbItem>General</BreadcrumbItem>
        </Breadcrumbs>
      </div>
      <NextUIProvider>
        <Card className="max-w-2xl m-auto flex flex-col gap-6 px-4 shadow-none border border-slate-300">
          <CardBody>
            <div className="flex flex-col w-full text-base pt-7 font-normal">
              <div className="flex items-center">
                <div className="flex">
                  {profilePicture ? (
                    <Avatar
                      src={
                        process.env.REACT_APP_STAGE === "Dev"
                          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${profilePicture}`
                          : profilePicture
                      }
                      alt={username}
                      className="h-8 w-8 mr-2"
                    />
                  ) : (
                    <div className="h-8 w-8 rounded-md flex items-center justify-center bg-secondary text-white text-xs font-semibold mr-2">
                      {orgInitial === "" ? "?" : orgInitial}
                    </div>
                  )}
                </div>

                <p className="text-lg flex font-medium">{organizationName}</p>
              </div>
              <div className="flex flex-col justify-center mt-6">
                <div className="flex gap-6">
                  <p className="text-xs text-slate-400">Organization Name:</p>
                  <p className="text-sm">{organizationName}</p>
                </div>
                <div className="flex gap-6">
                  <p className="text-xs text-slate-400 w-24">Region:</p>
                  <p className="text-sm ml-2">{region}</p>
                </div>
              </div>
              <div className="pt-6 flex justify-end">
                <Button
                  onClick={handleConfirmDelete}
                  disabled={!userPerm?.privileges?.[0]?.delete}
                  color={userPerm?.privileges?.[0]?.delete ? "danger" : "default"}
                  className="text-xs"
                >
                  Delete
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </NextUIProvider>
    </div>
  );
};

export default General;
