import { getFilteredByTwoRelation } from "../controllers/strapiController";

// Helper function to merge privileges
const mergePrivileges = (primaryPrivileges, secondaryPrivileges) => {
  secondaryPrivileges.forEach((secondaryRole) => {
    const primaryIndex = primaryPrivileges.findIndex(
      (role) => role.name === secondaryRole.name
    );
    if (primaryIndex !== -1) {
      primaryPrivileges[primaryIndex].privileges[0] = {
        read: primaryPrivileges[primaryIndex].privileges[0].read || secondaryRole.privileges[0].read,
        create: primaryPrivileges[primaryIndex].privileges[0].create || secondaryRole.privileges[0].create,
        update: primaryPrivileges[primaryIndex].privileges[0].update || secondaryRole.privileges[0].update,
        delete: primaryPrivileges[primaryIndex].privileges[0].delete || secondaryRole.privileges[0].delete,
      };
    }
  });
};

export const PermissionGroup = async (userId, orgId) => {
  try {
    const resp = await getFilteredByTwoRelation(
      "groups",
      "organization",
      orgId,
      "members",
      userId
    );
    if (resp.data.length === 0) {
      return false;
    }
    let isAdmin = resp.data[0].attributes.Type === "Admin" ? true : false;
    let org_priv = resp.data[0].attributes.org_privileges;
    let dev_priv = resp.data[0].attributes.dev_privileges;
    let prod_priv = resp.data[0].attributes.product_privileges;
    let sup_priv = resp.data[0].attributes.support_privileges;
    let ana_priv = resp.data[0].attributes.analytics_privileges;

    for (let i = 1; i < resp.data.length; i++) {
      isAdmin = isAdmin || (resp.data[i].attributes.Type === "Admin" ? true : false);
      mergePrivileges(org_priv, resp.data[i].attributes.org_privileges);
      mergePrivileges(dev_priv, resp.data[i].attributes.dev_privileges);
      mergePrivileges(prod_priv, resp.data[i].attributes.product_privileges);
      mergePrivileges(sup_priv, resp.data[i].attributes.support_privileges);
      mergePrivileges(ana_priv, resp.data[i].attributes.analytics_privileges);
    }

    return {roles : [...org_priv, ...dev_priv, ...prod_priv, ...sup_priv, ...ana_priv], admin : isAdmin};
  } catch (error) {
    console.error("Error fetching permissions:", error);
    return false;
  }
};

// Helper function to get privileges array based on category
const getPrivilegesArray = (data, category) => {
  switch (category) {
    case "org":
      return data.attributes.org_privileges;
    case "dev":
      return data.attributes.dev_privileges;
    case "pro":
      return data.attributes.product_privileges;
    case "sup":
      return data.attributes.support_privileges;
    case "ana":
      return data.attributes.analytics_privileges;
    default:
      return [];
  }
};

export const PermissionGroupSingleRole = async (userId, orgId, role, category) => {
  try {
    const resp = await getFilteredByTwoRelation(
      "groups",
      "organization",
      orgId,
      "members",
      userId
    );
    if (resp.data.length === 0) {
      return false;
    }

    let privilegesArray = getPrivilegesArray(resp.data[0], category);
    const index = privilegesArray.findIndex((item) => item.name === role);

    for (let i = 1; i < resp.data.length; i++) {
      let newPrivilegesArray = getPrivilegesArray(resp.data[i], category);
      const newIndex = newPrivilegesArray.findIndex((item) => item.name === role);
      if (newIndex !== -1) {
        privilegesArray[index].privileges[0] = {
          read: privilegesArray[index].privileges[0].read || newPrivilegesArray[newIndex].privileges[0].read,
          create: privilegesArray[index].privileges[0].create || newPrivilegesArray[newIndex].privileges[0].create,
          update: privilegesArray[index].privileges[0].update || newPrivilegesArray[newIndex].privileges[0].update,
          delete: privilegesArray[index].privileges[0].delete || newPrivilegesArray[newIndex].privileges[0].delete,
        };
      }
    }

    return privilegesArray[index];
  } catch (error) {
    console.error("Error fetching single role permissions:", error);
    return false;
  }
};
