import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteSingleAttribute } from "../../controllers/strapiController";
import { deleteData } from "../../controllers/vectorDB";
import toast, { Toaster } from "react-hot-toast";
import { inputClassNames } from "../../utils/default";

const TitleHeader = ({ ticket, handleUpdate, imageIds }) => {
  const [title, setTitle] = useState(ticket?.title);
  const [toggleTitle, setToggleTitle] = useState(false);
  const { ticketId, org } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      await deleteData(ticket?.id, org);
      const promises = imageIds.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      await Promise.all(promises);
      navigate(`/${org}/tickets`);
    } catch (error) {
      navigate(`/${org}/tickets`);
    }
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        window.location.origin + location.pathname
      );
      toast("Link copied", {
        duration: 2000,
        position: "bottom-right",
      });
    } catch (err) {
      toast("Cannot copy link!", {
        duration: 2000,
        position: "bottom-right",
      });
    }
  };
  useEffect(() => {
    setTitle(ticket?.title);
  }, [ticket]);

  return (
    <div className="w-full px-8 pt-4 flex items-center justify-between">
      <Toaster />
      <div className="flex items-center gap-4">
        <Chip size="md" color="danger" variant="flat">
          TKT-{ticketId}{" "}
        </Chip>
        {toggleTitle ? (
          <div className=" flex items-center gap-4">
            <Input
              className="w-96"
              classNames={inputClassNames}
              value={title}
              variant="bordered"
              onChange={(e) => setTitle(e.target.value)}
            />{" "}
            <Button
              onClick={async () => {
                await handleUpdate({ title: title });
                setToggleTitle(false);
              }}
              color="success"
              isIconOnly
              size="sm"
              variant="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </Button>
            <Button
              onClick={() => {
                setTitle(ticket?.title);
                setToggleTitle(false);
              }}
              color="danger"
              isIconOnly
              size="sm"
              variant="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </div>
        ) : (
          <div className=" flex items-center gap-4">
            <h1 className=" text-lg font-semibold">{ticket?.title}</h1>
            <Button
              size="sm"
              variant="light"
              isIconOnly
              onClick={() => setToggleTitle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            </Button>
          </div>
        )}
      </div>
      <div className=" flex items-center gap-2">
        <Button
          isIconOnly
          onClick={() => handleCopy()}
          endContent={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
          }
          size="sm"
          variant="light"
        ></Button>
        <Dropdown>
          <DropdownTrigger>
            <Button
              size="sm"
              variant="light"
              isIconOnly
              startContent={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              }
            ></Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem
              color="danger"
              onClick={() => handleDelete()}
              variant="flat"
              startContent={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              }
            >
              Delete Issue
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default TitleHeader;
