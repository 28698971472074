import { Avatar, Button, Divider, Spinner } from "@nextui-org/react";
import { EditorProvider } from "@tiptap/react";
import React, { useEffect, useRef, useState } from "react";
import {
  extensions,
  MenuBar,
  props,
} from "../../components/markdown/MessageEditor";
import Chat from "./Convo";
import { ConversationStages } from "../../utils/default";
import InboxTicket from "./InboxTicket";
import { getSingleDocument } from "../../controllers/vectorDB";
import Drawer from "../../components/Drawer/drawer";
import { useSelector } from "react-redux";
import {
  selectResData,
  selectUserData,
  selectUserNames,
} from "../../redux/actions";
import { generateMetadataStructure } from "../../utils/customAttributes";

function InboxDrawer({
  threadId,
  handleCloseDrawer,
  stageRef,
  socket,
  email,
  org,
  orgId,
  pageRef,
  tagRef,
  modalRef,
  sprintRef,
  customTags,
  emailRes,
  template,
  customAttributeRef,
}) {
  const users = useSelector(selectUserData);
  const id = `${org}_${email}`;
  const res = useSelector(selectResData);
  const [imageIds, setImageIds] = useState([]);
  const [start, setStart] = useState(true);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [clear, setClear] = useState(0);
  const submitRef = useRef();
  const [temp, setTemp] = useState([]);
  const [convo, setConvo] = useState([]);
  const [ticketAdd, setTicketAdd] = useState(true);
  const [thread, setThread] = useState(null);
  const [metaData, setMetadata] = useState([]);
  const [fetch, setFetch] = useState(true);
  const userNames = useSelector(selectUserNames);
  const [toggleTicket, setToggleTicket] = useState(false);
  const [ticketIds, setTicketIds] = useState([]);
  const handleSubmit = async () => {
    if (
      socket === null ||
      descriptionContent === "" ||
      descriptionContent === "<p></p>" ||
      ConversationStages.completed.includes(thread.payload.stage)
    ) {
      return;
    }

    setTemp((prev) => [...prev, { senderId: id, text: descriptionContent }]);
    socket.send(
      JSON.stringify({
        type: "text",
        threadId: threadId,
        payload: {
          text: descriptionContent,
        },
      })
    );
    setClear(clear + 1);
  };
  const Colors = ["secondary", "success", "warning", "danger", "primary"];

  const numColors = Colors.length;
  useEffect(() => {
    if (socket) {
      socket.onmessage = (data) => {
        const message = JSON.parse(data.data);

        if (
          message.type === "text" &&
          String(message.threadId) === String(threadId)
        ) {
          setConvo((prev) => [...prev, message.payload.message]);
          let arr = temp.filter(
            (item) => item.text !== message.payload.message.text
          );
          setTemp(arr);
        }
      };
    }
  }, [socket]);
  useEffect(() => {
    const fetchThread = async () => {
      try {
        setTicketIds([]);
        const resp = await getSingleDocument(threadId, org);
        setThread(resp[0]);
        setConvo(resp[0].payload.messages);
        setTicketIds(resp[0].payload.tickets);
        setMetadata(
          generateMetadataStructure(template, resp[0]?.payload?.metadata)
        );
      } catch (error) {
        console.log("Error:", error);
      }
    };
    if (threadId) {
      fetchThread();
    }
  }, [threadId, fetch, ticketAdd, template]);
  if (!thread) {
    return (
      <div
        className={`w-[60vw] overflow-y-auto absolute top-0 right-0 flex items-center justify-center h-screen bg-white dark:bg-[#1C1C21] z-20`}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div
      ref={pageRef}
      className={`w-auto overflow-y-auto absolute top-0 right-0 flex h-screen bg-white dark:bg-[#1C1C21] z-20`}
    >
      {toggleTicket && (
        <div className=" relative flex w-96">
          <Drawer
            customAttributeRef={customAttributeRef}
            toggleDrawer={() => setToggleTicket(false)}
            query="ticket"
            dropdownref={tagRef}
            modalref={modalRef}
            inputRef={sprintRef}
            users={users}
            res={res}
            tenant={{ name: org, id: orgId }}
            inboxThreadId={threadId}
            userNames={userNames}
            isInbox={ticketAdd}
            setIsInbox={setTicketAdd}
          />
        </div>
      )}
      <div className=" w-[60vw] flex h-full">
        <div className=" border-r border-l border-divider w-[35%]">
          <InboxTicket
            template={template}
            metadata={metaData}
            setMetadata={setMetadata}
            orgId={orgId}
            emailRes={emailRes}
            customTags={customTags}
            stageRef={stageRef}
            ticketIds={ticketIds}
            setTicketIds={setTicketIds}
            ticketAdd={ticketAdd}
            setTicketAdd={setTicketAdd}
            setToggleTicket={setToggleTicket}
            refetch={fetch}
            setRefetch={setFetch}
            thread={thread}
            setThread={setThread}
            org={org}
          />
        </div>
        <div className=" w-[65%] flex flex-col gap-4 h-full py-4 overflow-y-auto">
          <div className="flex items-center px-2 justify-between">
            <div className="pl-2 flex items-center">
              <div className=" flex items-center gap-2">
                <Avatar
                  size="md"
                  radius="lg"
                  src=""
                  color={
                    Colors[
                      Number(thread?.payload?.customerMail.length) % numColors
                    ]
                  }
                  showFallback
                  name={thread?.payload?.customerMail?.toUpperCase()?.charAt(0)}
                />
                <p className="text-xs">{thread?.payload?.customerMail}</p>
              </div>
            </div>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => handleCloseDrawer()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </div>
          <Divider className="border border-divider" />
          <div className="flex-1 w-full overflow-y-auto">
            <div className=" w-full py-2 h-full flex flex-col gap-2 overflow-y-auto">
              <Chat
                customerId={thread.payload.customerId}
                emailRes={emailRes}
                temp={temp}
                convo={convo}
                userId={id}
                tickets={ticketIds}
              />
            </div>
          </div>

          {ConversationStages.completed.includes(
                  thread.payload.stage
                ) ? <div className="mx-4 flex items-center justify-center py-4 font-light">Conversation has been closed.</div> : <div className=" h-[110px] overflow-y-auto flex flex-col mx-4 border border-divider rounded-lg">
            <EditorProvider
              editorProps={props("m")}
              slotBefore={
                <MenuBar
                  descriptionContent={descriptionContent}
                  setDescriptionContent={setDescriptionContent}
                  imageArray={imageIds}
                  setImageArray={setImageIds}
                  initial={start}
                  setInitial={setStart}
                  clear={clear}
                />
              }
              extensions={extensions(submitRef)}
              content={descriptionContent}
            ></EditorProvider>
            <div className=" pr-2 flex items-center justify-end pb-1">
              <Button
                disabled={ConversationStages.completed.includes(
                  thread.payload.stage
                )}
                onClick={handleSubmit}
                ref={submitRef}
                isIconOnly
                size="sm"
                className="text-white bg-black"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                  />
                </svg>
              </Button>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default InboxDrawer;
