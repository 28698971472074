export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ORG='SET_ORG';
export const SET_RES='SET_RES';
export const SET_USERNAMES='SET_USERNAMES';
export const SET_UPDATES='SET_UPDATES';
export const SET_TICKETS='SET_TICKETS';
export const SET_ISSUES='SET_ISSUES';
export const SET_PARTS='SET_PARTS';
export const SET_RELEASES='SET_RELEASES';
export const SET_BLUEPRINTS='SET_BLUEPRINTS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_TAGS = 'SET_TAGS';
export const SET_ADMIN = 'SET_ADMIN';
export const SET_COLLAPSE = 'SET_COLLAPSE';

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data,
});

export const setUpdates = (data) => ({
    type: SET_UPDATES,
    payload: data,
});
 
export const orgInfo=(data)=>({
    type: SET_ORG,
    payload: data,
})
 
export const setRes=(data)=>({
    type: SET_RES,
    payload: data,
})
 
export const setUsernames=(data)=>({
    type: SET_USERNAMES,
    payload: data,
})

export const setTickets=(data)=>({
    type: SET_TICKETS,
    payload: data,
})

export const setIssues=(data)=>({
    type: SET_ISSUES,
    payload: data,
})

export const setParts=(data)=>({
    type: SET_PARTS,
    payload: data,
})

export const setBlueprints=(data)=>({
    type: SET_BLUEPRINTS,
    payload: data,
})

export const setReleases=(data)=>({
    type: SET_RELEASES,
    payload: data,
})

export const setPermissions=(data)=>({
    type: SET_PERMISSIONS,
    payload: data,
})

export const setTags=(data)=>({
    type: SET_TAGS,
    payload: data,
})

export const setAdmin=(data)=>({
    type: SET_ADMIN,
    payload: data,
})

export const setCollapse=(data)=>({
    type: SET_COLLAPSE,
    payload: data,
})

// Select user data
export const selectUserData = (state) => state.data;

// Select organization data
export const selectOrgData = (state) => state.org;

// Select res data
export const selectResData = (state) => state.res;

// Select ActiveTab
export const selectUserNames = (state) => state.usernames;

// Select ActiveTab
export const selectUpdates = (state) => state.updates;

export const selectIssues = (state) => state.issues;

export const selectParts = (state) => state.parts;

export const selectBlueprints = (state) => state.blueprints;

export const selectReleases = (state) => state.releases;

export const selectTickets = (state) => state.tickets;

export const selectPermissions = (state) => state.permissions;

export const selectTags = (state) => state.tags;

export const selectAdmin = (state) => state.admin;

export const selectCollapse = (state) => state.collapse;