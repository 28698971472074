import React, { useEffect, useRef, useState } from "react";
import AccountsHeader from "./Header";
import AccountsDrawer from "./AccountsDrawer";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserNames } from "../../redux/actions";
import { useParams } from "react-router-dom";
import {
  getFilteredBySingleRelation,
  getOneData,
} from "../../controllers/strapiController";
import AccountTable from "./AccountTable";
import {
  DROPDOWN,
  generateFilter,
  LONG_TEXT,
  MARKDOWN_TEXT,
  PEOPLE,
} from "../../utils/customAttributes";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import FilterPeople from "../../components/Filters/FilterPeople";
import FilterDropdown from "../../components/Filters/Filter";

const Accounts = () => {
  const tagsref = useRef();
  const modalref = useRef();
  const drawerRef = useRef();
  const accountsRef = useRef();
  const { org } = useParams();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const orgData = useSelector(selectOrgData);
  const [customTags, setCustomTags] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const searchRef = useRef();
  const inputRef = useRef();
  const hidden = useSelector(selectOrgData)?.attributes?.configuration?.find((it) => it.title === "Accounts")?.hidden;
  const [grouping, setGrouping] = useState(new Set(["None"]));
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [accountsTemp, setAccountsTemp] = useState([]);
  const userNames = useSelector(selectUserNames);
  const customAttributeRef = useRef();
  const [filterDropdown, setFilterDropdowm] = useState(new Set([]));
  const [filterArr, setFilterArr] = useState([]);
  const handleClearFilters = () => {
    setSearch("");
    setFilterArr((prev) => prev.map((fil) => ({ ...fil, val: [] })));
  };
  const [customize, setCustomize] = useState(new Set([]));
  const coulmnDef = Array.from(customize).map((name) => {
    return {
      id: name,
      label: name,
    };
  });
  const columns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "website",
      label: "Website",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountsRef.current &&
        !accountsRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) && 
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        setIsOpenUpdate(false);
        setSelectedAccount(null);
      }
    };
    if (isOpenUpdate) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenUpdate]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && search === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, search]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        const template = await getOneData("organizations", orgData.id, [
          "account_metadata",
        ]);
        setAccountsTemp(template.data.attributes.account_metadata ?? []);
        setFilterArr(
          generateFilter(template.data.attributes.account_metadata ?? [])
        );
        setCustomTags(tags.data);
      } catch (error) {}
    };
    if (orgData?.id) {
      fetchData();
    }
  }, [orgData]);
  if(hidden === false){
    return (
      <div className="h-full overflow-y-auto flex flex-col w-full">
        {isOpen && (
          <div ref={drawerRef}>
            <AccountsDrawer
              customAttributeRef={customAttributeRef}
              accountTemplate={accountsTemp}
              toggleDrawer={() => setIsOpen(false)}
              dropdownref={tagsref}
              modalref={modalref}
              tenant={{ name: org, id: orgData?.id }}
              customTags={customTags}
              userNames={userNames}
              data={selectedAccount}
              type={"create"}
            />
          </div>
        )}
        {isOpenUpdate && (
          <div ref={accountsRef}>
            <AccountsDrawer
              customAttributeRef={customAttributeRef}
              accountTemplate={accountsTemp}
              toggleDrawer={() => setIsOpenUpdate(false)}
              dropdownref={tagsref}
              modalref={modalref}
              tenant={{ name: org, id: orgData?.id }}
              customTags={customTags}
              userNames={userNames}
              data={selectedAccount}
              type={"update"}
            />
          </div>
        )}
        <div className="h-[130px] ">
          <AccountsHeader setIsOpen={setIsOpen} />
          <div className="z-0 flex pb-4 px-8 border-b-1 mt-4 border-divider items-center justify-between">
            <div className="flex items-center gap-4">
              <div
                ref={searchRef}
                onClick={() => setIsSearchOpen(true)}
                className={`transition-all duration-300 dark:bg-[#121212] ease-in-out cursor-pointer border border-divider flex items-center ${
                  isSearchOpen
                    ? "pl-2 w-40 gap-2 justify-start"
                    : "w-8 justify-center"
                } rounded-full  h-8`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
                {isSearchOpen && (
                  <input
                    ref={inputRef}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ height: "20px", outline: "none" }}
                    className="w-full px-2 text-xs rounded-full"
                    placeholder="Search"
                  />
                )}
              </div>
              {accountsTemp
                .filter((a) => Array.from(filterDropdown).includes(String(a.id)))
                .map((item) => {
                  if (item.type === PEOPLE) {
                    return (
                      <FilterPeople
                        filterArr={filterArr}
                        setFilterArr={setFilterArr}
                        boilerPlate={item}
                      />
                    );
                  } else if (item.type === DROPDOWN) {
                    return (
                      <FilterDropdown
                        filterArr={filterArr}
                        setFilterArr={setFilterArr}
                        boilerPlate={item}
                      />
                    );
                  }
                })}
              {accountsTemp.filter(
                (a) => a.type === DROPDOWN || a.type === PEOPLE
              ).length > 0 && (
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      size="sm"
                      variant="light"
                      className=" border border-divider"
                      isIconOnly
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Multiple selection"
                    variant="flat"
                    closeOnSelect={false}
                    selectionMode="multiple"
                    disallowEmptySelection={false}
                    selectedKeys={filterDropdown}
                    onSelectionChange={setFilterDropdowm}
                  >
                    {accountsTemp
                      .filter((a) => a.type === DROPDOWN || a.type === PEOPLE)
                      .map((it) => (
                        <DropdownItem key={String(it.id)}>{it.name}</DropdownItem>
                      ))}
                  </DropdownMenu>
                </Dropdown>
              )}
              {accountsTemp.filter(
                (a) => a.type === DROPDOWN || a.type === PEOPLE
              ).length > 0 && (
                <Button
                  onClick={() => handleClearFilters()}
                  size="sm"
                  variant="light"
                >
                  Clear
                </Button>
              )}
            </div>
            <div className="flex items-center gap-2">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    variant="light"
                    size="sm"
                    className="capitalize"
                  >
                      Group
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Single selection example"
                  variant="flat"
                  disallowEmptySelection
                  closeOnSelect={false}
                  selectionMode="single"
                  selectedKeys={grouping}
                  onSelectionChange={setGrouping}
                >
                  <DropdownItem key="None" >None</DropdownItem>
                  {accountsTemp
                    .filter(
                      (item) =>
                        item.type === DROPDOWN || item.type === PEOPLE
                    )
                    .map((it) => (
                      <DropdownItem key={`metadata_${it.id}`}>
                        {it.name}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="light" size="sm" className="capitalize">
                    Customize
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Single selection example"
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  selectedKeys={customize}
                  onSelectionChange={setCustomize}
                >
                  {accountsTemp
                    .filter(
                      (item) =>
                        item.type !== MARKDOWN_TEXT && item.type !== LONG_TEXT
                    )
                    .map((it) => (
                      <DropdownItem key={`metadata_${it.id}`}>
                        {it.name}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
         <AccountTable
          grouping={grouping}
          template={accountsTemp}
          columns={columns}
          filterArr={filterArr}
          setFilterArr={setFilterArr}
          search={search}
          setSelectedAccount={setSelectedAccount}
          setIsOpenUpdate={setIsOpenUpdate}
        /> 
      </div>
    );
  }
  
};

export default Accounts;
