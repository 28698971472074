import React from 'react'
import CustomAttHeader from './Header'
import { Outlet } from 'react-router-dom'

const CustomAttributes = () => {
  return (
    <div className='h-full overflow-y-auto flex flex-col w-full'>
    <CustomAttHeader/>
    <Outlet/>
    </div>
  )
}

export default CustomAttributes
