import React, { useEffect, useState, useRef } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Avatar,
  Button,
  Divider,
  Input,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import Drawer from "../Drawer/drawer";
import {
  getFilteredBySingleRelation,
  getFilteredBySingleRelationSingleData,
  UpdateOneUserData,
} from "../../controllers/strapiController";
import { PermissionGroup } from "../../utils/permission";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiDark, CiLight, CiSettings, CiUser } from "react-icons/ci";
import Modals from "./Modal";
import Org from "./Org";
import { MdAutorenew } from "react-icons/md";
import InviteIcon from "../../Images/Icons/InviteIcon";
import { IoLogOutOutline } from "react-icons/io5";
import {
  orgInfo,
  selectAdmin,
  selectCollapse,
  selectOrgData,
  selectPermissions,
  selectResData,
  selectUserData,
  selectUserNames,
  setAdmin,
  setPermissions,
  setRes,
  setUserData,
  setUsernames,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Colors, numColors } from "../../utils/constant";
import SearchModal from "./SearchModal";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import { useTheme } from "next-themes";

const SideNav = () => {
  const { org } = useParams();
  const dispatch = useDispatch();
  const drawerRef = useRef();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const users = useSelector(selectUserData);
  const orgData = useSelector(selectOrgData);
  const res = useSelector(selectResData);
  const userNames = useSelector(selectUserNames);
  const location = useLocation();
  const modalRef = useRef();
  const customAttributeRef = useRef();
  const tagsref = useRef();
  const handleSearchButtonClick = () => {
    setIsOpen(true);
  };
  const [userDetails, setUserDetails] = useState(null);
  const [sideItems1, setSideItems1] = useState(new Set(["Teams"]));
  const [isInviteOpen, setInviteOpen] = useState(false);
  const { isOpenOrg, onOpenOrg, onOpenChangeOrg } = useDisclosure();
  const [isOpenPop, setIsOpenPop] = useState(false);
  const navigate = useNavigate();
  const admin = useSelector(selectAdmin);
  const handleClick = () => {
    navigate(`/${org}/settings/account`);
  };
  const {theme} = useTheme();
  const userPerm = useSelector(selectPermissions);
  const [loading, setLoading] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const [navItems, setNavItems] = useState(
    new Set(["Go Back", "Account", "Widget"])
  );
  const collapse = useSelector(selectCollapse);
  const UserId = UserLogged && UserLogged.user.id;
  const handleNotification = async () => {
    const formData = { Notification: !userDetails.Notification };
    try {
      await UpdateOneUserData("users", UserId, formData);
      setUserDetails((prev) => ({ ...prev, Notification: !prev.Notification }));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!UserLogged) {
      const pathname = location.pathname;
      let search = location.search;
      if (search !== "") {
        search = search.replace(/\?/g, "&");
      }
      window.location.href = `/sign-in?redirected-to=${pathname}${search}`;
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "organizations",
          "users",
          UserId,
          "Name",
          org
        );
        if (resp.data.length === 1) {
          dispatch(orgInfo(resp.data[0]));
        } else {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
    };
    if (org && UserId) {
      fetchOrg();
    }
  }, [org, UserId]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation(
          "users",
          "organizations",
          orgData.id,
          ["username", "email", "FirstName", "LastName", "Notification"],
          ["ProfilePicture", "organizations"]
        );
        dispatch(setUserData(results));
        setUserDetails(results.find((item) => item.id === UserId));
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgData?.id) {
      getAllDataByIds();
    }
  }, [orgData]);
  useEffect(() => {
    if (users.length > 0) {
      const convertedUsers = {};
      const userN = [];
      users.forEach((user) => {
        userN.push({
          id: user.id,
          name: user.FirstName
            ? `${user.FirstName} ${user.LastName ? user.LastName : ""}`
            : user.username,
          url: user.ProfilePicture?.formats?.small?.url || "",
        });
        convertedUsers[String(user.id)] = {
          UserName: user.username,
          FirstName: user.FirstName,
          LastName: user.LastName,
          ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
        };
      });
      dispatch(setUsernames(userN));
      dispatch(setRes(convertedUsers));
    }
  }, [users]);
  const searchButtonRef = useRef();
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "k") {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderNavItems = (navItems) =>
    navItems.map((item, index) => (
      <li key={item.ids}>
        <NavLink className="rounded-md" to={item.path} id={item.id}>
          <span
            className={`flex py-2 ${
              collapse
                ? "w-8 h-8 flex items-center justify-center "
                : ""
            } items-center gap-2 rounded-md px-2 text-xs hover:text-foreground ${
              theme === "light" ? "text-black" : "text-white"
            } hover:text-foreground ${
                        location.pathname.includes(item.path)
                          ? theme === "light"
                            ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                            : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                          : theme === "light"
                          ? "hover:bg-[#F3F4F6]"
                          : "hover:bg-[#3A3A40]"
                      }`}
          >
            {collapse ? (
                        <Tooltip placement="right" content={item.title}>
                          {item.icon}
                        </Tooltip>
                      ) : (
                        item.icon
                      )}
            {!collapse && item.title}
          </span>
        </NavLink>
      </li>
    ));

  const Nav1 = [
    {
      ids: 1,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/vduvxizq.json"
            trigger="hover"
            target="#Back"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "12px", height: "12px", transform: "scaleX(-1)" }}
            className="flex justify-center items-center text-center "
          ></lord-icon>
        </div>
      ),
      title: "Go Back",
      path: `/${org}`,
      id: "Back",
    },
    {
      ids: 2,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/yxczfiyc.json"
            trigger="hover"
            target="#general"
            state="morph-book"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "General",
      path: `/${org}/settings/general`,
      id: "general",
    },
  ];

  const Nav2 = [
    {
      ids: 1,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/jkzgajyr.json"
            trigger="hover"
            state="morph-book"
            target="#knowledge-base"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Knowledge Base",
      path: `/${org}/settings/knowledge-base`,
      id: "knowledge-base",
    },
    {
      ids: 2,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/fdxqrdfe.json"
            trigger="hover"
            state="morph-book"
            target="#Widget"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Widget",
      path: `/${org}/settings/widget`,
      id: "Widget",
    },
    {
      ids: 3,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/gfgzffjw.json"
            trigger="hover"
            state="morph-book"
            target="#tag"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Tags",
      path: `/${org}/settings/tags`,
      id: "tag",
    },
    {
      ids: 4,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/rguiapej.json"
            trigger="hover"
            state="morph-book"
            target="#custom-attributes"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Custom Attributes",
      path: `/${org}/settings/custom-attributes`,
      id: "custom-attributes",
    },
    {
      ids: 5,
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/xunzgeah.json"
            trigger="hover"
            state="morph-book"
            target="#configuration"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Configuration",
      path: `/${org}/settings/configuration`,
      id: "configuration",
    },
    // {
    //   icon: (
    //     <div
    //       className="flex items-center cursor-pointer"
    //       onClick={toggleAccordion}
    //       aria-label="Accordion"
    //     >
    //       <lord-icon
    //         src="https://cdn.lordicon.com/pflszboa.json"
    //         alt="Accordion Icon"
    //         trigger="hover"
    //         state="morph-book"
    //         target="#integrations"
    //         colors={`primary: "#000000"`}
    //         style={{ width: "16px", height: "16px" }}
    //       />
    //     </div>
    //   ),
    //   title: "Integrations",
    //   path: `/${org}/settings/integrations`,
    //   id: "integrations",
    //   content: isOpen && <div></div>,
    // },
  ];

  const userNav = [
    {
      ids: 1,
      icon: (
        <div className="flex justify-center items-center text-xs">
          <lord-icon
            src="https://cdn.lordicon.com/kddybgok.json"
            trigger="hover"
            state="morph-book"
            target="#groups"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            className=" text-xs"
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Groups",
      path: `/${org}/settings/groups`,
      id: "groups",
    },
    {
      ids: 2,
      icon: (
        <div className="flex justify-center items-center text-xs">
          <lord-icon
            src="https://cdn.lordicon.com/kthelypq.json"
            trigger="hover"
            state="morph-book"
            target="#users"
            className="text-xs"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Users",
      path: `/${org}/settings/users`,
      id: "users",
    },
    {
      ids: 3,
      icon: (
        <div className="flex justify-center items-center text-xs">
          <lord-icon
            src="https://cdn.lordicon.com/nzixoeyk.json"
            trigger="hover"
            state="morph-book"
            target="#invitation"
            className="text-xs"
            colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Invitations",
      path: `/${org}/settings/invitation`,
      id: "invitation",
    },
  ];
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const perm = await PermissionGroup(UserLogged.user.id, orgData.id);
        console.log("perm", perm);
        if (perm === false) {
          return;
        }
        dispatch(setPermissions(perm.roles));
        dispatch(setAdmin(perm.admin));
      } catch (error) {}
    };
    if (UserLogged && orgData) {
      fetchPerm();
    }
  }, [orgData]);
  useEffect(() => {
    if (userPerm) {
      let arr = Array.from(navItems);
      userPerm.map((item) => {
        if (item.privileges[0].read) {
          if (item.name === "Role-9") {
            arr.push("General");
          } else if (item.name === "Role-7") {
            arr.push("Users");
          } else if (item.name === "Role-14") {
            arr.push("Groups");
          } else if (item.name === "Role-15") {
            arr.push("Invitations");
          } else if (item.name === "Role-10") {
            arr.push("Integrations");
          } else if (item.name === "Role-8") {
            arr.push("Tags");
          }
        }
      });
      if (admin) {
        arr.push("Configuration");
      }
      orgData.attributes?.configuration?.map((it) => {
        if (it.hidden === false) {
          arr.push(it.title);
        }
      });
      setNavItems(new Set(arr));
    }
  }, [userPerm, orgData]);
  const handleNavigate = () => {
    navigate(`/${org}/settings`);
  };
  const handleLogout = () => {
    navigate(`/`);
  };
  const colorIndex = userDetails && userDetails.id % numColors;
  const UserProfile =
    userDetails && userDetails.ProfilePicture !== null
      ? process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userDetails.ProfilePicture.url}`
        : `${userDetails.ProfilePicture.url}`
      : "";
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!customAttributeRef.current ||
          !customAttributeRef.current.contains(event.target))
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  return (
    <div className="flex w-full h-screen">
      
      <SearchModal isOpenn={isOpen} setIsOpenn={setIsOpen} />
      <Modals
        isOpen={isOpenOrg}
        onOpenChange={onOpenChangeOrg}
        setIsPop={setIsOpenPop}
      />
      <div className={` ${collapse ? "w-20" : "w-64"} h-screen left-0 bg-[#F9F9FA] dark:bg-black dark:text-white border-r-1 border-divider`}>
        <div className="flex ml-6 mt-6 items-center justify-between  gap-4 cursor-pointer text-xs">
          <Popover
            isOpen={isOpenPop}
            onOpenChange={(open) => setIsOpenPop(open)}
          >
            <PopoverTrigger>
              <Avatar
                radius="full"
                className="rounded-md"
                size="sm"
                color={Colors[colorIndex]}
                src={UserProfile}
                name={
                  userDetails?.FirstName
                    ? userDetails.FirstName.slice(0, 1).toUpperCase()
                    : userDetails?.email?.slice(0, 1).toUpperCase() || ""
                }
              />
            </PopoverTrigger>

            <PopoverContent
              isReadOnly
              key="profile"
              className="left-0 flex flex-col justify-start opacity-100 text-xs"
              startContent={
                <Avatar
                  radius="full"
                  className="rounded-md"
                  size="sm"
                  color={Colors[colorIndex]}
                  src={UserProfile}
                  name={
                    userDetails?.FirstName
                      ? userDetails.FirstName.slice(0, 1).toUpperCase()
                      : userDetails?.email?.slice(0, 1).toUpperCase() || ""
                  }
                />
              }
            >
              <div className="flex flex-col p-2 w-full justify-start left-0 items-start text-xs">
                <div className=" flex gap-6 mb-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md w-full text-center">
                  <Avatar
                    radius="full"
                    className="rounded-md"
                    size="sm"
                    color={Colors[colorIndex]}
                    src={UserProfile}
                    name={
                      userDetails?.FirstName
                        ? userDetails.FirstName.slice(0, 1).toUpperCase()
                        : userDetails?.email?.slice(0, 1).toUpperCase() || ""
                    }
                  />

                  <div>
                    <p className="text-center mt-2 text-xs">
                      {userDetails?.email}
                    </p>
                    <p></p>
                  </div>
                </div>
                <Divider />
                <div
                  onClick={() => handleNotification()}
                  className=" flex px-1 py-2 mt-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row"
                >
                  {userDetails?.Notification ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-[16px] w-[16px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.143 17.082a24.248 24.248 0 0 0 3.844.148m-3.844-.148a23.856 23.856 0 0 1-5.455-1.31 8.964 8.964 0 0 0 2.3-5.542m3.155 6.852a3 3 0 0 0 5.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 0 0 3.536-1.003A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53"
                        />
                      </svg>

                      <p className=" text-xs">Pause Notifications</p>
                    </>
                  ) : (
                    <>
                      <IoIosNotificationsOutline
                        style={{ height: "16px", width: "16px" }}
                      />
                      <p className=" text-xs">Resume Notifications</p>
                    </>
                  )}
                </div>
                <div className=" flex px-1 py-2 mb-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row">
                  <CiUser style={{ height: "16px", width: "16px" }} />
                  <p className=" text-xs"> Set youself as away</p>
                </div>
                <Divider />
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center justify-between w-full mt-2">
                    <div>Organizations</div>
                    {/* <Button
                      onPress={onOpenOrg}
                      className="flex justify-center items-center"
                      size="sm"
                      isIconOnly
                      variant="light"
                    >
                      +
                    </Button> */}
                  </div>
                </div>

                <div className=" flex flex-row cursor-pointer pt-4  hover:rounded-md  w-full">
                  <Org />
                </div>
                <Divider />
              </div>
              <div>
                <ThemeSwitch/>
              </div>
              <Divider />
              <div className="w-full my-2">
                {Array.from(sideItems1).includes("Invitaion") && (
                  <div
                    onClick={() => setInviteOpen(true)}
                    className="flex px-1 py-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row"
                  >
                    <InviteIcon style={{ height: "16px", width: "16px" }} />
                    <p className="text-xs">Invite members</p>
                  </div>
                )}
                <div
                  className=" flex gap-4 w-full  px-1 py-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md"
                  onClick={handleNavigate}
                >
                  <CiSettings
                    style={{ height: "16px", width: "16px" }}
                    onClick={handleNavigate}
                  />
                  <p className=" text-xs">Settings</p>
                </div>
              </div>
              <Divider />
              <div className="mt-2 mb-2 flex px-1 py-2 cursor-pointer hover:bg-slate-100 dark:hover:bg-gray-700 hover:rounded-md  w-full gap-4 flex-row">
                <IoLogOutOutline style={{ height: "16px", width: "16px" }} />
                <p onClick={handleLogout} className="text-xs">
                  {" "}
                  Log Out
                </p>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full px-4 flex items-center my-4">
        {collapse ? (
              <Tooltip content="Search" placement="right" >
                <Button
                size="sm"
                variant="light"
                isIconOnly
                onClick={handleSearchButtonClick}
                className="border ml-2 border-divider dark:bg-[#2D2D32]"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/kkvxgpti.json"
                  trigger="hover"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ height: "16px", width: "16px" }}
                ></lord-icon>
              </Button>
              </Tooltip>
              
            ) : (
              <Button
                size="md"
                onClick={handleSearchButtonClick}
                className="border border-divider w-full dark:bg-[#2D2D32]"
                variant="light"
                radius="md"
                ref={searchButtonRef}
              >
                <div className={`flex items-center justify-between w-full text-black dark:text-white light:bg-white`}>
                  <div className="flex gap-2 items-center justify-start">
                    <lord-icon
                      src="https://cdn.lordicon.com/kkvxgpti.json"
                      trigger="hover"
                      colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                      style={{ height: "16px", width: "16px" }}
                    ></lord-icon>
                    Search
                  </div>
                  <div>
                    {window.navigator.userAgent.toLowerCase().indexOf("mac") !==
                    -1 ? (
                      <Kbd keys={["command"]} size="sm">
                        K
                      </Kbd>
                    ) : (
                      <div className="text-xs text-gray-400">Ctrl + K</div>
                    )}
                  </div>
                </div>
              </Button>
            )}
        </div>
        <div className="ml-4">
          <ul className="px-2" >
            {renderNavItems(
              Nav1.filter((item) => Array.from(navItems).includes(item.title))
            )}
            <li>
              <div
                id="Accounts"
                className={`flex text-xs ${
                  collapse
                    ? "w-8 h-8 flex items-center justify-center "
                    : ""} p-2 rounded-md cursor-pointer gap-2 items-center  hover:text-foreground ${
                      theme === "light" ? "text-black" : "text-white"
                    } hover:text-foreground ${
                                location.pathname === `/${org}/settings/account`
                                  ? theme === "light"
                                    ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                                    : "bg-[#2D2D32] hover:bg-[#2D2D32]"
                                  : theme === "light"
                                  ? "hover:bg-[#F3F4F6]"
                                  : "hover:bg-[#3A3A40]"
                              }`}
                onClick={handleClick}
              >
                {collapse ? (
                        <Tooltip placement="right" content="Accounts">
                          <lord-icon
                  src="https://cdn.lordicon.com/kthelypq.json"
                  trigger="hover"
                  target="#Accounts"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "16px", height: "16px" }}
                ></lord-icon>
                        </Tooltip>
                      ) : (
                        <lord-icon
                  src="https://cdn.lordicon.com/kthelypq.json"
                  trigger="hover"
                  target="#Accounts"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "16px", height: "16px" }}
                ></lord-icon>
                      )}
                
                {!collapse && <span>Account</span>}
              </div>
            </li>

            <Divider className="my-2" />

            <li>
              <div className="flex flex-col rounded-md text-sm">
                <div>
                  <div
                    className="flex items-center p-2 cursor-pointer"
                    style={{ fontSize: "2px" }}
                  >
                    {!collapse && <span className="text-sm ml-2">User management</span>}
                  </div>

                  <div>
                    {renderNavItems(
                      userNav.filter((item) =>
                        Array.from(navItems).includes(item.title)
                      )
                    )}
                  </div>
                </div>
              </div>
            </li>

            <Divider className="my-2" />

            {renderNavItems(
              Nav2.filter((item) => Array.from(navItems).includes(item.title))
            )}
          </ul>
        </div>
      </div>
      <div className="flex flex-col overflow-y-auto gap-2 w-full">
        <Outlet />
      </div>
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            customAttributeRef={customAttributeRef}
            userNames={userNames}
            inputRef={customAttributeRef}
            divRef={customAttributeRef}
            query={location.pathname.includes("issue") ? "issue" : "ticket"}
            users={users}
            res={res}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={customAttributeRef}
            toggleDrawer={toggleDrawer}
          />
        </div>
      )}
    </div>
  );
};

export default SideNav;
