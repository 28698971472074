import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs} from "@nextui-org/react";

function ConfigHeader() {
    
  return (
    <div className="flex mx-8 justify-between pt-8">
      <div className="w-full flex items-center justify-between">
        <div className="flex text-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm">
            <BreadcrumbItem>Configuration</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        
      </div>
    </div>
  );
}

export default ConfigHeader;
