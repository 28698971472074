import {
  Avatar,
  AvatarGroup,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  getMultipleFiltersSimilar,
  getSingleTicket,
  patchDataInbox,
} from "../../controllers/vectorDB";
import { ConversationStages, inputClassNames } from "../../utils/default";
import Tickets from "./Tickets";
import InboxSearch from "./InboxSearch";
import Tags from "../../components/Tag/tags";
import AttributesRenderer from "../../components/AttributesRenderer/AttributesRenderer";

function InboxTicket({
  stageRef,
  thread,
  setThread,
  org,
  orgId,
  setToggleTicket,
  ticketAdd,
  setTicketAdd,
  ticketIds,
  setTicketIds,
  customTags,emailRes,
  template,metadata,setMetadata,customAttributeRef
}) {
  const [title, setTitle] = useState(thread?.payload?.title);
  const [tags, setTags] = useState(thread?.payload?.tags);
  const [initialtag,setInitialtag] = useState(thread?.payload?.tags);
  const [selectedAttributes, setSelectedAttributes] = useState(new Set([]));
  const [initialTitle,setInitialTitle] = useState(thread?.payload?.title)
  const [tagDetail, setTagDetail] = useState([]);
  const [toggleTitle, setToggleTitle] = useState(false);
  const [stage, setStage] = useState(new Set([thread?.payload?.stage]));
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors?.length;
  const [tickets, setTickets] = useState([]);
  const [query, setQuery] = useState("");
  const [searchTickets, setSearchTickets] = useState([]);
  const handleUpdate = async (formData) => {
    try {
      await patchDataInbox(formData, thread?.payload?.id, org);
      setThread((prev) => ({...prev,payload : {...prev.payload,...formData}}));
      if(formData.title){
        setInitialTitle(formData.title)
      }
      if(formData.tags){
        setInitialtag(formData.tags)
      }
      //   setRefetch(!refetch);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  function removePrefix(fullString, prefix) {
    if (fullString === null) {
      return "";
    }
    if (fullString.startsWith(prefix)) {
      return fullString.substring(prefix.length);
    }
    return fullString; // return the full string if the prefix doesn't match
  }
  useEffect(() => {
    if(tags!==initialtag){
        handleUpdate({tags : tags});
    }
  },[tags])
  useEffect(() => {
    if (
      ConversationStages.new.includes(Array.from(stage)[0]) ||
      ConversationStages.progress.includes(Array.from(stage)[0]) ||
      ConversationStages.completed.includes(Array.from(stage)[0])
    ) {
      console.log(Array.from(stage)[0]);
      handleUpdate({ stage: Array.from(stage)[0] });
    }
  }, [stage]);
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const promises = ticketIds.map((item) =>
          getSingleTicket(Number(item.id), org)
        );
        const resp = await Promise.all(promises);
        setTickets(resp);
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (ticketIds) {
      fetchTickets();
      if (searchTickets.length > 0) {
        let arr = searchTickets;
        arr = arr.filter(
          (item) => {
            const find = ticketIds.find((it) => String(it.id) === String(item.payload.id) );
            if(!find){
              return true;
            }
            else{
              return false;
            }
          }
        );
        setSearchTickets(arr);
      }
    }
  }, [ticketIds.length]);
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const resp = await getMultipleFiltersSimilar(query, org);
        if (resp && resp.result.length > 0) {
          let arr = resp.result;
          arr = arr.filter(
            (item) => {
              const find = ticketIds.find((it) => String(it.id) === String(item.payload.id) );
              if(!find){
                return true;
              }
              else{
                return false;
              }
            }
          );
          setSearchTickets(arr);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    if (query === "") {
      setSearchTickets([]);
    } else {
      let time = setTimeout(() => fetchTickets(), 500);
      return () => clearTimeout(time);
    }
  }, [query]);
  useEffect(() => {
    const filtered = customTags.filter((option) =>
      thread?.payload?.tags.includes(option.id)
    );
    setTagDetail(filtered);
  }, [customTags]);
  useEffect(()=> {
    if(metadata.length > 0){
      let time = setTimeout(() => handleUpdate({metadata: metadata}), 1000);
      return () => clearTimeout(time);
    }
  },[metadata])
  return (
    <div className=" flex flex-col gap-4 p-4 overflow-y-auto h-screen">
      <div>
        {toggleTitle ? (
          <div className=" flex items-center gap-2">
            <Input
              size="sm"
              className="w-60"
              classNames={inputClassNames}
              value={title}
              variant="bordered"
              onChange={(e) => setTitle(e.target.value)}
            />{" "}
            <Button
              onClick={async () => {
                await handleUpdate({ title: title });
                setToggleTitle(false);
              }}
              className="text-green-500"
              isIconOnly
              size="sm"
              variant="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </Button>
            <Button
              onClick={() => {
                setTitle(initialTitle);
                setToggleTitle(false);
              }}
              className="text-red-500"
              isIconOnly
              size="sm"
              variant="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </div>
        ) : (
          <div className=" flex items-center gap-4">
            <h1 className=" text-lg font-semibold">
              {title === "" ? (
                <span className=" font-thin text-base text-gray-400">
                  Add title
                </span>
              ) : (
                title
              )}
            </h1>
            <Button
              size="sm"
              variant="light"
              isIconOnly
              onClick={() => setToggleTitle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            </Button>
          </div>
        )}
      </div>
      <div className=" flex items-center mt-8">
        <p className=" text-sm w-24">Customer</p>
        <Tooltip content={removePrefix(thread?.payload?.customerId, `${org}_`)}>
          <Button className="w-40 justify-start" size="sm" variant="light">
            {removePrefix(thread?.payload?.customerId, `${org}_`).length > 20
              ? `${removePrefix(thread?.payload?.customerId, `${org}_`).slice(
                  0,
                  20
                )}...`
              : removePrefix(thread?.payload?.customerId, `${org}_`)}
          </Button>
        </Tooltip>
      </div>
      <div className=" flex items-center">
        <p className=" text-sm w-24">Owner</p>
        <Button
          className="w-40 justify-start"
          variant="light"
          size="sm"
          startContent={
            <Avatar
              color={
                emailRes[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                  ? Colors[
                      Number(
                        emailRes[
                          removePrefix(thread?.payload?.assignedId, `${org}_`)
                        ].Id
                      ) % numColors
                    ]
                  : ""
              }
              className="w-6 h-6"
              radius="sm"
              src={
                emailRes[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                  ? emailRes[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                      .ProfilePicture
                  : ""
              }
              showFallback
              name={
                thread?.payload?.assignedId === null
                  ? ""
                  : `${removePrefix(thread?.payload?.assignedId, `${org}_`)?.toUpperCase()?.charAt(0)}`
              }
            />
          }
        >
          <Tooltip
            content={
              thread?.payload?.assignedId === null
                ? "Unassigned"
                : emailRes[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                ? emailRes[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                    .FirstName
                : ""
            }
          >
            Owner
          </Tooltip>
        </Button>
      </div>

      <div className=" flex items-center">
        <p className=" w-24 text-sm">Stage</p>
        <Dropdown ref={stageRef}>
          <DropdownTrigger>
            <Button size="sm" className="w-40 justify-start" variant="light">
              {Array.from(stage)[0]}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            selectedKeys={stage}
            onSelectionChange={setStage}
            selectionMode="single"
          >
            <DropdownSection title="New">
              {ConversationStages.new.map((item) => (
                <DropdownItem key={item}>{item}</DropdownItem>
              ))}
            </DropdownSection>
            <DropdownSection title="In progress">
              {ConversationStages.progress.map((item) => (
                <DropdownItem key={item}>{item}</DropdownItem>
              ))}
            </DropdownSection>
            <DropdownSection title="Completed">
              {ConversationStages.completed.map((item) => (
                <DropdownItem key={item}>{item}</DropdownItem>
              ))}
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className=" flex items-center">
        <p className=" w-24 text-sm">Tags</p>
        <Tags
                param={org}
                customTags={customTags}
                org={orgId}
                dropdownref={stageRef}
                tags={tags}
                setTags={setTags}
                tagsDetail={tagDetail}
                setTagsDetail={setTagDetail}
                modalref={stageRef}
              />
      </div>
      <AttributesRenderer
      attributesRef={customAttributeRef}
        attributes={Array.from(selectedAttributes)}
        metadata={metadata}
        setMetadata={setMetadata}
        template={template}
      />
      {template?.length > 0 && (
        <Dropdown>
          <DropdownTrigger>
            <Button size="sm" className="mb-4 w-28" variant="light">
              + Add Attributes
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            selectionMode="multiple"
            selectedKeys={selectedAttributes}
            onSelectionChange={setSelectedAttributes}
          >
            {template?.map((item) => (
              <DropdownItem key={item.name}>{item.name}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
      <p className="text-sm ">Tickets</p>
      <div className=" flex items-center justify-between">
        <Input
          size="sm"
          className="w-[70%]"
          classNames={inputClassNames}
          value={query}
          placeholder="Search for tickets.."
          onChange={(e) => setQuery(e.target.value)}
          startContent={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          }
        />
        <Button
          onClick={() => setToggleTicket(true)}
          variant="light"
          size="sm"
          className="w-[25%] "
        >
          +Add new
        </Button>
      </div>
      <Tickets
        ticketIds={ticketIds}
        setTicketIds={setTicketIds}
        handleUpdate={handleUpdate}
        tickets={tickets}
      />
      
      {searchTickets?.filter((item) => item?.payload?.type === "ticket")?.length > 0 && <p className="text-xs text-gray-400" >Search results -</p>}
      <InboxSearch
        ticketAdd={ticketAdd}
        setTicketAdd={setTicketAdd}
        handleUpdate={handleUpdate}
        ticketIds={ticketIds}
        setTicketIds={setTicketIds}
        tickets={searchTickets}
      />
    </div>
  );
}

export default InboxTicket;
