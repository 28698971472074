import React from 'react'
import Breadcrumb from '../../components/BreadCrumb/Breadcrumb'
import { BreadcrumbItem, Breadcrumbs } from '@nextui-org/react'

const Header = () => {
  return (
    <div className=" pt-8 mx-8 flex items-center justify-between ">
        <div className=" flex items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Updates</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        
      </div>
  )
}

export default Header
