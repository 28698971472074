import { Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { CiDark, CiLight } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import { useTheme } from "next-themes";

const ThemeSwitch = () => {
    const { theme,setTheme } = useTheme();
  const [selected, setSelected] = useState(theme);
  
  useEffect(() => {
    const handleSystemThemeChange = (e) => {
      if (selected === "auto") {
        setTheme(e.matches ? "dark" : "light");
      }
    };

    // Check the initial system theme preference
    if (selected === "auto") {
      const isSystemDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setTheme(isSystemDark ? "dark" : "light");
    } else {
      setTheme(selected);
    }

    // Listen for system theme changes
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleSystemThemeChange);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleSystemThemeChange);
    };
  }, [selected, setTheme]);
  return (
    <Tabs
      selectedKey={selected}
      onSelectionChange={setSelected}
      size="sm"
      aria-label="Tabs sizes"
      className="mb-2"
    >
      <Tab
        key={"dark"}
        title={
          <div className="flex items-center space-x-2">
            <CiDark />
            <span className=" text-xs">Dark</span>
          </div>
        }
      />
      <Tab
        key={"light"}
        title={
          <div className="flex items-center space-x-2">
            <CiLight />
            <span className=" text-xs">Light</span>
          </div>
        }
      />
      <Tab
        key={"auto"}
        title={
          <div className="flex items-center space-x-2">
            <MdAutorenew />
            <span className=" text-xs">Auto</span>
          </div>
        }
      />
    </Tabs>
  );
};

export default ThemeSwitch;
