import { DatePicker, Input, Textarea } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import UserPick from "../../components/UserModal/UserPick";
import Tags from "../../components/Tag/tags";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { postData } from "../../controllers/vectorDB";
import { useNavigate, useParams } from "react-router-dom";
import useTags from "../../hooks/useTags";
import { inputClassNames } from "../../utils/default";

const CreateRelease = () => {
  const { org } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [releaseDate, setReleaseDate] = useState(null);
  const [owner, setOwner] = useState(new Set([]));
  const [tags, setTags] = useState([]);
  const [tagsDetail, setTagsDetail] = useState([]);
  const customTags = useTags();
  const orgData = useSelector(selectOrgData);
  const handleSubmit = async () => {
    if (
      title === "" ||
      description === "" ||
      Array.from(owner).length === 0 ||
      startDate === null ||
      releaseDate === null
    ) {
      return;
    }
    const formData = {
      title,
      description,
      startDate,
      releaseDate,
      owner: String(Array.from(owner)[0]),
      tags,
      type: "release",
      status : "Unreleased"
    };
    try {
      const id = Date.now();
      await postData(id, formData, org);
      navigate(`/${org}/releases/${id}`);
    } catch (error) {}
  };
  return (
    <div className="flex flex-col w-full gap-4 p-4">
      <Input
        size="sm"
        variant="bordered"
        placeholder="Enter release title"
        classNames={inputClassNames}
        label="Title"
        labelPlacement="outside"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Textarea
        classNames={inputClassNames}
        variant="bordered"
        placeholder="Enter description"
        label="Description"
        labelPlacement="outside"
        size="sm"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div className="flex flex-col gap-2">
        <p className="text-xs">Owner</p>
        <UserPick
          menuClass="w-[400px]"
          selected={owner}
          setSelected={setOwner}
          text="Select Owner"
          buttonClass="w-full flex justify-start border border-divider "
          selectionMode="single"
        />
      </div>
      <div className="w-full flex items-center justify-between">
        <DatePicker
          labelPlacement="outside"
          label="Start date"
          className="w-[40%]"
          size="sm"
          value={startDate}
          onChange={setStartDate}
        />
        <DatePicker
          labelPlacement="outside"
          label="Release date"
          className="w-[40%]"
          size="sm"
          value={releaseDate}
          onChange={setReleaseDate}
        />
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-xs">Tags</p>
        <Tags
          param={orgData.attributes.Name}
          customTags={customTags}
          org={orgData.id}
          tags={tags}
          classnames="w-full border border-divider rounded-lg min-h-8 overflow-y-auto flex items-center"
          setTags={setTags}
          tagsDetail={tagsDetail}
          setTagsDetail={setTagsDetail}
        />
      </div>
      <div className="flex items-center justify-end ">
        <PrimaryButton
          text="Create"
          handler={handleSubmit}
          disabled={
            title === "" ||
            description === "" ||
            Array.from(owner).length === 0 ||
            startDate === null ||
            releaseDate === null
          }
        />
      </div>
    </div>
  );
};

export default CreateRelease;
