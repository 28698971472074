import React, { useEffect, useState } from "react";
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Tooltip,
} from "@nextui-org/react";
import { RiVipDiamondLine } from "react-icons/ri";
import { FaPlayCircle } from "react-icons/fa";
import { trimString } from "../../utils/default";

const PartItem = (item, Name, url) => {
  return (
    <div className="flex items-center gap-2">
      {renderIcon(item.attributes.Type)}
      <div className="flex flex-col">
        <p>{trimString(Name || "--", 30)}</p>
        <p className="text-xs">{trimString(url || "--", 40)}</p>
      </div>
    </div>
  );
};
function PartsPicker({
  selected,
  setSelected,
  parts,
  parsedParts,
  divRef,
  inputRef,
  single,
}) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState("");

  const handleDelete = (item) => {
    const arr = Array.from(selected).filter(
      (id) => Number(item) !== Number(id)
    );
    setSelected(new Set(arr));
  };

  const newGenerateUrl = (part) => {
    return part.attributes.Type !== "Product"
      ? `${part.attributes.ParentPath?.split("/")
          ?.map((id) => parsedParts[String(id)]?.Name)
          .join("/ ")}`
      : "Product";
  };

  useEffect(() => {
    const filteredParts = parts.filter((item) =>
      (item?.attributes?.Type !== "Product"
        ? `${item?.attributes?.ParentPath?.split("/")
            ?.map((item) => parsedParts[String(item)]?.Name)
            .join("/ ")}/ ${item?.attributes?.Name}`
        : `${item?.attributes?.Name}`
      )
        .toLowerCase()
        .includes(search.toLowerCase())
    );
    setFilteredOptions(filteredParts);
  }, [search, parts]);

  return (
    <div
      ref={divRef}
      className="w-full flex items-center py-2 h-auto rounded-lg"
    >
      <Dropdown className="w-full">
        <DropdownTrigger>
          <div className="rounded-lg w-full cursor-pointer min-h-4 h-auto flex items-center">
            <div className="gap-2 flex items-center h-auto w-full flex-wrap pt-[2px] px-2">
              {Array?.from(selected)?.length > 0 ? (
                Array?.from(selected)?.length < 4 ? (
                  Array?.from(selected)?.map((item) => (
                    <Chip
                      size="sm"
                      key={item}
                      onClose={() => {
                        handleDelete(item);
                      }}
                      className="cursor-pointer"
                    >
                      {parsedParts[String(item)]?.Type !== "Product"
                        ? `${parsedParts[String(item)]?.ParentPath?.split("/")
                            ?.map((item) => parsedParts[String(item)]?.Name)
                            .join("/ ")}/ ${parsedParts[String(item)]?.Name}`
                        : `${parsedParts[String(item)]?.Name}`}
                    </Chip>
                  ))
                ) : (
                  <>
                    {Array.from(selected)
                      ?.slice(0, 3)
                      ?.map((item) => (
                        <Chip
                          size="sm"
                          key={item}
                          onClose={() => {
                            handleDelete(item);
                          }}
                          className="cursor-pointer"
                        >
                          {parsedParts[String(item)]?.Type !== "Product"
                            ? `${parsedParts[String(item)]?.ParentPath?.split(
                                "/"
                              )
                                ?.map((item) => parsedParts[String(item)]?.Name)
                                .join("/ ")}/ ${
                                parsedParts[String(item)]?.Name
                              }`
                            : `${parsedParts[String(item)]?.Name}`}
                        </Chip>
                      ))}
                    <Tooltip
                      content={
                        <div className="flex flex-col">
                          {Array.from(selected)
                            .slice(3)
                            .map((item) => (
                              <div key={item}>
                                {parsedParts[String(item)]?.Type !== "Product"
                                  ? `${parsedParts[
                                      String(item)
                                    ].ParentPath?.split("/")
                                      ?.map(
                                        (item) =>
                                          parsedParts[String(item)]?.Name
                                      )
                                      .join("/ ")}/ ${
                                      parsedParts[String(item)]?.Name
                                    }`
                                  : `${parsedParts[String(item)]?.Name}`}
                              </div>
                            ))}
                        </div>
                      }
                    >
                      <p className="text-xs">
                        +{Array.from(selected).length - 3}
                      </p>
                    </Tooltip>
                  </>
                )
              ) : (
                <p className="text-xs w-full text-start">Add Parts</p>
              )}
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          selectedKeys={selected}
          onSelectionChange={setSelected}
          ref={divRef}
          selectionMode={single ? "single" : "multiple"}
          className="w-fit h-auto"
          aria-label="Static Actions"
          closeOnSelect={false}
        >
          <DropdownSection>
            <DropdownItem
              classNames={{
                base: "gap-0 data-[selectable=true]:focus:bg-white",
                selectedIcon: "w-0",
              }}
              isReadOnly
              key="new"
            >
              <Input
                ref={inputRef}
                size="sm"
                startContent={
                  <lord-icon
                    src="https://cdn.lordicon.com/kkvxgpti.json"
                    trigger="hover"
                    style={{ height: "16px" }}
                  ></lord-icon>
                }
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search parts.."
                style={{ fontSize: "12px" }}
                className="w-full"
              ></Input>
            </DropdownItem>
          </DropdownSection>

          {filteredOptions?.length > 0 ? (
            <DropdownSection className="h-[200px] w-[350px] overflow-y-auto">
              {filteredOptions?.map((item) => (
                <DropdownItem key={String(item.id)}>
                  {PartItem(item, item?.attributes?.Name, newGenerateUrl(item))}
                </DropdownItem>
              ))}
            </DropdownSection>
          ) : (
            <DropdownItem
              className="cursor-default h-[200px] w-[350px] overflow-y-auto"
              isReadOnly
            >
              <p className="text-center font-semibold">No matching records.</p>
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

function renderIcon(type) {
  switch (type) {
    case "Product":
      return (
        <RiVipDiamondLine
          className="rounded-md size-6 p-1.5"
          style={{
            color: "#006FEE",
            backgroundColor: "#dff3ff",
          }}
        />
      );
    case "Capability":
      return (
        <svg
          style={{
            color: "#7828C8",
            backgroundColor: "#E4D4F4",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="rounded-md size-6 p-1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
          />
        </svg>
      );
    case "Runnable":
      return (
        <FaPlayCircle
          style={{
            color: "#F31260",
            backgroundColor: "#F3126033",
          }}
          className="rounded-md size-6 p-1.5"
        />
      );
    case "Enhancement":
      return (
        <svg
          style={{
            color: "#C4841D",
            backgroundColor: "#F5A52433",
            // borderRadius:'1px',
            // padding:'5px'
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="rounded-md size-6 p-1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
          />
        </svg>
      );
    case "Feature":
      return (
        <svg
          style={{
            color: "#12A150",
            backgroundColor: "#17C96433",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="rounded-md size-6 p-1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
          />
        </svg>
      );
    default:
      return null;
  }
}
export default PartsPicker;
