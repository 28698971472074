import { Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllInbox, getDocument } from "../../controllers/vectorDB";
import HelixLoader from "../../components/Loader/HelixLoader";
import ChartsFirst, { ChartFifth, ChartFourth, ChartsSecond, ChartThird } from "./Charts";


const AnalyticsCharts = () => {
  const { org } = useParams();
  const [tickets, setTickets] = useState([]);
  const [issues, setIssues] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const issueData = await getDocument("type", "issue", org);
        const ticketData = await getDocument("type", "ticket", org);
        const convo = await getAllInbox(org);
        setTickets(ticketData);
        setIssues(issueData);
        setConversation(convo);
        setLoading(false);
      } catch (e) {}
    };
    if (org) {
      fetchData()
    }
  }, [org]);
  if (loading) {
    <div className="flex flex-col items-center justify-center mt-64">
      <HelixLoader />
    </div>;
  }
  else{
    return (
      <div className="flex flex-1 overflow-y-auto w-full flex-col gap-4">
        <Tabs variant="underlined" className="px-8 pb-8">
          <Tab className="px-8" title="Resolv AI Analytics">
            <div className="flex gap-16 items-center" >
              <div className="w-[50%] h-[400px] border p-4 flex flex-col gap-2 border-divider dark:bg-[#2D2D32] rounded-lg " >
                <div>Active tickets linked to conversations</div>
                <div className="w-full flex items-center justify-center" >
                    <ChartsFirst tickets={tickets} convos={conversation} />
                </div>
              </div>
              <div className="w-[50%] h-[400px] border p-4 flex flex-col gap-2 border-divider dark:bg-[#2D2D32] rounded-lg " >
              <div>Active tickets vs closed tickets</div>
                <div className="w-full flex items-center justify-center" >
                    <ChartsSecond tickets={tickets} />
                </div>
              </div>
            </div>
            <div className="w-full my-8 h-[400px] border p-4 flex flex-col gap-2 border-divider dark:bg-[#2D2D32] rounded-lg">
            <div>Active tickets per part</div>
            <div className="w-full flex items-center justify-center" >
                    <ChartThird tickets={tickets} />
                </div>
            </div>
            <div className="flex gap-16 items-center mb-4" >
              <div className="w-[50%] h-[400px] border p-4 flex flex-col gap-2 border-divider dark:bg-[#2D2D32] rounded-lg " >
                <div>Customers with most open tickets</div>
                <div className="w-full flex items-center justify-center" >
                    <ChartFourth tickets={tickets} convos={conversation} />
                </div>
              </div>
              <div className="w-[50%] h-[400px] border p-4 flex flex-col gap-2 border-divider dark:bg-[#2D2D32] rounded-lg " >
              <div>Users with most open tickets</div>
                <div className="w-full flex items-center justify-center" >
                    <ChartFifth tickets={tickets} />
                </div>
              </div>
            </div>
          </Tab>
          <Tab title="Resolv Analytics"></Tab>
        </Tabs>
      </div>
    );
  }
  
};

export default AnalyticsCharts;
