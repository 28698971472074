import React, { useEffect, useState, useRef } from 'react';

function useSocket(email, organization) {
    const [socket, setSocket] = useState(null);
    const reconnectAttempts = useRef(0);
    const maxReconnectAttempts = 5;
    const pingIntervalRef = useRef(null);
    
    useEffect(() => {
        let ws;

        const connectSocket = () => {
            ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SERVER}?access_token=${email}&org=${organization}`);
            
            ws.onopen = () => {
                console.log("Connected");
                setSocket(ws);
                reconnectAttempts.current = 0; // Reset reconnect attempts on success
                
                // Start sending ping messages every 30 seconds to keep connection alive
                pingIntervalRef.current = setInterval(() => {
                    if (ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify({ type: 'ping' }));
                    }
                }, 30000);
            };
            
            ws.onclose = () => {
                console.log("Disconnected");
                setSocket(null);
                
                // Clear ping interval when disconnected
                if (pingIntervalRef.current) {
                    clearInterval(pingIntervalRef.current);
                }

                // Attempt to reconnect with exponential backoff
                if (reconnectAttempts.current < maxReconnectAttempts) {
                    reconnectAttempts.current++;
                    const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts.current), 30000); // Exponential backoff
                    setTimeout(connectSocket, timeout);
                } else {
                    console.log('Max reconnection attempts reached.');
                }
            };
        };

        // Initial connection attempt
        connectSocket();

        return () => {
            if (ws) {
                ws.close();
            }
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current);
            }
        };
    }, [email, organization]);

    return socket;
}

export default useSocket;
