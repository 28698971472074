import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import SprintTable from "../Table/SprintTable";
import CreateSprint from "../Drawer/CreateSprint";
import Icons from "../../../components/Tag/Icon";
import {
  getFilteredBySingleRelation
} from "../../../controllers/strapiController";
import Breadcrumb from "../../../components/BreadCrumb/Breadcrumb";
import ParentDropDown from "../../Components/ParentDropDown";
import OwnerDropdown from "../../Components/OwnerDropdown";
import { useSelector } from "react-redux";
import { selectOrgData, selectPermissions, selectResData, selectUserData, selectUserNames } from "../../../redux/actions";
import HelixLoader from "../../../components/Loader/HelixLoader";

const SprintBoard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [customizeItems, setCustomizeItems] = useState(new Set([]));
  const customizeCompleteList = ["Priority", "Tags"];
  const [loader,setLoader] = useState(true);
  const divRef = useRef();
  const inputRef = useRef();
  const dropdownref = useRef();
  const modalref = useRef();
  const popoverref = useRef();
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const userNames = useSelector(selectUserNames)
  const orgData = useSelector(selectOrgData);

  const [partData, setPartData] = useState([]);
  const [parentKeys, setParentKeys] = useState(new Set([]));
  const searchRef = useRef();
  const [searchTicket, setSearchTicket] = useState("");
  const [dropdownOwner, setDropdownOwner] = useState(new Set([]));
  
  const closeref =useRef();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [data, setData] = useState([]);
  const drawerRef = useRef();
  const userPerm = useSelector(selectPermissions)?.find((it) => it.name === "Role-4");

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleClearFilter = () => {
    setParentKeys(new Set([]));
    setDropdownOwner(new Set([]));
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    fetchSprints();
  };

  const handleClickOutside = (event) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target) &&
      (!inputRef.current || !inputRef.current.contains(event.target)) &&
      (!divRef.current || !divRef.current.contains(event.target)) &&
      (!dropdownref.current || !dropdownref.current.contains(event.target)) &&
      (!modalref.current || !modalref.current.contains(event.target)) &&
      (!popoverref.current || !popoverref.current.contains(event.target))&&
      (!closeref.current || !closeref.current.contains(event.target))
    ) {
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);

  const fetchSprints = async () => {
    try {
      const resp = await getFilteredBySingleRelation(
        "sprints",
        "organization",
        orgData.id
      );
      setData(
        resp.data.map((item) => ({
          id: item.id,
          owners: item.attributes.owners.data,
          title: item.attributes.Title,
          duration: item.attributes.Duration,
          startdate: item.attributes.StartDate,
          impactedParts: item.attributes.parts.data,
          pendingIssues: item.attributes.PendingIssues,
          Priority: item.attributes.Priority,
          icon:item.attributes.Icon,
          Tags:
            item.attributes.Tags.data.map((tag) => ({
              name: tag.attributes.Name,
              color: tag.attributes.Color || "",
            })) || [],
        }))
      )
      setLoader(false)
    } catch (error) {
      console.error("Error fetching sprints:", error);
    }
  };
  useEffect(() => {
    if (orgData?.id) {
      fetchSprints();
    }
  }, [orgData]);

  const handleDrawerButtonClick = () => {
    toggleDrawer();
  };
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation(
          "parts",
          "organization",
          orgData.id
        );
        setPartData(results.data);
      } catch (error) {
        throw error;
      }
    };
    if (orgData?.id) {
      getAllDataByIds();
    }
  }, [orgData]);


  return (
    <div className=" overflow-y-auto">
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <CreateSprint
            userNames={userNames}
            type="create"
            divRef={divRef}
            inputRef={inputRef}
            handleDrawerClose={handleDrawerClose}
            handleDrawerButtonClick={handleDrawerButtonClick}
            dropdownref={dropdownref}
            modalref={modalref}
            popoverref={popoverref}
          />
        </div>
      )}

      <div className="flex gap-4 pt-8 mb-4 px-8 text-xs justify-between items-center">
        <div className=" flex text-center justify-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Sprint Board</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        {userPerm?.privileges?.[0]?.create === true && (
          <Button
            className="text-xs bg-[rgb(51,51,245)] text-white"
            onClick={handleDrawerButtonClick}
            size="sm"
          >
            + Sprint Board
          </Button>
        )}
      </div>
      <div className=" flex items-center justify-between px-8">
        <div className="flex gap-4 items-center">
          <div
            ref={searchRef}
            onClick={() => setIsSearchOpen(true)}
            className={`transition-all dark:bg-[#121212] duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
              isSearchOpen
                ? "pl-2 w-40 gap-2 justify-start"
                : "w-8 justify-center"
            } rounded-full  h-8 `}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
            {isSearchOpen && (
              <input
                ref={inputRef}
                value={searchTicket}
                onChange={(e) => setSearchTicket(e.target.value)}
                style={{ height: "20px", outline: "none" }}
                className="w-full px-2 text-xs rounded-full"
                placeholder="Search"
              />
            )}
          </div>
          <div className="flex border border-divider rounded-sm  ">
                             <div className=" flex items-center">
                <h2 className="text-xs ml-2 mr-2">Parts</h2>
                <div className="border-l border-divider h-full " /> 
              </div>
                <div className="  rounded-r-md text-xs">
              <ParentDropDown
                partData={partData}
                parentKeys={parentKeys}
                setParentKeys={setParentKeys}
              />
            </div>
          </div>
          <div className="flex border border-divider rounded-sm  ">
                             <div className=" flex items-center">
                <h2 className="text-xs ml-2 mr-2">Owner</h2>
                <div className="border-l border-divider h-full " /> 
              </div>
                <div className="  rounded-r-md text-xs">
              <OwnerDropdown
                res={res}
                users={users}
                dropdownOwner={dropdownOwner}
                setDropdownOwner={setDropdownOwner}
              />
            </div>
          </div>
          <Button
              onClick={() => handleClearFilter()}
              size="sm"
              variant="light"
            >
              Clear
            </Button>
        </div>

        <Dropdown>
          <DropdownTrigger>
            <Button size="sm" variant="light">
              Customize
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Multiple selection"
            selectionMode="multiple"
            selectedKeys={customizeItems}
            onSelectionChange={(keys) => setCustomizeItems(keys)}
          >
            {customizeCompleteList.map((item) => (
              <DropdownItem key={item}>{item}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      { loader ? 
       <div className="flex flex-col items-center justify-center mt-64">
       <HelixLoader />
       
     </div>
      : <div className="">
        {data.length === 0 ? (
          <div className="flex  flex-col text-xs gap-2 mt-72 justify-center items-center ">
            <Icons />
          </div>
        ) : (
          <div className="">
            <Divider className="my-4 border border-divider" />
            <SprintTable
              
              owners={dropdownOwner}
              partKey={parentKeys}
              search={searchTicket}
              customizeItems={customizeItems}
              orgId={orgData?.id}
              sprints={data}
            />
          </div>
        )}
      </div>}
    </div>
  );
};

export default SprintBoard;