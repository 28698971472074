import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMultipleFilters, patchData } from "../../controllers/vectorDB";
import TicketHeader from "./Header";
import TitleHeader from "./TitleHeader";
import useTags from "../../hooks/useTags";
import { Chip } from "@nextui-org/react";
import DescriptionEditor from "./DescriptionEditor";
import Attributes from "./Attributes";

const TicketPage = () => {
  const { ticketId, org } = useParams();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [imageIds, setImageIds] = useState([]);
  const customTags = useTags();
  const [logs ,setLogs] = useState([]);
  const handleUpdate = async (formData) => {
    if (!ticket?.id) {
      return;
    }
    try {
      await patchData(formData, ticket?.id, org);
      setTicket((prev) => ({ ...prev, ...formData }));
    } catch (error) {}
  };
  const checkLogs = (data, type) => {
    const logTimestamp = Date.now();
    const fieldsMap = {
      description: "description",
      title: "title",
      estimateDays: "estimated days",
      part: "part",
      stage: "stage",
      assigned: "assigned",
      priority: "priority",
      tags: "tags",
      targetclosedate: "target close date"
    };
    const field = fieldsMap[type];
    const log = field
      ? {
          operation: "updated",
          field,
          to:
            type === "targetclosedate"
              ? {
                  day: data.targetclosedate.day,
                  month: data.targetclosedate.month,
                  year: data.targetclosedate.year,
                }
              : type === "estimateDays"
              ? data["estimatedays"]
              : data[field],
          by: userDataId,
          timestamp: logTimestamp,
        }
      : null;

    if (log) {
      const finalLogs = [...logs, log];
      setLogs(finalLogs);
      return finalLogs;
    }

    return logs;
  };
  useEffect(() => {
    const fetchTicket = async () => {
      const resp = await getMultipleFilters(
        [
          { key: "type", value: "ticket" },
          { key: "latest", value: String(ticketId) },
        ],
        org
      );
      if (resp.length !== 1) {
        navigate(`/${org}/tickets`);
      } else {
        setTicket(resp[0]?.payload);
        setImageIds(resp[0]?.payload?.imageIds);
      }
    };
    if (ticketId && org) {
      fetchTicket();
    }
  }, [ticketId, org]);
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <TicketHeader />
      <TitleHeader
        ticket={ticket}
        handleUpdate={handleUpdate}
        imageIds={imageIds}
      />
      <div className="w-full flex-1" >
      <div className=" px-8 pt-8 w-full flex overflow-y-auto">
        <div className="w-[60%] flex flex-col gap-4 overflow-y-auto">
          <div className=" flex items-center gap-2 flex-wrap">
            {customTags
              ?.filter((item) => ticket?.tags.includes(item.id))
              ?.map((item) => (
                <Chip
                  className=" border border-divider"
                  // size="sm"
                  variant="light"
                >
                  {" "}
                  <div className=" flex items-center gap-1">
                    <div
                      style={{ backgroundColor: item?.attributes?.Color }}
                      className="h-2 w-2 rounded-full"
                    ></div>
                    <p>{item?.attributes?.Name}</p>
                  </div>{" "}
                </Chip>
              ))}
          </div>
          <DescriptionEditor
            ticket={ticket}
            imageIds={imageIds}
            setImageIds={setImageIds}
            handleUpdate={handleUpdate}
          />
        </div>
        <div className=" w-[40%] overflow-y-auto ">
          {ticket && <Attributes ticket={ticket} handleUpdate={handleUpdate} />}
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default TicketPage;
