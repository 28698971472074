import { Chip, Tooltip } from "@nextui-org/react";
import React from "react";

function PartChips({ part, parsedParts }) {
  return (
    <>
      {parsedParts && (
        <div className="flex items-center gap-1">
          {part.slice(0, 2).map((item) => {
            return (
              <Chip
                variant="flat"
                color={
                  item.attributes.Type === "Product"
                    ? "primary"
                    : item.attributes.Type === "Capability"
                    ? "secondary"
                    : item.attributes.Type === "Feature"
                    ? "success"
                    : item.attributes.Type === "Enhancement"
                    ? "warning"
                    : "danger"
                }
                size="sm"
                key={item.id}
              >
                {parsedParts[String(item.id)]?.Type !== "Product"
                  ? `${parsedParts[String(item.id)]?.ParentPath?.split("/")
                      ?.map((it) => parsedParts[String(it)]?.Name)
                      .join("/ ")}/ ${parsedParts[String(item.id)]?.Name}`
                  : `${parsedParts[String(item.id)]?.Name}`}
              </Chip>
            );
          })}
          {part.length > 2 && (
            <Tooltip
              content={
                <div className=" text-xs flex flex-col gap-1">
                  {part.slice(2, part.length).map((item) => {
                    return (
                    <p>
                      {parsedParts[String(item.id)]?.Type !== "Product"
                        ? `${parsedParts[String(item.id)]?.ParentPath?.split(
                            "/"
                          )
                            ?.map((it) => parsedParts[String(it)]?.Name)
                            .join("/ ")}/ ${parsedParts[String(item.id)]?.Name}`
                        : `${parsedParts[String(item.id)]?.Name}`}
                    </p>)
                  })}
                </div>
              }
            >
              <p className=" text-xs font-light">+{part.length - 2}</p>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
}

export default PartChips;
