import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDoc, getAllDocs } from "../../controllers/vectorDB";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tooltip,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import { Colors, numColors } from "../../utils/constant";
import { timeAgo } from "../../utils/default";
import { DeleteSingleAttribute } from "../../controllers/strapiController";
import HelixLoader from "../../components/Loader/HelixLoader";
import Icon from "../../components/Tag/Icon";

const Docs = () => {
  const navigate = useNavigate();
  const { org } = useParams();
  const [docs, setDocs] = useState([]);
  const [loader,setLoader] = useState(true)
  const res = useSelector(selectResData);
  const handleDelete = async (item) => {
    if (item?.strapiId && item?.strapiId !== "null") {
      try {
        await DeleteSingleAttribute("upload/files", Number(item.strapiId));
      } catch (error) {}
    }
    await handleDeleteDoc(item?.file_document_id);
  };
  const handleDeleteDoc = async (id) => {
    try {
      await deleteDoc(id, org);
      setDocs((prev) => prev.filter((item) => item.file_document_id !== id));
    } catch (error) {}
  };
  useEffect(() => {
    const getAllDoc = async () => {
      try {
        const resp = await getAllDocs(org);
        setDocs(resp.documents)
        setLoader(false);
      } catch (error) {
        console.log("err",error)
      }
    };
    if (org) {
      getAllDoc();
    }
  }, [org]);
if(loader){
    return (
        <div className="flex flex-col items-center justify-center mt-64">
      <HelixLoader />
      
    </div>
    )
}
  return (
    <div className="flex flex-col">
      <div className="flex items-center mx-8 py-4 justify-end">
        {/* <input type="file" className="" ></input> */}
        <PrimaryButton
          handler={() => navigate(`/${org}/settings/knowledge-base/ingest`)}
          text={"+ Add Articles"}
        />{" "}
      </div>
      <div className="flex items-center w-full justify-center px-8">
        {docs.length === 0 ? 
            <div className="flex flex-col mt-32 gap-4 items-center justify-center h-48">
            <Icon className="mr-2" />
            <span className="text-xs text-gray-400">
            Recently uploaded documents may take some time to appear.
            </span>
          </div>
        :<div className="w-full flex flex-wrap gap-4">
          {docs.map((item) => (
            <Card
              shadow="none"
              className="flex flex-col gap-4 p-4 border border-divider w-[350px] h-[250px]"
            >
              <div className="w-full flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Avatar
                    showFallback
                    name={item?.document_title.toUpperCase().charAt(0)}
                    color={Colors[item?.document_title?.length % numColors]}
                  />
                  <p className=" text-lg font-semibold">
                    {item?.document_title.length > 20
                      ? `${item?.document_title.slice(0, 20)}...`
                      : item?.document_title}
                  </p>
                </div>
                <Dropdown>
                  <DropdownTrigger>
                    <Button isIconOnly variant="light">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                        />
                      </svg>
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem
                      variant="flat"
                      onClick={() => handleDelete(item)}
                      color="danger"
                      startContent={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      }
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className=" flex-1 flex text-sm text-gray-400">
                {item?.document_description.length > 230
                  ? `${item?.document_description.slice(0, 230)}...`
                  : item?.document_description}
              </div>
              <div className="flex items-center gap-4">
                <Tooltip
                  content={res && (res[String(item?.user_id)]?.FirstName ?? "")}
                >
                  <Avatar
                    size="sm"
                    src={res && res[String(item?.user_id)]?.ProfilePicture}
                    showFallback
                    name={
                      res &&
                      (res[
                        String(item?.user_id)
                      ]?.UserName?.toUpperCase()?.charAt(0) ??
                        "")
                    }
                    color={Colors[Number(item?.user_id) % numColors]}
                  />
                </Tooltip>

                <p className="text-sm text-gray-400">
                  Uploaded {timeAgo(Number(item?.file_document_id))}
                </p>
              </div>
            </Card>
          ))}
        </div>}
      </div>
    </div>
  );
};

export default Docs;
