import {
  DATE_INPUT,
  DROPDOWN,
  EMAIL,
  MARKDOWN_TEXT,
  NUMBER_INPUT,
  PEOPLE,
  SHORT_TEXT,
} from "./customAttributes";

export const ticketAttributes = [
  {
    type: SHORT_TEXT,
    name: "Title",
  },
  {
    type: MARKDOWN_TEXT,
    name: "Description",
  },
  {
    type: DROPDOWN,
    name: "Part",
  },
  {
    type: PEOPLE,
    name: "Assigned to",
  },
  {
    type: DROPDOWN,
    name: "Priority",
  },
  {
    type: DROPDOWN,
    name: "Tags",
  },
  {
    type: DATE_INPUT,
    name: "Target close date",
  },
  {
    type: PEOPLE,
    name: "Owner",
  },
  {
    type: NUMBER_INPUT,
    name: "Estimated days",
  },
];

export const issueAttributes = [
  {
    type: SHORT_TEXT,
    name: "Title",
  },
  {
    type: MARKDOWN_TEXT,
    name: "Description",
  },
  {
    type: DROPDOWN,
    name: "Part",
  },
  {
    type: DROPDOWN,
    name: "Stage",
  },
  {
    type: DROPDOWN,
    name: "Sprint",
  },
  {
    type: PEOPLE,
    name: "Assigned to",
  },
  {
    type: DROPDOWN,
    name: "Priority",
  },
  {
    type: DROPDOWN,
    name: "Tags",
  },
  {
    type: DATE_INPUT,
    name: "Target close date",
  },
  {
    type: PEOPLE,
    name: "Owner",
  },
  {
    type: NUMBER_INPUT,
    name: "Estimated days",
  },
];

export const inboxAttributes = [
  {
    type: SHORT_TEXT,
    name: "Title",
  },
  {
    type: PEOPLE,
    name: "Customer",
  },
  {
    type: PEOPLE,
    name: "Owner",
  },
  {
    type: DROPDOWN,
    name: "Stage",
  },
  {
    type: PEOPLE,
    name: "Members",
  },
  {
    type: DROPDOWN,
    name: "Tags",
  },
];

export const accountAttributes = [
  {
    type: SHORT_TEXT,
    name: "Title",
  },
  {
    type: MARKDOWN_TEXT,
    name: "Description",
  },
  {
    type: SHORT_TEXT,
    name: "Website",
  },
  {
    type: DROPDOWN,
    name: "Tags",
  },
];

export const customerAttributes = [
  {
    type: SHORT_TEXT,
    name: "Title",
  },
  {
    type: MARKDOWN_TEXT,
    name: "Description",
  },
  {
    type: EMAIL,
    name: "Email",
  },
  {
    type: NUMBER_INPUT,
    name: "Phone",
  },
  {
    type: DROPDOWN,
    name: "Accounts",
  },
  {
    type: DROPDOWN,
    name: "Tags",
  },
];

export const componentAttributes = [
  {
    type: SHORT_TEXT,
    name: "Title",
  },
  {
    type: MARKDOWN_TEXT,
    name: "Description",
  },
  {
    type: PEOPLE,
    name: "Owner",
  },
  {
    type: DROPDOWN,
    name: "Status",
  },
  {
    type: DROPDOWN,
    name: "Part",
  },
  {
    type: DROPDOWN,
    name: "Tags",
  },
];
