import { Button } from "@nextui-org/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCollapse, setCollapse } from "../../redux/actions";
import { useTheme } from "next-themes";

const CollapseButton = () => {
  const collapse = useSelector(selectCollapse);
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => dispatch(setCollapse(!collapse))}
      isIconOnly
      radius="full"
      size="md"
      className={`absolute text-[rgb(51,51,245)] bg-white dark:bg-[#2D2D32] bottom-8 z-[1000] ${
        collapse ? " left-16" : " left-52 "
      }  `}
    >
      {collapse ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
          />
        </svg>
      )}
    </Button>
  );
};

export default CollapseButton;
