import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Input,
  Avatar,
  AvatarGroup,
} from "@nextui-org/react";

function InviteDropdown({ dropdownOwner, setDropdownOwner, users, res }) {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    if (users.length > 0 && res) {
      const filtered = users.filter((item) =>
        `${item?.FirstName || ''}${item?.LastName || ''}`
          .toLowerCase()
          .includes(search.replace(/\s+/g, "").toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [search, users, res]);

  return (
    <div>
      <Dropdown>
        <DropdownTrigger>
          <Button variant="light" size="sm" className="bg-white dark:bg-black text-xs h-8">
            {Array.from(dropdownOwner).length === 0 ? (
              "Add User"
            ) : (
              <div className="flex items-center">
                <AvatarGroup
                  max={2}
                  total={Array.from(dropdownOwner).length}
                  renderCount={(count) =>
                    count > 2 ? (
                      <p className="text-xs text-foreground font-medium ms-2">
                        +{count - 2}
                      </p>
                    ) : null
                  }
                >
                  {Array.from(dropdownOwner).map((user) => {
                    const userData = res[String(user)];
                    const name =
                      userData?.FirstName
                        ? userData.FirstName.charAt(0).toUpperCase()
                        : userData?.UserName?.charAt(0).toUpperCase();
                    return (
                      <Avatar
                        key={user}
                        showFallback
                        color={Colors[Number(user) % numColors]}
                        name={name}
                        style={{ height: "20px", width: "20px" }}
                        src={
                          process.env.REACT_APP_STAGE === 'Dev'
                            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userData?.ProfilePicture?.formats?.small?.url}`
                            : `${userData?.ProfilePicture?.formats?.small?.url}`
                        }
                      />
                    );
                  })}
                </AvatarGroup>
              </div>
            )}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          variant="flat"
          className="text-xs"
          closeOnSelect={false}
          selectionMode="multiple"
          selectedKeys={dropdownOwner}
          onSelectionChange={setDropdownOwner}
        >
          <DropdownSection showDivider>
            <DropdownItem isReadOnly key="new">
              <Input
                size="sm"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search owners.."
              />
            </DropdownItem>
          </DropdownSection>
          <DropdownSection className="max-h-[200px] overflow-y-auto">
            {filteredUsers.map((user) => {
              const userData = res[String(user?.id)];
              const name =
                userData?.FirstName
                  ? `${userData.FirstName} ${userData.LastName || ""}`
                  : userData?.UserName || "";
              const avatarName =
                userData?.FirstName
                  ? userData.FirstName.charAt(0).toUpperCase()
                  : userData?.UserName?.charAt(0).toUpperCase();
              return (
                <DropdownItem
                  key={String(user?.id)}
                  startContent={
                    <Avatar
                      showFallback
                      color={Colors[Number(user?.id) % numColors]}
                      name={avatarName}
                      size="sm"
                      radius="sm"
                      src={
                        process.env.REACT_APP_STAGE === 'Dev'
                          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userData?.ProfilePicture?.formats?.small?.url}`
                          : `${userData?.ProfilePicture?.formats?.small?.url}`
                      }
                    />
                  }
                >
                  {name}
                </DropdownItem>
              );
            })}
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default InviteDropdown;
