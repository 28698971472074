import React, { useEffect, useState } from "react";
import { getMultipleFiltersSimilar } from "../../controllers/vectorDB";
import { Card, CardBody, Chip, Input, Spinner } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { inputClassNames } from "../../utils/default";

const SearchIssues = ({ type, setSelected, selected, inputRef }) => {
  const [issues, setIssues] = useState([]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const { org } = useParams();
  const addIssue = (id) => {
    const arr = Array.from(selected);
    arr.push(id);
    setSelected(new Set(arr));
  };
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoader(true);
        const resp = await getMultipleFiltersSimilar(query, org);

        if (resp && resp?.result?.length > 0) {
          let arr = resp.result;
          arr = arr.filter(
            (item) =>
              !Array.from(selected).includes(
                Number(item.payload.id) || String(item.payload.id)
              ) && item.payload.type === type
          );
          setIssues(arr);
          setLoader(false);
        }
      } catch (error) {
        console.log("Error:", error);
        setLoader(false);
      }
    };
    if (query === "") {
      setIssues([]);
    } else {
      let time = setTimeout(() => fetchTickets(), 500);
      return () => clearTimeout(time);
    }
  }, [query, type, selected]);

  return (
    <div className=" flex flex-col h-full gap-4 w-full">
      <Input
        ref={inputRef}
        size="sm"
        className="w-full"
        classNames={inputClassNames}
        value={query}
        placeholder={`Search for ${type}s..`}
        onChange={(e) => setQuery(e.target.value)}
        startContent={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        }
      />
      {loader ? (
        <Spinner />
      ) : (
        <div className=" flex flex-col max-h-[350px] min-h-[200px] overflow-y-auto gap-4 w-full">
          {issues.map((t, index) => (
            <Card
              onClick={() => addIssue(t.payload.id)}
              key={index}
              fullWidth
              className="border border-divider"
              isPressable
              shadow="none"
            >
              <CardBody className=" flex flex-col gap-4">
                <div className=" flex items-center gap-4">
                  <Chip
                    variant="flat"
                    size="sm"
                    color={type === "ticket" ? "danger" : "primary"}
                  >
                    {type === "ticket" ? "TKT" : "ISS"}-{t.payload.latest}
                  </Chip>
                  <p className="text-sm">{t.payload.title}</p>
                </div>

                <div className=" text-xs font-light">
                  {t.payload.description.replace(/<[^>]*>/g, "").slice(0, 25)}{" "}
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchIssues;
