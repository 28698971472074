import CryptoJS from "crypto-js";
import { FaPlayCircle } from "react-icons/fa";
import { RiVipDiamondLine } from "react-icons/ri";
import { getMonthName } from "./constant";

export const GroupData = {
  org_privileges: [
    {
      name: "Role-9",
      class: "Organization details",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-10",
      class: "Integrations",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-7",
      class: "User Management",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-8",
      class: "Tags",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-14",
      class: "User Management",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-15",
      class: "User Management",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
  ],
  product_privileges: [
    {
      name: "Role-6",
      class: "Components",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
  ],
  dev_privileges: [
    {
      name: "Role-2",
      class: "Issues",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-5",
      class: "Road Maps",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-3",
      class: "Queues",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-4",
      class: "Sprint Boards",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
  ],
  support_privileges: [
    {
      name: "Role-1",
      class: "Conversations",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-11",
      class: "Tickets",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
  ],
  analytics_privileges: [
    {
      name: "Role-13",
      class: "Team Activity",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
    {
      name: "Role-12",
      class: "Analytics",
      privileges: [
        {
          read: true,
          create: false,
          delete: false,
          update: false,
        },
      ],
    },
  ],
};

export const UserPermissions = [
  ...GroupData.org_privileges,
  ...GroupData.dev_privileges,
  ...GroupData.product_privileges,
  ...GroupData.support_privileges,
  ...GroupData.analytics_privileges,
];

export const defaultTag = [
  { name: "PASTEL BLUE", hex: "#A5BCFB" },
  { name: "PERMAFROST", hex: "#99E5F5" },
  { name: "FREEZY BREEZY", hex: "#99F0E4" },
];

export const defaultConfiguration = [
  {
    title: "Custom Attributes",
    hidden: true,
  },
  {
    title: "Knowledge Base",
    hidden: false,
  },
  {
    title: "Blueprints",
    hidden: false,
  },
  {
    title: "Customers",
    hidden: false,
  },
  {
    title: "Accounts",
    hidden: false,
  },
  {
    title: "Releases",
    hidden: false,
  },
];
export const defaultGroups = (orgId, userIds, ownerId) => {
  return [
    {
      Name: "Customers",
      Description: "This is a customer group",
      organization: {
        disconnect: [],
        connect: [
          {
            id: Number(orgId),
            position: {
              end: true,
            },
          },
        ],
      },
      Type: "External",
      owner: {
        disconnect: [],
        connect: [
          {
            id: Number(ownerId),
            position: {
              end: true,
            },
          },
        ],
      },
      members: {
        disconnect: [],
        connect: userIds.map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
      path: "customers",
      ...GroupData,
    },
    {
      Name: "Developers",
      Description: "This is a developer group",
      organization: {
        disconnect: [],
        connect: [
          {
            id: Number(orgId),
            position: {
              end: true,
            },
          },
        ],
      },
      Type: "Internal",
      owner: {
        disconnect: [],
        connect: [
          {
            id: Number(ownerId),
            position: {
              end: true,
            },
          },
        ],
      },
      members: {
        disconnect: [],
        connect: userIds.map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
      path: "developers",
      ...GroupData,
    },
    {
      Name: "Admins",
      Description: "This is a customer group",
      organization: {
        disconnect: [],
        connect: [
          {
            id: Number(orgId),
            position: {
              end: true,
            },
          },
        ],
      },
      Type: "Admin",
      owner: {
        disconnect: [],
        connect: [
          {
            id: Number(ownerId),
            position: {
              end: true,
            },
          },
        ],
      },
      members: {
        disconnect: [],
        connect: [
          {
            id: Number(ownerId),
            position: {
              end: true,
            },
          },
        ],
      },
      path: "admins",
      org_privileges: [
        {
          name: "Role-9",
          class: "Organization details",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-10",
          class: "Integrations",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-7",
          class: "User Management",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-8",
          class: "Tags",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-14",
          class: "User Management",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-15",
          class: "User Management",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
      ],
      product_privileges: [
        {
          name: "Role-6",
          class: "Components",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
      ],
      dev_privileges: [
        {
          name: "Role-2",
          class: "Issues",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-5",
          class: "Road Maps",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-3",
          class: "Queues",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-4",
          class: "Sprint Boards",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
      ],
      support_privileges: [
        {
          name: "Role-1",
          class: "Conversations",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-11",
          class: "Tickets",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
      ],
      analytics_privileges: [
        {
          name: "Role-13",
          class: "Team Activity",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
        {
          name: "Role-12",
          class: "Analytics",
          privileges: [
            {
              read: true,
              create: true,
              delete: true,
              update: true,
            },
          ],
        },
      ],
    },
  ];
};
export const defaultWidget = (orgId) => {
  return {
    appid: CryptoJS.lib.WordArray.random(16).toString(),
    organization: {
      disconnect: [],
      connect: [
        {
          id: Number(orgId),
          position: {
            end: true,
          },
        },
      ],
    },
    Theme: "#000000",
    Icon: "Message",
    ButtonText: "Send Message",
    Tabs: [
      {
        title: "Home",
        isVisible: true,
      },
      {
        title: "Conversation",
        isVisible: false,
      },
      {
        title: "Ticket",
        isVisible: true,
      },
      {
        title: "Help",
        isVisible: true,
      },
    ],
  };
};
export const defaultTicket = (tagIds, partId, assignedId, ownerId) => {
  return [
    {
      latest: "1",
      type: "ticket",
      imageIds: [],
      imagePresent: false,
      title: "Default ticket 1",
      description: "<p>This is a default ticket</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "High",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "2",
      type: "ticket",
      imageIds: [],
      imagePresent: false,
      title: "Default ticket 2",
      description: "<p>This is a default ticket</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Low",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "3",
      type: "ticket",
      imageIds: [],
      imagePresent: false,
      title: "Default ticket 3",
      description: "<p>This is a default ticket</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Normal",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "4",
      type: "ticket",
      imageIds: [],
      imagePresent: false,
      title: "Default ticket 4",
      description: "<p>This is a default ticket</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Urgent",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "5",
      type: "ticket",
      imageIds: [],
      imagePresent: false,
      title: "Default ticket 5",
      description: "<p>This is a default ticket</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "High",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
  ];
};

export const defaultIssue = (tagIds, partId, assignedId, ownerId) => {
  return [
    {
      latest: "1",
      type: "issue",
      imageIds: [],
      imagePresent: false,
      title: "Default issue 1",
      description: "<p>This is a default issue</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "High",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "2",
      type: "issue",
      imageIds: [],
      imagePresent: false,
      title: "Default issue 2",
      description: "<p>This is a default issue</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Low",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "3",
      type: "issue",
      imageIds: [],
      imagePresent: false,
      title: "Default issue 3",
      description: "<p>This is a default issue</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Urgent",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "4",
      type: "issue",
      imageIds: [],
      imagePresent: false,
      title: "Default issue 4",
      description: "<p>This is a default issue</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Normal",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
    {
      latest: "5",
      type: "issue",
      imageIds: [],
      imagePresent: false,
      title: "Default issue 5",
      description: "<p>This is a default issue</p>",
      owner: String(ownerId),
      stage: "Queued",
      priority: "Normal",
      tags: tagIds,
      assigned: String(assignedId),
      targetclosedate: "",
      estimatedays: 0,
      created_by: String(ownerId),
      part: String(partId),
      sprint: null,
      currentSprint: null,
    },
  ];
};

export const basicIcons = [
  {
    name: "Academic",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5",
    },
  },
  {
    name: "Adjustments",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75",
    },
  },
  {
    name: "Archive",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z",
    },
  },
  {
    name: "Arrow",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3",
    },
  },
  {
    name: "Arrow Circle",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
    },
  },
  {
    name: "Arrow Points",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15",
    },
  },
  {
    name: "At Symbol",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25",
    },
  },
  {
    name: "Bank Notes",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5v-.75M3 11.25v.75a.75.75 0 0 0 .75.75h.75m-1.5-1.5V12a.75.75 0 0 1 .75.75h.75m18-9.75v.75c0 .621-.504 1.125-1.125 1.125H3.75m0 12.75c-.621 0-1.125.504-1.125 1.125v.75c0 .621.504 1.125 1.125 1.125H20.25c.621 0 1.125-.504 1.125-1.125v-.75c0-.621-.504-1.125-1.125-1.125H3.75",
    },
  },
  {
    name: "X-Circle",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
    },
  },
  {
    name: "Wrench",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z",
    },
  },
  {
    name: "Wifi",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z",
    },
  },
  {
    name: "View-Finder",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z",
    },
  },
  {
    name: "Bell",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0",
    },
  },
  {
    name: "Video-Camera",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z",
    },
  },
  {
    name: "User",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z",
    },
  },
  {
    name: "Trash",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0",
    },
  },
  {
    name: "Sun",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z",
    },
  },
  {
    name: "Star",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z",
    },
  },
  {
    name: "Square",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z",
    },
  },
  {
    name: "Mute",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z",
    },
  },
  {
    name: "Shopping Cart",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z",
    },
  },
  {
    name: "Shield",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z",
    },
  },
  {
    name: "Share",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z",
    },
  },
  {
    name: "Scissor",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m7.848 8.25 1.536.887M7.848 8.25a3 3 0 1 1-5.196-3 3 3 0 0 1 5.196 3Zm1.536.887a2.165 2.165 0 0 1 1.083 1.839c.005.351.054.695.14 1.024M9.384 9.137l2.077 1.199M7.848 15.75l1.536-.887m-1.536.887a3 3 0 1 1-5.196 3 3 3 0 0 1 5.196-3Zm1.536-.887a2.165 2.165 0 0 0 1.083-1.838c.005-.352.054-.695.14-1.025m-1.223 2.863 2.077-1.199m0-3.328a4.323 4.323 0 0 1 2.068-1.379l5.325-1.628a4.5 4.5 0 0 1 2.48-.044l.803.215-7.794 4.5m-2.882-1.664A4.33 4.33 0 0 0 10.607 12m3.736 0 7.794 4.5-.802.215a4.5 4.5 0 0 1-2.48-.043l-5.326-1.629a4.324 4.324 0 0 1-2.068-1.379M14.343 12l-2.882 1.664",
    },
  },
  {
    name: "Rocket",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z",
    },
  },
  {
    name: "Puzzle",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z",
    },
  },
  {
    name: "Power",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9",
    },
  },
  {
    name: "Play",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z",
    },
  },
  {
    name: "Camera",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z",
    },
  },
  {
    name: "Paper Clip",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13",
    },
  },
  {
    name: "Block",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636",
    },
  },
  {
    name: "Microphone",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z",
    },
  },
  {
    name: "Map",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z",
    },
  },
  {
    name: "Search",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z",
    },
  },
  {
    name: "Lock",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z",
    },
  },
  {
    name: "Key",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z",
    },
  },
  {
    name: "Like",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z",
    },
  },
  {
    name: "Filter",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z",
    },
  },
  {
    name: "Finger-Print",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33",
    },
  },
  {
    name: "Code",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5",
    },
  },
  {
    name: "Message",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    className: "size-4",
    path: {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z",
    },
  },
];
export const EnhancementStage = {
  open: ["Ideation", "Prioritised", "Deprioritised"],
  progress: ["UX Design Phase", "In development", "Testing"],
  deployed: ["Staging", "Production"],
  Inactive: ["Deprecated", "Won't do"],
};

export const RoadmapStages = {
  later: ["Ideation"],
  current: ["UX Design Phase", "In development", "Testing"],
  planned: ["Prioritised"],
};

export const ConversationStages = {
  new: ["New", "Suspended"],
  progress: ["Hold", "Await customer response"],
  completed: ["Resolved", "Archived"],
};

export const priorityData = {
  P1: {
    color: "text-red-500",
    label: "Urgent",
    id: "P1",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="size-5 text-red-500"
      >
        <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
      </svg>
    ),
  },
  P2: {
    color: "text-yellow-500",
    id: "P2",
    label: "High",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="size-5 text-yellow-500"
      >
        <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
      </svg>
    ),
  },
  P3: {
    color: "text-blue-500",
    label: "Normal",
    id: "P3",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="size-5 text-blue-500"
      >
        <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
      </svg>
    ),
  },
  P4: {
    color: "text-gray-500",
    id: "P4",
    label: "Low",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="size-5 text-gray-500"
      >
        <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
      </svg>
    ),
  },
};

export const lorem =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export const roleIcons = {
  Issues: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  "Road Maps": {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Queues: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  "Sprint Boards": {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Tags: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 6h.008v.008H6V6Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  "User Management": {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  "Organization details": {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Integrations: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.867 19.125h.008v.008h-.008v-.008Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Tickets: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Conversations: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Analytics: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  "Team Activity": {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  Components: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z"
        />
      </svg>
    ),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
};

export function timeAgo(timestamp) {
  const now = new Date();
  const timeDifference = now - new Date(timestamp); // Time difference in milliseconds
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  const pluralize = (value, unit) => {
    return `${value} ${unit}${value === 1 ? "" : "s"} ago`;
  };

  if (seconds < 60) {
    return pluralize(seconds, "second");
  } else if (minutes < 60) {
    return pluralize(minutes, "minute");
  } else if (hours < 24) {
    return pluralize(hours, "hour");
  } else if (days < 365) {
    return pluralize(days, "day");
  } else {
    return pluralize(years, "year");
  }
}

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  // Get date components
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Get time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes}${ampm}`;

  // Format as mm/dd/yyyy hh:mm(am/pm)
  return `${month}/${day}/${year} ${formattedTime}`;
}

export function removePrefix(fullString, prefix) {
  if (fullString === null) {
    return "";
  }
  if (typeof fullString === "string" && fullString.startsWith(prefix)) {
    return fullString.substring(prefix.length);
  }
  return fullString; // return the full string if the prefix doesn't match
}

export const trimString = (str, len) => {
  if (!str) {
    return "";
  }
  if (str.length > len) {
    return `${str.slice(0, len)}...`;
  } else {
    return str;
  }
};

export function convertToDateString(dateObj) {
  if (!dateObj) {
    return "";
  }
  const { day, month, year } = dateObj;

  // Ensure day and month are always two digits (pad with leading zeros if needed)
  const paddedDay = String(day).padStart(2, "0");
  const paddedMonth = String(month).padStart(2, "0");

  // Return the formatted date string
  return `${year}-${paddedMonth}-${paddedDay}`;
}

export function convertToDate(dateObj) {
  if (!dateObj) {
    return "";
  }
  const { day, month, year } = dateObj;
  return `${getMonthName(month)} ${day} ${year}`;
}

export const checkSameArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Create shallow copies and sort them
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  // Compare the sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

export function renderIcon(type) {
  switch (type) {
    case "Product":
      return (
        <RiVipDiamondLine
          className="rounded-md size-6 p-1.5"
          style={{
            color: "#006FEE",
            backgroundColor: "#dff3ff",
          }}
        />
      );
    case "Capability":
      return (
        <svg
          style={{
            color: "#7828C8",
            backgroundColor: "#E4D4F4",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="rounded-md size-6 p-1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
          />
        </svg>
      );
    case "Runnable":
      return (
        <FaPlayCircle
          style={{
            color: "#F31260",
            backgroundColor: "#F3126033",
          }}
          className="rounded-md size-6 p-1.5"
        />
      );
    case "Enhancement":
      return (
        <svg
          style={{
            color: "#C4841D",
            backgroundColor: "#F5A52433",
            // borderRadius:'1px',
            // padding:'5px'
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="rounded-md size-6 p-1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
          />
        </svg>
      );
    case "Feature":
      return (
        <svg
          style={{
            color: "#12A150",
            backgroundColor: "#17C96433",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="rounded-md size-6 p-1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
          />
        </svg>
      );
    default:
      return null;
  }
}

export const paginateArray = (arr, total, page) => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  const si = page * total;
  const ei = (page + 1) * total;
  return arr.slice(si, ei);
};

export const paginateMultipleArray = (arr1, arr2, total, page) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return [];
  }
  const si = page * total;
  const ei = (page + 1) * total - 1;
  if (ei < arr1.length) {
    return { first: arr1.slice(si, ei + 1), second: [] };
  } else if (si >= arr1.length) {
    return { first: [], second: arr2.slice(si - arr1.length, ei + 1) };
  } else if (si < arr1.length && ei >= arr1.length) {
    return {
      first: arr1.slice(si),
      second: arr2.slice(0, ei - arr1.length + 1),
    };
  } else {
    return { first: [], second: [] };
  }
};

export const disableInputs = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const inputs = tempDiv.querySelectorAll("input");
  inputs.forEach((input) => {
    input.disabled = true;
  });
  return tempDiv.innerHTML;
};

export const mockIssue = [
  {
    id: "1",
    latest: "1",
    type: "issue",
    imageIds: [],
    imagePresent: false,
    title: "Default issue 1",
    description: "<p>This is a default issue</p>",
    owner: "11",
    stage: "Queued",
    priority: "High",
    tags: [],
    assigned: "11",
    targetclosedate: "",
    estimatedays: 0,
    created_by: "11",
    part: "104",
    sprint: null,
    currentSprint: null,
  },
  {
    id: "2",
    latest: "2",
    type: "issue",
    imageIds: [],
    imagePresent: false,
    title: "Default issue 2",
    description: "<p>This is a default issue</p>",
    owner: "11",
    stage: "Queued",
    priority: "High",
    tags: [],
    assigned: "11",
    targetclosedate: "",
    estimatedays: 0,
    created_by: "11",
    part: "104",
    sprint: null,
    currentSprint: null,
  },
  {
    id: "3",
    latest: "3",
    type: "issue",
    imageIds: [],
    imagePresent: false,
    title: "Default issue 3",
    description: "<p>This is a default issue</p>",
    owner: "11",
    stage: "Queued",
    priority: "High",
    tags: [],
    assigned: "11",
    targetclosedate: "",
    estimatedays: 0,
    created_by: "11",
    part: "104",
    sprint: null,
    currentSprint: null,
  },
  {
    id: "4",
    latest: "4",
    type: "issue",
    imageIds: [],
    imagePresent: false,
    title: "Default issue 4",
    description: "<p>This is a default issue</p>",
    owner: "11",
    stage: "Queued",
    priority: "High",
    tags: [],
    assigned: "11",
    targetclosedate: "",
    estimatedays: 0,
    created_by: "11",
    part: "104",
    sprint: null,
    currentSprint: null,
  },
  {
    id: "5",
    latest: "5",
    type: "issue",
    imageIds: [],
    imagePresent: false,
    title: "Default issue 5",
    description: "<p>This is a default issue</p>",
    owner: "11",
    stage: "Queued",
    priority: "High",
    tags: [],
    assigned: "11",
    targetclosedate: "",
    estimatedays: 0,
    created_by: "11",
    part: "104",
    sprint: null,
    currentSprint: null,
  },
];

export const ConfigurationIcons = (name) => {
  let src = "";
  switch (name) {
    case "Custom Attributes":
      src = "https://cdn.lordicon.com/rguiapej.json";
      break;
    case "Blueprints":
      src = "https://cdn.lordicon.com/lyrrgrsl.json";
      break;
    case "Knowledge Base":
      src = "https://cdn.lordicon.com/jkzgajyr.json";
      break;
    case "Customers":
      src = "https://cdn.lordicon.com/kddybgok.json";
      break;
    case "Accounts":
      src = "https://cdn.lordicon.com/ciawvzjk.json";
      break;
    case "Releases":
      src = "https://cdn.lordicon.com/kikjlzqr.json";
      break;
  }
  if (src !== "") {
    return (
      <lord-icon
        src={src}
        colors={`primary: "#000000"`}
        style={{ width: "28px", height: "28px" }}
      ></lord-icon>
    );
  } else {
    return <></>;
  }
};

export const inputClassNames = {
  input: [
    "bg-white",
    "hover:bg-white",
    "dark:bg-black",
    "dark:hover:bg-[#1C1C21]",
  ],
  innerWrapper: ["bg-white", "hover:bg-white", "dark:bg-black"],
  inputWrapper: [
    "shadow-none",
    "border",
    "border-divider",
    "dark:border-[#444554]",
    "bg-white",
    "dark:bg-black",
    "hover:bg-white",
    "dark:hover:bg-[#1C1C21]",
    "group-data-[focus=true]:bg-white",
    "dark:group-data-[focus=true]:border-[#444554]",
    "dark:group-data-[focus=true]:bg-black",
    "data-[hover=true]:bg-white",
    "dark:data-[hover=true]:bg-black",
  ],
};

export const ThemeColors = [
  "#FF6B6B", // Soft Red
  "#FFD93D", // Golden Yellow
  "#6BCB77", // Fresh Green
  "#4D96FF", // Soft Blue
  "#C780FA", // Light Purple
  "#FF9F1C", // Vivid Orange
  "#2EC4B6", // Turquoise
  "#B5838D", // Muted Pink
  "#8D99AE", // Cool Gray
  "#6A0572", // Deep Purple
];
