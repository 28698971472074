import React from "react";
import Header from "./Header";
import { Divider, Tab, Tabs } from "@nextui-org/react";
import UpdateList from "./UpdateList";

const Update = () => {
  return (
    <div className="flex flex-col w-full">
      <Header />
      <Divider className="mt-8" />
      <div className="flex pt-8 w-full flex-1 flex-col gap-8 justify-center items-center">
        <Tabs size="sm">
          <Tab className="w-full" title="Unread">
            <UpdateList type={"unread"} />
          </Tab>
          <Tab className="w-full" title="Read">
          <UpdateList type={"read"} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Update;
