import {
  Avatar,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Chip
} from "@nextui-org/react";
import React, { useState, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getDocument, getMultipleFilters } from "../../../controllers/vectorDB";
import {
  DeleteSingleAttribute,
  getAllData,
  getOneData,
  UpdateData,
} from "../../../controllers/strapiController";
import { useParams } from "react-router-dom";
import { EditorProvider } from "@tiptap/react";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { MenuBar } from "../../../components/markdown/componenteditor";
import Tags from "../../../components/Tag/tags";
import { getOnedata } from "../../../controllers/APIController";
function MessageDrawer({ data, handleClose, tenant, partsData = [] }) {
  const [owners, setOwners] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [start,setStart] = useState(false);
  const [name, setName] = useState(data?.attributes.name);
  const [numTickets, setNumTickets] = useState(0);
  const [numIssues, setNumIssues] = useState(0);
  const { org } = useParams();
  const [userNames,setUserNames] = useState([]);
  const [partIds, setPartIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [tagsDetailMap, setTagsDetailMap] = useState(tagsDetail);
  const userDataId =
    JSON.parse(sessionStorage.getItem("userData"))?.user?.id || null;
  const [currentOrg, setCurrentOrg] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(new Set([]));
  const [assignedPart, setAssignedPart] = useState(new Set([]));
  const [assignedDetail, setAssignedDetail] = useState({ id: "", owner: "" });
  const [deleteInput, setDeleteInput] = useState("");
  const [users, setUsers] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [deletePart, setDeletePart] = useState(null);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [descriptionContent, setDescriptionContent] = React.useState(
    data.attributes.Description || ""
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [customTags, setCustomTags] = useState();
  const dropdownref = useRef();
  const modalref = useRef();
  const [tags, setTags] = useState(data.attributes[0] || []);

  useEffect(() => {
    if (isDrawerOpen) {
      setName(data?.attributes.name || "");
      setDescriptionContent(data?.attributes.description || "");
    }
  }, [isDrawerOpen, data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getAllData("organizations");
        setOwners(resp.data);
       
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setTagsDetailMap(tagsDetail);
  }, [tagsDetail]);
  const handleSelect = (item) => {
    if (!tagsDetailMap.includes(item)) {
      setTagsDetailMap((prev) => [...prev, item]);
    }
    if (!tagsDetail.includes(item)) {
      setTagsDetail((prev) => [...prev, item]);
    }
    if (!tags.includes(item.id)) {
      setTags((prev) => [...prev, item.id]);
    }
  };

  useEffect(() => {
    if (owners.length > 0) {
      const orgData = owners.find((item) => item.attributes.Name === org);
      if (orgData) {
        const userIds = orgData.attributes.users.data.map((user) => user.id);
        const tag = orgData.attributes.tags.data.map((user) => user.id);
        const part = orgData.attributes.parts.data.map((user) => user.id);
        setPartIds(part);
        setTagIds(tag);
        setUserIds(userIds);
        setCurrentOrg(orgData);
        
      }
    }
  }, [org, owners]);

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const promises = userIds.map((id) => getOneData("users", id));
        const results = await Promise.all(promises);
        setUsers(results);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userIds.length > 0) {
      fetchUsersData();
    }
  }, [userIds]);
  useEffect(() => {
    const userN = [];
    users.forEach((user) => {
      userN.push({
        id: user.id,
        name: user.FirstName
          ? `${user.FirstName} ${user.LastName ? user.LastName : ""}`
          : user.username,
        url: user.ProfilePicture?.formats?.small?.url || "",
      });
    });
    setUserNames(userN);
  }, [users]);

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = tagIds.map((id) => getOneData("tags", id));
        const results = await Promise.all(promises);
        let partarray = [];
        for (let i = 0; i < results.length; i++) {
          partarray.push(results[i].data);
        }
        setCustomTags(partarray);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (tagIds.length > 0) {
      getAllDataByIds();
    }
  }, [tagIds]);
  const [ownerInfo, setOwnerInfo] = useState({
    name: "",
    url: "",
    id: "",
    username: "",
  });
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const isPM = hours >= 12;
    hours = hours % 12 || 12;
    const period = isPM ? "PM" : "AM";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${hours}:${minutes} ${period} ${day}-${month}-${year}`;
  };
  const handleTickets = async () => {
    const deleteIdArray = Array.from(deleteId);
    try {
      const responses = await Promise.all(
        deleteIdArray.map((id) => getDocument("part", id, tenant))
      );
      const allTickets = responses.flat();
    
      setTickets(allTickets);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const openDeleteModal = (part) => {
    let products = 0;
    partsData.forEach((item) => {
      if (item?.attributes?.Type === "Product") {
        products = products + 1;
      }
    });
    if (part?.attributes?.Type === "Product" && products === 1) {
      toast("Cannot delete this product.", {
        duration: 4000,
        position: "bottom-right",
      });
    } else {
      setDeletePart(String(part.id));
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (deletePart === null) {
      return;
    }
    const handleOpenDeleteTag = () => {
      let arrdel = [];
      arrdel.push(deletePart);
      partsData.forEach((item) => {
        const arr = item?.attributes?.ParentPath?.split("/") || [];
        if (arr.includes(deletePart)) {
          arrdel.push(String(item?.id));
        }
      });
      setDeleteId(new Set(arrdel));
    };
    handleOpenDeleteTag();
  }, [deletePart, partsData]);
  const handleUpdate = (user) => {
    setOwnerInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
      url: user.ProfilePicture
        ? process.env.REACT_APP_STAGE === "Dev"
          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture}`
          : `${user.ProfilePicture}`
        : "",
    });
  };

  useEffect(() => {
    setAssignedDetail({ id: "", owner: "" });
    setAssignedPart(new Set([]));
    setDeleteInput("");
  }, [isOpen]);

  useEffect(() => {
    if (deleteId.size > 0) {
      handleTickets();
    }
  }, [deleteId]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const tickets = await getMultipleFilters(
          [
            { key: "type", value: "ticket" },
            { key: "part", value: String(data.id) },
          ],
          tenant
        );
        const issues = await getMultipleFilters(
          [
            { key: "type", value: "issue" },
            { key: "part", value: String(data.id) },
          ],
          tenant
        );
        setNumTickets(tickets.length);
        setNumIssues(issues.length);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };
    if (data) {
      fetchTickets();
    }
  }, [data, tenant]);

  return (
    <div className="w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white dark:bg-[#1C1C21] z-50">
      <Toaster />
      <Modal size="md" isOpen={isOpen} onOpenChange={() => setIsOpen(false)}>
        <ModalContent>
          <ModalHeader>Delete Part</ModalHeader>
          <ModalBody className="flex flex-col items-center justify-center">
            {tickets.length > 0 &&
              partsData.filter(
                (item) => !Array.from(deleteId)?.includes(String(item?.id))
              ).length > 0 && (
                <div className="flex flex-col gap-4 items-center">
                  <div className="text-xs">
                    Select part to reassign tickets and issues
                  </div>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm">
                        {Array.from(assignedPart).length === 0
                          ? "Select Part"
                          : Array.from(assignedPart)[0]}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      variant="flat"
                      disallowEmptySelection
                      selectionMode="single"
                      selectedKeys={assignedPart}
                      onSelectionChange={setAssignedPart}
                    >
                      {partsData
                        .filter(
                          (item) =>
                            !Array.from(deleteId)?.includes(String(item?.id))
                        )
                        .map((item) => (
                          <DropdownItem
                            key={item?.id}
                            onClick={() =>
                              setAssignedDetail({
                                id: String(item?.id),
                                owner: String(
                                  item?.attributes?.users_permissions_user?.data
                                    ?.id
                                ),
                              })
                            }
                          >
                            {item?.attributes?.Name}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </Dropdown>
                  <div className="text-xs">
                    {partsData.filter(
                      (item) =>
                        !Array.from(deleteId)?.includes(String(item?.id))
                    ).length > 0
                      ? `Write "DELETE" to Permanently delete the part`
                      : `Cannot delete this part`}
                  </div>
                  <Input
                    disabled={
                      !(
                        partsData.filter(
                          (item) =>
                            !Array.from(deleteId)?.includes(String(item?.id))
                        ).length > 0
                      )
                    }
                    placeholder="Type here"
                    size="md"
                    value={deleteInput}
                    onChange={(e) => setDeleteInput(e.target.value)}
                  />
                  <Button
                    disabled={
                      !(deleteInput === "DELETE" && assignedPart.size > 0)
                    }
                    onClick={() => {
                      DeleteSingleAttribute(deleteId, tenant, assignedDetail);
                      handleClose();
                      setDeletePart(null);
                      setAssignedPart(new Set([]));
                    }}
                    size="md"
                  >
                    Confirm Deletion
                  </Button>
                </div>
              )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="  h-full">
      <div>
            <div className="flex justify-between">
              <div>
                <Chip
                  size="sm"
                  variant="flat"
                  className=""
                  color={
                    data?.attributes?.Type === "Product"
                      ? "primary"
                      : data?.attributes?.Type === "Capability"
                      ? "secondary"
                      : data?.attributes?.Type === "Feature"
                      ? "success"
                      : data?.attributes?.Type === "Enhancement"
                      ? "warning"
                      : "danger"
                  }
                >
                  {data?.attributes?.Type === "Product"
                    ? "PRO"
                    : data?.attributes?.Type === "Capability"
                    ? "CAP"
                    : data?.attributes?.Type === "Feature"
                    ? "FEA"
                    : data?.attributes?.Type === "Enhancement"
                    ? "ENH"
                    : "RUN"}
                  -{data?.attributes?.latest_part}
                </Chip>
              </div>

              <div className="flex">
                <Button
                  size="sm"
                  isIconOnly
                  variant="light"
                  color="danger"
                  className="cursor-pointer"
                  onClick={() => openDeleteModal(data)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </Button>

                <Button
                  size="sm"
                  isIconOnly
                  variant="light"
                  onClick={() => handleClose()}
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </Button>
              </div>
            </div>
            <Input
              variant="bordered"
              value={data.attributes.Name}
              className="my-4 text-xs"
              style={{ fontSize: "12px" }}
              type="text"
              size="sm"
            />

            <div className="flex flex-col gap-4 mt-4  text-xs">
              <div className="flex flex-col gap-4">
                <div className=" flex     text-xs">


                  <p className="w-52 gap-2 flex">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
</svg>
                    Type</p>

                  <p
                    disabled
                    size="sm"
                    className="text-xs w-full ml-10  bg-white text-blue-500"
                  >
                    {data?.attributes?.Type}
                  </p>
                </div>
                <div className=" flex gap-6 w-full">
                  <div className="flex justify-center items-center text-center">
                    <p className=" flex w-32 gap-2  text-xs  ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                      Owner
                      <span className=" text-red-600">*</span>
                    </p>
                  </div>

                  {users.map((user) => (
                    <p onClick={() => handleUpdate(user)} key={user.id}>
                      <div className=" items-center">
                        <Avatar
                          color={Colors[Number(user.id) % numColors]}
                          style={{ width: "18px", height: "18px" }}
                          showFallback
                          radius="sm"
                          name={
                            user.FirstName
                              ? user.FirstName?.charAt(0).toUpperCase()
                              : user.username?.charAt(0).toUpperCase()
                          }
                          className="flex-shrink-0 text-xs"
                          size="sm"
                          src={
                            user.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.formats.small.url}`
                                : `${user.ProfilePicture.formats.small.url}`
                              : ""
                          }
                        />
                      </div>
                    </p>
                  ))}
                </div>
                <div className="flex w-full  gap-4 items-center">
                  <p className=" text-xs w-32 gap-2 flex">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 6h.008v.008H6V6Z"
                      />
                    </svg>
                    Tags</p>
                  <Tags
                  className="w-fit"
                  param={org}
                  org={currentOrg}
                  customTags={customTags}
                  dropdownref={dropdownref}
                  modalref={modalref}
                  tags={tags}
                  setTags={setTags}
                  tagsDetail={tagsDetail}
                  setTagsDetail={setTagsDetail}
                />
                </div>
              </div>
              <div className=" gap-4 w-64 flex text-xs">
                <p className="flex gap-2 w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
</svg>

                  Created Date</p>

                <p className="w-full "> {formatDateTime(data?.attributes?.publishedAt)}</p>
              </div>
              <div className=" gap-4 w-64 flex text-xs">
                <p className="flex gap-2 w-32">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z" />
</svg>

                  Modified Date</p>

                <p> {formatDateTime(data?.attributes?.updatedAt)}</p>
              </div>

             
            </div>

            <div className=" h-auto my-4 border rounded-lg ">
              <EditorProvider
                editor={Editor}
                extensions={[StarterKit]}
                content={descriptionContent}
                onChange={({ editor }) =>
                  setDescriptionContent(editor.getHTML())
                }
                slotBefore={
                  <MenuBar
                    descriptionContent={descriptionContent}
                    setDescriptionContent={setDescriptionContent}
                    initial={start}
                setInitial={setStart}
                  />
                }
              ></EditorProvider>
            </div>

            <p className="mt-2 ml-2 text-xs">Related</p>
            <div className=" mt-4 flex justify-between p-4">
              <div className="flex gap-4 flex-col">
                <div className=" gap-2 flex text-xs ">
                  <p className="text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
                      />
                    </svg>
                  </p>
                  Parts
                </div>

                <p className=" gap-2 flex text-xs">
                  <p className=" text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                      />
                    </svg>
                  </p>
                  {numTickets} Tickets
                </p>

                <p className="gap-2 flex text-xs">
                  <p className="  text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                      />
                    </svg>
                  </p>
                  {numIssues} Issues
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <p className=" gap-2 flex text-xs">
                  <p className="text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                  </p>
                  0 Docs
                </p>

                <p className="flex gap-2 text-xs">
                  <p className="text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                      />
                    </svg>
                  </p>
                  0 Articles
                </p>

                <p className=" flex gap-2 text-xs">
                  <p className=" text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
                      />
                    </svg>
                  </p>
                  0 Active Sprint Boards
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default MessageDrawer;