import { sendNotification } from "../controllers/middlewareController";

function extractUsersFromHtml(htmlString, userData) {
  const regex =
    /<span class="mention" data-type="mention" data-id="([^"]+)">[^<]+<\/span>/g;
  const emails = [];
  let match;

  // Loop through all matches and extract emails
  while ((match = regex.exec(htmlString)) !== null) {
    emails.push(match[1]); // match[1] contains the email from data-id
  }

  const usernameRes = {};

  userData.forEach((user) => {
    usernameRes[user.username] = {
      id: user.id,
    };
  });

  const ids = emails.map((item) => {
    if (usernameRes[item]?.id) {
      return usernameRes[item].id;
    }
  });
  const setArr = new Set(ids);
  return Array.from(setArr);
}

export const sendUpdateTickets = async (
  ownerId,
  assignedId,
  desc,
  userId,
  type,
  operation,
  userData,
  res,
  destination,
  partId,
  latest,
  orgId
) => {
  const finArr = [];
  if (type === "issue" || type === "ticket") {
    if (String(ownerId) !== String(userId)) {
      const update = {
        userId: ownerId,
        destination,
        type,
        operation,
        message: { by: userId, role: "owner", partId, latest },
        messageAlt: `${
          res[String(userId)].FirstName
            ? `${res[String(userId)].FirstName} ${
                res[String(userId)].LastName ?? ""
              }`
            : res[String(userId)].UserName
        } ${operation === "create" ? "created the" : "updated the"} ${type}`,
      };
      finArr.push(update);
    }
    if (String(assignedId) !== String(userId)) {
      const update = {
        userId: assignedId,
        destination,
        type,
        operation,
        message: { by: userId, role: "assigned", partId, latest },
        messageAlt: `${
          res[String(userId)].FirstName
            ? `${res[String(userId)].FirstName} ${
                res[String(userId)].LastName ?? ""
              }`
            : res[String(userId)].UserName
        } ${operation === "create" ? "created the" : "updated the"} ${type}`,
      };
      finArr.push(update);
    }
  }
  if (type === "part") {
    if (String(ownerId) !== String(userId)) {
      const update = {
        userId: ownerId,
        destination,
        type,
        operation,
        message: { by: userId, role: "owner", partId, latest },
        messageAlt: `${
          res[String(userId)].FirstName
            ? `${res[String(userId)].FirstName} ${
                res[String(userId)].LastName ?? ""
              }`
            : res[String(userId)].UserName
        } ${
          operation === "create" ? "created the" : "updated the"
        } ${type}-${latest}`,
      };
      finArr.push(update);
    }
  }

  const idArray = extractUsersFromHtml(desc, userData);
  idArray
    .filter((item) => String(item) !== String(userId))
    .map((id) => {
      const update = {
        userId: id,
        destination,
        type,
        operation,
        message: { by: userId, role: "mention", partId, latest },
        messageAlt: `${
          res[String(userId)].FirstName
            ? `${res[String(userId)].FirstName} ${
                res[String(userId)].LastName ?? ""
              }`
            : res[String(userId)].UserName
        } ${operation === "create" ? "created the" : "updated the"} ${type}`,
      };
      finArr.push(update);
    });

  await sendNotification(orgId, finArr);
};
