import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs} from "@nextui-org/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const pages = ["tickets","issues","components","accounts","customers","inbox"];
function CustomAttHeader() {
    const navigate = useNavigate();
    const {org} = useParams();
    const location = useLocation();
    
  return (
    <div className="flex mx-8 justify-between pt-8">
      <div className="w-full flex items-center justify-between">
        <div className="flex text-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm">
            <BreadcrumbItem onClick={() => navigate(`/${org}/settings/custom-attributes`)} >Custom Attributes</BreadcrumbItem>
            {pages.includes(location.pathname.split('/').at(-1)) &&  <BreadcrumbItem className=" capitalize" >{location.pathname.split('/').at(-1)}</BreadcrumbItem>}
          </Breadcrumbs>
        </div>
        
      </div>
    </div>
  );
}

export default CustomAttHeader;
