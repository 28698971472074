import {
  Avatar,
  AvatarGroup,
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  TableCell,
  Textarea,
} from "@nextui-org/react";
import { basicIcons, inputClassNames, trimString } from "./default";
import { Colors, getMonthName, numColors } from "./constant";
import { parseDate } from "@internationalized/date";

export const SHORT_TEXT = "SHORT_TEXT";
export const LONG_TEXT = "LONG_TEXT";
export const MARKDOWN_TEXT = "MARKDOWN_TEXT";
export const DROPDOWN = "DROPDOWN";
export const NUMBER_INPUT = "NUMBER_INPUT";
export const DATE_INPUT = "DATE_INPUT";
export const PEOPLE = "PEOPLE";
export const EMAIL = "EMAIL";

export const TypesArray = [
  SHORT_TEXT,
  LONG_TEXT,
  MARKDOWN_TEXT,
  DROPDOWN,
  NUMBER_INPUT,
  DATE_INPUT,
  PEOPLE,
  EMAIL,
];

export const attributeFields = {
  tickets: "ticket_metadata",
  issues: "issue_metadata",
  components: "component_metadata",
  inbox: "inbox_metadata",
  customers: "customer_metadata",
  accounts: "account_metadata",
};

export const generateBoilerPlate = (field) => {
  switch (field) {
    case SHORT_TEXT:
      return {
        label: "Short text",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-green-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          description: "",
          type: SHORT_TEXT,
          icon: "Message",
          required: false,
        },
      };
    case LONG_TEXT:
      return {
        label: "Long text",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-red-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          type: LONG_TEXT,
          description: "",
          icon: "Message",
          required: false,
        },
      };
    case MARKDOWN_TEXT:
      return {
        label: "Markdown text",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-blue-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          type: MARKDOWN_TEXT,
          description: "",
          icon: "Message",
          required: false,
        },
      };
    case EMAIL:
      return {
        label: "Email",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-yellow-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          description: "",
          type: EMAIL,
          icon: "Message",
          required: false,
        },
      };
    case NUMBER_INPUT:
      return {
        label: "Number",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-purple-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          description: "",
          type: NUMBER_INPUT,
          icon: "Message",
          required: false,
        },
      };
    case DATE_INPUT:
      return {
        label: "Date",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-pink-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          type: DATE_INPUT,
          description: "",
          icon: "Message",
          required: false,
        },
      };
    case DROPDOWN:
      return {
        label: "Dropdown",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-emerald-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25",
          },
        },
        boilerPlate: {
          id: "",
          name: "",
          description: "",
          type: DROPDOWN,
          required: false,
          icon: "Message",
          mode: "single",
          section: false,
          fields: "",
          sectionList: [
            {
              id: "123456",
              header: "",
              fields: "",
            },
          ],
        },
      };
    case PEOPLE:
      return {
        label: "People",
        description: "",
        icon: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          strokeWidth: 1.5,
          stroke: "currentColor",
          className: "size-4 text-orange-400",
          path: {
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z",
          },
        },
        boilerPlate: {
          id: "",
          icon: "Message",
          name: "",
          description: "",
          type: PEOPLE,
          mode: "single",
          required: false,
        },
      };
    default:
      return null;
  }
};

export const validationCheckField = (metadata, boilerPlate) => {
  const checkSameName = (metadata, name) => {
    for (let i = 0; i < metadata.length; i++) {
      if (metadata[i].name === name) {
        return false;
      }
    }
    return true;
  };
  function validateString(input) {
    const arr = input.split(",");
    const trimmedArr = arr.map((str) => str.trim());
    const isValid =
      trimmedArr.length > 0 && !trimmedArr.some((str) => str === "");
    return isValid;
  }

  if (
    boilerPlate.name === "" ||
    boilerPlate.icon === "" ||
    boilerPlate.description === "" ||
    !TypesArray.includes(boilerPlate.type) ||
    (boilerPlate.type === DROPDOWN || boilerPlate.type === PEOPLE
      ? !(boilerPlate.mode === "single" || boilerPlate.mode === "multiple")
      : false)
  ) {
    return { status: false, message: "Fields cannot be empty" };
  }
  if (!checkSameName(metadata, boilerPlate.name)) {
    return { status: false, message: "Field with same name already exists" };
  }

  if (boilerPlate.type === DROPDOWN) {
    if (boilerPlate.section === false) {
      if (!validateString(boilerPlate.fields)) {
        return {
          status: false,
          message: "Options cannot be empty inside dropdown",
        };
      }
    } else {
      if (boilerPlate.sectionList.length < 1) {
        return { status: false, message: "Add sections to dropdown" };
      }
      for (let i = 0; i < boilerPlate.sectionList.length; i++) {
        if (
          boilerPlate.sectionList[i].header === "" ||
          !validateString(boilerPlate.sectionList[i].fields)
        ) {
          return { status: false, message: "Sections are invalid" };
        }
      }
    }
  }
  return { status: true, message: "Field is valid" };
};

export const generateFilter = (arr) => {
  const filter = [];
  arr.map((item) => {
    if (item.type === DROPDOWN || item.type === PEOPLE) {
      filter.push({ id: item.id, val: [] });
    }
  });
  return filter;
};

export const generateMetadataStructure = (arr, meta) => {
  if (!meta) {
    meta = [];
  }
  arr.map((item) => {
    if (!meta.find((it) => String(it?.id) === String(item.id))) {
      if (item.type === DROPDOWN || item.type === PEOPLE) {
        meta.push({ id: item.id, val: [] });
      } else if (item.type === DATE_INPUT) {
        meta.push({ id: item.id, val: null });
      } else {
        meta.push({ id: item.id, val: "" });
      }
    }
  });
  return meta;
};

export const checkIfSame = (final, prev) => {
  if (!Array.isArray(prev)) {
    return false;
  }
  if (final.length !== prev.length) {
    return false;
  }
  const sortedFinal = final.sort((a, b) => (a.id = b.id));
  const sortedPrev = prev.sort((a, b) => (a.id = b.id));
  for (let i = 0; i < sortedFinal.length; i++) {
    if (
      sortedFinal[i].id !== sortedPrev[i].id ||
      sortedFinal[i].val !== sortedPrev[i].val
    ) {
      return false;
    }
  }
  return true;
};

export const renderCustomAttributes = (
  boilerPlate,
  metadata,
  setMetadata,
  users,
  res,
  attributesRef
) => {
  // Function to handle input change
  const handleInputChange = (id, newValue) => {
    setMetadata((prevMetaData) =>
      prevMetaData.map((item) =>
        item.id === id ? { ...item, val: newValue } : item
      )
    );
  };

  function convertToDateString(dateObj) {
    const { day, month, year } = dateObj;

    // Ensure day and month are always two digits (pad with leading zeros if needed)
    const paddedDay = String(day).padStart(2, "0");
    const paddedMonth = String(month).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${paddedMonth}-${paddedDay}`;
  }
  // Extract icon data from basicIcons
  const icon = basicIcons.find((it) => it.name === boilerPlate.icon);

  // Component for displaying label with optional required indicator
  const LabelWithIcon = ({ children }) => (
    <p
      className={` ${
        boilerPlate.type === LONG_TEXT ? "w-60" : "w-32 text-center"
      } gap-2 text-xs flex items-center`}
    >
      {icon && (
        <svg
          xmlns={icon.xmlns}
          fill={icon.fill}
          viewBox={icon.viewBox}
          strokeWidth={icon.strokeWidth}
          stroke={icon.stroke}
          className="size-4"
        >
          <path
            strokeLinecap={icon.path.strokeLinecap}
            strokeLinejoin={icon.path.strokeLinejoin}
            d={icon.path.d}
          ></path>
        </svg>
      )}
      <span className="text-xs">
        {boilerPlate?.name}{" "}
        {boilerPlate.required && <span className="text-red-400">*</span>}{" "}
      </span>
    </p>
  );


  // Extract metadata value by boilerplate id
  const getMetadataValue = () =>
    metadata.find((item) => String(item.id) === String(boilerPlate.id)).val;

  switch (boilerPlate.type) {
    case SHORT_TEXT:
      return (
        <div className="flex gap-2 items-center">
          <LabelWithIcon />
          <div className="w-40">
            <Input
              value={getMetadataValue()}
              variant="bordered"
              placeholder="Empty"
              classNames={inputClassNames}
              onChange={(e) =>
                handleInputChange(boilerPlate.id, e.target.value)
              }
              size="sm"
            />
          </div>
        </div>
      );

    case LONG_TEXT:
      return (
        <div className="flex flex-col gap-2">
          <LabelWithIcon />
          <Textarea
            className="w-full"
            classNames={inputClassNames}
            variant="bordered"
            placeholder="Empty"
            size="sm"
            value={getMetadataValue()}
            onChange={(e) => handleInputChange(boilerPlate.id, e.target.value)}
          />
        </div>
      );

    case DATE_INPUT:
      return (
        <div className="flex gap-2 items-center">
          <LabelWithIcon />
          <div className="w-40">
            <DatePicker
              variant="bordered"
              value={
                getMetadataValue()
                  ? parseDate(convertToDateString(getMetadataValue()))
                  : null
              }
              onChange={(e) => handleInputChange(boilerPlate.id, e)}
              size="sm"
            />
          </div>
        </div>
      );

    case NUMBER_INPUT:
      return (
        <div className="flex gap-2 items-center">
          <LabelWithIcon />
          <div className="w-40">
            <Input
              variant="bordered"
              placeholder="Empty"
              classNames={inputClassNames}
              value={getMetadataValue()}
              onChange={(e) =>
                handleInputChange(boilerPlate.id, e.target.value)
              }
              type="number"
              size="sm"
            />
          </div>
        </div>
      );

    case EMAIL:
      return (
        <div className="flex gap-2 items-center">
          <LabelWithIcon />
          <div className="w-40">
            <Input
              value={getMetadataValue()}
              variant="bordered"
              placeholder="Empty"
              classNames={inputClassNames}
              onChange={(e) =>
                handleInputChange(boilerPlate.id, e.target.value)
              }
              type="email"
              size="sm"
            />
          </div>
        </div>
      );

    case PEOPLE:
      return (
        <div className="flex gap-2 items-center">
          <LabelWithIcon />
          <Dropdown>
            <DropdownTrigger>
              <Button size="sm" variant="light" className="justify-start w-40">
                {getMetadataValue().length === 0 ? (
                  "Select User"
                ) : getMetadataValue().length === 1 ? (
                  <div className="flex gap-2 items-center">
                    <Avatar
                      color={
                        Colors[Number(getMetadataValue()?.[0]) % numColors]
                      }
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="sm"
                      name={
                        res &&
                        res?.[
                          getMetadataValue()?.[0]
                        ]?.UserName?.toUpperCase().charAt(0)
                      }
                      className="flex-shrink-0"
                      size="sm"
                      src={res && res?.[getMetadataValue()[0]]?.ProfilePicture}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs w-full">
                        {res &&
                          (res?.[getMetadataValue()[0]]?.FirstName ??
                            res?.[getMetadataValue()[0]]?.UserName)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <AvatarGroup>
                    {getMetadataValue().map((it) => (
                      <Avatar
                        color={Colors[Number(it) % numColors]}
                        style={{ width: "22px", height: "22px" }}
                        showFallback
                        radius="full"
                        name={
                          res && res?.[it]?.UserName?.toUpperCase().charAt(0)
                        }
                        className="flex-shrink-0"
                        size="sm"
                        src={res && res?.[it]?.ProfilePicture}
                      />
                    ))}
                  </AvatarGroup>
                )}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              ref={attributesRef}
              selectionMode={boilerPlate.mode}
              selectedKeys={new Set(getMetadataValue())}
              onSelectionChange={(e) =>
                handleInputChange(boilerPlate.id, Array.from(e))
              }
            >
              {users.map((user) => (
                <DropdownItem key={user.id}>
                  <div className="flex gap-2 items-center">
                    <Avatar
                      showfallback
                      name={user.username?.toUpperCase().charAt(0)}
                      color={Colors[Number(user.id) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      radius="sm"
                      size="sm"
                      src={user.ProfilePicture}
                    />
                    <span className="text-xs">
                      {user.FirstName || user.username}
                    </span>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      );

    case DROPDOWN:
      return (
        <div className="flex gap-2 items-center">
          <LabelWithIcon />
          <Dropdown>
            <DropdownTrigger>
              <Button size="sm" variant="light" className="justify-start w-40">
                {getMetadataValue().length > 0 ? (
                  <>
                    {getMetadataValue()[0]}{" "}
                    {getMetadataValue().length > 1 && (
                      <span className="text-gray-400 text-xs">
                        +{getMetadataValue().length - 1}
                      </span>
                    )}
                  </>
                ) : (
                  <span className="text-gray-400 text-xs">Empty</span>
                )}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              ref={attributesRef}
              selectedKeys={new Set(getMetadataValue())}
              onSelectionChange={(e) =>
                handleInputChange(boilerPlate.id, Array.from(e))
              }
              selectionMode={boilerPlate.mode}
            >
              {boilerPlate.section === false
                ? boilerPlate.fields
                    .split(",")
                    .map((field) => (
                      <DropdownItem key={field}>{field}</DropdownItem>
                    ))
                : boilerPlate.sectionList.map((section) => (
                    <DropdownSection
                      title={section.header}
                      key={section.header}
                    >
                      {section.fields.split(",").map((op) => (
                        <DropdownItem key={op}>{op}</DropdownItem>
                      ))}
                    </DropdownSection>
                  ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      );

    default:
      return null;
  }
};

export const customizeTableCell = (columnId, template, metadata, res) => {
  function removePrefix(fullString, prefix) {
    if (fullString === null) {
      return "";
    }
    if (typeof fullString === "string" && fullString.startsWith(prefix)) {
      return fullString.substring(prefix.length);
    }
    return fullString; // return the full string if the prefix doesn't match
  }
  const id = removePrefix(columnId, "metadata_");
  const boilerPlate = template?.find((item) => String(item.id) === String(id));
  const val = metadata?.find((item) => String(item.id) === String(id))?.val;
  if (!boilerPlate || !boilerPlate.type) {
    return <TableCell>-</TableCell>;
  }
  switch (boilerPlate.type) {
    case SHORT_TEXT:
      return (
        <TableCell>
          {typeof val === "string" && val.length > 0
            ? trimString(val, 20)
            : "-"}
        </TableCell>
      );
    case NUMBER_INPUT:
      return (
        <TableCell>
          {(typeof val === "string" || typeof val === "number") &&
          String(val).length > 0
            ? val
            : "-"}
        </TableCell>
      );
    case EMAIL:
      return (
        <TableCell>
          {typeof val === "string" && val.length > 0
            ? trimString(val, 20)
            : "-"}
        </TableCell>
      );
    case DATE_INPUT:
      return (
        <TableCell>
          {val
            ? `${val?.day} ${val?.month ? getMonthName(val?.month) : ""} ${
                val?.year
              } `
            : "-"}
        </TableCell>
      );
    case PEOPLE:
      return (
        <TableCell>
          {val && Array.isArray(val) && val.length > 0 ? (
            <AvatarGroup>
              {val?.map((it) => (
                <Avatar
                  color={Colors[Number(it) % numColors]}
                  style={{ width: "22px", height: "22px" }}
                  showFallback
                  radius="full"
                  name={res && res?.[it]?.UserName?.toUpperCase().charAt(0)}
                  className="flex-shrink-0"
                  size="sm"
                  src={res && res?.[it]?.ProfilePicture}
                />
              ))}
            </AvatarGroup>
          ) : (
            "-"
          )}
        </TableCell>
      );
    case DROPDOWN:
      return (
        <TableCell>
          {val && Array.isArray(val) ? (
            val?.length > 0 ? (
              <>
                {val[0]}{" "}
                {val.length > 1 && (
                  <span className="text-gray-400 text-xs">
                    +{val.length - 1}
                  </span>
                )}
              </>
            ) : (
              <span>-</span>
            )
          ) : (
            "-"
          )}
        </TableCell>
      );
    default:
      return <TableCell></TableCell>;
  }
};

export const groupingCustomAttributes = (
  groupId,
  template,
  metadata,
  group,
  row,
  setType
) => {
  function removePrefix(fullString, prefix) {
    if (fullString === null) {
      return "";
    }
    if (typeof fullString === "string" && fullString.startsWith(prefix)) {
      return fullString.substring(prefix.length);
    }
    return fullString; // return the full string if the prefix doesn't match
  }
  const id = removePrefix(groupId, "metadata_");
  const boilerPlate = template?.find((item) => String(item.id) === String(id));
  const val = metadata?.find((item) => String(item.id) === String(id))?.val;
  if (!boilerPlate || !boilerPlate.type || !val || !Array.isArray(val)) {
    return;
  }
  if (boilerPlate.type === DROPDOWN || boilerPlate.type === PEOPLE) {
    setType(boilerPlate.type);
    val?.map((item) => {
      if (!group[String(item)]) {
        group[String(item)] = [];
      }
      group[String(item)].push(row);
    });
  } else {
    return;
  }
};
