import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const Releases = () => {
  const navigate = useNavigate();
  const { org, docId } = useParams();
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <div className="flex mx-8 justify-between pt-8">
        <div className="w-full flex items-center justify-between">
          <div className="flex text-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm">
              <BreadcrumbItem onClick={() => navigate(`/${org}/releases`)}>
                Releases
              </BreadcrumbItem>
              {docId && <BreadcrumbItem>{docId}</BreadcrumbItem>}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Releases;
