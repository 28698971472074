import React, { useMemo } from "react";
import useTags from "../../hooks/useTags";
import { Chip } from "@nextui-org/react";

const TagsRenderer = ({ ids }) => {
  const tags = useTags();
  const filteredTags = useMemo(() => {
    console.log(tags);
    return tags?.filter((item) =>
      ids.includes(item.id)
    );
  }, [ids, tags]);
  return (
    <div className="w-full flex flex-wrap gap-2">
      {filteredTags.map((item) => (
        <Chip
          size="sm"
          key={item?.id}
          startContent={
            <div
              style={{ backgroundColor: item?.attributes?.Color }}
              className="w-2 h-2 rounded-full"
            ></div>
          }
          className=" bg-white dark:bg-black rounded-full border border-divider flex justify-between gap-2"
        >
          {item?.attributes?.Name}
        </Chip>
      ))}
    </div>
  );
};

export default TagsRenderer;
