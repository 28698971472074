import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { RiVipDiamondLine } from "react-icons/ri";
import { FaCog, FaPlayCircle, FaRocket, FaStar } from "react-icons/fa";
import {
  Dropdown,
  DropdownTrigger,
  DropdownItem,
  DropdownMenu,
} from "@nextui-org/react";

export function CustomNode({ data }) {
  const [hidden, setHidden] = useState(true);
  const getParentId = (item, part) => {
    if (part === "Product") {
      return "";
    } else {
      const arr = item.split("/");
      return arr[arr.length - 1];
    }
  };
  const truncateText = (text, maxLength = 20) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleToggleChild = (data) => {
    data.toggleChild({
      type: data?.item?.attributes?.Type,
      id: data?.item?.id,
      ParentPath: data?.item?.attributes?.ParentPath,
    });
  };
  const getIcon = () => {
    switch (data.item.attributes.Type) {
      case "Product":
        return (
          <RiVipDiamondLine
            className="rounded-md size-6 p-1.5"
            style={{
              color: "#006FEE",
              backgroundColor: "#E0F0FF",
            }}
          />
        );
      case "Capability":
        return (
          <svg
            style={{
              color: "#7828C8",
              backgroundColor: "#E4D4F4",
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="rounded-md size-6 p-1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
        );
      case "Runnable":
        return (
          <FaPlayCircle
            style={{
              color: "#F31260",
              backgroundColor: "#F3126033",
            }}
            className="rounded-md size-6 p-1.5"
          />
        );
      case "Enhancement":
        return (
          <svg
            style={{
              color: "#C4841D",
              backgroundColor: "#F5A52433",
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="rounded-md size-6 p-1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
            />
          </svg>
        );
      case "Feature":
        return (
          <svg
            style={{
              color: "#12A150",
              backgroundColor: "#17C96433",
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="rounded-md size-6 p-1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {data.item.attributes.Type !== "Product" && (
        <Handle
          type="target"
          position={Position.Left}
          style={{ visibility: "hidden" }}
        />
      )}
      <div className="flex flex-col w-48 cursor-default">
        <div className="pb-2 flex items-center gap-2 w-full">
          <div className="border border-divider w-[20px] flex items-center justify-center h-[20px] rounded-lg">
            <svg
              style={{ height: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </div>
          <p className="text-[.6em]">{data.item.attributes.Type}</p>
          <div
            onClick={() =>
              data.toggleCreate({
                path: data.item.attributes.ParentPath,
                type: data.item.attributes.Type,
                parent: getParentId(
                  data.item.attributes.ParentPath,
                  data.item.attributes.Type
                ),
              })
            }
            className="border border-divider w-[20px] cursor-pointer flex items-center justify-center h-[20px] rounded-lg"
          >
            <svg
              style={{ height: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </div>
          <div className="border border-divider w-[20px] flex items-center justify-center h-[20px] rounded-lg">
            <svg
              style={{ height: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
          <div className="border border-divider w-[20px] flex items-center justify-center h-[20px] rounded-lg">
            <svg
              style={{ height: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
              />
            </svg>
          </div>
          <div className="border border-divider w-[20px] flex items-center justify-center h-[20px] rounded-lg">
            <svg
              style={{ height: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </div>
        </div>
        <div className="h-16 w-full">
          <div
            onMouseLeave={() => setHidden(true)}
            className={`${hidden ? "" : "border border-divider"} rounded-lg`}
          >
            <div
              onClick={() => handleToggleChild(data)}
              onMouseEnter={() => setHidden(false)}
              className="bg-slate-50 dark:bg-black dark:border dark:border-divider cursor-pointer py-2 px-1 flex items-center rounded-lg h-10 gap-2"
            >
              <div className=" h-10 w-10  flex  items-center justify-center ">
                {getIcon()}
              </div>
              <div className="flex flex-col">
                <p className="text-xs">{data.item.attributes.Name}</p>
                <p className="text-xs">
                  {data.item.attributes.users_permissions_user.data.attributes
                    .FirstName
                    ? truncateText(
                        `${data.item.attributes.users_permissions_user.data.attributes.FirstName} ${data.item.attributes.users_permissions_user.data.attributes.LastName}`
                      )
                    : truncateText(
                        data.item.attributes.users_permissions_user.data
                          .attributes.username
                      )}
                </p>
              </div>
            </div>
            <div
              className={`flex ${
                hidden ? "hidden" : ""
              } items-start justify-between px-4 h-5`}
            >
              <div className="flex items-center">
                <Dropdown>
                  <DropdownTrigger>
                    <div className="items-start text-xs cursor-pointer">
                      Add
                    </div>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem
                      isReadOnly={!data.permissions.issue}
                      className={`${data.permissions.issue ? "" : " cursor-default text-gray-400"}`}
                      onClick={() =>
                        data.ticket({ query: "issue", part: data.item })
                      }
                      startContent={
                        <svg
                          style={{ height: "16px" }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                          />
                        </svg>
                      }
                    >
                      Create Issue
                    </DropdownItem>
                    <DropdownItem
                      isReadOnly={!data.permissions.ticket}
                      className={`${data.permissions.ticket ? "" : " cursor-default text-gray-400"}`}
                      onClick={() =>
                        data.ticket({ query: "ticket", part: data.item })
                      }
                      startContent={
                        <svg
                          style={{ height: "16px" }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                          />
                        </svg>
                      }
                    >
                      Create Ticket
                    </DropdownItem>
                    {data.item.attributes.Type === "Product" && (
                      <DropdownItem
                      className={`${data.permissions.product ? "" : " cursor-default text-gray-400"}`}
                      isReadOnly={!data.permissions.product}
                        onClick={() =>
                          data.toggleCreate({
                            path: String(data.item.id),
                            type: "Capability",
                            parent: String(data.item.id),
                          })
                        }
                        startContent={
                          <svg
                            style={{
                              color: "#7828C8",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="rounded-md size-8 p-1.5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                            />
                          </svg>
                        }
                      >
                        Create Capability
                      </DropdownItem>
                    )}
                    {(data.item.attributes.Type === "Product" ||
                      data.item.attributes.Type === "Capability") && (
                      <DropdownItem
                      className={`${data.permissions.product ? "" : " cursor-default text-gray-400"}`}
                      isReadOnly={!data.permissions.product}
                        onClick={() =>
                          data.toggleCreate({
                            path:
                              data.item.attributes.Type === "Product"
                                ? String(data.item.id)
                                : `${data.item.attributes.ParentPath}/${data.item.id}`,
                            type: "Feature",
                            parent: String(data.item.id),
                          })
                        }
                        startContent={
                          <svg
                            style={{
                              color: "#12A150",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="rounded-md size-8 p-1.5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                            />
                          </svg>
                        }
                      >
                        Create Feature
                      </DropdownItem>
                    )}
                    {(data.item.attributes.Type === "Product" ||
                      data.item.attributes.Type === "Feature") && (
                      <DropdownItem
                      className={`${data.permissions.product ? "" : " cursor-default text-gray-400"}`}
                      isReadOnly={!data.permissions.product}
                        onClick={() =>
                          data.toggleCreate({
                            path:
                              data.item.attributes.Type === "Product"
                                ? String(data.item.id)
                                : `${data.item.attributes.ParentPath}/${data.item.id}`,
                            type: "Enhancement",
                            parent: String(data.item.id),
                          })
                        }
                        startContent={ <svg
                          style={{
                            color: "#C4841D"
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="rounded-md size-8 p-1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
                          />
                        </svg>}
                      >
                        Create Enhancement
                      </DropdownItem>
                    )}
                    {(data.item.attributes.Type === "Enhancement" ||
                      data.item.attributes.Type === "Product") && (
                      <DropdownItem
                      className={`${data.permissions.product ? "" : " cursor-default text-gray-400"}`}
                      isReadOnly={!data.permissions.product}
                        onClick={() =>
                          data.toggleCreate({
                            path:
                              data.item.attributes.Type === "Product"
                                ? String(data.item.id)
                                : `${data.item.attributes.ParentPath}/${data.item.id}`,
                            type: "Runnable",
                            parent: String(data.item.id),
                          })
                        }
                        startContent={
                          <FaPlayCircle
            style={{
              color: "#F31260",
            }}
            className="rounded-md size-8 p-1.5"
          />
                        }
                      >
                        Create Runnable
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                <svg
                  style={{ height: "14px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </div>
              <div
                onClick={() => data.toggle(data.item)}
                className="h-full cursor-pointer flex items-start text-xs"
              >
                View
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.item.attributes.Type !== "Runnable" && (
        <Handle
          type="source"
          position={Position.Right}
          style={{ visibility: "hidden" }}
        />
      )}
    </>
  );
}
