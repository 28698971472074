// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mydiv1 ul {
  padding-left: 16px; /* For ordered lists */
  list-style-type: disc; /* For unordered lists */
}

.mydiv1 ol {
  padding-left: 16px; /* For ordered lists */
  list-style-type: decimal; /* For unordered lists */
}

.mydiv1 .mention {
  background-color: #F4F4F5;
  border-radius: 0.4rem;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  color: purple;
  padding: 0.1rem 0.3rem;
}

.mydiv1 ul[data-type="taskList"] {
  list-style: none;
  margin-left: 0;
  padding: 0;


  li {
    align-items: center;
    display: flex;

    > label {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      margin-right: 0.5rem;
      -webkit-user-select: none;
              user-select: none;
      > input {
        cursor: default;
      }
    }

    > div {
      flex: 1 1 auto;
    }
  }

  

  input[type="checkbox"] {
    margin-top: 3px;
  }

  ul[data-type="taskList"] {
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Tickets/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EACK,sBAAsB;EAC7C,qBAAqB,EAAE,wBAAwB;AACjD;;AAEA;EACE,kBAAkB,EACQ,sBAAsB;EAChD,wBAAwB,EAAE,wBAAwB;AACpD;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,mCAA2B;UAA3B,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,UAAU;;;EAGV;IACE,mBAAmB;IACnB,aAAa;;IAEb;MACE,mBAAmB;MACnB,aAAa;MACb,cAAc;MACd,oBAAoB;MACpB,yBAAiB;cAAjB,iBAAiB;MACjB;QACE,eAAe;MACjB;IACF;;IAEA;MACE,cAAc;IAChB;EACF;;;;EAIA;IACE,eAAe;EACjB;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".mydiv1 ul {\n  padding-left: 16px;\n  list-style-type: disc; /* For ordered lists */\n  list-style-type: disc; /* For unordered lists */\n}\n\n.mydiv1 ol {\n  padding-left: 16px;\n  list-style-type: decimal; /* For ordered lists */\n  list-style-type: decimal; /* For unordered lists */\n}\n\n.mydiv1 .mention {\n  background-color: #F4F4F5;\n  border-radius: 0.4rem;\n  box-decoration-break: clone;\n  color: purple;\n  padding: 0.1rem 0.3rem;\n}\n\n.mydiv1 ul[data-type=\"taskList\"] {\n  list-style: none;\n  margin-left: 0;\n  padding: 0;\n\n\n  li {\n    align-items: center;\n    display: flex;\n\n    > label {\n      align-items: center;\n      display: flex;\n      flex: 0 0 auto;\n      margin-right: 0.5rem;\n      user-select: none;\n      > input {\n        cursor: default;\n      }\n    }\n\n    > div {\n      flex: 1 1 auto;\n    }\n  }\n\n  \n\n  input[type=\"checkbox\"] {\n    margin-top: 3px;\n  }\n\n  ul[data-type=\"taskList\"] {\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
