import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Input, Image, Card, CardHeader, Divider, CardBody, CardFooter, Link, Avatar } from '@nextui-org/react';
import SearchIcon from '../../Images/Search';
import { RightArrow } from '../../Images/RightArrow';
import { getOneData } from '../../controllers/strapiController';
import AdditionalOptions from './Additional';
import TableRender from './TableRenderer';
import { useSelector } from 'react-redux';
import { selectResData } from '../../redux/actions';
const Resolv = () => {
  // STATE INITIALIZATION
  const [chatMessages, setChatMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [FinalResult, setFinalResult] = useState([]);
  const [UserData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const responseTable = [];
  const ref = useRef(null);
  const res = useSelector(selectResData)
  const UserInfo = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserInfo && UserInfo.user.id;
  const [visibleOptions, setVisibleOptions] = useState(Array(chatMessages.length).fill(false));

  // MOUSE OVER TO SHOW COPY,THUMBSUP OPTIONS
  const handleMouseEnter = (index) => {
    setVisibleOptions(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  // MOUSE LEAVE TO HIDE COPY,THUMBSUP OPTIONS
  const handleMouseLeave = (index) => {
    setVisibleOptions(prevState => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  function apphend_table(props) {
    responseTable.push(props);
    // console.log("responseTable", responseTable)
  }


  // API RESPONSE
  useEffect(() => {
    getOneData("users", UserId)
      .then((data) => setUserData(data))
      .catch((error) => error);
  }, [UserId]);


  // "ENTER" KEY TO SEARCH 
  const handleKeyPress = async (event, data) => {
    setLoading(true);


    if (event.key === 'Enter' && inputValue.trim() !== '') {

      const Value = inputValue.trim();
      setChatMessages([...chatMessages, Value]);
      setFinalResult([...FinalResult, { Question: Value, response: { searchResult: "", similarQuestions: [], documents: [] } }])
      setInputValue('');

      const socket = new WebSocket(`${process.env.REACT_APP_WS_AI_SERVER}/testing/ws/stream`);
      socket.onopen = () => {
        socket.send(JSON.stringify({ app_id: "scrum", query: Value, collection_name: "pdf" }));
      };

      socket.onmessage = (event) => {
        setFinalResult((prevResults) => {
          const lastResult = prevResults[prevResults.length - 1];
          if (lastResult && lastResult.Question === Value) {

            const updatedResult = {
              ...lastResult,
              response: {
                ...lastResult.response,
                searchResult: lastResult.response.searchResult + event.data
              }
            };

            return [
              ...prevResults.slice(0, prevResults.length - 1),
              updatedResult
            ];
          } else {
            const newResult = { Question: Value, response: { searchResult: event.data, similarQuestions: [], documents: [] } };
            console.log("newResults", newResult)
            return [...prevResults, newResult];
          }
        });
        setLoading(false);
        
      };

      socket.onclose = () => {
      };

      socket.onerror = (error) => {
        setLoading(false);
        setFinalResult((prevResults) => {
          const lastResult = prevResults[prevResults.length - 1];

          if (lastResult && lastResult.Question === Value) {
            const updatedResult = {
              ...lastResult,
              response: {
                ...lastResult.response,
                searchResult: lastResult.response.searchResult + event.data
              }
            };

            return [
              ...prevResults.slice(0, prevResults.length - 1),
              updatedResult
            ];
          } else {
            const newResult = { Question: Value, response: { searchResult: "Server issue. Cannot connect to Resolv.", similarQuestions: [], documents: [] } };
            return [...prevResults, newResult];
          }
        });
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      };
    } else if (data) {
      setChatMessages([...chatMessages, data]);
      setInputValue('');
      setLoading(true);

      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  // STRING SPLIT FUNCTION
  const renderBoldText = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split(boldRegex).map((part, index) => {
      if (index % 2 === 0) {
        return <span key={index}>{part}</span>;
      } else {
        return <strong key={index}>{part}</strong>;
      }
    });
  };

  const cardClick = (link) => {
    window.open(link, '_blank');
  };

  const Colors = ["secondary", "success", "warning", "danger", "primary"];
    const numColors = Colors.length;
  // RETURN UI

  console.log("chatMessages", chatMessages);
  console.log("FinalResult", FinalResult)
  return (
    <div className='flex flex-col justify-between h-[calc(100vh-170px)] overflow-y-hidden scroll-smooth'>
      {chatMessages && chatMessages.length > 0 ? <>
        <div className='overflow-y-auto scroll-smooth'>
          {chatMessages && chatMessages.map((data, index) => {
            const USerProfiled = UserData && UserData.Picture !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${UserData && UserData.Picture && UserData.Picture.url}` : `${UserData?.Picture?.url}`}` : "";
            return (
              <React.Fragment key={`${index}`}>
                <div className={index === chatMessages.length - 1 ? `mb-2 t-20` : "mb-2"}>
                  <div className='flex flex-col'>
                    <div className='flex flex-row gap-4 items-center'>
                      <Avatar size='sm' src={res && res[String(UserId)]?.ProfilePicture} showFallback color={Colors[Number(UserId) % numColors] } name={res && res[String(UserId)]?.UserName.toUpperCase().charAt(0)} />
                      <p className='font-semibold'>You</p>
                    </div>
                    <p className='pl-12'>{data}{loading && index === chatMessages.length - 1 && <span className="cursor-dot"></span>}</p>
                  </div>
                </div>
                <div onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)} className='mb-8 relative' ref={index === chatMessages.length - 1 ? ref : null}>
                  <div className='flex flex-row gap-4 items-center pt-10'>
                    <Avatar src={"https://avatars.githubusercontent.com/u/170258109?s=200&v=4"} size="sm" />
                    <p className='font-semibold'>Resolv</p>
                  </div>
                  {FinalResult[index] && FinalResult[index].response &&
                    <>
                      {!FinalResult[index].response.searchResult && FinalResult[index].response.documents.length === 0 && !loading && (
                        <div className="flex justify-center my-4">
                          <h4 className='font-bold'>Interact with your issues, tickets, conversations and more...</h4>
                        </div>
                      )}
                      {FinalResult[index].response.searchResult && (
                        <div className={`mb-2 ${loading ? '' : 'fade-in'}`}>
                          <div className='flex items-center gap-4'>
                            <Image
                              width={30}
                              alt="Search Icon"
                              src={SearchIcon}
                              className="text-large text-default-400 pointer-events-none flex-shrink-0"
                            />
                            <div>
                              {FinalResult[index].response.searchResult.split('\n').map((part, index) => {
                                const tablepart = part.trim().startsWith('|');
                                if (part.trim().startsWith('-')) {
                                  return (
                                    <p key={`${index}`} className='leading-loose'>
                                      &bull; {renderBoldText(part.substring(1))}
                                      <br />
                                    </p>
                                  );
                                } else if (tablepart) {
                                  if (part.trim().includes('----')) {
                                    return (
                                      responseTable && (
                                        <>
                                          <div className='max-h-100px overflow-y-auto'>
                                            <TableRender markdownTable={responseTable} />
                                          </div>
                                        </>
                                      )
                                    );
                                  } else {
                                    const appendedPart = part.trim() + '\n';
                                    apphend_table(appendedPart);
                                    return null;
                                  }
                                } else {
                                  return (
                                    <p key={index} className='leading-loose'>
                                      {renderBoldText(part)}
                                      <br />
                                    </p>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      {FinalResult[index].response.documents && FinalResult[index].response.documents.length > 0 && (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-2 pt-8 px-4">
                          {FinalResult[index].response.documents.map((document, index) => (
                            <div key={`${index}`} onClick={() => cardClick(document.payload.document_url)}>
                              <Card className="max-w-[400px] border border-divider" shadow='none'>
                                <CardHeader className="flex gap-3">
                                  <Image
                                    alt="NextUI hero Image"
                                    width={40}
                                    height={40}
                                    src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                    radius="sm"
                                  />
                                  <div className="flex flex-col">
                                    <p className="text-md">{document.payload.document_title}</p>
                                    <p className="text-small text-default-500">{document.payload.document_url}</p>
                                  </div>
                                </CardHeader>
                                <Divider />
                                <CardBody className="max-h-[100px] overflow-hidden">
                                  <p>{document.payload.description.substring(0, 80)}{document.payload.description.length > 80 && '...'}</p>
                                </CardBody>
                                <Divider />
                                <CardFooter>
                                  <Link isExternal showAnchorIcon href={document.payload.document_url}>
                                    Visit source
                                  </Link>
                                </CardFooter>
                              </Card>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  }
                  <div className='h-6'>
                    {visibleOptions?.[index] && <AdditionalOptions data={FinalResult?.[index]} text={FinalResult?.[index]?.response?.searchResult} />}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          {loading && (
            <div className="flex justify-center my-4">
              <CircularProgress color="danger" size='lg' aria-label="Loading..." />
            </div>
          )}
        </div>
      </>
        :
        <>
          <div className="flex flex-col gap-4 justify-center items-center h-full">
            <lord-icon
              src="https://cdn.lordicon.com/zyzoecaw.json"
              trigger="hover"
              state="morph-book"
              target="#Knowledges"
              colors={`primary:#000000`}
              style={{ width: "32px", height: "32px" }}>
            </lord-icon>
            <p id="Knowledges">Interact with your issues, tickets, conversations and more...</p>
          </div>
        </>}
      <div>
        <Input
            isDisabled={loading}
          startContent={<Image
            src={"https://avatars.githubusercontent.com/u/170258109?s=200&v=4"}
            width={20}
            radius='sm'
            className="text-large text-default-400 pointer-events-none flex-shrink-0 " />}
          endContent={<RightArrow />}
          placeholder='Message Resolv...'
          variant='outline'
          value={inputValue}
          onChange={(event) => { setInputValue(event.target.value) }}
        //   onKeyPress={(event) => {
        //     if (event.key === 'Enter') {
        //       handleKeyPress(event);
        //     }
        //   }}
        />
      </div>
    </div>
  );
};

export default Resolv;