import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  TableCell,
} from "@nextui-org/react";
import { convertToDateString, trimString } from "../../utils/default";
import { Colors, numColors } from "../../utils/constant";

export const renderReleaseCell = (columnId, item, handleDelete, res) => {
  switch (columnId) {
    case "title":
      return <TableCell>{trimString(item.payload.title, 30)}</TableCell>;
    case "releaseDate":
      return (
        <TableCell>{convertToDateString(item.payload.releaseDate)}</TableCell>
      );
    case "owner":
      return (
        <TableCell>
          <Avatar
            showFallback
            name={
              res &&
              res[item.payload.owner] &&
              (res[item.payload.owner].FirstName
                ? `${res[item.payload.owner].FirstName?.charAt(0)}`
                : `${res[item.payload.owner].UserName?.charAt(
                    0
                  ).toUpperCase()}`)
            }
            radius="sm"
            color={Colors[Number(item.payload.owner) % numColors]}
            size="sm"
            src={
              res?.[item.payload.owner]?.ProfilePicture
                ? `${res[item.payload.owner].ProfilePicture}`
                : null
            }
          />
        </TableCell>
      );
    case "delete":
      return (
        <TableCell className="flex justify-end">
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Button size="sm" color="danger" variant="light" isIconOnly>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </Button>
            </DropdownTrigger>
            <DropdownMenu size="sm">
              <DropdownItem
                onClick={() => handleDelete(item.payload.id)}
                variant="flat"
                color="danger"
              >
                Delete Permanently
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </TableCell>
      );

    default:
      return <TableCell></TableCell>;
  }
};
