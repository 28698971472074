import { Button, Card, CardBody, Chip } from "@nextui-org/react";
import React from "react";

function Tickets({ tickets,ticketIds,setTicketIds,handleUpdate }) {
    const handleRemove = async(id) => {
        try{
            const arr = ticketIds.filter((item) => Number(item.id)!==Number(id));
            await handleUpdate({tickets:arr});
            setTicketIds(arr);
            
        }
        catch(error){

        }
    }
  return (
    <div className=" flex flex-col gap-4 w-full">
      {tickets.map((t, index) => {
        if (t.length > 0 && t[0].payload.type === "ticket") {
          return (
            <Card
              key={index}
              fullWidth
              className="border border-divider"
              isPressable
              shadow="none"
            >
              <CardBody className=" flex flex-col gap-4">
                <div className=" flex items-center justify-between">
                  <div className=" flex items-center gap-4">
                    <Chip variant="flat" size="sm" color="danger">
                      TKT-{t[0].payload.latest}
                    </Chip>
                    <p className="text-sm">{t[0].payload.title}</p>
                  </div>
                  <Button
                    onClick={() => handleRemove(t[0].payload.id)}
                    isIconOnly
                    variant="light"
                    color="danger"
                    size="sm"
                    
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                  </Button>
                </div>

                <div className=" text-xs font-light">
                  {t[0].payload.description
                    .replace(/<[^>]*>/g, "")
                    .slice(0, 25)}{" "}
                </div>
              </CardBody>
            </Card>
          );
        }
      })}
    </div>
  );
}

export default Tickets;
