import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import CustomerDrawer from "./CustomerDrawer";
import { useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  getFilteredBySingleRelation,
  getOneData,
} from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import {
  selectOrgData,
  selectResData,
  selectUserNames,
} from "../../redux/actions";
import HelixLoader from "../../components/Loader/HelixLoader";
import {
  customizeTableCell,
  DROPDOWN,
  generateFilter,
  groupingCustomAttributes,
  LONG_TEXT,
  MARKDOWN_TEXT,
  PEOPLE,
} from "../../utils/customAttributes";
import FilterPeople from "../../components/Filters/FilterPeople";
import FilterDropdown from "../../components/Filters/Filter";
import { paginateArray, removePrefix } from "../../utils/default";
import { renderCustomerCell } from "./renderCustomer";
import CustomerGroup from "./CustomerGroup";
import Icon from "../../components/Tag/Icon";

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTicket, setSearchTicket] = useState("");
  const drawerRef = useRef();
  const [customerTemp, setCustomerTemp] = useState([]);
  const customerRef = useRef();
  const [loader, setLoader] = useState(true);
  const [customTags, setCustomTags] = useState([]);
  const userNames = useSelector(selectUserNames);
  const [grouping, setGrouping] = useState(new Set(["None"]));
  const res = useSelector(selectResData);
  const [group, setGroup] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const [page,setPage] = useState(1);
  const tagsref = useRef();
  const customAttributeRef = useRef();
  const modalref = useRef();
  const searchRef = useRef();
  const inputRef = useRef();
  const { org } = useParams();
  const [accounts, setAccounts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [updateDrawer, setUpdateDrawer] = useState(false);
  const orgData = useSelector(selectOrgData);
  const [customize, setCustomize] = useState(new Set([]));
  const coulmnDef = Array.from(customize).map((name) => {
    if (name === "phone") {
      return {
        id: name,
        label: "Phone Number",
      };
    } else {
      return {
        id: name,
        label: name,
      };
    }
  });
  const columns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "account",
      label: "Account",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleDelete = async (id) => {
    try {
      await DeleteSingleAttribute("customers", id);
      setAccounts((prev) => prev.filter((it) => String(it.id) !== String(id)));
    } catch (error) {}
  };
  const [filterDropdown, setFilterDropdowm] = useState(new Set([]));
  const [filterArr, setFilterArr] = useState([]);
  const handleClearFilters = () => {
    setSearchTicket("");
    setFilterArr((prev) => prev.map((fil) => ({ ...fil, val: [] })));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    if (
      Array.from(grouping).length > 0 &&
      Array.from(grouping)[0] !== "None" &&
      Array.from(grouping)[0].startsWith("metadata_")
    ) {
      const finalGroup = {};
      filteredCustomers.map((item) => {
        groupingCustomAttributes(
          Array.from(grouping)[0],
          customerTemp,
          item.attributes.metadata,
          finalGroup,
          item,
          setGroupType
        );
      });
      setGroup(finalGroup);
    }
  }, [grouping]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customerRef.current &&
        !customerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!customAttributeRef.current || !customAttributeRef.current.contains(event.target))
      ) {
        setUpdateDrawer(false);
      }
    };
    if (updateDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [updateDrawer]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        const customers = await getFilteredBySingleRelation(
          "customers",
          "organization",
          orgData.id
        );
        const accounts = await getFilteredBySingleRelation(
          "accounts",
          "organization",
          orgData.id,
          ["Website", "Name"]
        );
        const template = await getOneData("organizations", orgData.id, [
          "customer_metadata",
        ]);
        setCustomerTemp(template.data.attributes.customer_metadata ?? []);
        setFilterArr(
          generateFilter(template.data.attributes.customer_metadata ?? [])
        );
        setCustomers(customers.data);
        setCustomTags(tags.data);
        setAccounts(accounts.data);
        setLoader(false);
      } catch (error) {}
    };
    if (orgData?.id) {
      fetchData();
    }
  }, [orgData]);
  useEffect(() => {
    const filterCustomers = () => {
      let filterCustomers = customers;
      if (searchTicket) {
        const lowerCaseSearchTicket = searchTicket.toLowerCase();
        filterCustomers = customers.filter((customer) => {
          const name = customer.attributes.Name
            ? customer.attributes.Name.toLowerCase()
            : "";
          const email = customer.attributes.Email
            ? customer.attributes.Email.toLowerCase()
            : "";
          const phoneNumber = customer.attributes.PhoneNumber
            ? customer.attributes.PhoneNumber.toLowerCase()
            : "";

          return (
            name.includes(lowerCaseSearchTicket) ||
            email.includes(lowerCaseSearchTicket) ||
            phoneNumber.includes(lowerCaseSearchTicket)
          );
        });
      }
      filterArr.map((item) => {
        if (item?.val?.length > 0) {
          filterCustomers = filterCustomers.filter((t) => {
            if (t.attributes.metadata && Array.isArray(t.attributes.metadata)) {
              const find = t.attributes.metadata.find(
                (field) => String(field.id) === String(item.id)
              );
              if (find) {
                return find?.val?.some((m) => item.val.includes(m));
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      });
      setFilteredCustomers(filterCustomers);
    };

    filterCustomers();
  }, [searchTicket, customers, filterArr]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
      </div>
    );
  }
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full" >
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <CustomerDrawer
            customAttributeRef={customAttributeRef}
            template={customerTemp}
            userNames={userNames}
            customTags={customTags}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={toggleDrawer}
            type="create"
            accounts={accounts}
          />
        </div>
      )}
      {updateDrawer && (
        <div ref={customerRef}>
          <CustomerDrawer
            customAttributeRef={customAttributeRef}
            template={customerTemp}
            data={selectedCustomer}
            userNames={userNames}
            customTags={customTags}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={() => setUpdateDrawer(false)}
            type="update"
            accounts={accounts}
          />
        </div>
      )}
      <div className="">
        <div className="flex mx-8 justify-between pt-8">
          <div className="flex text-center justify-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm" isDisabled>
              <BreadcrumbItem>Customers</BreadcrumbItem>
            </Breadcrumbs>
          </div>

          <div className="flex items-center">
            <Button
              size="sm"
              className="mx-2 text-white bg-[rgb(51,51,245)]"
              onClick={toggleDrawer}
            >
              + Customer
            </Button>
          </div>
        </div>
        <div className="z-0 flex pb-4 px-8 border-b-1 mt-4 border-divider items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 dark:bg-[#121212] ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={searchTicket}
                  onChange={(e) => setSearchTicket(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
            {customerTemp
              .filter((a) => Array.from(filterDropdown).includes(String(a.id)))
              .map((item) => {
                if (item.type === PEOPLE) {
                  return (
                    <FilterPeople
                      filterArr={filterArr}
                      setFilterArr={setFilterArr}
                      boilerPlate={item}
                    />
                  );
                } else if (item.type === DROPDOWN) {
                  return (
                    <FilterDropdown
                      filterArr={filterArr}
                      setFilterArr={setFilterArr}
                      boilerPlate={item}
                    />
                  );
                }
              })}
            {customerTemp.filter(
              (a) => a.type === DROPDOWN || a.type === PEOPLE
            ).length > 0 && (
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className=" border border-divider"
                    isIconOnly
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Multiple selection"
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  disallowEmptySelection={false}
                  selectedKeys={filterDropdown}
                  onSelectionChange={setFilterDropdowm}
                >
                  {customerTemp
                    .filter((a) => a.type === DROPDOWN || a.type === PEOPLE)
                    .map((it) => (
                      <DropdownItem key={String(it.id)}>{it.name}</DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
            )}
            {customerTemp.filter(
              (a) => a.type === DROPDOWN || a.type === PEOPLE
            ).length > 0 && (
              <Button
                onClick={() => handleClearFilters()}
                size="sm"
                variant="light"
              >
                Clear
              </Button>
            )}
          </div>
          <div className="flex items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  Group
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                disallowEmptySelection
                closeOnSelect={false}
                selectionMode="single"
                selectedKeys={grouping}
                onSelectionChange={setGrouping}
              >
                <DropdownItem key="None">None</DropdownItem>
                {customerTemp
                  .filter(
                    (item) => item.type === DROPDOWN || item.type === PEOPLE
                  )
                  .map((it) => (
                    <DropdownItem key={`metadata_${it.id}`}>
                      {it.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  Customize
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                selectedKeys={customize}
                onSelectionChange={setCustomize}
              >
                <DropdownItem key={"phone"}>Phone Number</DropdownItem>
                {customerTemp
                  .filter(
                    (item) =>
                      item.type !== MARKDOWN_TEXT && item.type !== LONG_TEXT
                  )
                  .map((it) => (
                    <DropdownItem key={`metadata_${it.id}`}>
                      {it.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      {filteredCustomers.length === 0 ? (
          <div className="flex-1 flex flex-col h-full justify-center items-center">
            <Icon/>
            <p className="text-gray-500">No customers found...</p>
          </div>
        ) : (
          <div className="flex-1 w-full overflow-y-auto">
            {Array.from(grouping).length === 0 ||
            Array.from(grouping)[0] === "None" ? (
              <>
              <Table className="pt-8 px-8" removeWrapper>
                <TableHeader columns={columns}>
                  {(column) => (
                    <TableColumn key={column.id} className="text-xs">
                      {column.id.startsWith("metadata_")
                        ? customerTemp?.find(
                            (it) =>
                              it.id === removePrefix(column.id, "metadata_")
                          )?.name ?? ""
                        : column.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody>
                  {paginateArray(filteredCustomers,10,page-1)?.map((customer) => {
                    return (
                      <TableRow
                        onClick={() => {
                          setSelectedCustomer(customer);
                          setUpdateDrawer(true);
                        }}
                        key={customer.id}
                        className="cursor-pointer text-xs rounded-lg hover:bg-gray-100 dark:hover:bg-[#2D2D32] border-b border-divider "
                      >
                        {columns.map((column) => {
                          if (column.id.startsWith("metadata_")) {
                            return customizeTableCell(
                              column.id,
                              customerTemp,
                              customer.attributes.metadata,
                              res
                            );
                          } else {
                            return renderCustomerCell(
                              column.id,
                              customer,
                              handleDelete
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <div className="flex items-center justify-center w-full" >
              <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="danger"
                  page={page}
                  total={Math.ceil(filteredCustomers?.length/10)}
                  onChange={setPage}
                  className="mt-4"
                />
            
              </div>
              </>
            ) : Array.from(grouping)[0].startsWith("metadata_") ? (
              <CustomerGroup
                group={group}
                type={groupType}
                columns={columns}
                handleDelete={handleDelete}
                customerTemp={customerTemp}
                setSelectedCustomer={setSelectedCustomer}
                setUpdateDrawer={setUpdateDrawer}
              />
            ) : (
              <></>
            )}
          </div>
        )}
    </div>
  );
}

export default Customers;
