import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getMultipleFilters } from '../../../controllers/vectorDB';

function IssueChips({sprintId}) {
    const {org} = useParams();
    const [issues,setIssues] = useState([]);
    useEffect(() => {
        const fetchIssues = async () => {
          try {
            const issue = await getMultipleFilters(
              [
                { key: "type", value: "issue" },
                { key: "sprint", value: String(sprintId) },
              ],
              org
            );
            setIssues(issue);
            console.log("adahvdajda",issue)
          } catch (error) {
            console.error("Error fetching documents:", error);
          }
        };
        if (sprintId && org) {
          fetchIssues();
        }
      }, [sprintId, org]);
  return (
    <div className=' flex items-center gap-1'>
      {issues.length}
        {/* {issues.slice(0,3).map((item) => (
            <Chip size='sm' variant='flat' color='primary'>ISS-{item.payload.latest}</Chip>
        ))}
        {issues.length >3 && 
        <p>+{issues.length-3}</p>} */}
    </div>
  )
}

export default IssueChips
