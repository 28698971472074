import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { Avatar, Chip, Divider } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { disableInputs, formatTimestamp } from "../../utils/default";

function Chat({ convo, customerId, temp, tickets, emailRes }) {
  const messagesEndRef = useRef(null);
  const [finalConvo, setFinalConvo] = useState([]);
  const { org } = useParams();
  
  // Helper functions
  function removePrefix(fullString, prefix) {
    if (!fullString) return "";
    return fullString.startsWith(prefix) ? fullString.substring(prefix.length) : fullString;
  }

  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;

  useEffect(() => {
    const arr = [...convo, ...tickets];
    setFinalConvo(arr);
  }, [convo, tickets]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [finalConvo, temp]);

  return (
    <div className="w-full h-full px-4 flex flex-col overflow-y-auto gap-[2px]">
      {finalConvo
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((item, index) => (
          <div key={index} className="w-full flex flex-col items-start gap-[2px]">
            {item.senderId ? (
              <>
                {(index === 0 || finalConvo[index - 1]?.senderId !== item?.senderId) && (
                  <div className="flex flex-col w-full">
                    {index !== 0 && <Divider className="w-full my-4" />}
                    <div className="flex items-center gap-2">
                      <Avatar
                        size="sm"
                        src={emailRes?.[removePrefix(item.senderId, `${org}_`)]?.ProfilePicture}
                        color={item.senderId === customerId
                          ? Colors[removePrefix(item.senderId, `${org}_`).length % numColors]
                          : Colors[emailRes?.[removePrefix(item.senderId, `${org}_`)]?.Id % numColors]
                        }
                        showFallback
                        name={removePrefix(item.senderId, `${org}_`)?.toUpperCase()?.charAt(0)}
                      />
                      <p className="font-semibold text-lg">
                        {emailRes?.[removePrefix(item.senderId, `${org}_`)]?.FirstName ??
                          removePrefix(item.senderId, `${org}_`)}
                      </p>
                    </div>
                  </div>
                )}
                <div className="group hover:bg-gray-100 dark:hover:bg-[#2D2D32] w-full rounded-lg p-1 flex justify-between">
                  <div className="mydiv w-[65%] ml-10"
                    dangerouslySetInnerHTML={{ __html: disableInputs(item.text) }}
                  ></div>
                  <p className="text-xs pt-1 pr-2 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400">
                    {formatTimestamp(item?.timestamp)}
                  </p>
                </div>
              </>
            ) : item.latest ? (
              <>
                {finalConvo?.[index - 1]?.senderId && <Divider className="w-full my-4" />}
                <div className="w-full flex flex-col items-start gap-2 py-2">
                  <div className="flex items-center gap-2">
                    <Avatar size="sm" src="https://avatars.githubusercontent.com/u/170258109?s=200&v=4" />
                    <p className="font-semibold text-lg">Resolv Bot</p>
                  </div>
                  <div className="w-full p-1 hover:bg-gray-100 dark:hover:bg-[#2D2D32] rounded-lg group flex items-center justify-between">
                    <div className="flex items-center gap-2 ml-10">
                      <Chip size="sm" variant="flat" color="danger">TKT-{item?.latest}</Chip>
                      <p className="text-sm">{item?.title}</p>
                    </div>
                    <p className="text-xs pt-1 pr-2 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400">
                      {formatTimestamp(item?.timestamp)}
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        ))}
      
      {temp.map((item, index) => (
        <div key={index} className="w-full flex justify-start">
          <div className="hover:bg-gray-100 dark:hover:bg-[#2D2D32] w-full rounded-lg p-1 flex flex-col">
            <div className="mydiv ml-10" dangerouslySetInnerHTML={{ __html: disableInputs(item.text) }}></div>
          </div>
        </div>
      ))}
      
      {/* Invisible div to scroll to the end */}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default Chat;
