import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs} from "@nextui-org/react";
import {  useNavigate, useParams } from "react-router-dom";

export const pages = ["tickets","issues","components","accounts","customers","inbox"];
function TicketHeader() {
    const navigate = useNavigate();
    const {ticketId,org} = useParams();
  return (
    <div className="flex mx-8 justify-between py-8">
      <div className="w-full flex items-center justify-between">
        <div className="flex text-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm">
            <BreadcrumbItem onClick={() => navigate(`/${org}/tickets`)} >Tickets</BreadcrumbItem>
            <BreadcrumbItem  >TKT-{ticketId}</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        
      </div>
    </div>
  );
}

export default TicketHeader;
