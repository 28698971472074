import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";

const ResolvHeader = () => {
  return (
    <div className=" mx-8 pt-10 pb-8 flex flex-col gap-8">
      <div className="flex text-center items-center">
        <Breadcrumb />
        <Breadcrumbs size="sm">
          <BreadcrumbItem>Resolv AI</BreadcrumbItem>
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default ResolvHeader;
