import React from 'react'
import ResolvHeader from './ResolvHeader'
import Resolv from './ResolvMain'

const ResolvAi = () => {
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <ResolvHeader/>
      <div className='px-40 pt-8'>
      <Resolv />
        </div>
      
    </div>
  )
}

export default ResolvAi
