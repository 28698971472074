import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";

const KnowledgeBase = () => {
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <Header />
      <div className=" flex-1 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default KnowledgeBase;
