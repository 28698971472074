import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { Chip } from "@nextui-org/react";
import SingleAvatar from "../AvatarRender/Avatar";
import { trimString } from "../../utils/default";
import { useTheme } from "next-themes";
// import CustomAvatar from '../TableComponents/CustomAvatar'
// import { ReactComponent as RedArrow } from '../../assets/icons/High.svg'
// import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg'
// import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg'


const TaskCard = ({ item, index }) => {
  const {theme} = useTheme();
  const IssueInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
  /* background: ${({ isDragging }) =>
    isDragging ?  "rgba(255, 59, 59, 0.15)" : "white"}; */
  background: ${theme === "dark" ? "black" : "white"};
  margin-top: 15px;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;
  return (
    <Draggable key={String(item?.payload?.id)} draggableId={String(item?.payload?.id)} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <IssueInformation>
            <div className="p-2 w-full flex flex-col gap-2">
              <div className=" w-full  flex items-center justify-between ">
                <div className=" flex gap-2 items-center">
                  <Chip size="sm" variant="flat" color={`${item?.payload?.type === "ticket" ? "danger" : "primary"}`}>
                    {item?.payload?.type ==="ticket" ? "TKT" : "ISS"}-{item?.payload?.latest}
                  </Chip>
                  <p className=" font-light">
                    {trimString(item?.payload?.title , 20)}
                  </p>
                </div>
                <SingleAvatar id={item?.payload?.assigned} />
              </div>
              <p className=" text-xs font-light">
                {trimString(item?.payload?.description.replace(/<[^>]*>/g, ""),200)}
              </p>
            </div>
          </IssueInformation>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
