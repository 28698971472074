import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from "@nextui-org/react";
import { getFilteredBySingleRelationSingleData } from "../../controllers/strapiController";
import { useParams } from "react-router-dom";
import UserGroupModal from "./UserModal";
import Privileges from "./Privileges";
import GroupDetails from "./settings";
import { useSelector } from "react-redux";
import {
  selectOrgData,
  selectPermissions,
  selectUserData,
} from "../../redux/actions";

const Groups = () => {
  const [nonMembers, setNonMembers] = useState([]);
  const { id } = useParams();
  const [update, setUpdate] = useState(true);
  const [selectedTab, setSelectedTab] = useState("users");
  const [isOpen, setIsOpen] = useState(false);
  const users = useSelector(selectUserData);
  const orgDetails = useSelector(selectOrgData);
  const [group, setGroup] = useState(null);
  const [owner, setOwner] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [members, setMembers] = useState([]);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const userPerm = useSelector(selectPermissions)?.find(
    (it) => it.name === "Role-14"
  );

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "groups",
          "organization",
          orgDetails.id,
          "path",
          id
        );
        setGroup(resp.data[0]);
        const owner = users.find(
          (item) => item.id === resp.data[0].attributes.owner.data.id
        );
        setOwner(owner);
        const memIds = resp.data[0].attributes.members.data.map((item) => {
          return item.id;
        });
        const mem = users.filter((item) => memIds.includes(item.id));
        setMembers(mem);
      } catch (error) {
        console.error("Error fetching group:", error);
      }
    };
    if (id && orgDetails?.id) {
      fetchGroup();
    }
  }, [id, orgDetails, users]);

  useEffect(() => {
    if (owner && members.length > 0 && users.length > 0) {
      const nonMemberList = users.filter(
        (user) =>
          user.id !== owner.id &&
          !members.some((member) => member.id === user.id)
      );
      setNonMembers(nonMemberList);
    }
  }, [owner, members, users]);
  return (
    <div className=" overflow-y-auto h-screen">
      <UserGroupModal
        isOpen={isOpen}
        isClose={() => setIsOpen(false)}
        users={nonMembers}
        groupId={group?.id}
      />
      <div className="mt-8 mx-8">
        {/* Name of the group */}
        <div className="flex items-center justify-between font-light">
          {group?.attributes?.Name}
        </div>

        {/* Description of the group */}
        <div className="flex items-center justify-between text-xs font-light mt-4">
          {group?.attributes?.Description}
        </div>
        {/* Owner of the group */}
        <div className="flex gap-2 items-center mt-4">
          <div className="items-center text-xs font-light text-gray-500">
            Owner
          </div>
          <Tooltip
            placement="bottom"
            size="sm"
            content="Group owner is the default assignee for new work items that are routed to group"
          >
            <lord-icon
              src="https://cdn.lordicon.com/yxczfiyc.json"
              style={{ width: "16px", height: "16px" }}
            ></lord-icon>
          </Tooltip>
          <div className="flex gap-2 ml-4 text-xs font-light text-gray-500 items-center">
            <Avatar
              showFallback
              name={owner?.username?.charAt(0).toUpperCase()}
              radius="sm"
              color={Colors[Number(owner?.id) % numColors]}
              size="sm"
              src={
                process.env.REACT_APP_STAGE === "Dev"
                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${owner?.ProfilePicture?.formats?.small?.url}`
                  : `${owner?.ProfilePicture?.formats?.small?.url}`
              }
            />
            {owner?.FirstName ? owner?.FirstName : owner?.username}{" "}
            {owner?.LastName}
          </div>
        </div>
      </div>
      <div className="mt-8 w-full">
        <Divider />
      </div>
      {/* Users and Privileges */}
      <div className="mt-8 mx-8">
        <Tabs
          selectedKey={selectedTab}
          onSelectionChange={setSelectedTab}
          aria-label="Options"
          size="sm"
        >
          <Tab
            key="users"
            title={
              <div className=" flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <p className=" text-sm">Users</p>
              </div>
            }
          >
            {userPerm.privileges[0].update && (
              <div className="flex justify-end">
                <Button
                  size="sm"
                  onClick={() => setIsOpen(true)}
                  className="text-white bg-[rgb(51,51,245)]"
                >
                  <div className="text-xs font-light">+ User</div>
                </Button>
              </div>
            )}
            <div className="mt-4">
              <Table removeWrapper>
                <TableHeader>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Name
                    </div>
                  </TableColumn>

                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Email
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Job Title
                    </div>
                  </TableColumn>
                </TableHeader>
                <TableBody>
                  {/* Onclick of the row same drawer opens */}
                  {members.map((member) => (
                    <TableRow key="1" className=" border-b border-divider">
                      <TableCell>
                        <div className="items-center flex gap-4 text-xs font-light">
                          <Avatar
                            showFallback
                            name={member?.username?.charAt(0).toUpperCase()}
                            radius="sm"
                            color={Colors[Number(member?.id) % numColors]}
                            size="sm"
                            src={
                              process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${member?.ProfilePicture?.formats?.small?.url}`
                                : `${member?.ProfilePicture?.formats?.small?.url}`
                            }
                          />
                          {member?.FirstName} {member?.LastName}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center text-xs font-light">
                          {member?.email}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center text-xs font-light ">
                          {member.JobTitle ?? "--"}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Tab>
          {userPerm.privileges[0].update && (
            <Tab
              key="privileges"
              title={
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                    />
                  </svg>
                  <p className="text-sm">Privileges</p>
                </div>
              }
            >
              <Privileges
                type={group?.attributes?.Type}
                groupId={group?.id}
                setUpdate={setUpdate}
                update={update}
                orgPrivileges={group?.attributes?.org_privileges}
                productPrivileges={group?.attributes?.product_privileges}
                analyticsPrivileges={group?.attributes?.analytics_privileges}
                supportPrivileges={group?.attributes?.support_privileges}
                devPrivileges={group?.attributes?.dev_privileges}
              />
            </Tab>
          )}
          {userPerm.privileges[0].update && (
            <Tab
              key="settings"
              title={
                <div className=" flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <p className="text-sm">Settings</p>
                </div>
              }
            >
              <GroupDetails group={group} users={users} />
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default Groups;
