import {
  Button,
  Chip,
  Input,
  Kbd,
  Modal,
  ModalContent,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBlueprints,
  selectIssues,
  selectOrgData,
  selectParts,
  selectPermissions,
  selectReleases,
  selectTickets,
  setBlueprints,
  setIssues,
  setParts,
  setReleases,
  setTickets,
} from "../../redux/actions";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchAllBluePrints,
  fetchAllIssues,
  fetchAllParts,
  fetchAllReleases,
  fetchAllTickets,
  searchParts,
  searchVectorDb,
} from "../../utils/searchHelper";
import SearchResults from "./SearchResults";
import useParts from "../../hooks/useParts";
import { useTheme } from "next-themes";

const SearchModal = ({ isOpenn, setIsOpenn }) => {
  const [search, setSearch] = useState("");
  const orgData = useSelector(selectOrgData);
  const dispatch = useDispatch();
  const [neuralMode,setNeuralMode] = useState(false);
  const { org } = useParams();
  const [tags, setTags] = useState([]);
  const tickets = useSelector(selectTickets);
  const issues = useSelector(selectIssues);
  const parts = useParts();
  const releases = useSelector(selectReleases);
  const blueprints = useSelector(selectBlueprints);
  const permissions = useSelector(selectPermissions);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [filteredParts, setFilteredParts] = useState([]);
  const [filteredBluePrints, setFilteredBluePrints] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const {theme} = useTheme();
  const [filteredReleases, setFilteredReleases] = useState([]);
  useEffect(() => {
    const fetchAll = async (ticketPerm, issuePerm) => {
      if (ticketPerm && (!tickets || tickets.length === 0)) {
        const ticketData = await fetchAllTickets(org);
        dispatch(setTickets(ticketData));
      }
      if (issuePerm && (!issues || issues.length === 0)) {
        const issueData = await fetchAllIssues(org);
        dispatch(setIssues(issueData));
      }
      if (!blueprints || blueprints.length === 0) {
        const blueprintData = await fetchAllBluePrints(org);
        dispatch(setBlueprints(blueprintData));
      }
      if (!releases || releases.length === 0) {
        const releaseData = await fetchAllReleases(org);
        dispatch(setReleases(releaseData));
      }
    };
    if (permissions) {
      const ticketPerm =
        permissions?.find((item) => item.name === "Role-11")?.privileges?.[0]
          ?.read ?? false;
      const issuePerm =
        permissions?.find((item) => item.name === "Role-2")?.privileges?.[0]
          ?.read ?? false;

      fetchAll(ticketPerm, issuePerm);
    }
  }, [permissions]);
  useEffect(() => {
    setIsOpenn(false);
  }, [location]);
  useEffect(() => {
    const filterData = () => {
      const tagIds = tags.filter((item) => item.attributes.Name === search);
      setFilteredBluePrints(
        searchVectorDb(blueprints, search, tagIds, "blueprint", userDataId)
      );
      setFilteredIssues(
        searchVectorDb(issues, search, tagIds, "issue", userDataId)
      );
      setFilteredTickets(
        searchVectorDb(tickets, search, tagIds, "ticket", userDataId)
      );
      setFilteredReleases(
        searchVectorDb(releases, search, tagIds, "release", userDataId)
      );
      setFilteredParts(searchParts(parts, search, tagIds));
    };
    if (search === "") {
      setFilteredBluePrints([]);
      setFilteredIssues([]);
      setFilteredParts([]);
      setFilteredReleases([]);
      setFilteredTickets([]);
    } else {
      filterData();
    }
  }, [search, tickets, issues, parts, releases, blueprints]);
  useEffect(() => {
    setSelectedGroup(
      filteredTickets?.length > 0
        ? "ticket"
        : filteredIssues?.length > 0
        ? "issue"
        : filteredParts?.length > 0
        ? "part"
        : filteredBluePrints?.length > 0
        ? "blueprint"
        : filteredReleases?.length > 0
        ? "release"
        : null
    );
  }, [
    filteredBluePrints,
    filteredIssues,
    filteredParts,
    filteredReleases,
    filteredTickets,
  ]);
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagsResp = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id,
          ["Name"]
        );
        setTags(tagsResp.data);
      } catch (error) {}
    };
    if (orgData?.id) {
      fetchTags();
    }
  }, [orgData]);
  useEffect(() => {
    setSearch("");
    setNeuralMode(false);
  }, [isOpenn]);
  return (
    <Modal
      size="full"
      className=" bg-transparent shadow-none"
      hideCloseButton
      isOpen={isOpenn}
      onClose={() => setIsOpenn(false)}
      backdrop="blur"
      classNames={{
        backdrop: "backdrop-opacity-20",
      }}
      shadow="none"
    >
      <ModalContent className="bg-transparent shadow-none flex flex-col h-full items-center justify-center">
        <div className="w-[90%] h-[90%] flex flex-col gap-4 ">
          <div className="w-full flex items-center">
            <div className="w-[20%]"></div>
            <div className="w-[60%] px-2">
              <Input
                startContent={
                  <lord-icon
                    src="https://cdn.lordicon.com/kkvxgpti.json"
                    colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                    trigger="hover"
                    style={{ height: "16px" }}
                  ></lord-icon>
                }
                className="w-full"
                value={search}
                size="lg"
                onChange={(e) => setSearch(e.target.value)}
                placeholder={neuralMode ? "Neural Search..." : "Search..."}
              />
            </div>
            <div className="w-[20%] flex items-center justify-center ">
              <Button
                onClick={() => setIsOpenn(false)}
                isIconOnly
                size="lg"
                className=" bg-white dark:bg-[#27272A]"
              >
                <div className="flex flex-col justify-center items-center" >
                  <div className="text-[16px]  flex items-center justify-center " >x</div>
                <div className="text-xs flex items-center justify-center" >ESC</div>
                </div>
                
              </Button>
            </div>
          </div>

          <div className="flex-1 w-full flex overflow-y-auto">
            <div className="w-[20%]">
              {!neuralMode ? search !== "" &&
                (filteredBluePrints.length > 0 ||
                  filteredIssues.length > 0 ||
                  filteredParts.length > 0 ||
                  filteredReleases.length > 0 ||
                  filteredTickets.length > 0) && (
                  <div className="w-full max-h-full h-auto bg-opacity-50 backdrop-blur-lg bg-white rounded-lg overflow-y-auto p-4">
                    <div className="flex flex-col gap-2">
                      {filteredTickets.length > 0 && (
                        <div
                          onClick={() => setSelectedGroup("ticket")}
                          className={`w-full p-4 rounded-lg cursor-pointer opacity-70 flex transition-colors duration-300 items-center justify-between hover:bg-white dark:hover:bg-gray-800 hover:shadow-lg ${
                            selectedGroup === "ticket"
                              ? "bg-white shadow-lg dark:bg-gray-700"
                              : ""
                          }  `}
                        >
                          <p>Tickets</p>
                          <Chip className="bg-gray-200 dark:bg-gray-500" radius="sm">
                            {filteredTickets.length}
                          </Chip>
                        </div>
                      )}
                      {filteredIssues.length > 0 && (
                        <div
                          onClick={() => setSelectedGroup("issue")}
                          className={`w-full p-4 rounded-lg cursor-pointer opacity-70 flex transition-colors duration-300 items-center justify-between dark:hover:bg-gray-800 hover:bg-white hover:shadow-lg ${
                            selectedGroup === "issue"
                              ? "bg-white  shadow-lg"
                              : ""
                          }  `}
                        >
                          <p>Issues</p>
                          <Chip className="bg-gray-200 dark:bg-gray-500" radius="sm">
                            {filteredIssues.length}
                          </Chip>
                        </div>
                      )}
                      {filteredParts.length > 0 && (
                        <div
                          onClick={() => setSelectedGroup("part")}
                          className={`w-full p-4 rounded-lg cursor-pointer opacity-70 flex transition-colors duration-300 items-center justify-between dark:hover:bg-gray-800 hover:bg-white hover:shadow-lg ${
                            selectedGroup === "part" ? "bg-white shadow-lg" : ""
                          }  `}
                        >
                          <p>Components</p>
                          <Chip className="bg-gray-200 dark:bg-gray-500" radius="sm">
                            {filteredParts.length}
                          </Chip>
                        </div>
                      )}
                      {filteredBluePrints.length > 0 && (
                        <div
                          onClick={() => setSelectedGroup("blueprint")}
                          className={`w-full p-4 rounded-lg cursor-pointer opacity-70 flex transition-colors duration-300 items-center justify-between dark:hover:bg-gray-800 hover:bg-white hover:shadow-lg ${
                            selectedGroup === "blueprint"
                              ? "bg-white shadow-lg"
                              : ""
                          }  `}
                        >
                          <p>Blueprints</p>
                          <Chip className="bg-gray-200 dark:bg-gray-500" radius="sm">
                            {filteredBluePrints.length}
                          </Chip>
                        </div>
                      )}
                      {filteredReleases.length > 0 && (
                        <div
                          onClick={() => setSelectedGroup("release")}
                          className={`w-full p-4 rounded-lg cursor-pointer opacity-70 flex transition-colors duration-300 items-center justify-between dark:hover:bg-gray-800 hover:bg-white hover:shadow-lg ${
                            selectedGroup === "release"
                              ? "bg-white shadow-lg"
                              : ""
                          }  `}
                        >
                          <p>Releases</p>
                          <Chip className="bg-gray-200 dark:bg-gray-500" radius="sm">
                            {filteredReleases.length}
                          </Chip>
                        </div>
                      )}
                    </div>
                  </div>
                ) : <></>}
            </div>
            <div className="w-[60%] h-full bg-opacity-50 backdrop-blur-lg bg-white rounded-lg overflow-y-auto mx-2">
              {!neuralMode ? search === "" ? (
                <div className="flex flex-col gap-4 p-4">
                  <p>Popular Searches</p>
                  <div className="w-full flex flex-wrap gap-4">
                  <div
                      className="bg-white hover:bg-gray-200 dark:bg-black dark:hover:bg-gray-700 cursor-pointer gap-2 flex items-center shadow-lg p-2 rounded-lg transition-colors duration-300 "
                      onClick={() => setNeuralMode(true)}
                    >
                      Neural Search
                      <span >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5 text-purple-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                          />
                        </svg>
                      </span>
                    </div>
                    {tags?.map((item, index) => (
                      <div
                        key={index}
                        className="bg-white hover:bg-gray-200 dark:bg-black dark:hover:bg-gray-700 cursor-pointer shadow-lg p-2 rounded-lg transition-colors duration-300 "
                        onClick={() => setSearch(item.attributes.Name)}
                      >
                        {item.attributes.Name}
                      </div>
                    ))}
                    
                  </div>
                </div>
              ) : filteredBluePrints?.length > 0 ||
                filteredIssues?.length > 0 ||
                filteredParts?.length > 0 ||
                filteredReleases?.length > 0 ||
                filteredTickets?.length > 0 ? (
                <SearchResults
                  selectedGroup={selectedGroup}
                  tickets={filteredTickets}
                  issues={filteredIssues}
                  parts={filteredParts}
                  releases={filteredReleases}
                  blueprints={filteredBluePrints}
                />
              ) : (
                <div className="flex items-center justify-between p-4">
                  {" "}
                  <p>{`Haven’t found what you were looking for? "${search}".`}</p>
                  <Button
                    size="sm"
                    className="bg-white text-black"
                    onClick={() => setSearch("")}
                  >
                    Clear
                  </Button>{" "}
                </div>
              ) : <></>}
            </div>
            <div className="w-[20%]"></div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
