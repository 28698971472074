import { getSingleDoc, patchData } from "../controllers/vectorDB";

const updateIssues = async (type, id, arr, org) => {
  try {
    const promises = arr.map((item) => {
      let newIds =
        type === "ticket"
          ? item.payload.linkedTickets
          : item.payload.linkedIssues;
      if (newIds && newIds.length > 0) {
        newIds.push(id);
      } else {
        newIds = [id];
      }
      const formData =
        type === "ticket"
          ? { linkedTickets: newIds }
          : { linkedIssues: newIds };
      return patchData(formData, item.payload.id, org);
    });
    await Promise.all(promises);
  } catch (error) {}
};

export const issuesTicketLink = async (type, idArray, id, org) => {
  try {
    const promises = idArray.map((item) => getSingleDoc(item, org));
    const resp = await Promise.all(promises);
    const issArr = resp.map((item) => {
      if (item?.length > 0) {
        return item[0];
      }
    });
    await updateIssues(type,id,issArr,org);
  } catch (error) {}
};
