import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUpdates, setUpdates } from "../../redux/actions";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import Icon from "../../components/Tag/Icon";
import { UpdateData } from "../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import MessageRenderer from "./MessageRenderer";
import { useTheme } from "next-themes";

const UpdateList = ({ type }) => {
  const {theme} = useTheme();
  const updates = useSelector(selectUpdates);
  const [hover, setHover] = useState(null);
  const dispatch = useDispatch();
  const [filteredUpdates, setFilteredUpdates] = useState([]);
  const navigate = useNavigate();

  const { org } = useParams();
  useEffect(() => {
    if (updates && updates.updates.length > 0) {
      let final = updates.updates;
      final = final
        .filter((item) => item?.status === type)
        .sort((a, b) => b.timestamp - a.timestamp);
      setFilteredUpdates(final);
    }
  }, [updates]);
  const handleMarkUnread = async (destination,timestamp, status) => {
    if (updates && updates.updates.length > 0 && updates.id) {
      let newUpdatesArr = updates.updates;
      if (status === "read") {
        newUpdatesArr = updates.updates.map((update) => {
            if (update.destination === destination) {
              return { ...update, status: "read" }; // Return a new object with updated status
            }
            return update; // Return the object as-is if destination doesn't match
          });
      } else {
        const index = newUpdatesArr.findIndex(
          (item) => item.timestamp === timestamp
        );
        let up;
        if (index !== -1) {
          up = newUpdatesArr[index];
          up.status = status;
          newUpdatesArr.splice(index, 1);
          newUpdatesArr.push(up);
        }
      }

      try {
        await UpdateData("updates", updates.id, { updates: newUpdatesArr });
        dispatch(setUpdates({ id: updates.id, updates: newUpdatesArr }));
      } catch (error) {}
    }
  };
  const handleDelete = async (timestamp) => {
    if (updates && updates.updates.length > 0 && updates.id) {
      const newUpdatesArr = updates.updates.filter(
        (item) => item.timestamp !== timestamp
      );
      try {
        await UpdateData("updates", updates.id, { updates: newUpdatesArr });
        dispatch(setUpdates({ id: updates.id, updates: newUpdatesArr }));
      } catch (error) {}
    }
  };
  return (
    <div className="w-full py-4 px-8">
      {filteredUpdates.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-48">
          <Icon />
          <span className="text-xs pt-4">{"No updates"}</span>
        </div>
      ) : (
        <Table aria-label="Table" className="w-full" removeWrapper>
          <TableHeader>
            <TableColumn>Updates</TableColumn>
            <TableColumn></TableColumn>
          </TableHeader>
          <TableBody>
            {filteredUpdates &&
              filteredUpdates.length > 0 &&
              filteredUpdates.map((update) => (
                <TableRow
                  key={update.timestamp}
                  onMouseEnter={() => setHover(update.timestamp)}
                  onMouseLeave={() => setHover(null)}
                  onClick={async () => {
                    await handleMarkUnread(update.destination ,null, "read");
                    navigate(
                      update.type === "issue"
                        ? `/${org}/${update.destination}`
                        : update.type === "ticket"
                        ? `/${org}/tickets`
                        : update.type === "part" ?
                         `/${org}/${update.destination}` : `/${org}`
                    );
                  }}
                  className={`border-b h-12 border-divider cursor-pointer ${ theme === "dark" ? "hover:bg-[#2D2D32]" : "hover:bg-gray-100"} text-xs`}
                >
                  <TableCell>
                    <div className="flex items-center gap-2 h-10">
                      {update.status === "unread" && (
                        <div className="h-2 w-2 bg-gray-500 rounded-full" />
                      )}
                      <MessageRenderer update={update} />
                    </div>
                  </TableCell>
                  <TableCell className="flex justify-end items-center h-full">
                    {hover === update.timestamp && (
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            isIconOnly
                            size="sm"
                            variant="light"
                            color={
                              update.status === "unread" ? "danger" : "default"
                            }
                          >
                            {update.status === "read" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            )}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="options">
                          {update.status === "read" && (
                            <DropdownItem
                              onClick={async () =>
                                handleMarkUnread(null,update.timestamp, "unread")
                              }
                              startContent={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="size-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                  />
                                </svg>
                              }
                            >
                              Mark as unread
                            </DropdownItem>
                          )}
                          <DropdownItem
                            onClick={async () => handleDelete(update.timestamp)}
                            color="danger"
                            variant="flat"
                            startContent={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            }
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default UpdateList;
