import React from 'react'
import AnalyticsHeader from './Header'
import AnalyticsCharts from './AnalyticsCharts'

const Analytics = () => {
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <AnalyticsHeader/>
      <AnalyticsCharts/>
    </div>
  )
}

export default Analytics