import React, { useState, useEffect } from "react";
import {
  Avatar,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import {
  getAllData,
  getOneData,
  UpdateData
} from "../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import InviteModal from "../Invitations/modal";
import { format } from "date-fns";
import UserDrawer from "./UserDrawer";
import Icon from "../../components/Tag/Icon";
import { PermissionGroupSingleRole } from "../../utils/permission";
import DeleteModal from "./DeleteModal";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";

const generateColor = (userId) => {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const colorIndex = userId % Colors.length;
  return Colors[colorIndex];
};

const User = () => {
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [parts, setParts] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [userDrawer, setUserDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const { org } = useParams();
  const navigate = useNavigate();
  const userDataId = JSON.parse(sessionStorage.getItem("userData"))?.user?.id;
  const [customizeItems, setCustomizeItems] = useState(new Set());
  const [sortOption, setSortOption] = useState("latest");
  const customizeCompleteList = ["createdAt"];
  const [userPerm, setUserPerm] = useState({
    name: "Role-14",
    class: "User Management",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });

  const columnDef = Array.from(customizeItems).map((name) => ({
    id: name.toLowerCase().replace(" ", ""),
    label: name,
  }));

  const customizeHeaderList = [
    {
      id: "fullName",
      label: "Full Name",
    },
    {
      id: "jobtitle",
      label: "Role",
    },
    {
      id: "status",
      label: "Status",
    },
    ...columnDef,
    {
      id: "action",
      label: "Actions",
    },
  ];

  const handleInviteClick = () => setInviteModalOpen(true);
  const handleInviteModalClose = () => setInviteModalOpen(false);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const resp = await getAllData("organizations");
        setOrganizations(resp.data);
        if (resp.data.length === 0) {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
    };

    if (org) {
      fetchOrganizations();
    }
  }, [org, navigate]);
  useEffect(() => {
    const fetchParts = async () => {
      try {
        const resp = await getAllData("parts");
        setParts(resp.data);
        if (resp.data.length === 0) {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
    };

    if (org) {
      fetchParts();
    }
  }, [org, navigate]);

  useEffect(() => {
    if (organizations.length > 0) {
      const orgDetails = organizations.find(
        (tenant) => tenant.attributes.Name === org
      );

      if (orgDetails) {
        const usersData = orgDetails.attributes.users?.data || [];
        const filteredUsers = usersData.filter(
          (user) => user.id === userDataId
        );
        if (filteredUsers.length > 0) {
          const userIdsArray = usersData.map((user) => user.id);
          setUserIds(userIdsArray);
          setOrgId(orgDetails.id);
        }
      }
    }
  }, [organizations, org, userDataId]);

  useEffect(() => {
    if (parts.length > 0) {
      const partDetails = parts.find(
        (tenant) => tenant.attributes.Name === org
      );

      if (partDetails) {
        const usersData = partDetails.attributes.users?.data || [];
        const filteredUsers = usersData.filter(
          (user) => user.id === userDataId
        );
        if (filteredUsers.length > 0) {
          const userIdsArray = usersData.map((user) => user.id);
          setUserIds(userIdsArray);
          setOrgId(partDetails.id);
        }
      }
    }
  }, [parts, org, userDataId]);

  useEffect(() => {
    const fetchUsersByIds = async () => {
      try {
        const promises = userIds.map((id) => getOneData("users", id));
        const results = await Promise.all(promises);
        setUsers(results);
      } catch (error) { }
    };

    if (userIds.length > 0) {
      fetchUsersByIds();
    }
  }, [userIds]);

  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(
          userDataId,
          orgId,
          "Role-7",
          "org"
        );
        if (resp !== false) {
          setUserPerm(resp);
        }
      } catch (error) { }
    };
    if (orgId) {
      fetchPerm();
    }
  }, [orgId, userDataId]);

  useEffect(() => {
    if (sortOption === "latest") {
      setUsers((prevUsers) => [...prevUsers].sort((a, b) => b.id - a.id));
    } else if (sortOption === "oldest") {
      setUsers((prevUsers) => [...prevUsers].sort((a, b) => a.id - b.id));
    }
  }, [sortOption]);

  const toggleUserDrawer = (user) => {
    setSelectedUser(user);
    setUserDrawer(true);
  };

  const renderCell = (row, key) => {
    switch (key) {
      case "fullName":
        const fullName = `${row?.FirstName || ""} ${row?.LastName || ""}`;
        return (
          <div className="flex items-center">
            {row?.ProfilePicture ? (
              <Avatar
                src={
                  row?.ProfilePicture?.formats?.small?.url
                    ? process.env.REACT_APP_STAGE === "Dev"
                      ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${row.ProfilePicture.formats.small.url}`
                      : `${row.ProfilePicture.formats.small.url}`
                    : null
                }
                alt={`${row?.FirstName} `}
                color={generateColor(row?.id)}
                className="h-8 w-8 mr-4"
                radius="sm"
              />
            ) : (
              <Avatar
                name={
                  fullName.trim() === "" && row?.username
                    ? row.username.charAt(0).toUpperCase()
                    : `${row?.FirstName?.slice(0, 1)?.toUpperCase()}`
                }
                color={generateColor(row?.id)}
                className="h-8 w-8 mr-4"
                radius="sm"
              />
            )}
            <div className="flex flex-col">
              <p className="font-medium">{fullName}</p>
              <p>{row?.username}</p>
            </div>
          </div>
        );
      case "jobtitle":
        return row?.JobTitle || "";
      case "status":
        if (row?.Status?.toLowerCase() === "active") {
          return (
            <Chip color="success" variant="flat" size="sm">
              Active
            </Chip>
          );
        }
        return row?.Status || "";
      case "createdat":
        return row?.createdAt
          ? format(new Date(row.createdAt), "yyyy-MM-dd")
          : "";
      case "action":
        return (
          <div className="flex gap-2">
            <div
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(row.id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </div>
            <div
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(row);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </div>
          </div>
        );
      default:
        if (customizeItems.has(key)) {
          return row[key] || "";
        }
        return "";
    }
  };

  const handleEdit = (id) => {

  };

  const handleDelete = (user) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!userToDelete) return;
    try {
      const userOrganizations = organizations.filter((org) =>
        org.attributes.users.data.some((user) => user.id === userToDelete.id)
      );
      const isPartOwner = userOrganizations.some((org) =>
        org.attributes.owners?.data.some(
          (owner) => owner.id === userToDelete.id
        )
      );

      if (isPartOwner) {
        alert(
          "This user is a part owner and cannot be deleted directly. Please reassign ownership before deleting."
        );
        setDeleteModalOpen(false);
        return;
      }
      if (userOrganizations.length > 1) {
        const currentOrg = userOrganizations.find((org) => org.id === orgId);
        if (currentOrg) {
          const updatedUsers = currentOrg.attributes.users.data.filter(
            (user) => user.id !== userToDelete.id
          );
          await UpdateData("organizations", orgId, { users: updatedUsers });
        }
      } else {
        await UpdateData("users", userToDelete.id, { deleted: true });
      }

      const updatedOrganizations = await getAllData("organizations");
      setOrganizations(updatedOrganizations.data);
      setDeleteModalOpen(false);
      setUserToDelete(null);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete or detach user:", error);
    }
  };

  const handlePartDelete = async () => {
    if (!userToDelete) return;
    try {
      const userParts = parts.filter((org) =>
        org.attributes.users.data.some((user) => user.id === userToDelete.id)
      );
      const isPartOwner = userParts.some((org) =>
        org.attributes.owners?.data.some(
          (owner) => owner.id === userToDelete.id
        )
      );

      if (isPartOwner) {
        alert(
          "This user is a part owner and cannot be deleted directly. Please reassign ownership before deleting."
        );
        setDeleteModalOpen(false);
        return;
      }
      if (userParts.length > 1) {
        const currentPart = userParts.find((org) => org.id === orgId);
        if (currentPart) {
          const updatedUsers = currentPart.attributes.users.data.filter(
            (user) => user.id !== userToDelete.id
          );
          await UpdateData("organizations", orgId, { users: updatedUsers });
        }
      } else {
        await UpdateData("users", userToDelete.id, { deleted: true });
      }

      const updatedOrganizations = await getAllData("organizations");
      setOrganizations(updatedOrganizations.data);
      setDeleteModalOpen(false);
      setUserToDelete(null);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete or detach user:", error);
    }
  };
  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setUserToDelete(null);
  };

  return (
    <div>
      {userDrawer && selectedUser && (
        <UserDrawer
          user={selectedUser}
          handleClose={() => setUserDrawer(false)}
        />
      )}
      {inviteModalOpen && (
        <InviteModal
          orgid={orgId}
          isOpen={inviteModalOpen}
          onClose={handleInviteModalClose}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={handleCancelDelete}
          onConfirm={() => {
            handleConfirmDelete();
            handlePartDelete();
          }}
          userName={userToDelete ? userToDelete.username : ""}
        />
      )}

      <div className="flex justify-between p-10">
        <div className="flex">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Settings</BreadcrumbItem>
            <BreadcrumbItem>User</BreadcrumbItem>
          </Breadcrumbs>
        </div>

        {userPerm.privileges[0].create && (
          <Button
            size="sm"
            className="text-white bg-[rgb(51,51,245)]"
            onClick={handleInviteClick}
          >
            + Invite
          </Button>
        )}
      </div>
      <div className="flex justify-between">
        <div className="flex gap-4 ml-10">
          <Button className="bg-white">All</Button>
          <Button className="bg-white">Clear</Button>
        </div>
        <div className="flex gap-4 mr-10">
          <Dropdown>
            <DropdownTrigger>
              <Button size="sm" variant="light">
                Sort By
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Sort options"
              variant="flat"
              selectionMode="single"
              selectedKeys={new Set([sortOption])}
              onSelectionChange={(keys) => {
                setSortOption(Array.from(keys)[0]);
              }}
            >
              <DropdownItem key="latest">Latest</DropdownItem>
              <DropdownItem key="oldest">Oldest</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown>
            <DropdownTrigger>
              <Button size="sm" variant="light">
                Customize
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Customize columns"
              variant="flat"
              closeOnSelect={false}
              selectionMode="multiple"
              disallowEmptySelection={false}
              selectedKeys={customizeItems}
              onSelectionChange={setCustomizeItems}
            >
              {customizeCompleteList.map((name) => (
                <DropdownItem key={name}>{name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {users.length === 0 ? (
        <div className="flex flex-col items-center p-10">
          <Icon />
        </div>
      ) : (
        <div className="flex justify-center p-10">
          <Table
            removeWrapper
            aria-label="User table"
            className="cursor-pointer"
          >
            <TableHeader columns={customizeHeaderList}>
              {(column) => (
                <TableColumn key={column.id} className="text-xs">
                  {column.label}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody emptyContent={"No users found."}>
              {users.map((data) => (
                <TableRow
                  key={data?.id}
                  className="hover:bg-gray-100  border-b border-divider text-xs"
                  onClick={() => toggleUserDrawer(data)}
                >
                  {customizeHeaderList.map((item) => (
                    <TableCell key={item.id}>
                      {renderCell(data, item.id)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default User;
