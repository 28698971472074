import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { BubbleMenu, useCurrentEditor } from "@tiptap/react";
import React, { useState } from "react";
import italic from "../../Images/Icons/italic.svg";
import bold from "../../Images/Icons/bold.svg";
import strike from "../../Images/Icons/strike.svg";
import quote from "../../Images/Icons/quote.svg";
import code from "../../Images/Icons/code.svg";

const Bubble = () => {
  const { editor } = useCurrentEditor();
  const hexArray = [
    { name: "Red", hex: "#F5A9A9" },
    { name: "Orange", hex: "#F6C6A6" },
    { name: "Yellow", hex: "#F6F2A6" },
    { name: "Green", hex: "#A9F5A9" },
    { name: "Blue", hex: "#A9D9F5" },
    { name: "Indigo", hex: "#A9A9F5" },
    { name: "Violet", hex: "#F5A9F2" },
  ];

  const [color, setColor] = useState(hexArray[0].hex);

  return (
    <div>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <div className="bg-white dark:bg-[#2D2D32] border border-divider rounded-lg flex items-center gap-1">
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={
                editor.isActive("bold")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md  "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[20px] h-[20px]"
            >
              <path
                strokeLinejoin="round"
                d="M6.75 3.744h-.753v8.25h7.125a4.125 4.125 0 0 0 0-8.25H6.75Zm0 0v.38m0 16.122h6.747a4.5 4.5 0 0 0 0-9.001h-7.5v9h.753Zm0 0v-.37m0-15.751h6a3.75 3.75 0 1 1 0 7.5h-6m0-7.5v7.5m0 0v8.25m0-8.25h6.375a4.125 4.125 0 0 1 0 8.25H6.75m.747-15.38h4.875a3.375 3.375 0 0 1 0 6.75H7.497v-6.75Zm0 7.5h5.25a3.75 3.75 0 0 1 0 7.5h-5.25v-7.5Z"
              />
            </svg>
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={
                editor.isActive("italic")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md   "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-[20px] w-[20px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803"
              />
            </svg>
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={
                editor.isActive("underline")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md   "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ height: "20px", width: "20px" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.995 3.744v7.5a6 6 0 1 1-12 0v-7.5m-2.25 16.502h16.5"
                />
              </svg>
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              className={
                editor.isActive("strike")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md   "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 12a8.912 8.912 0 0 1-.318-.079c-1.585-.424-2.904-1.247-3.76-2.236-.873-1.009-1.265-2.19-.968-3.301.59-2.2 3.663-3.29 6.863-2.432A8.186 8.186 0 0 1 16.5 5.21M6.42 17.81c.857.99 2.176 1.812 3.761 2.237 3.2.858 6.274-.23 6.863-2.431.233-.868.044-1.779-.465-2.617M3.75 12h16.5"
              />
            </svg>
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={
                editor.isActive("blockquote")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md  "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="size-5"
            >
              <path d="M16 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z" />
              <path d="M5 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z" />
            </svg>
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleCode().run()}
              disabled={!editor.can().chain().focus().toggleCode().run()}
              className={
                editor.isActive("code")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md  "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
              />
            </svg>
            </Button>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  className=" flex items-center"
                  variant="light"
                >
                  <div
                    className={`w-5 h-5 rounded-full `}
                    style={{ backgroundColor: `${color}` }}
                  ></div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ widhth: "20px", height: "20px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu selectionMode="single">
                <DropdownItem
                  onClick={() => editor.chain().focus().unsetHighlight().run()}
                  disabled={!editor.isActive("highlight")}
                >
                  None
                </DropdownItem>
                {hexArray.map((item, index) => (
                  <DropdownItem
                    key={index}
                    className={
                      editor.isActive("highlight", { color: `${item.hex}` })
                        ? "is-active"
                          ? " bg-gray-200  rounded-md  "
                          : "bg-transparent"
                        : ""
                    }
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .toggleHighlight({ color: `${item.hex}` })
                        .run();
                      setColor(item.hex);
                    }}
                    startContent={
                      <div
                        className=" w-4 h-4 rounded-lg"
                        style={{ backgroundColor: `${item.hex}` }}
                      ></div>
                    }
                  >
                    {item.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </BubbleMenu>
      )}
    </div>
  );
};

export default Bubble;
