export const postData = async (id,formData, tenant) => {
  try { // Generate timestamp as ID
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_NAME}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllInbox = async (tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/all?app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_INBOX}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data[0];
  } catch (err) {
    throw err;
  }
};

export const postDataInbox = async (id, formData, tenant) => {
  try {
    // Generate timestamp as ID
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_INBOX}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleDocument = async (id,tenant) => {
    try {
        const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?collection_name=${process.env.REACT_APP_COLLECTION_INBOX}&app_id=${tenant}&id=${id}`;
        
        const response = await fetch(url, { // Use the constructed URL
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export const getSingleDoc = async (id,tenant) => {
    try {
        const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?collection_name=${process.env.REACT_APP_COLLECTION_NAME}&app_id=${tenant}&id=${id}`;
        
        const response = await fetch(url, { // Use the constructed URL
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export const getSingleTicket = async (id,tenant) => {
    try {
        const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?collection_name=${process.env.REACT_APP_COLLECTION_NAME}&app_id=${tenant}&id=${id}`;
        
        const response = await fetch(url, { // Use the constructed URL
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}
export const getDocument = async (key, value, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-doc/filter?qdrant_collection=${process.env.REACT_APP_COLLECTION_NAME}&app_id=${tenant}`;

    const body = {
      filters: [
        {
          key: key,
          value: value,
        },
      ],
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.documents;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFilters = async (value, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-doc/filter?qdrant_collection=${process.env.REACT_APP_COLLECTION_NAME}&app_id=${tenant}`;

    const body = {
      filters: value,
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.documents;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFiltersInbox = async (value, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-doc/filter?qdrant_collection=${process.env.REACT_APP_COLLECTION_INBOX}&app_id=${tenant}`;

    const body = {
      filters: value,
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.documents;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFiltersSimilar = async (query, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/search/?collection_name=${process.env.REACT_APP_COLLECTION_NAME}&app_id=${tenant}&query=${query}`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const url = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users?populate=*`;
    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return null; // Or you can handle the error in a different way
  }
};
export const getUserData = async (id) => {
  try {
    const url = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/${id}?populate=*`;
    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return null; // Or you can handle the error in a different way
  }
};

export const patchData = async (formData, id, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/meta?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const patchDataInbox = async (formData, id, tenant) => {
    try {
      const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/meta?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_INBOX}`;
  
      const response = await fetch(url, {
        method: "PATCH",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

export const deleteData = async (id, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteDataInbox = async (id, tenant) => {
    try {
      const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_COLLECTION_INBOX}`;
  
      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  export const postIngest = async (id,formData, tenant) => {
    try { // Generate timestamp as ID
      const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-management/upload?document_id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_UPLOAD_COLLECTION_NAME}`;
      
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  export const getAllDocs = async (tenant) => {
    try {
      const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-management/doc?app_id=${tenant}&collection_name=${process.env.REACT_APP_UPLOAD_COLLECTION_NAME}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      throw err;
    }
  };

  export const deleteDoc = async (id, tenant) => {
    try {
      const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-management/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_UPLOAD_COLLECTION_NAME}`;
  
      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };