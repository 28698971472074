import {
  Avatar,
  Button,
  Card,
  CardBody,
  Input,
  Tab,
  Tabs,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import Conversations from "./Conversations";
import useSocket from "../../hooks/useWebsocket";
import ConvoList from "./ConvoList";
import { getMultipleFiltersInbox } from "../../controllers/vectorDB";
import ChatTicket from "./ChatTicket";
import { inputClassNames } from "../../utils/default";

function Chatbox({ buttonText, chatTabs, hex, image, setToggle, org, email }) {
  const userId = `${org}_${email}`
  const [toggleConvo, setToggleConvo] = useState(false);
  const [tabSelection, setTabSelection] = useState(new Set([chatTabs[0].title]));
  const [threadId, setThreadId] = useState(null);
  const [threads, setThreads] = useState([]);
  const [convo, setConvo] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const socket = useSocket(email, org);
  const handleSendPress = () => {
    if (socket) {
      setThreadId(Date.now());
      setToggleConvo(true);
      setConvo([]);
    }
  }
  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const resp = await getMultipleFiltersInbox([{ key: "customerId", value: userId }], org);
        setThreads(resp);
        console.log("Threads", resp);
      }
      catch (error) {
        console.log("Error:", error)
      }
    }
    if (userId && org) {
      fetchThreads();
    }
  }, [userId, org, toggleConvo, refetch]);
  useEffect(() => {
    if (socket) {
      socket.onmessage = (data) => {
        const message = JSON.parse(data.data);
        if (message.type === "text") {
          const mesthread = threads.find((item) => String(item.payload.id) === String(message.threadId));
          if (mesthread) {
            mesthread.payload.messages.push(message.payload.message);
            mesthread.payload.updatedAt = message.payload.message.timestamp;
            let arr = threads;
            arr = arr.filter((item) => String(item.payload.id) !== String(message.threadId));
            arr.push(mesthread);
            setThreads(arr);
          }
          else {
            setRefetch(!refetch)
          }
        }
      }
    }
  }, [socket, threads])
  return (
    <div className="relative h-full w-[80%] rounded-lg ">
      <Card className="w-full h-full border border-divider" shadow="none">
        {(toggleConvo && threadId) ? (
          <Conversations
            org={org}
            threadId={threadId}
            userId={userId}
            hex={hex}
            socket={socket}
            setToggle={() => setToggle(false)}
            setToggleConvo={() => setToggleConvo(false)}
            convo={convo}
            setConvo={setConvo}
            email={email}
          />
        )
          : (
            <CardBody className="flex flex-col px-4 gap-4 items-center">
              <div className="flex w-full  pt-2 items-center justify-between">
                <div className="flex items-center gap-4">
                  <Avatar
                    radius="sm"
                    size="sm"
                    src={
                      image?.data?.attributes?.url
                        ? image?.data?.attributes?.url
                        : "https://avatars.githubusercontent.com/u/170258109?s=200&v=4"
                    }
                  />
                  <p className="font-semibold">How can we help?</p>
                </div>
                <Button
                  onClick={() => setToggle()}
                  size="sm"
                  radius="full"
                  variant="light"
                  isIconOnly
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </Button>
              </div>
              <Input
                classNames={inputClassNames}
                size="sm"
                startContent={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                }
                placeholder="Search for article..."
              />


              <Tabs selectedKey={tabSelection} onSelectionChange={setTabSelection} size="sm">
                {chatTabs.map((item) => {
                  if (item.isVisible) {
                    return <Tab className="w-full" key={item.title} title={<div className=" flex items-center gap-1">
                      <svg
                        xmlns={item?.icon?.xmlns}
                        fill={item?.icon?.fill}
                        viewBox={item?.icon?.viewBox}
                        strokeWidth={item?.icon?.strokeWidth}
                        stroke={item?.icon?.stroke}
                        className="size-4"
                      >
                        <path
                          strokeLinecap={item?.icon?.path?.strokeLinecap}
                          strokeLinejoin={item?.icon?.path?.strokeLinejoin}
                          d={item?.icon?.path?.d}
                        ></path>
                      </svg>
                      <p>{item.title}</p></div>}>
                      {item.title === "Conversation" ?
                        <ConvoList org={org} setConvo={setConvo} threads={threads} setToggle={setToggleConvo} setThreadId={setThreadId} /> :
                        item.title === "Ticket" ? <ChatTicket threads={threads} org={org} /> : <></>}

                    </Tab>;
                  }
                })}
              </Tabs>
              <Button
                disabled={socket === null}
                onClick={handleSendPress}
                size="sm"
                style={{ backgroundColor: hex }}
                className="absolute bottom-4 w-[80%] text-white "
              >
                {buttonText}
              </Button>
            </CardBody>
          )}
      </Card>
    </div>
  );
}

export default Chatbox;
