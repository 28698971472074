import { BreadcrumbItem, Breadcrumbs } from '@nextui-org/react';
import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectOrgData } from '../../redux/actions';

function Breadcrumb() {
    const currentOrg = useSelector(selectOrgData);
   
    const navigate = useNavigate();
    const { org } = useParams();
    const handleNavigate = () => {
        navigate(`/${org}`);
    };

    return (
        <div className=' cursor-pointer'>
            <Breadcrumbs size="sm" isDisabled>
                <BreadcrumbItem onClick={handleNavigate} size="xs" >
                    {currentOrg?.attributes?.Tenant || 'Loading...'}
                </BreadcrumbItem>
                <BreadcrumbItem></BreadcrumbItem>
            </Breadcrumbs>
        </div>
    );
}

export default Breadcrumb;
