import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Input,
  Avatar,
  AvatarGroup,
} from "@nextui-org/react";
import SingleAvatar from "../../components/AvatarRender/Avatar";

function OwnerDropdown({ dropdownOwner, setDropdownOwner, users, res }) {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  useEffect(() => {
    if (users?.length > 0 && res) {
      const filtered = users.filter((item) =>
        `${item?.FirstName}${item?.LastName}`
          .toLowerCase()
          .includes(search.replace(/\s+/g, "").toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [search, users, res]);
  return (
    <div>
      <Dropdown>
        <DropdownTrigger>
          <Button size="sm" className="bg-white dark:bg-black text-xs h-8">
            {Array.from(dropdownOwner).length === 0 ? (
              "Add User"
            ) : (
              <div className="flex items-center">
                <AvatarGroup
                  max={2}
                  total={Array.from(dropdownOwner).length}
                  renderCount={(count) =>
                    count > 2 ? (
                      <p className="text-xs text-foreground font-medium ms-2">
                        +{count - 2}
                      </p>
                    ) : (
                      <></>
                    )
                  }
                >
                  {Array.from(dropdownOwner).map((user) => (
                    <Avatar
                      showFallback
                      color={Colors[Number(user) % numColors]}
                      name={
                        res[String(user)]?.FirstName
                          ? res[String(user)]?.FirstName[0]?.toUpperCase()
                          : res[String(user)]?.UserName[0]?.toUpperCase()
                      }
                      style={{ height: "20px", width: "20px" }}
                      src={
                        process.env.REACT_APP_STAGE === "Dev"
                          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                              res[String(user?.id)]?.ProfilePicture
                            }`
                          : `${res[String(user?.id)]?.ProfilePicture}`
                      }
                    />
                  ))}
                </AvatarGroup>
              </div>
            )}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          variant="flat"
          className=" text-xs w-60"
          closeOnSelect={false}
          selectionMode="multiple"
          selectedKeys={dropdownOwner}
          onSelectionChange={setDropdownOwner}
        >
          <DropdownSection showDivider>
            <DropdownItem
              classNames={{
                base: "gap-0 data-[selectable=true]:focus:bg-white dark:data-[selectable=true]:focus:bg-[#18181B]",
                selectedIcon: "w-0",
              }}
              isReadOnly
              key="new"
            >
              <Input
                size="sm"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search owners.."
              ></Input>
            </DropdownItem>
          </DropdownSection>
          <DropdownSection className="max-h-[200px] overflow-y-auto">
            {filteredUsers?.map((user) => (
              <DropdownItem
                key={String(user?.id)}
                startContent={<SingleAvatar id={user?.id} />}
              >
                {res[String(user?.id)].FirstName
                  ? res[String(user?.id)].FirstName
                  : res[String(user?.id)].UserName}{" "}
                {res[String(user?.id)]?.LastName}
              </DropdownItem>
            ))}
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default OwnerDropdown;
