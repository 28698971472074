import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import "./styles.css";
import {
  Avatar,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  Chip,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Tabs,
  Tooltip,
} from "@nextui-org/react";
import {
  deleteData,
  getMultipleFilters,
  patchData,
} from "../../controllers/vectorDB";
import {
  DeleteSingleAttribute,
  getFilteredBySingleRelation,
} from "../../controllers/strapiController";
import Tags from "../../components/Tag/tags";
import { parseDate } from "@internationalized/date";
import PartPicker from "../../components/Drawer/PartPicker";
import HelixLoader from "../../components/Loader/HelixLoader";
import { EditorProvider } from "@tiptap/react";
import { MenuBar, extensions, props } from "../../components/markdown/markdown";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  selectOrgData,
  selectResData,
  selectUserData,
  selectUserNames,
} from "../../redux/actions";
import {
  basicIcons,
  disableInputs,
  inputClassNames,
  priorityData,
} from "../../utils/default";
import Logs from "./Logs";
import WarningIcon from "../../Images/Icons/WarningIcon";
import ScredriverWrench from "../../Images/Icons/ScredriverWrench";
import ChevronArrow from "../../Images/Icons/ChevronArrow";

function IssuePage() {
  const progress = [
    "Work in progress",
    "Awaiting Product assist",
    "Awaiting Development",
    "In Development",
    "Awaiting Customer Response",
  ];
  const closed = ["Accepted", "Resolved", "Cancelled"];
  const { issueId, org } = useParams();
  const navigate = useNavigate();
  const [stage, setStage] = useState(new Set([]));
  const [toggleTitle, setToggleTitle] = useState(false);
  const [issue, setIssue] = useState(null);
  const [selectedReporter, setSelectedReporter] = useState(new Set([]));
  const [reporterInfo, setReporterInfo] = useState();
  const [partData, setPartData] = useState([]);
  const [parsedParts, setParsedParts] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedPart, setSelectedPart] = useState(new Set([]));
  const [customTags, setCustomTags] = useState([]);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;

  const location = useLocation();
  const [ownerInfo, setOwnerInfo] = useState({
    id: "",
    username: "",
    name: "",
    url: "",
  });
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const userNames = useSelector(selectUserNames);
  const [toggleEditor, setToggleEditor] = useState(false);
  const [sprints, setSprints] = useState([]);
  const [parsedSprints, setParsedSprints] = useState(null);
  const [imageIds, setImageIds] = useState([]);
  const [title, setTitle] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [temDesc, setTempDesc] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEstimatedDays, setSelectedEstimatedDays] = useState();
  const [selectedSprint, setSelectedSprint] = useState(new Set([]));
  const [sprintNumber, setSprintNumber] = useState();
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const [logs, setLogs] = useState([]);
  const numColors = Colors.length;
  const [tagsDetail, setTagsDetail] = useState([]);
  const [search, setSearch] = useState("");
  const [onHover, setOnHover] = useState("");
  const [loading, setLoading] = useState({ fir: true, sec: true });
  const [filteredSprints, setFilteredSprints] = useState([]);
  const [start, setStart] = useState(false);
  const handleMouseEnter = (id) => {
    setOnHover(id);
  };
  const orgDetails = useSelector(selectOrgData);
  function convertToDateStr(dateObj) {
    if (!dateObj) {
      return null;
    }
    const { year, month, day } = dateObj;
    const monthStr = String(month).padStart(2, "0");
    const dayStr = String(day).padStart(2, "0");
    return `${year}-${monthStr}-${dayStr}`;
  }
  const handleSelectSprint = (id, num) => {
    if (
      Array.from(selectedSprint)[0] === id &&
      (Number(sprintNumber) === Number(num) ||
        (sprintNumber === num && num === "Backlog"))
    ) {
      setSelectedSprint(new Set([]));
      setSprintNumber("");
      return;
    }
    setSelectedSprint(new Set([id]));
    setSprintNumber(num);
  };
  const handleMouseLeave = () => {
    setOnHover("");
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const updateReporter = (user) => {
    setReporterInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
    });
  };
  const [selectedPriority, setSelectedPriority] = useState(new Set([]));
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        window.location.origin + location.pathname
      );
      toast("Link copied", {
        duration: 2000,
        position: "bottom-right",
      });
    } catch (err) {
      toast("Cannot copy link!", {
        duration: 2000,
        position: "bottom-right",
      });
    }
  };
  const handleDelete = async () => {
    try {
      await deleteData(issue?.id, org);
      const promises = imageIds.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      await Promise.all(promises);
      navigate(`/${org}/issues`);
    } catch (error) {
      navigate(`/${org}/issues`);
    }
  };
  const checkLogs = (data, type) => {
    const logTimestamp = Date.now();
    const fieldsMap = {
      description: "description",
      title: "title",
      estimateDays: "estimated days",
      part: "part",
      stage: "stage",
      assigned: "assigned",
      priority: "priority",
      tags: "tags",
      targetclosedate: "target close date",
      sprint: "sprint",
    };
    const field = fieldsMap[type];
    const log = field
      ? {
          operation: "updated",
          field,
          to:
            type === "sprint"
              ? { sprint: data.sprint, currentSprint: data.currentSprint }
              : type === "targetclosedate"
              ? {
                  day: data.targetclosedate.day,
                  month: data.targetclosedate.month,
                  year: data.targetclosedate.year,
                }
              : type === "estimateDays"
              ? data["estimatedays"]
              : data[field],
          by: userDataId,
          timestamp: logTimestamp,
        }
      : null;

    if (log) {
      const finalLogs = [...logs, log];
      setLogs(finalLogs);
      return finalLogs;
    }

    return logs;
  };

  const handleUpdate = async (formData, type) => {
    if (type === "targetclosedate") {
      if (
        issue.targetclosedate.day === formData.targetclosedate.day &&
        issue.targetclosedate.month === formData.targetclosedate.month &&
        issue.targetclosedate.year === formData.targetclosedate.year
      ) {
        return;
      }
    }
    const updatedLogs = checkLogs(formData, type);
    formData.logs = updatedLogs;
    let deletearr;
    if (type === "description") {
      const arr = imageIds.filter((item) => temDesc.includes(item.url));
      deletearr = imageIds.filter((item) => !temDesc.includes(item.url));
      formData = {
        ...formData,
        imageIds: arr,
        imagePresent: arr.length === 0 ? false : true,
      };
    }
    try {
      await patchData(formData, issue.id, org);
      setIssue((prev) => ({ ...prev, ...formData }));
      if (type === "description") {
        const promises = deletearr.map((id) =>
          DeleteSingleAttribute("upload/files", Number(id.id))
        );
        await Promise.all(promises);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const fetchSprints = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "sprints",
          "organization",
          orgDetails.id
        );
        const resp2 = await getFilteredBySingleRelation(
          "parts",
          "organization",
          orgDetails.id
        );
        const resp3 = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgDetails.id
        );
        setSprints(resp.data);
        setPartData(resp2.data);
        setCustomTags(resp3.data);
        setLoading((prev) => ({ ...prev, fir: false }));
      } catch (error) {
        console.log("Error:", error);
      }
    };
    if (orgDetails?.id) {
      fetchSprints();
    }
  }, [orgDetails]);

  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[String(item?.id)] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });

      setParsedParts(convertedParts);
    }
  }, [partData]);
  useEffect(() => {
    if (sprints.length > 0) {
      const convertedParts = {};
      sprints?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Title,
          Icon: item?.attributes?.Icon,
          Curr: item?.attributes?.current,
        };
      });
      setParsedSprints(convertedParts);
    }
  }, [sprints]);
  useEffect(() => {
    const fetchIssue = async () => {
      const resp = await getMultipleFilters(
        [
          { key: "type", value: "issue" },
          { key: "latest", value: String(issueId) },
        ],
        org
      );
      if (resp.length !== 1) {
        navigate(`/${org}/issues`);
      } else {
        setIssue(resp[0]?.payload);
        setStage(new Set([resp[0]?.payload?.stage]));
        setSelectedReporter(new Set([resp[0]?.payload?.assigned]));
        setReporterInfo({
          username: res[resp[0]?.payload?.assigned]?.UserName,
          id: resp[0]?.payload?.assigned,
          name: res[resp[0]?.payload?.assigned]?.FirstName,
          url:
            process.env.REACT_APP_STAGE === "Dev"
              ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                  res[resp[0]?.payload?.assigned]?.ProfilePicture
                }`
              : `${res[resp[0]?.payload?.assigned]?.ProfilePicture}`,
        });
        setTags(resp[0].payload.tags);
        setSelectedPriority(
          resp[0]?.payload?.priority
            ? new Set([resp[0]?.payload?.priority])
            : new Set([])
        );
        setImageIds(resp[0]?.payload?.imageIds);
        setTitle(resp[0].payload.title);
        setLogs(resp[0]?.payload?.logs ?? []);
        setSelectedDate(resp[0]?.payload?.targetclosedate);
        setSelectedEstimatedDays(resp[0]?.payload?.estimatedays);
        setSelectedPart(new Set([resp[0]?.payload?.part]));
        setDescriptionContent(resp[0]?.payload?.description);
        setTempDesc(resp[0]?.payload?.description);
        setSelectedSprint(
          resp[0]?.payload?.sprint
            ? new Set([resp[0]?.payload?.sprint])
            : new Set([])
        );
        setSprintNumber(
          resp[0]?.payload?.currentSprint
            ? Number(resp[0]?.payload?.currentSprint)
            : ""
        );
        const filtered = customTags.filter((option) =>
          resp[0]?.payload?.tags.includes(option.id)
        );
        setTagsDetail(filtered);
        setLoading((prev) => ({ ...prev, sec: false }));
      }
    };
    if (issueId && orgDetails?.id && sprints && res) {
      fetchIssue();
    }
  }, [issueId, orgDetails, sprints, res, customTags]);
  useEffect(() => {
    if (sprints.length > 0) {
      const filtered = sprints.filter((option) =>
        option.attributes.Title.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredSprints(filtered);
    }
  }, [search, sprints]);
  useEffect(() => {
    if (Array.from(selectedPart).length > 0 && parsedParts) {
      const idOwner = String(
        parsedParts[String(Array.from(selectedPart)[0])]?.Owner
      );

      setOwnerInfo({
        id: idOwner,
        username: res[String(idOwner)]?.UserName,
        name: res[String(idOwner)]?.FirstName,
        url:
          process.env.REACT_APP_STAGE === "Dev"
            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                res[String(idOwner)]?.ProfilePicture
              }`
            : `${res[String(idOwner)]?.ProfilePicture}`,
      });
    }
  }, [selectedPart, parsedParts]);
  const Navigate = useNavigate();
  const handleNavigate = () => {
    Navigate(`/${org}/issues`);
  };

  useEffect(() => {
    if (issue) {
      if (
        Array.from(selectedPart).length > 0 &&
        Array.from(selectedPart)[0] !== issue.part
      ) {
        handleUpdate(
          {
            part: Array.from(selectedPart)[0],
            owner: String(
              parsedParts[String(Array.from(selectedPart)[0])]?.Owner
            ),
          },
          "part"
        );
      }
      if (Array.from(stage).length && Array.from(stage)[0] !== issue.stage) {
        handleUpdate({ stage: Array.from(stage)[0] }, "stage");
      }
      if (
        Array.from(selectedReporter).length > 0 &&
        Array.from(selectedReporter)[0] !== issue.assigned
      ) {
        handleUpdate({ assigned: Array.from(selectedReporter)[0] }, "assigned");
      }
      if (
        Array.from(selectedPriority).length > 0 &&
        Array.from(selectedPriority)[0] !== issue.priority
      ) {
        handleUpdate({ priority: Array.from(selectedPriority)[0] }, "priority");
      }
      if (
        Array.from(selectedSprint).length > 0 &&
        sprintNumber !== null &&
        (Array.from(selectedSprint)[0] !== issue.sprint ||
          String(sprintNumber) !== issue.currentSprint)
      ) {
        handleUpdate(
          {
            sprint: Array.from(selectedSprint)[0],
            currentSprint: String(sprintNumber),
          },
          "sprint"
        );
      }

      if (tags !== issue.tags) {
        handleUpdate({ tags: tags }, "tags");
      }
      if (
        selectedDate &&
        (selectedDate?.day !== issue.targetclosedate?.day ||
          selectedDate?.month !== issue.targetclosedate?.month ||
          selectedDate?.year !== issue.targetclosedate?.year)
      ) {
        handleUpdate({ targetclosedate: selectedDate }, "targetclosedate");
      }
    }
  }, [
    selectedPart,
    stage,
    selectedReporter,
    selectedPriority,
    selectedSprint,
    sprintNumber,
    tags,
    selectedDate,
  ]);
  if (
    loading.fir === true ||
    loading.sec === true ||
    !parsedParts ||
    !res ||
    !parsedSprints
  ) {
    return (
      <div className=" flex items-center justify-center h-screen">
        <HelixLoader />
      </div>
    );
  }

  return (
    <div className="h-full px-8 pb-8 overflow-y-auto w-full">
      <Toaster />
      <div className=" py-8">
        <div className=" flex cursor-pointer text-center justify-start items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem onClick={handleNavigate}>Issues</BreadcrumbItem>
            <BreadcrumbItem>ISS-{issueId}</BreadcrumbItem>
          </Breadcrumbs>
        </div>
      </div>
      <div className=" mt-4 flex items-center justify-between">
        <div className="  flex gap-4 items-center">
          <Chip size="md" color="primary" variant="flat">
            ISS-{issueId}{" "}
          </Chip>

          {toggleTitle ? (
            <div className=" flex items-center gap-4">
              <Input
                className="w-96"
                classNames={inputClassNames}
                value={title}
                variant="bordered"
                onChange={(e) => setTitle(e.target.value)}
              />{" "}
              <Button
                onClick={async () => {
                  await handleUpdate({ title: title });
                  setToggleTitle(false);
                }}
                color="success"
                isIconOnly
                size="sm"
                variant="light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 12.75 6 6 9-13.5"
                  />
                </svg>
              </Button>
              <Button
                onClick={() => {
                  setTitle(issue?.title);
                  setToggleTitle(false);
                }}
                color="danger"
                isIconOnly
                size="sm"
                variant="light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </Button>
            </div>
          ) : (
            <div className=" flex items-center gap-4">
              <h1 className=" text-lg font-semibold">{title}</h1>
              <Button
                size="sm"
                variant="light"
                isIconOnly
                onClick={() => setToggleTitle(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              </Button>
            </div>
          )}
        </div>

        <div className=" flex items-center gap-2">
          <Tooltip content="Similar Issues">
            <Button
              variant="light"
              isIconOnly
              startContent={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                  />
                </svg>
              }
            ></Button>
          </Tooltip>

          <Button
            isIconOnly
            onClick={() => handleCopy()}
            endContent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                />
              </svg>
            }
            size="sm"
            variant="light"
          ></Button>
          <Dropdown>
            <DropdownTrigger>
              <Button
                size="sm"
                variant="light"
                isIconOnly
                startContent={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                }
              ></Button>
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem
                color="danger"
                onClick={() => handleDelete()}
                variant="flat"
                startContent={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                }
              >
                Delete Issue
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <div className=" flex w-full pt-8 gap-8">
        <div className="w-[60%] flex flex-col gap-4 overflow-y-auto">
          <div className=" flex items-center gap-2 flex-wrap">
            {tagsDetail.map((item) => (
              <Chip
                className=" border border-divider"
                // size="sm"
                variant="light"
              >
                {" "}
                <div className=" flex items-center gap-1">
                  <div
                    style={{ backgroundColor: item?.attributes?.Color }}
                    className="h-2 w-2 rounded-full"
                  ></div>
                  <p>{item.attributes.Name}</p>
                </div>{" "}
              </Chip>
            ))}
          </div>
          {toggleEditor ? (
            <div>
              <div className=" h-auto mb-4 border border-divider rounded-lg">
                <EditorProvider
                  editorProps={props()}
                  slotBefore={
                    <MenuBar
                      descriptionContent={temDesc}
                      imageArray={imageIds}
                      setImageArray={setImageIds}
                      setDescriptionContent={setTempDesc}
                      initial={start}
                      setInitial={setStart}
                    />
                  }
                  extensions={extensions(userNames)}
                  content={descriptionContent}
                ></EditorProvider>
              </div>
              <div className=" flex pl-2 items-center gap-2">
                <Button
                  onClick={async () => {
                    await handleUpdate({ description: temDesc }, "description");
                    setDescriptionContent(temDesc);
                    setToggleEditor(false);
                  }}
                  className="bg-[rgb(51,51,245)] text-white"
                  size="sm"
                >
                  Save
                </Button>
                <Button
                  onClick={async () => {
                    await setTempDesc(descriptionContent);
                    setStart(false);
                    setToggleEditor(false);
                  }}
                  variant="light"
                  size="sm"
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className=" text-sm py-4 flex justify-between gap-2 px-2  rounded-lg">
              <div
                className="mydiv1"
                dangerouslySetInnerHTML={{
                  __html: disableInputs(descriptionContent),
                }}
              ></div>

              <Button
                startContent={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                }
                onClick={() => setToggleEditor(true)}
                size="sm"
                variant="light"
              >
                Edit
              </Button>
            </div>
          )}
          <div className=" py-8">
            <Tabs variant="light" size="sm">
              <Tab title="Logs">
                <Logs
                  type="issue"
                  logs={logs}
                  parsedParts={parsedParts}
                  parsedSprints={parsedSprints}
                />
              </Tab>
              <Tab title="Comments"></Tab>
            </Tabs>
          </div>
        </div>

        <div className="w-[40%]">
          <Card shadow="none" className=" border border-divider">
            <CardBody className="p-8">
              <p className="pb-8 text-lg font-semibold">Details</p>
              <div className=" flex flex-col gap-6">
                <div className="flex gap-2 text-center items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                      />
                    </svg>

                    <p className="  text-sm text-center flex items-center">
                      Part<span className="text-red-500"> * </span>
                    </p>
                  </div>

                  <div className=" min-w-40 max-w-80 text-xs  flex ">
                    {partData.length > 0 && (
                      <PartPicker
                        parsedParts={parsedParts}
                        selected={selectedPart}
                        setSelected={setSelectedPart}
                        parts={partData}
                      />
                    )}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.867 19.125h.008v.008h-.008v-.008Z"
                      />
                    </svg>

                    <p className="text-sm text-center flex items-center">
                      Stage<span className=" text-red-600 "> *</span>
                    </p>
                  </div>
                  <div className="w-40">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          size="sm"
                          variant="light"
                          className="w-24 text-xs text-center justify-start flex items-center"
                        >
                          {Array.from(stage)[0]}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        disallowEmptySelection
                        selectionMode="single"
                        selectedKeys={stage}
                        onSelectionChange={setStage}
                      >
                        <DropdownSection title="Open">
                          <DropdownItem key="Queued">Queued</DropdownItem>
                        </DropdownSection>
                        <DropdownSection title="In progress">
                          {progress.map((item) => (
                            <DropdownItem key={item}> {item}</DropdownItem>
                          ))}
                        </DropdownSection>
                        <DropdownSection title="Closed">
                          {closed.map((item) => (
                            <DropdownItem key={item}> {item}</DropdownItem>
                          ))}
                        </DropdownSection>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>

                    <p className="text-sm text-center flex items-center">
                      Assigned to<span className=" text-red-600 "> *</span>
                    </p>
                  </div>
                  <div className="w-40">
                    <Dropdown size="sm">
                      <DropdownTrigger>
                        <Button
                          size="sm"
                          variant="light"
                          className=" justify-start capitalize"
                        >
                          {selectedReporter === "" ? (
                            <p className=" text-xs ">Assign user</p>
                          ) : (
                            <div className="flex gap-2 items-center">
                              <Avatar
                                color={
                                  Colors[Number(reporterInfo?.id) % numColors]
                                }
                                style={{ width: "22px", height: "22px" }}
                                showFallback
                                radius="sm"
                                name={
                                  reporterInfo?.name
                                    ? reporterInfo?.name
                                        ?.charAt(0)
                                        .toUpperCase()
                                    : reporterInfo?.username
                                        ?.charAt(0)
                                        .toUpperCase()
                                }
                                className="flex-shrink-0"
                                size="sm"
                                src={reporterInfo?.url}
                              />
                              <div className="flex flex-col">
                                <span className="text-xs">
                                  {reporterInfo?.name
                                    ? reporterInfo?.name?.length > 25
                                      ? `${reporterInfo?.name.slice(0, 25)}...`
                                      : reporterInfo?.name
                                      ? reporterInfo.name
                                      : ""
                                    : reporterInfo?.username?.length > 25
                                    ? `${reporterInfo?.username.slice(
                                        0,
                                        25
                                      )}...`
                                    : reporterInfo?.username
                                    ? reporterInfo.username
                                    : ""}
                                </span>
                              </div>
                            </div>
                          )}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        aria-label="Select Tenant"
                        variant="flat"
                        disallowEmptySelection
                        selectionMode="single"
                        selectedKeys={selectedReporter}
                        onSelectionChange={setSelectedReporter}
                      >
                        {users.map((report) => {
                          const colorIndex = report && report.id % numColors;
                          return (
                            <DropdownItem
                              onClick={(e) => updateReporter(report)}
                              key={String(report.id)}
                            >
                              <div className="flex gap-2 items-center">
                                <Avatar
                                  style={{ width: "22px", height: "22px" }}
                                  showFallback
                                  name={
                                    report.FirstName
                                      ? report.FirstName?.charAt(
                                          0
                                        ).toUpperCase()
                                      : report.username?.charAt(0).toUpperCase()
                                  }
                                  className="flex-shrink-0"
                                  radius="sm"
                                  size="sm"
                                  color={Colors[colorIndex]}
                                  src={
                                    report.ProfilePicture?.formats?.small?.url
                                      ? process.env.REACT_APP_STAGE === "Dev"
                                        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${report.ProfilePicture.formats.small.url}`
                                        : `${report.ProfilePicture.formats.small.url}`
                                      : ""
                                  }
                                />
                                <div className="flex flex-col">
                                  <span className="text-xs">
                                    {report.FirstName
                                      ? report.FirstName
                                      : report.username}{" "}
                                    {report?.LastName}
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                      />
                    </svg>

                    <p className=" text-sm text-center flex items-center">
                      Priority<span className=" text-red-600"> *</span>
                    </p>
                  </div>
                  <div className="w-40">
                    <Dropdown variant="light" size="sm">
                      <DropdownTrigger>
                        <Button
                          size="sm"
                          variant="light"
                          className="w-24 text-xs items-center justify-start text-center flex"
                        >
                          {Array.from(selectedPriority).length > 0 ? (
                            <div className="flex flex-row items-center gap-2">
                              {
                                priorityData[Array.from(selectedPriority)[0]]
                                  ?.icon
                              }
                              <p className="text-xs">
                                {
                                  priorityData[Array.from(selectedPriority)[0]]
                                    ?.label
                                }
                              </p>
                            </div>
                          ) : (
                            <p className="text-xs">Select Priority</p>
                          )}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        aria-label="Priority selection"
                        variant="flat"
                        disallowEmptySelection
                        selectionMode="single"
                        t
                        selectedKeys={selectedPriority}
                        onSelectionChange={setSelectedPriority}
                      >
                        {Object.keys(priorityData).map((priority) => (
                          <DropdownItem
                            key={priority}
                            className="flex items-center w-full gap-2"
                          >
                            <div className="flex  gap-2">
                              {priorityData[priority].icon}
                              <p className="text-xs">
                                {priorityData[priority].label}
                              </p>
                            </div>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                {sprints.length > 0 && (
                  <div className="flex gap-2 items-center">
                    <div className="w-40 flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                        />
                      </svg>

                      <p className="  text-sm text-center flex items-center">
                        Sprint
                      </p>
                    </div>
                    <div className="w-40">
                      <Dropdown>
                        <DropdownTrigger>
                          <div className="w-full pl-4 text-xs cursor-pointer">
                            {Array.from(selectedSprint).length === 0
                              ? "Add sprint"
                              : `${
                                  parsedSprints[
                                    Number(Array.from(selectedSprint)[0])
                                  ]?.Name
                                }/ ${
                                  sprintNumber === "Backlog"
                                    ? "Backlog"
                                    : `Sprint ${sprintNumber}`
                                }`}
                          </div>
                        </DropdownTrigger>
                        <DropdownMenu
                          selectedKeys={selectedSprint}
                          onSelectionChange={setSelectedSprint}
                          selectionMode="single"
                        >
                          <DropdownSection isReadOnly>
                            <DropdownItem
                              classNames={{
                                base: "gap-0 data-[selectable=true]:focus:bg-white dark:data-[selectable=true]:focus:bg-[#18181B]",
                                selectedIcon: "w-0",
                              }}
                              isReadOnly
                              key="new"
                            >
                              <Input
                                className="w-full"
                                size="sm"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search sprint.."
                                style={{ fontSize: "12px" }}
                              ></Input>
                            </DropdownItem>
                          </DropdownSection>
                          <DropdownSection className="overflow-y-auto max-h-[100px]">
                            {filteredSprints.map((item) => {
                              const iconDetail = basicIcons.filter(
                                (icon) =>
                                  icon.name.toLowerCase() ===
                                  item?.attributes?.Icon?.toLowerCase()
                              );
                              return (
                                <DropdownItem
                                  onMouseEnter={() => handleMouseEnter(item.id)}
                                  onMouseLeave={() => handleMouseLeave()}
                                  className="hover:bg-[#F4F4F5] dark:hover:bg-[#2D2D32]"
                                  isReadOnly
                                  key={item.id}
                                >
                                  <Popover
                                    isOpen={onHover === item.id}
                                    showArrow
                                    placement="left-start"
                                  >
                                    <PopoverTrigger>
                                      <div className="w-full text-xs flex gap-4 items-center">
                                        <svg
                                          xmlns={iconDetail[0]?.xmlns}
                                          fill={iconDetail[0]?.fill || "none"}
                                          viewBox={
                                            iconDetail[0]?.viewBox ||
                                            "0 0 24 24"
                                          }
                                          strokeWidth={
                                            iconDetail[0]?.strokeWidth || 1.5
                                          }
                                          stroke={
                                            iconDetail[0]?.stroke ||
                                            "currentColor"
                                          }
                                          className={
                                            iconDetail[0]?.className || "size-4"
                                          }
                                        >
                                          <path
                                            strokeLinecap={
                                              iconDetail[0]?.path?.d || "round"
                                            }
                                            strokeLinejoin={
                                              iconDetail[0]?.path?.d || "round"
                                            }
                                            d={
                                              iconDetail[0]?.path?.d ||
                                              "m4.5 12.75 6 6 9-13.5"
                                            }
                                          />
                                        </svg>
                                        <p>{item.attributes.Title}</p>

                                        {Array.from(selectedSprint)[0] ===
                                          item.id && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="size-4"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="m4.5 12.75 6 6 9-13.5"
                                            />
                                          </svg>
                                        )}
                                      </div>
                                    </PopoverTrigger>
                                    <PopoverContent className="flex flex-col items-center gap-2 justify-center px-2 py-2">
                                      <Button
                                        endContent={
                                          Array.from(selectedSprint)[0] ===
                                            item.id &&
                                          sprintNumber ===
                                            Number(item.attributes.Current) ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="size-4"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                              />
                                            </svg>
                                          ) : (
                                            <></>
                                          )
                                        }
                                        onClick={() =>
                                          handleSelectSprint(
                                            item.id,
                                            Number(item.attributes.Current)
                                          )
                                        }
                                        variant="light"
                                        size="sm"
                                        className="w-40"
                                      >
                                        <div className="w-full text-xs flex p-1 gap-2 items-center">
                                          <ScredriverWrench size="size-4" />

                                          <p>
                                            Sprint {item.attributes.Current}
                                          </p>
                                        </div>
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          handleSelectSprint(
                                            item.id,
                                            Number(item.attributes.Current) + 1
                                          )
                                        }
                                        endContent={
                                          Array.from(selectedSprint)[0] ===
                                            item.id &&
                                          sprintNumber ===
                                            Number(item.attributes.Current) +
                                              1 ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="size-4"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                              />
                                            </svg>
                                          ) : (
                                            <></>
                                          )
                                        }
                                        variant="light"
                                        size="sm"
                                        className="w-40"
                                      >
                                        <div className="w-full text-xs flex p-1 gap-2 items-center">
                                          <ChevronArrow size="size-4" />

                                          <p>
                                            Sprint{" "}
                                            {Number(item.attributes.Current) +
                                              1}
                                          </p>
                                        </div>
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          handleSelectSprint(item.id, "Backlog")
                                        }
                                        endContent={
                                          Array.from(selectedSprint)[0] ===
                                            item.id &&
                                          sprintNumber === "Backlog" ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="size-4"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                              />
                                            </svg>
                                          ) : (
                                            <></>
                                          )
                                        }
                                        variant="light"
                                        size="sm"
                                        className="w-40"
                                      >
                                        <div className="w-full text-xs flex gap-2 p-1 items-center">
                                          <WarningIcon size="size-4" />
                                          <p>Backlog</p>
                                        </div>
                                      </Button>
                                    </PopoverContent>
                                  </Popover>
                                </DropdownItem>
                              );
                            })}
                          </DropdownSection>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                )}

                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 6h.008v.008H6V6Z"
                      />
                    </svg>

                    <p className="text-sm text-center flex items-center">
                      Tags
                    </p>
                  </div>
                  <Tags
                    param={org}
                    customTags={customTags}
                    org={orgDetails?.id}
                    tags={tags}
                    setTags={setTags}
                    tagsDetail={tagsDetail}
                    setTagsDetail={setTagsDetail}
                  />
                </div>

                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <p className="text-sm gap-2 text-center flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                        />
                      </svg>
                      Target Close Date
                    </p>
                  </div>
                  <div className="w-40">
                    <DatePicker
                      value={
                        selectedDate === ""
                          ? null
                          : selectedDate === null
                          ? null
                          : parseDate(convertToDateStr(selectedDate))
                      }
                      selectedDate={selectedDate}
                      onChange={handleDateChange}
                      size="sm"
                    />
                  </div>
                </div>

                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>

                    <p className=" text-sm text-center flex items-center">
                      Owner
                    </p>
                  </div>
                  <div className="w-40">
                    <Button
                      size="sm"
                      variant="light"
                      className="w-40 justify-start"
                    >
                      <div className="flex gap-2 items-center">
                        <Avatar
                          color={Colors[Number(ownerInfo?.id) % numColors]}
                          style={{ width: "22px", height: "22px" }}
                          showFallback
                          radius="sm"
                          name={
                            ownerInfo?.name
                              ? ownerInfo?.name?.charAt(0).toUpperCase()
                              : ownerInfo?.username?.charAt(0).toUpperCase()
                          }
                          className="flex-shrink-0"
                          size="sm"
                          src={ownerInfo?.url}
                        />
                        <div className="flex flex-col">
                          <span className="text-xs">
                            {ownerInfo.name
                              ? ownerInfo.name
                              : ownerInfo.username}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>

                <div className="flex gap-2 items-center">
                  <div className="w-40 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                      />
                    </svg>

                    <p className=" text-sm text-center flex items-center">
                      Estimated Days
                    </p>
                  </div>
                  <div className="w-40">
                    <Input
                      onBlur={() => {
                        if (
                          selectedEstimatedDays &&
                          selectedEstimatedDays !== issue.estimatedays
                        ) {
                          handleUpdate(
                            { estimatedays: selectedEstimatedDays },
                            "estimateDays"
                          );
                        }
                      }}
                      classNames={inputClassNames}
                      value={selectedEstimatedDays}
                      variant="bordered"
                      onChange={(e) => setSelectedEstimatedDays(e.target.value)}
                      type="number"
                      size="sm"
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default IssuePage;
