import { Dropdown, DropdownItem, DropdownTrigger, DropdownMenu, Button } from '@nextui-org/react';
import React, { useEffect, useRef, useState } from 'react'
import FilterDropdown from '../../components/Filters/Filter';
import FilterPeople from '../../components/Filters/FilterPeople';
import { DROPDOWN, LONG_TEXT, MARKDOWN_TEXT, PEOPLE } from '../../utils/customAttributes';

function InboxFilter({search,setSearch,isCreated,setIsCreated,sortOrder,setSortOrder,selectedGroup,setSelectedGroup,customizeItems,setCustomizeItems,customizeCompleteList,filterArr,setFilterArr,template}) {
    const searchRef = useRef();
    const [isSearchOpen,setIsSearchOpen] = useState(false);
    const inputRef = useRef();
    const groupItems = ["None","Owner","Tags","Customer","Stage","Members"];
    const [filterDropdown , setFilterDropdowm] = useState(new Set([]));
    const handleClearFilters = () => {
      setSearch("");
      setIsCreated(false);
      setFilterArr((prev)=> prev.map((fil)=> ({...fil,val:[]})));
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            searchRef.current &&
            !searchRef.current.contains(event.target) &&
            (!inputRef.current || !inputRef.current.contains(event.target))
          ) {
            setIsSearchOpen(false);
          }
        };
        if (isSearchOpen && search === "") {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [isSearchOpen, search]);
  return (
    
      <div className="z-0 flex pb-4 mt-4 items-center justify-between">
          <div className="flex flex-wrap items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 dark:bg-[#121212] ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8 `}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
            <div
              onClick={(e) => setIsCreated(!isCreated)}
              className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${
                isCreated ? " bg-[#DBDADF] dark:bg-[#2D2D32]" : " hover:bg-[#DBDADF] dark:hover:bg-[#2D2D32]"
              } items-center justify-center`}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                />
              </svg>
            </div>
            {template
              .filter((a) => Array.from(filterDropdown).includes(String(a.id)))
              .map((item) => {
                if (item.type === PEOPLE) {
                  return (
                    <FilterPeople
                      filterArr={filterArr}
                      setFilterArr={setFilterArr}
                      boilerPlate={item}
                    />
                  );
                } else if (item.type === DROPDOWN) {
                  return (
                    <FilterDropdown
                      filterArr={filterArr}
                      setFilterArr={setFilterArr}
                      boilerPlate={item}
                    />
                  );
                }
              })}
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  variant="light"
                  className=" border border-divider"
                  isIconOnly
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={filterDropdown}
                onSelectionChange={setFilterDropdowm}
              >
                
                {template
                  .filter((a) => a.type === DROPDOWN || a.type === PEOPLE)
                  .map((it) => (
                    <DropdownItem key={String(it.id)}>{it.name}</DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              onClick={() => handleClearFilters()}
              size="sm"
              variant="light"
            >
              Clear
            </Button>
          </div>
          <div className="flex gap-2 items-center">
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Sort By
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Sort options"
                variant="flat"
                selectionMode="single"
                selectedKeys={sortOrder}
                onSelectionChange={setSortOrder}
              >
                <DropdownItem key="latest">Latest</DropdownItem>
                <DropdownItem key="oldest">Oldest</DropdownItem>
                <DropdownItem key="high">Highest Tickets</DropdownItem>
                <DropdownItem key="low">Lowest Tickets</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Group
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                className=" max-h-[200px] overflow-y-auto"
                aria-label="Single selection"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedGroup}
                onSelectionChange={setSelectedGroup}
              >
                {groupItems.map((group) => (
                  <DropdownItem key={group}>
                    <div className=" capitalize">{group}</div>
                  </DropdownItem>
                ))}
                {template
                  .filter(
                    (item) =>
                      item.type === DROPDOWN || item.type === PEOPLE
                  )
                  .map((it) => (
                    <DropdownItem key={`metadata_${it.id}`}>
                      {it.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Customize
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={customizeItems}
                onSelectionChange={setCustomizeItems}
              >
                {customizeCompleteList.map((name) => (
                  <DropdownItem key={name}>{name}</DropdownItem>
                ))}
                {template
                  .filter(
                    (item) =>
                      item.type !== MARKDOWN_TEXT && item.type !== LONG_TEXT
                  )
                  .map((it) => (
                    <DropdownItem key={`metadata_${it.id}`}>
                      {it.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
    
  )
}

export default InboxFilter
