import React from 'react'
import ConfigHeader from './Header'
import { lorem } from '../../utils/default'
import ConfigPages from './ConfigPages'
import { useSelector } from 'react-redux'
import { selectAdmin } from '../../redux/actions'

const Configuration = () => {
  const admin = useSelector(selectAdmin);
  if(admin){
    return (
      <div className="h-full overflow-y-auto flex flex-col w-full">
        <ConfigHeader/>
        <div className='flex w-full flex-col gap-4 py-8 px-8'>
          <div className='text-lg ' >Configuration</div>
          <div className='text-sm text-gray-400' >Select the pages you wish to hide from view; these pages will no longer be visible to others.</div>
        </div>
        <ConfigPages/>
        
      </div>
    )
  }
  
}

export default Configuration
