import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleDoc, patchData } from "../../controllers/vectorDB";
import { EditorProvider } from "@tiptap/react";
import {
  extensions,
  MenuBar,
  props,
} from "../../components/BlurprintEditor/BlueprintMarkdown";
import Bubble from "../../components/BlurprintEditor/Bubble";
import { useSelector } from "react-redux";
import { selectResData, selectUserNames } from "../../redux/actions";

function BlueprintDoc() {
  const { docId } = useParams();
  const { org } = useParams();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true);
  const [access,setAccess] = useState({public:false,writeAccess:"false"})
  const [writeAccess,setWriteAccess] = useState(false);
  const res = useSelector(selectResData);
  const userNames = useSelector(selectUserNames);
  const [owner,setOwner] = useState(null);
  const [permissions,setPermissions] = useState([]);
  const [title,setTitle] = useState("")
  const [imageIds, setImageIds] = useState([]);
  const [start, setStart] = useState(false);
  const [members, setMembers] = useState([]);
  const [descriptionContent, setDescriptionContent] = useState("");
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const resp = await getSingleDoc(docId, org);
        if (resp?.length > 0) {
          setDoc(resp[0]);
          setDescriptionContent(resp[0].payload.description);
          setMembers(resp[0].payload.authors);
          setTitle(resp[0].payload.title);
          setPermissions(resp[0].payload?.permissions);
          setOwner(resp[0].payload?.owner);
          setAccess(resp[0]?.payload?.access)
          if(resp[0]?.payload?.access?.public === false){
            const perm = resp[0]?.payload?.permissions?.find((mem) => String(mem?.id) === String(userDataId) );
            if(perm && perm?.permissions?.read){
              if(perm?.permissions?.write){
                setWriteAccess(true);
              }
            }
            else{
              navigate(`/${org}/blueprints`)
            }
          }
          else if(resp[0]?.payload?.access?.public && resp[0]?.payload?.access?.writeAccess){
            setWriteAccess(true);
          }
        } else {
          navigate(`/${org}/blueprints`)
        }
        setLoading(false);
      } catch (error) {}
    };
    if (docId && org) {
      fetchDoc();
    }
  }, [docId, org]);
  useEffect(() => {
    const updateDoc = async () => {
      if(!writeAccess || loading){
        return;
      }
      const arr = members;
      arr.push(userDataId);
      const mem = new Set(arr);
      const h1Content = descriptionContent.match(/<h1.*?>(.*?)<\/h1>/i);
      const textInsideH1 = h1Content ? h1Content[1].replace(/<[^>]*>/g, '') : '';
      try {
        await patchData(
          {
            description: descriptionContent,
            updatedAt: Date.now(),
            authors: Array.from(mem),
            title : textInsideH1
          },
          docId,
          org
        );
        setMembers(Array.from(mem));
        setTitle(textInsideH1)
      } catch (error) {}
    };
    if (
      descriptionContent !== "" &&
      doc &&
      userDataId &&
      org &&
      members.length > 0
    ) {
      const time = setTimeout(() => updateDoc(), 2000);
      return () => clearTimeout(time);
    }
  }, [descriptionContent]);
  return (
    <div className="flex flex-col gap-8 items-center">
      {(doc && !loading) && (
        <EditorProvider
          editorProps={props(!writeAccess)}
          slotBefore={
            <MenuBar
              members={members}
              res={res}
              access={access}
              setAccess={setAccess}
              owner={owner}
              id={docId}
              permissions={permissions}
              setPermissions={setPermissions}
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
              imageArray={imageIds}
              setImageArray={setImageIds}
              initial={start}
              setInitial={setStart}
            />
          }
          extensions={extensions(userNames)}
          content={descriptionContent}
        >
          <Bubble/>
        </EditorProvider>
      )}
    </div>
  );
}

export default BlueprintDoc;
