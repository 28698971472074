import React from 'react'

export const  Icon = () => {

  return (
    <div className='' >
                <svg width="100" height="85" viewBox="0 0 100 85"  fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.0771 4.42261L41.3146 19.1016" stroke="#3AB8FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.1362 20.2756C36.0807 23.5164 41.2355 28.4043 46.2988 22.0295" stroke="#66E389" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.5568 21.8545C52.3869 24.4344 56.5732 29.8231 58.6778 30.7381L63.3294 21.8545L73.4712 27.4592" stroke="#FFC702" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.4991 33.7688L62.0135 39.8691L57.4383 48.1046" stroke="#FF720D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.7992 48.8577C38.1076 48.8196 46.4955 50.7412 45.5805 58.7327C44.6654 66.7241 41.0561 67.807 39.3658 67.3494" stroke="#B062FF" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M45.5977 69.8381L52.3564 70.2285L50.144 80.872" stroke="#FF63DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.8993 72.8137C34.1541 75.1836 39.9883 79.0147 45.2866 75.3801" stroke="#66E389" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M71.6918 36.55L65.0447 30.8016" stroke="#B062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.50537 52.7269L18.4305 46.2834V58.7129L31.6225 52.7269L25.6365 68.2066" stroke="#3AB8FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M73.6003 17.3601L77.924 20.4531V26.5683L85.1773 27.5648" stroke="#66E389" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M74.0789 1.53563C72.0528 4.24462 68.4261 10.3518 70.1287 13.1086" stroke="#FF63DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.5563 43.688C74.6914 43.1524 74.3667 42.6088 73.8312 42.4738C73.2957 42.3388 72.7521 42.6634 72.617 43.199L71.263 48.5687L64.9581 46.9901C64.4223 46.8559 63.8793 47.1815 63.7451 47.7172C63.611 48.253 63.9366 48.796 64.4723 48.9302L70.774 50.508L69.4118 55.9107C69.2767 56.4462 69.6014 56.9898 70.1369 57.1248C70.6724 57.2599 71.216 56.9352 71.3511 56.3997L72.7142 50.9938L78.822 52.5232C79.3577 52.6573 79.9008 52.3317 80.0349 51.796C80.1691 51.2603 79.8435 50.7172 79.3077 50.5831L73.2031 49.0545L74.5563 43.688Z" fill="#3AB8FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.2484 35.5838C22.1654 35.0378 21.6555 34.6624 21.1095 34.7453C20.5635 34.8283 20.1881 35.3382 20.2711 35.8842L21.9106 46.6742C21.9935 47.2202 22.5034 47.5956 23.0494 47.5126C23.5955 47.4297 23.9708 46.9198 23.8879 46.3738L22.2484 35.5838ZM27.3703 35.6881C27.3223 35.1379 26.8373 34.7309 26.2871 34.7789C25.7369 34.827 25.3298 35.3119 25.3779 35.8621L26.1404 44.5932C26.1885 45.1434 26.6735 45.5505 27.2236 45.5025C27.7738 45.4544 28.1809 44.9694 28.1328 44.4192L27.3703 35.6881Z" fill="#FFEE59"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.2039 8.53903C12.9483 8.44298 12.6646 8.45559 12.4186 8.57393C12.1726 8.69226 11.9856 8.90604 11.9011 9.16565L8.24092 20.4132C8.07002 20.9383 8.35721 21.5026 8.88239 21.6735C9.40756 21.8444 9.97185 21.5572 10.1428 21.0321L13.4793 10.7792L23.5571 14.567C24.0741 14.7613 24.6507 14.4998 24.845 13.9828C25.0393 13.4658 24.7777 12.8892 24.2607 12.6949L13.2039 8.53903ZM16.7943 16.4509C16.7027 15.9062 16.187 15.5389 15.6424 15.6304C15.0977 15.722 14.7304 16.2377 14.8219 16.7823C15.3705 20.0465 18.4268 27.197 26.3457 30.3195C26.8594 30.5221 27.4402 30.2698 27.6428 29.756C27.8454 29.2422 27.5931 28.6615 27.0793 28.4589C19.9913 25.6641 17.2624 19.2362 16.7943 16.4509Z" fill="#B062FF"/>
<rect x="33.9769" y="32.6748" width="12.4883" height="12.4883" rx="6.24416" stroke="#3AB8FF" stroke-width="2" stroke-linejoin="round"/>
<rect x="52.9001" y="11.5523" width="7.76056" height="7.76055" rx="3.88028" transform="rotate(-14.0017 52.9001 11.5523)" stroke="#FF720D" stroke-width="2" stroke-linejoin="round"/>
<rect x="1.41421" y="67.5137" width="12.2178" height="12.2178" rx="6.1089" transform="rotate(-45 1.41421 67.5137)" stroke="#FF720D" stroke-width="2" stroke-linejoin="round"/>
<rect x="58.1693" y="73.026" width="14.0821" height="15.5771" rx="7.04107" transform="rotate(-51.7263 58.1693 73.026)" stroke="#B062FF" stroke-width="2" stroke-linejoin="round"/>
<circle cx="56.6065" cy="58.1968" r="5.36724" stroke="#FFC702" stroke-width="2" stroke-linejoin="round"/>
<path d="M15.6687 33.7676C15.6687 37.3425 12.7707 40.2405 9.19582 40.2405C5.62093 40.2405 2.7229 37.3425 2.7229 33.7676C2.7229 30.1927 5.62093 27.2947 9.19582 27.2947C12.7707 27.2947 15.6687 30.1927 15.6687 33.7676Z" stroke="#FF3465" stroke-width="2" stroke-linejoin="round"/>
<circle cx="31.3835" cy="6.82476" r="5.82476" stroke="#FF720D" stroke-width="2" stroke-linejoin="round"/>
<circle cx="84.6059" cy="37.0002" r="3.72777" stroke="#66E389" stroke-width="2" stroke-linejoin="round"/>
<circle cx="91.3452" cy="52.2003" r="7.65487" stroke="#FF63DD" stroke-width="2" stroke-linejoin="round"/>
</svg>
    </div>
  )
}

export default Icon;
