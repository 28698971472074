import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData } from "../../redux/actions";
import {
  DeleteSingleAttribute,
  getFilteredBySingleRelation,
} from "../../controllers/strapiController";
import Icon from "../../components/Tag/Icon";
import HelixLoader from "../../components/Loader/HelixLoader";
import { paginateArray, removePrefix } from "../../utils/default";
import {
  customizeTableCell,
  groupingCustomAttributes,
} from "../../utils/customAttributes";
import { renderaccountcell } from "./renderaccountcell";
import AccountsGroup from "./AccountsGroup";
import { useTheme } from "next-themes";

const AccountTable = ({
  search,
  setSelectedAccount,
  setIsOpenUpdate,
  filterArr,
  columns,
  template,
  grouping,
}) => {
  const orgData = useSelector(selectOrgData);
  const {theme} = useTheme();
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [group, setGroup] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const [loader, setLoader] = useState(true);
  const res = useSelector(selectResData);
  const [page,setPage] = useState(1);
  const handleDelete = async (id) => {
    try {
      await DeleteSingleAttribute("accounts", id);
      setAccounts((prev) => prev.filter((it) => String(it.id) !== String(id)));
    } catch (error) {}
  };
  useEffect(() => {
    if (
      Array.from(grouping).length > 0 &&
      Array.from(grouping)[0] !== "None" &&
      Array.from(grouping)[0].startsWith("metadata_")
    ) {
      const finalGroup = {};
      filteredAccounts.map((item) => {
        groupingCustomAttributes(
          Array.from(grouping)[0],
          template,
          item.attributes.metadata,
          finalGroup,
          item,
          setGroupType
        );
      });
      setGroup(finalGroup);
    }
  }, [grouping]);
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "accounts",
          "organization",
          orgData.id
        );
        setAccounts(resp.data);
        setFilteredAccounts(resp.data);
        setLoader(false);
      } catch (error) {}
    };
    if (orgData.id) {
      fetchAccounts();
    }
  }, [orgData]);
  useEffect(() => {
    let filter = accounts.filter(
      (item) =>
        item.attributes.Name.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.Website.toLowerCase().includes(search.toLowerCase())
    );
    filterArr.map((item) => {
      if (item?.val?.length > 0) {
        filter = filter.filter((t) => {
          if (t.attributes.metadata && Array.isArray(t.attributes.metadata)) {
            const find = t.attributes.metadata.find(
              (field) => String(field.id) === String(item.id)
            );
            if (find) {
              return find?.val?.some((m) => item.val.includes(m));
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      }
    });
    setFilteredAccounts(filter);
  }, [search, accounts, filterArr]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
      </div>
    );
  }
  return (
    <div className="flex-1 w-full overflow-y-auto">
      {Array.from(grouping).length === 0 ||
      Array.from(grouping)[0] === "None" ? (
        <div className="w-full h-full  flex flex-col px-6 pt-4">
          {filteredAccounts?.length > 0 ? (
            <>
            <Table removeWrapper>
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn key={column.id} className="text-xs">
                    {column.id.startsWith("metadata_")
                      ? template?.find(
                          (it) => it.id === removePrefix(column.id, "metadata_")
                        )?.name ?? ""
                      : column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody>
                {paginateArray(filteredAccounts,10,page-1)?.map((item) => (
                  <TableRow
                    className={`cursor-pointer text-xs rounded-lg ${ theme === "dark" ? "hover:bg-[#2D2D32]" : "hover:bg-gray-100"} border-b border-divider`}
                    onClick={() => {
                      setSelectedAccount(item);
                      setIsOpenUpdate(true);
                    }}
                    key={item.id}
                  >
                    {columns.map((column) => {
                      if (column.id.startsWith("metadata_")) {
                        return customizeTableCell(
                          column.id,
                          template,
                          item.attributes.metadata,
                          res
                        );
                      } else {
                        return renderaccountcell(column.id, item, handleDelete);
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {Math.ceil(filteredAccounts?.length/10) > 1 && <div className="w-full flex items-center justify-center" >
            <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="danger"
                  page={page}
                  total={Math.ceil(filteredAccounts?.length/10)}
                  onChange={setPage}
                  className="mt-4"
                />
            </div>}
            </>
          ) : (
            <div className="flex-1 flex flex-col relative w-full h-full text-xs gap-2 justify-center items-center text-slate-400">
              <Icon />
              No Accounts present
            </div>
          )}
        </div>
      ) : Array.from(grouping)[0].startsWith("metadata_") ? (
        <AccountsGroup handleDelete={handleDelete} group={group} type={groupType} columns={columns} setIsOpenUpdate={setIsOpenUpdate} setSelectedAccount={setSelectedAccount} template={template} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AccountTable;
