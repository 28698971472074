import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  BreadcrumbItem,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Table,
  TableHeader,
  TableColumn,
  TableCell,
  TableBody,
  TableRow,
  Avatar,
  Pagination,
} from "@nextui-org/react";
import InviteModal from "./modal";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import InviteDropdown from "./InviteFilter";
import Icon from "../../components/Tag/Icon";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { useSelector } from "react-redux";
import {
  selectOrgData,
  selectPermissions,
  selectResData,
  selectUserData,
} from "../../redux/actions";
import { paginateArray } from "../../utils/default";

function Invitations() {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const [anyofKeys, setAnyofKeys] = useState(new Set(["Any of"]));
  const numColors = Colors.length;
  const [isOpen, setOpen] = useState(false);
  const orgId = useSelector(selectOrgData);
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const [page, setPage] = useState(1);
  const [invitations, setInvitations] = useState([]);
  const [filteredInvitations, setFilteredInvitations] = useState([]);
  const [filterState, setFilterState] = useState(new Set([]));
  const [filterGroup, setFilterGroup] = useState(new Set([]));
  const [filterInvite, setFilterInvite] = useState(new Set([]));
  const States = ["Pending", "Deleted", "Expired", "Accepted"];
  const userPerm = useSelector(selectPermissions)?.find(
    (it) => it?.name === "Role-15"
  );

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const partarray = await getFilteredBySingleRelation(
          "invitations",
          "organization",
          orgId.id
        );
        setInvitations(partarray.data);
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgId) {
      getAllDataByIds();
    }
  }, [orgId]);

  useEffect(() => {
    if (invitations.length > 0) {
      let finalInv = invitations;
      if (Array.from(filterState).length > 0) {
        finalInv = finalInv.filter((item) =>
          Array.from(filterState).includes(item.attributes.Status)
        );
      }
      if (Array.from(anyofKeys)[0] === "Any of") {
        if (Array.from(filterInvite).length > 0) {
          finalInv = finalInv.filter((item) =>
            Array.from(filterInvite).includes(
              String(item.attributes.invitedBy.data.id)
            )
          );
        }
      }
      if (Array.from(anyofKeys)[0] === "None of") {
        if (Array.from(filterInvite).length > 0) {
          finalInv = finalInv.filter(
            (item) =>
              !Array.from(filterInvite).includes(
                String(item.attributes.invitedBy.data.id)
              )
          );
        }
      }
      setFilteredInvitations(finalInv);
    }
  }, [invitations, filterState, filterGroup, filterInvite, anyofKeys]);

  return (
    <div className="h-[100%]">
      <InviteModal
        orgid={orgId}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
      <div className="relative flex flex-col bg-white dark:bg-black">
        <div className="top-0 flex flex-col border-b border-divider px-4 py-6 z-10 sticky bg-white dark:bg-black">
          <div className="flex items-center justify-between">
            <div className=" flex text-center justify-center items-center">
              <Breadcrumb />
              <Breadcrumbs size="sm" isDisabled>
                <BreadcrumbItem>Settings</BreadcrumbItem>
                <BreadcrumbItem>User Management</BreadcrumbItem>
                <BreadcrumbItem>Invitations</BreadcrumbItem>
              </Breadcrumbs>
            </div>
            {userPerm?.privileges?.[0]?.create && (
              <Button
                onClick={() => setOpen(true)}
                size="sm"
                className="text-white bg-[rgb(51,51,245)]"
              >
                + Invite
              </Button>
            )}
          </div>
        </div>
        <div className="flex p-4 items-center justify-start gap-4">
          <div className="flex border border-divider rounded-sm  ">
            <div className=" flex items-center">
              <h2 className="text-xs ml-2 mr-2">State</h2>
              <div className="border-l border-divider h-full " />
            </div>
            <div className="  rounded-r-md text-xs">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    radius="none"
                    variant="light"
                    className="text-xs"
                  >
                    {filterState.size > 0 ? (
                      <p className="font-light text-xs">
                        {Array.from(filterState)[0]}
                      </p>
                    ) : (
                      <p className="font-light text-xs">Add</p>
                    )}{" "}
                    {filterState.size > 1 ? (
                      <p className="font-light text-xs">{`+${
                        Array.from(filterState).length - 1
                      }`}</p>
                    ) : (
                      ""
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  selectedKeys={filterState}
                  onSelectionChange={setFilterState}
                >
                  {States.map((state) => (
                    <DropdownItem className="font-light text-xs" key={state}>
                      {state}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex border border-divider rounded-sm  ">
            <div className=" flex items-center">
              <h2 className="text-xs ml-2 mr-2">Groups</h2>
              <div className="border-l border-divider h-full " />
            </div>
            <div className="  rounded-r-md text-xs">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    radius="none"
                    variant="light"
                    className="text-xs"
                  >
                    {filterGroup.size > 0 ? (
                      <p className="font-light text-xs">
                        {Array.from(filterGroup)[0]}
                      </p>
                    ) : (
                      <p className="font-light text-xs">Add</p>
                    )}{" "}
                    {filterGroup.size > 1 ? (
                      <p className="font-light text-xs">{`+${
                        Array.from(filterGroup).length - 1
                      }`}</p>
                    ) : (
                      ""
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  selectedKeys={filterGroup}
                  onSelectionChange={setFilterGroup}
                >
                  {States.map((state) => (
                    <DropdownItem className="font-light text-xs" key={state}>
                      {state}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex border border-divider rounded-sm  ">
            <div className=" flex items-center">
              <h2 className="text-xs ml-2 mr-2">Invited by</h2>
              <div className="border-l border-divider h-full " />
            </div>
            <div className=" flex  rounded-r-md text-xs">
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="light" size="sm" className=" text-xs font-light">
                    {Array.from(anyofKeys)[0]}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Single selection example"
                  variant="flat"
                  closeOnSelect={false}
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={anyofKeys}
                  onSelectionChange={setAnyofKeys}
                >
                  <DropdownItem key="Any of">Any of</DropdownItem>
                  <DropdownItem key="None of">None of</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <div className="border-l border-divider h-full " />
              <InviteDropdown
                res={res}
                users={users}
                dropdownOwner={filterInvite}
                setDropdownOwner={setFilterInvite}
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          {filteredInvitations.length === 0 ? (
            <p className="flex flex-col mt-60 text-xs gap-2  justify-center items-center  text-slate-400">
              <Icon />
              Your organization has no Invitations
            </p>
          ) : (
            <>
              <Table removeWrapper>
                <TableHeader>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Email
                    </div>
                  </TableColumn>

                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Groups
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Status
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Invited by
                    </div>
                  </TableColumn>
                </TableHeader>
                <TableBody>
                  {paginateArray(filteredInvitations,10,page-1).map((invitation) => (
                    <TableRow
                      key={invitation.id}
                      className=" border-b border-divider"
                    >
                      <TableCell>
                        <div className="items-center flex gap-4 text-xs font-light">
                          {invitation.attributes.Email}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center text-xs font-light">
                          {invitation.attributes.groups.data[0].attributes.Name}
                          <span>
                            {invitation.attributes.groups.data.length > 1
                              ? `+${
                                  invitation.attributes.groups.data.length - 1
                                }`
                              : ""}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center text-xs font-light ">
                          {invitation?.attributes?.Status}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center flex gap-4 text-xs font-light">
                          <Avatar
                            showFallback
                            name={
                              res &&
                              res[
                                String(
                                  invitation?.attributes?.invitedBy?.data?.id
                                )
                              ] &&
                              (res[
                                String(
                                  invitation?.attributes?.invitedBy?.data?.id
                                )
                              ].FirstName
                                ? `${res[
                                    String(
                                      invitation?.attributes?.invitedBy?.data
                                        ?.id
                                    )
                                  ].FirstName?.charAt(0)}`
                                : `${res[
                                    String(
                                      invitation?.attributes?.invitedBy?.data
                                        ?.id
                                    )
                                  ].UserName?.charAt(0)}`)
                            }
                            radius="sm"
                            size="sm"
                            color={
                              Colors[
                                Number(
                                  invitation?.attributes?.invitedBy?.data?.id
                                ) % numColors
                              ]
                            }
                            src={
                              res &&
                              res[
                                String(
                                  invitation?.attributes?.invitedBy?.data?.id
                                )
                              ] &&
                              res[
                                String(
                                  invitation?.attributes?.invitedBy?.data?.id
                                )
                              ].ProfilePicture
                                ? process.env.REACT_APP_STAGE === "Dev"
                                  ? `${
                                      process.env.REACT_APP_STRAPI_IP_ADDRESS
                                    }${
                                      res[
                                        String(
                                          invitation?.attributes?.invitedBy
                                            ?.data?.id
                                        )
                                      ].ProfilePicture
                                    }`
                                  : `${
                                      res[
                                        String(
                                          invitation?.attributes?.invitedBy
                                            ?.data?.id
                                        )
                                      ].ProfilePicture
                                    }`
                                : ""
                            }
                          />
                          {
                            invitation.attributes.invitedBy?.data?.attributes
                              ?.email
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="flex items-center w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="danger"
                  page={page}
                  total={Math.ceil(filteredInvitations?.length / 10)}
                  onChange={setPage}
                  className="mt-4"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Invitations;
