import React, { useMemo } from "react";
import { closed } from "../../utils/ticketconstants";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useParts from "../../hooks/useParts";
import { ThemeColors, trimString } from "../../utils/default";
import { useTheme } from "next-themes";
import { useSelector } from "react-redux";
import { selectResData, selectUserData } from "../../redux/actions";
import { renderUserName } from "../../components/AvatarRender/Avatar";


const CustomBar = ({ fill, x, y, width, height, radius = 10 }) => {
    const barWidth = 40; // Set a consistent width for each bar
    const centeredX = x + (width - barWidth) / 2; // Center the bar
  
    return (
      <rect
        x={centeredX}
        y={y}
        width={barWidth}
        height={height}
        rx={radius} // Horizontal corner radius
        ry={radius} // Vertical corner radius
        fill={fill}
      />
    );
  };
export const ChartFifth = ({ tickets }) => {
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const data = useMemo(() => {
    const usersTickets = {};

    tickets.map((it) => {
      if (!closed.includes(it?.payload?.stage)) {
        if (usersTickets[String(it?.payload?.assigned)]) {
          usersTickets[String(it?.payload?.assigned)].Ticket_count += 1;
        } else {
          usersTickets[String(it?.payload?.assigned)] = {
            name: renderUserName(it?.payload?.assigned, res),
            Ticket_count: 1,
          };
        }
      }
    });
    const finalData = [];
    Object.keys(usersTickets).map((it) => {
      finalData.push({
        name: usersTickets[it].name,
        value: usersTickets[it].Ticket_count,
        fill: ThemeColors[Number(it) % ThemeColors.length],
      });
    });
    finalData.sort((a, b) => b.value - a.value);
    return finalData.slice(0, 3);
  }, [tickets, users, res]);
  return (
    <PieChart width={600} height={300}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
      />

      <Tooltip
        contentStyle={{
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "8px",
          border: "none",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          padding: "10px",
        }}
        wrapperStyle={{ outline: "none" }}
        itemStyle={{ color: "#ddd" }}
        labelStyle={{ fontWeight: "bold", color: "#fff" }}
        formatter={(value) => `${value} tickets`}
      />
      <Legend
        wrapperStyle={{
          marginTop: "-20px", // Adjust this value to increase the space
        }}
      />
    </PieChart>
  );
};

export const ChartFourth = ({ tickets, convos }) => {
  const data = useMemo(() => {
    const activeTickets = tickets.filter(
      (it) => !closed.includes(it?.payload?.stage)
    );
    const customerTickets = {};
    for (let i = 0; i < convos.length; i++) {
      for (let j = 0; j < convos[i].payload.tickets.length; j++) {
        if (
          convos[i]?.payload?.customerMail &&
          activeTickets.some(
            (it) => it.payload.id === convos[i]?.payload?.tickets[j].id
          )
        ) {
          if (customerTickets[convos[i]?.payload?.customerMail]) {
            if (
              !customerTickets[
                convos[i]?.payload?.customerMail
              ].tickets.includes(convos[i]?.payload?.tickets[j].id)
            ) {
              customerTickets[convos[i]?.payload?.customerMail].tickets.push(
                convos[i]?.payload?.tickets[j].id
              );
            }
          } else {
            customerTickets[convos[i]?.payload?.customerMail] = {
              name: convos[i]?.payload?.customerMail,
              tickets: [convos[i]?.payload?.tickets[j].id],
            };
          }
        }
      }
    }
    const finalData = [];
    Object.keys(customerTickets).map((it) => {
      finalData.push({
        name: it,
        value: customerTickets[it].tickets.length,
        fill: ThemeColors[Number(it.length) % ThemeColors.length],
      });
    });
    return finalData.slice(0, 3);
  }, [tickets, convos]);
  return (
    <PieChart width={600} height={300}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
      />

      <Tooltip
        contentStyle={{
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "8px",
          border: "none",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          padding: "10px",
        }}
        wrapperStyle={{ outline: "none" }}
        itemStyle={{ color: "#ddd" }}
        labelStyle={{ fontWeight: "bold", color: "#fff" }}
        formatter={(value) => `${value} tickets`}
      />
      <Legend
        payload={data.map((item, index) => ({
          id: item.name,
          type: "square",
          value: `${trimString(item.name,20)}`,
          color: item.fill,
        }))}
      />
    </PieChart>
  );
};

export const ChartThird = ({ tickets }) => {
  const { theme } = useTheme();
  const partData = useParts();
  const data = useMemo(() => {
    const convertedParts = {};
    const ticketsPerPart = {};
    partData.forEach((item) => {
      ticketsPerPart[String(item.id)] = {
        Name: item.attributes?.Name,
        Ticket_count: 0,
      };
      convertedParts[String(item.id)] = {
        Name: item.attributes?.Name,
        Type: item.attributes?.Type,
        ParentPath: item.attributes?.ParentPath,
        Owner: item.attributes?.users_permissions_user?.data?.id,
      };
    });

    tickets.map((it) => {
      if (
        !closed.includes(it?.payload?.stage) &&
        ticketsPerPart[String(it?.payload?.part)]
      ) {
        ticketsPerPart[String(it?.payload?.part)].Ticket_count += 1;
      }
    });
    const finalData = [];
    Object.keys(ticketsPerPart).map((it) => {
      finalData.push({
        name: ticketsPerPart[it].Name,
        tickets: ticketsPerPart[it].Ticket_count,
        fill: ThemeColors[Number(it) % ThemeColors.length],
      });
    });
    finalData.sort((a, b) => b.tickets - a.tickets);
    return finalData.slice(0, 10);
  }, [partData, tickets]);
  return (
    <BarChart width={1000} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip
        contentStyle={{
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "8px",
          border: "none",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          padding: "10px",
        }}
        wrapperStyle={{ outline: "none" }}
        itemStyle={{ color: "#ddd" }}
        labelStyle={{ fontWeight: "bold", color: "#fff" }}
        formatter={(value) => `${value} tickets`}
        cursor={{ fill: theme === "dark" ? "#4B5563" : "#E5E7EB" }}
      />
      <Bar dataKey="tickets" shape={<CustomBar />}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Bar>
      <Legend
        payload={data.map((item, index) => ({
          id: index,
          type: "square",
          value: `${trimString(item.name,20)}`,
          color: item.fill,
        }))}
      />
    </BarChart>
  );
};

export const ChartsSecond = ({ tickets }) => {
  const data = useMemo(() => {
    const activeTickets = tickets.filter(
      (it) => !closed.includes(it?.payload?.stage)
    );

    return [
      {
        name: "Active Tickets",
        value: activeTickets.length,
        fill: "#8884d8",
      },
      {
        name: "Closed Tickets",
        value: tickets.length - activeTickets.length,
        fill: "#82ca9d",
      },
    ];
  }, [tickets]);
  return (
    <PieChart width={400} height={300}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
      />

      <Tooltip
        contentStyle={{
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "8px",
          border: "none",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          padding: "10px",
        }}
        wrapperStyle={{ outline: "none" }}
        itemStyle={{ color: "#ddd" }}
        labelStyle={{ fontWeight: "bold", color: "#fff" }}
        formatter={(value) => `${value} tickets`}
      />
      <Legend
        wrapperStyle={{
          marginTop: "-20px", // Adjust this value to increase the space
        }}
      />
    </PieChart>
  );
};

const ChartsFirst = ({ tickets, convos }) => {
  const data = useMemo(() => {
    const activeTickets = tickets.filter(
      (it) => !closed.includes(it?.payload?.stage)
    );
    let attachedTickets = convos.flatMap(
      (it) => it?.payload?.tickets?.map((t) => t.id) || []
    );
    attachedTickets = Array.from(new Set(attachedTickets));
    const activeAttachedTickets = attachedTickets?.filter((it) =>
      activeTickets.some((a) => a.payload.id === it)
    );
    return [
      {
        name: "Linked Tickets",
        value: activeAttachedTickets.length,
        fill: "#8884d8",
      },
      {
        name: "Unlinked Tickets",
        value: activeTickets.length - activeAttachedTickets.length,
        fill: "#82ca9d",
      },
    ];
  }, [tickets, convos]);
  return (
    <PieChart width={400} height={300}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
      />
      <Tooltip
        contentStyle={{
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "8px",
          border: "none",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          padding: "10px",
        }}
        wrapperStyle={{ outline: "none" }}
        itemStyle={{ color: "#ddd" }}
        labelStyle={{ fontWeight: "bold", color: "#fff" }}
        formatter={(value) => `${value} tickets`}
      />
      <Legend
        wrapperStyle={{
          paddingTop: "-20px", // Adjust this value to increase the space
        }}
      />
    </PieChart>
  );
};

export default ChartsFirst;
